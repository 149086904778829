"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    staticClass: "custom-reports-page",
    attrs: {
      menu: _vm.menuItems.children,
      "sub-menu": _vm.menuItems.subMenu,
      "return-text": "Lista de fazendas",
      "return-menu": _vm.menuItems.path
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h2", {
    staticClass: "header-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.customReports.title")) + "\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "search-bar"
  }, [_c("div", {
    staticClass: "field search-input"
  }), _vm._v(" "), _c("div", {
    staticClass: "field-new-item"
  }, [_c("b-button", {
    on: {
      click: function click($event) {
        return _vm.openModalNew();
      }
    }
  }, [_vm._v("\n            Nova venda\n          ")])], 1)])]), _vm._v(" "), _vm.list.length ? _c("div", [_c("listagem-romaneio")], 1) : _c("no-record-found", {
    attrs: {
      title: "Não foram encontrados romaneios lançados"
    }
  })], 1)], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;