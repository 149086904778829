"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformDate = transformDate;
/**
 * sort array by valu propertie
 * @param {string} date
 * @param {string} format
 * @returns {string}
 */
function transformDate(date) {
  var v = date.replace(/\D/g, '').slice(0, 10);
  if (v.length >= 5) {
    return "".concat(v.slice(0, 2), "/").concat(v.slice(2, 4), "/").concat(v.slice(4));
  } else if (v.length >= 3) {
    return "".concat(v.slice(0, 2), "/").concat(v.slice(2));
  }
  return v;
}