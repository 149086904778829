"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-nutritional-information"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h3", [_vm._v("INFORMAÇÃO NUTRICIONAL")]), _vm._v(" "), _c("h4", [_vm._v("Quantidade por porção de " + _vm._s(_vm.information.portion))])]), _vm._v(" "), _c("div", {
    staticClass: "content-card"
  }, _vm._l(_vm.itensList, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "columns is-mobile"
    }, [_c("div", {
      staticClass: "column is-two-thirds"
    }, [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]), _vm._v(" "), _c("div", {
      staticClass: "column"
    }, [_vm._v("\n        " + _vm._s(_vm.information[item.percent] || "") + "\n      ")]), _vm._v(" "), _c("div", {
      staticClass: "column is-one-fifth center"
    }, [idx === 0 ? _c("span", {
      staticClass: "detail-label"
    }, [_vm._v("%VD*")]) : _vm._e(), _vm._v("\n        " + _vm._s(_vm.information[item.vd] || "**") + "%\n      ")])]);
  }), 0), _vm._v(" "), _c("div", {
    staticClass: "footer"
  }, [_vm._v("\n    (*) Valores diários de refêrencia com base em dieta de 2000kcal ou 8400KJ. Seus valores diários podem ser maiores ou menores dependendo de suas necessidades energéticas.\n  ")])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;