"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "modal-content modal-user-detail-team"
  }, [_c("div", {
    staticClass: "card"
  }, [_c("header", {
    staticClass: "card-header"
  }, [_c("p", {
    staticClass: "card-header-title"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.team.modal_detail.title")) + "\n        ")]), _vm._v(" "), _c("a", {
    staticClass: "card-header-icon",
    attrs: {
      "aria-label": "close detail"
    }
  }, [_c("b-dropdown", {
    attrs: {
      "aria-role": "list",
      position: _vm.position
    }
  }, [_vm.user.role !== "Owner" ? _c("b-button", {
    staticClass: "btn-click-dots",
    attrs: {
      slot: "trigger",
      outlined: ""
    },
    slot: "trigger"
  }, [_c("i", {
    staticClass: "icon-ic_more_horizontal btn-more"
  })]) : _vm._e(), _vm._v(" "), _c("b-dropdown-item", {
    attrs: {
      "aria-role": "listitem"
    },
    on: {
      click: function click($event) {
        return _vm.prepareAction(_vm.actions.DISABLED, _vm.user);
      }
    }
  }, [_vm._v("\n              " + _vm._s(_vm.user.activated ? _vm.$t("pages.team.dropdown.disable") : _vm.$t("pages.team.dropdown.active")) + "\n            ")]), _vm._v(" "), _vm.user.activated ? _c("b-dropdown-item", {
    attrs: {
      "aria-role": "listitem"
    },
    on: {
      click: function click($event) {
        return _vm.prepareAction(_vm.actions.EDIT, _vm.user);
      }
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t("pages.team.dropdown.edit")) + "\n            ")]) : _vm._e(), _vm._v(" "), _vm.user.activated ? _c("b-dropdown-item", {
    attrs: {
      "aria-role": "listitem"
    },
    on: {
      click: function click($event) {
        return _vm.prepareAction(_vm.actions.DELETE, _vm.user);
      }
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t("pages.team.dropdown.delete")) + "\n            ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("a", {
    staticClass: "card-header-icon",
    attrs: {
      "aria-label": "close detail"
    },
    on: {
      click: _vm.cancelModal
    }
  }, [_vm._m(0)])]), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "image-details"
  }, [_c("div", {
    staticClass: "image-details__img"
  }, [_vm.user.imageUrl ? _c("img", {
    attrs: {
      src: _vm.user.imageUrl
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/images/user-icon.png")
    }
  })]), _vm._v(" "), _c("div", {
    staticClass: "image-details__data"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("h1", [_vm._v("\n                " + _vm._s("".concat(_vm.user.firstName, " ").concat(_vm.user.lastName)) + "\n              ")]), _vm._v(" "), _c("span", {
    class: "row--branch ".concat(!_vm.user.activated ? "row--branch-inactive" : "")
  }, [_vm._v("\n                " + _vm._s(_vm._f("translateActivatedDescription")(_vm.user.activated)) + "\n              ")])]), _vm._v(" "), _c("p", {
    staticClass: "image-details__data--role"
  }, [_vm._v("\n              " + _vm._s(_vm._f("convertRole")(_vm.user.role)) + "\n            ")]), _vm._v(" "), _c("p", {
    staticClass: "image-details__data--registration"
  }, [_vm._v("\n              Nº " + _vm._s(_vm.user.registration) + "\n            ")])])]), _vm._v(" "), _c("div", {
    staticClass: "user-data"
  }, [_c("div", {
    staticClass: "user-data__row columns"
  }, [_c("div", {
    staticClass: "user-data__row--coll column"
  }, [_c("label", [_vm._v("\n                " + _vm._s(_vm.$t("pages.team.modal_detail.cpf")) + "\n              ")]), _vm._v(" "), _c("p", [_vm._v("\n                " + _vm._s(_vm._f("maskCPF")(_vm.user.cpf)) + "\n              ")])]), _vm._v(" "), _c("div", {
    staticClass: "user-data__row--coll column"
  }, [_c("label", [_vm._v("\n                " + _vm._s(_vm.$t("pages.team.modal_detail.email")) + "\n              ")]), _vm._v(" "), _c("p", [_vm._v("\n                " + _vm._s(_vm.user.email) + "\n              ")])]), _vm._v(" "), _c("div", {
    staticClass: "user-data__row--coll column"
  }, [_c("label", [_vm._v("\n                " + _vm._s(_vm.$t("pages.team.modal_detail.phone")) + "\n              ")]), _vm._v(" "), _c("p", [_vm._v("\n                " + _vm._s(_vm.user.cpf) + "\n              ")])])]), _vm._v(" "), _c("div", {
    staticClass: "user-data__row columns"
  }, [_c("div", {
    staticClass: "user-data__row--coll column"
  }, [_c("label", [_vm._v("\n                " + _vm._s(_vm.$t("pages.team.modal_detail.temporary")) + "\n              ")]), _vm._v(" "), _c("p", [_vm._v("\n                " + _vm._s(_vm.user.temporary ? _vm.$t("pages.team.text_yes") : _vm.$t("pages.team.text_no")) + "\n              ")])]), _vm._v(" "), _c("div", {
    staticClass: "user-data__row--coll column"
  }, [_c("label", [_vm._v("\n                " + _vm._s(_vm.$t("pages.team.modal_detail.access_valid")) + "\n              ")]), _vm._v(" "), _c("p", [_vm._v("\n                " + _vm._s(_vm._f("date")(_vm.user.temporaryExpirationDate || _vm.$t("pages.team.undetermined"))) + "\n              ")])]), _vm._v(" "), _c("div", {
    staticClass: "user-data__row--coll column"
  }, [_c("label", [_vm._v("\n                " + _vm._s(_vm.$t("pages.team.modal_detail.Schooling")) + "\n              ")]), _vm._v(" "), _c("p", [_vm._v("\n                " + _vm._s(_vm._f("translateSchooling")(_vm.user.scholarship)) + "\n              ")])])])]), _vm._v(" "), _c("div", {
    staticClass: "action"
  }, [_c("b-button", {
    on: {
      click: function click($event) {
        return _vm.print(_vm.idElement);
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.team.modal_detail.btn_print")) + "\n          ")])], 1)])])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      id: _vm.idElement
    }
  }, [_c("user-badge", {
    attrs: {
      user: _vm.user
    }
  })], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "icon"
  }, [_c("i", {
    staticClass: "icon-ic_close"
  })]);
}];
render._withStripped = true;