"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    staticClass: "products-types-page",
    attrs: {
      "show-return": false,
      "return-enable": false,
      menu: _vm.menuAdmin.children,
      "sub-menu": _vm.menuAdmin.subMenu,
      "sub-menu-three": _vm.menuAdmin.childrenThree,
      subtitle: "Serviços",
      "sub-menu-title": "Ocorrências",
      "sub-menu-title-three": "Produtos",
      "title-menu": "Gestão FIBOO"
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h2", {
    staticClass: "header-title"
  }, [_vm._v("\n        Tipos de produtos\n      ")])]), _vm._v(" "), _c("div", {
    staticClass: "content-items-list"
  }, [_c("div", {
    staticClass: "columns"
  }, _vm._l(_vm.colsItems, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "column is-4"
    }, [_c("div", {
      staticClass: "col-list-items"
    }, [_c("h2", {
      staticClass: "title-col"
    }, [_vm._v("\n              " + _vm._s(item.title) + "\n            ")]), _vm._v(" "), _c("input-filter-modal", {
      attrs: {
        placeholder: item.placeholder
      },
      model: {
        value: _vm.search,
        callback: function callback($$v) {
          _vm.search = $$v;
        },
        expression: "search"
      }
    }), _vm._v(" "), !_vm.verifyList(item.type).length ? _c("h4", {
      staticClass: "text-not-found"
    }, [_vm._v("\n              " + _vm._s(item.title) + " não encontrato! Deseja cadastrar no sistema?\n            ")]) : _vm._e(), _vm._v(" "), _vm.verifyList(item.type) ? _c("div", {
      staticClass: "list-items"
    }, _vm._l(_vm.verifyList(item.type), function (itemProd, idxProd) {
      return _c("div", {
        key: idxProd,
        staticClass: "list-item",
        class: {
          active: _vm.verifyActive(item.type, itemProd.id)
        },
        on: {
          click: function click($event) {
            return _vm.selectItem(item.type, itemProd.id);
          }
        }
      }, [_c("div", [_vm._v("\n                  " + _vm._s(itemProd.name) + "\n                  "), _c("img", {
        attrs: {
          src: require("@/assets/images/atoms-ic_arrow_right.png")
        }
      })])]);
    }), 0) : _vm._e(), _vm._v(" "), _vm.verifyShow(item.type) ? _c("div", {
      staticClass: "ctd-buttons",
      on: {
        click: function click($event) {
          return _vm.verifyAction(item.type);
        }
      }
    }, [_c("b-button", [_vm._v("\n                + Adicionar novo " + _vm._s(item.title) + "\n              ")])], 1) : _vm._e()], 1)]);
  }), 0)])])], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;