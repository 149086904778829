"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "payment-page-details-pay"
  }, [_c("div", {
    staticClass: "context-paymnet"
  }, [_c("div", {
    staticClass: "payment-details"
  }, [_c("button-actions-header", {
    attrs: {
      "farm-name": _vm.farm.name,
      "verify-area": _vm.verifyArea,
      "show-update-signature": _vm.showUpdateSignature,
      "update-signature": _vm.updateSignature,
      "confirm-cancel": _vm.confirmCancel,
      "go-to-back": _vm.goToBack
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "farms-details-signature"
  }, [_vm._m(0), _vm._v(" "), _c("card-description-plan", {
    attrs: {
      payment: _vm.itemPayment,
      plan: _vm.plan,
      area: _vm.totalArea.area
    }
  }), _vm._v(" "), _vm.showUpdateSignature ? _c("content-cards-plans", {
    attrs: {
      area: _vm.totalArea.area,
      farm: _vm.itemPayment,
      "type-payment": _vm.typePayment,
      "form-of-payment": _vm.formOfPayment,
      "select-plan": _vm.selectPaymentPlan
    },
    on: {
      "update:typePayment": function updateTypePayment($event) {
        _vm.typePayment = $event;
      },
      "update:type-payment": function updateTypePayment($event) {
        _vm.typePayment = $event;
      },
      "update:formOfPayment": function updateFormOfPayment($event) {
        _vm.formOfPayment = $event;
      },
      "update:form-of-payment": function updateFormOfPayment($event) {
        _vm.formOfPayment = $event;
      }
    }
  }) : _vm._e()], 1), _vm._v(" "), !_vm.typePayment.id && !_vm.showUpdateSignature ? _c("content-detail-payments-list", {
    attrs: {
      subscription: _vm.itemPayment,
      "type-payment": _vm.typePayment,
      "data-invoices": _vm.dataInvoices,
      "confirm-change-payment-method": _vm.confirmChangePaymentMethod,
      "generate-new-ticket": _vm.generateNewTicket,
      "open-ticket-new-tab": _vm.openTicketNewTab,
      area: _vm.totalArea.area
    },
    on: {
      "update:typePayment": function updateTypePayment($event) {
        _vm.typePayment = $event;
      },
      "update:type-payment": function updateTypePayment($event) {
        _vm.typePayment = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.typePayment.id && _vm.showUpdateSignature ? _c("content-payment-options", {
    attrs: {
      "form-of-payment": _vm.formOfPayment,
      plan: _vm.selectedPlan,
      discount: _vm.discount,
      subscription: _vm.itemPayment,
      "calculate-form": _vm.calculateValuePerHectare,
      "open-modal": _vm.openModal,
      area: _vm.totalArea.area
    },
    on: {
      "update:formOfPayment": function updateFormOfPayment($event) {
        _vm.formOfPayment = $event;
      },
      "update:form-of-payment": function updateFormOfPayment($event) {
        _vm.formOfPayment = $event;
      },
      "update:plan": function updatePlan($event) {
        _vm.selectedPlan = $event;
      },
      "update:discount": function updateDiscount($event) {
        _vm.discount = $event;
      }
    }
  }) : _vm._e()], 1)])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "farms-details-signature-title"
  }, [_c("h3", [_vm._v("Assinatura Atual")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;