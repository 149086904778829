"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "layout-full-width",
    class: "wraper-container-".concat(this.$route.name)
  }, [_c("div", {
    staticClass: "content-wrap",
    attrs: {
      id: "content-wrap"
    }
  }, [_c("main", {
    staticClass: "content",
    attrs: {
      id: "content",
      role: "main"
    }
  }, [_c("keep-alive", {
    attrs: {
      include: "foo"
    }
  }, [_c("router-view")], 1)], 1)]), _vm._v(" "), _c("b-loading", {
    attrs: {
      "is-full-page": true,
      active: _vm.isLoading,
      "can-cancel": false
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.isLoading = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;