"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "component-upload-photo-user"
  }, [_vm.imageBase64 ? _c("span", {
    staticClass: "file-image"
  }, [_c("img", {
    attrs: {
      src: _vm.imageBase64
    }
  })]) : _vm._e(), _vm._v(" "), !_vm.fileUser && _vm.imageUrl ? _c("span", {
    staticClass: "file-image"
  }, [_c("img", {
    attrs: {
      src: _vm.imageUrl
    }
  })]) : _vm._e(), _vm._v(" "), _vm.fileUser || _vm.imageUrl ? _c("a", {
    staticClass: "button btn-remove-photo",
    on: {
      click: _vm.removePhoto
    }
  }, [_c("span", [_vm._v("\n      Alterar imagem\n    ")])]) : _vm._e(), _vm._v(" "), !_vm.fileUser && !_vm.imageUrl ? _c("b-upload", {
    model: {
      value: _vm.fileUser,
      callback: function callback($$v) {
        _vm.fileUser = $$v;
      },
      expression: "fileUser"
    }
  }, [_c("span", {
    staticClass: "btn-description-upload"
  }, [_c("i", {
    staticClass: "icon-ic_plus"
  }), _vm._v("\n      Imagem\n    ")])]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;