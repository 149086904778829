"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'Card',
  props: {
    headline: {
      type: String,
      default: null
    },
    imgName: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    }
  }
};