"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'ModalDetailClassification',
  props: {
    link: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      isModal: true
    };
  },
  methods: {
    close: function close() {
      this.$parent.close();
    }
  }
};