"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.menuItemsServices = exports.menuItemsOccurrences = exports.menuItemsFarm = exports.menuAdmin = void 0;
var menuItemsFarm = exports.menuItemsFarm = function menuItemsFarm(state) {
  return state.menu.farm;
};
var menuItemsServices = exports.menuItemsServices = function menuItemsServices(state) {
  return state.menu.services;
};
var menuItemsOccurrences = exports.menuItemsOccurrences = function menuItemsOccurrences(state) {
  return state.menu.occurrences;
};
var menuAdmin = exports.menuAdmin = function menuAdmin(state) {
  return state.menu.admin;
};