"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.measureService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var measureService = exports.measureService = {
  fetchMeasure: fetchMeasure
};
function fetchMeasure(params) {
  var path = "/measure";
  return _http.default.get(path, {
    params: params
  });
}