"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stockService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var stockService = {
  fetchInventory: fetchInventory,
  createInventory: createInventory,
  fetchDetailInventory: fetchDetailInventory,
  updateInventory: updateInventory,
  fetchLogInventory: fetchLogInventory,
  checkInProduct: checkInProduct,
  checkOutProduct: checkOutProduct,
  deleteInventory: deleteInventory
};
exports.stockService = stockService;
function fetchInventory(idFarm) {
  var path = "/farms/".concat(idFarm, "/inventory");

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function createInventory(idFarm, payload) {
  var path = "/farms/".concat(idFarm, "/inventory");
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}
function fetchDetailInventory(id) {
  var path = "/inventory/".concat(id);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function updateInventory(id, payload) {
  var path = "/inventory/".concat(id);
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.put(path, data);
}
function deleteInventory(id) {
  var path = "/inventory/".concat(id);

  // eslint-disable-next-line no-undef
  return _http.default.delete(path);
}
function fetchLogInventory(id, payload) {
  var path = "/inventory/".concat(id, "/logs");

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function checkInProduct(id, payload) {
  var path = "/inventory/".concat(id, "/entry");
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}
function checkOutProduct(id, payload) {
  var path = "/inventory/".concat(id, "/exit");
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}