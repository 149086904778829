"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "modal-form-farm-list-farms",
    attrs: {
      active: _vm.showModalItem,
      width: 750,
      scroll: "keep",
      "can-cancel": ["escape"],
      "on-cancel": _vm.cancelModal
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.showModalItem = $event;
      }
    }
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "media"
  }, [_c("div", {
    staticClass: "media-content"
  }, [_c("button", {
    staticClass: "btn-x-close",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.cancelModal
    }
  }, [_c("i", {
    staticClass: "icon-ic_close"
  })]), _vm._v(" "), _c("p", {
    staticClass: "title"
  }, [_vm._v("\n            " + _vm._s(_vm.form.id ? "Alterando cliente" : "Incluir Novo Cliente (Distribuidor)") + "\n          ")])])]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [!_vm.isLoading ? _c("form", {
    staticClass: "form-list-farm",
    on: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.submitFormClient.apply(null, arguments);
      }
    }
  }, [_c("b-field", {
    attrs: {
      label: "Nome do cliente",
      type: _vm.$v.form.name.$error ? "is-danger" : "",
      message: _vm.$v.form.name.$error ? "Nome do cliente é obrigatório" : ""
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "Nome do cliente"
    },
    model: {
      value: _vm.$v.form.name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.name, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.name.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "CNPJ",
      type: _vm.$v.form.cnpj.$error ? "is-danger" : "",
      message: _vm.$v.form.cnpj.$error ? "CNPJ válido é obrigatório" : ""
    }
  }, [_c("the-mask", {
    staticClass: "input",
    class: {
      "is-danger": _vm.$v.form.cnpj.$error
    },
    attrs: {
      autocomplete: "off",
      placeholder: "00.000.000/0000-00",
      mask: ["##.###.###/####-##"]
    },
    model: {
      value: _vm.$v.form.cnpj.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.cnpj, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.cnpj.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "IE",
      type: _vm.$v.form.stateRegistration.$error ? "is-danger" : "",
      message: _vm.$v.form.stateRegistration.$error ? "IE é obrigatório" : ""
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "IE"
    },
    model: {
      value: _vm.$v.form.stateRegistration.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.stateRegistration, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.stateRegistration.$model"
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Endereço",
      type: _vm.$v.form.address.$error ? "is-danger" : "",
      message: _vm.$v.form.address.$error ? "Endereço do cliente é obrigatório" : ""
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "Endereço completo do cliente"
    },
    model: {
      value: _vm.$v.form.address.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.address, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.address.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "Cidade",
      type: _vm.$v.form.city.$error ? "is-danger" : "",
      message: _vm.$v.form.city.$error ? "Informe a cidade" : ""
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "Nome da cidade"
    },
    model: {
      value: _vm.$v.form.city.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.city, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.city.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "field-uf",
    attrs: {
      label: "Estado",
      type: _vm.$v.form.state.$error ? "is-danger" : "",
      message: _vm.$v.form.state.$error ? "Informe o estado" : ""
    }
  }, [_c("div", {
    staticClass: "control is-clearfix"
  }, [_c("b-select", {
    attrs: {
      placeholder: "Estado"
    },
    model: {
      value: _vm.$v.form.state.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.state, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.state.$model"
    }
  }, _vm._l(_vm.states, function (state, index) {
    return _c("option", {
      key: index,
      domProps: {
        value: state.uf
      }
    }, [_vm._v("\n                    " + _vm._s(state.uf) + " - " + _vm._s(state.description) + "\n                  ")]);
  }), 0)], 1)])], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "CEP"
    }
  }, [_c("div", {
    staticClass: "control is-clearfix"
  }, [_c("b-input", {
    attrs: {
      placeholder: "CEP"
    },
    model: {
      value: _vm.$v.form.postalCode.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.postalCode, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.postalCode.$model"
    }
  })], 1)])], 1), _vm._v(" "), _c("div", {
    staticClass: "field btns-actions"
  }, [_c("button", {
    staticClass: "button is-primary",
    attrs: {
      type: "submit",
      disabled: _vm.$v.form.$invalid
    }
  }, [_vm._v("\n              Cadastrar\n            ")])])], 1) : _vm._e()])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;