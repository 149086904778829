"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'DetailsCollapse',
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    name: {
      type: String,
      default: ''
    },
    place: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      content: this.value
    };
  },
  computed: {
    productName: function productName() {
      var product = this.item.productId || {};
      return product.name || '--';
    },
    varieties: function varieties() {
      var variety = this.item.varietyId || {};
      return variety.name || '--';
    }
  },
  methods: {
    handleInput: function handleInput(e) {
      this.$emit('input', this.content);
    }
  }
};