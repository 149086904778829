"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.URL_STORAGE_AWS = exports.TYPE_DRAW_MAP = exports.TYPES_PAYMENTS = exports.SUBPLOT = exports.SCHOOLINGS = exports.SCHEDULING_STATUS = exports.ROW = exports.ROLES_USERS_FUNCTIONS_COMPLETE = exports.ROLES_USERS_FUNCTIONS = exports.QUANTITY_MEASURE = exports.POSITION_DROPDOWN = exports.OPTIONS_TABLE = exports.NUMBER_SUB_PLOTS = exports.MONEY = exports.MODELS_TAGS_RASTREIO = exports.LIST_TYPES_PROVIDERS = exports.LIST_STATES = exports.LIST_MONTHS_MINUS = exports.LIST_EMBALAGENS = exports.LIST_CUSTOM_FIELDS_OPTIONS = exports.LIST_CUSTOM_FIELDS = exports.LIST_ADMINS = exports.KEY_USER_LOCAL_STORAGE_FARM = exports.KEY_USER_LOCAL_STORAGE = exports.FAMILIE = exports.BUNCH = exports.BASE_DRAW_MAP = void 0;
/**
 * URL S3 AWS
 */
var URL_STORAGE_AWS = 'https://fiboo-documents.s3.us-east-2.amazonaws.com';
/**
 * KEY LocalStorageUser
 */
exports.URL_STORAGE_AWS = URL_STORAGE_AWS;
var KEY_USER_LOCAL_STORAGE = 'current_user';
/**
 * KEY LocalStorageUser
 */
exports.KEY_USER_LOCAL_STORAGE = KEY_USER_LOCAL_STORAGE;
var KEY_USER_LOCAL_STORAGE_FARM = 'farm_selected';
/**
 * STATUS ACCEPTED IN THE SERVICES
 */
exports.KEY_USER_LOCAL_STORAGE_FARM = KEY_USER_LOCAL_STORAGE_FARM;
var SCHEDULING_STATUS = [{
  name: 'Agendado',
  value: 'SCHEDULED'
}, {
  name: 'Em andamento',
  value: 'IN_PROGRESS'
}, {
  name: 'Concluído',
  value: 'FINISHED'
}];
/**
 * NUMBER OF SUBPLOTS THAT ARE CREATED BY DRAWING UP
 */
exports.SCHEDULING_STATUS = SCHEDULING_STATUS;
var NUMBER_SUB_PLOTS = 4;
/**
 * LIST USERS MASTERS
 */
exports.NUMBER_SUB_PLOTS = NUMBER_SUB_PLOTS;
var LIST_ADMINS = ['Owner', 'Admin'];
/**
 * LIST USERS MASTERS
 */
exports.LIST_ADMINS = LIST_ADMINS;
var LIST_STATES = [{
  uf: 'AC',
  description: 'Acre'
}, {
  uf: 'AL',
  description: 'Alagoas'
}, {
  uf: 'AP',
  description: 'Amapá'
}, {
  uf: 'AM',
  description: 'Amazonas'
}, {
  uf: 'BA',
  description: 'Bahia'
}, {
  uf: 'CE',
  description: 'Ceará'
}, {
  uf: 'DF',
  description: 'Distrito Federal'
}, {
  uf: 'ES',
  description: 'Espírito Santo'
}, {
  uf: 'GO',
  description: 'Goiás'
}, {
  uf: 'MA',
  description: 'Maranhão'
}, {
  uf: 'MT',
  description: 'Mato Grosso'
}, {
  uf: 'MS',
  description: 'Mato Grosso do Sul'
}, {
  uf: 'MG',
  description: 'Minas Gerais'
}, {
  uf: 'PA',
  description: 'Pará'
}, {
  uf: 'PB',
  description: 'Paraíba'
}, {
  uf: 'PR',
  description: 'Paraná'
}, {
  uf: 'PE',
  description: 'Pernambuco'
}, {
  uf: 'PI',
  description: 'Piauí'
}, {
  uf: 'RJ',
  description: 'Rio de Janeiro'
}, {
  uf: 'RN',
  description: 'Rio Grande do Norte'
}, {
  uf: 'RS',
  description: 'Rio Grande do Sul'
}, {
  uf: 'RO',
  description: 'Rondônia'
}, {
  uf: 'RR',
  description: 'Roraima'
}, {
  uf: 'SC',
  description: 'Santa Catarina'
}, {
  uf: 'SP',
  description: 'São Paulo'
}, {
  uf: 'SE',
  description: 'Sergipe'
}, {
  uf: 'TO',
  description: 'Tocantins'
}];
/**
 * DRAWING FOR MOUNTING DRAWING ON MAP
 */
exports.LIST_STATES = LIST_STATES;
var BASE_DRAW_MAP = [{
  type: 'Feature',
  id: '',
  geometry: {
    type: 'Polygon',
    coordinates: []
  },
  properties: null
}];
/**
 * DRAWING FOR MOUNTING DRAWING ON MAP
 */
exports.BASE_DRAW_MAP = BASE_DRAW_MAP;
var TYPE_DRAW_MAP = 'polygon';
/**
 * Quantity measure
 */
exports.TYPE_DRAW_MAP = TYPE_DRAW_MAP;
var QUANTITY_MEASURE = [{
  type: 'TONS',
  name: 'Tonelada',
  short: 'TON'
}, {
  type: 'KILOGRAMS',
  name: 'Quilograma',
  short: 'Kg'
}, {
  type: 'GRAMS',
  name: 'Gramo',
  short: 'g'
}, {
  type: 'LITERS',
  name: 'Litro',
  short: 'l'
}, {
  type: 'MILLILITERS',
  name: 'Mililitro',
  short: 'ml'
}];
/**
 * FORMAT MONEY
 */
exports.QUANTITY_MEASURE = QUANTITY_MEASURE;
var MONEY = {
  decimal: ',',
  thousands: '.',
  prefix: '',
  suffix: '',
  precision: 2,
  masked: false
};
/**
 * POTISITION DEFAULT DROPDOWN
 */
exports.MONEY = MONEY;
var POSITION_DROPDOWN = 'is-bottom-left';
/**
 * DEFAULT OPTIONS TABLE
 */
exports.POSITION_DROPDOWN = POSITION_DROPDOWN;
var OPTIONS_TABLE = {
  isPaginated: true,
  perPage: 10,
  isPaginationSimple: false,
  defaultSortDirection: 'asc'
};
/**
 * OPTIONS SCHOOLINGS
 */
exports.OPTIONS_TABLE = OPTIONS_TABLE;
var SCHOOLINGS = [{
  value: 'ANF',
  description: 'Analfabeto'
}, {
  value: 'EFI',
  description: 'Ensino fundamental incompleto'
}, {
  value: 'EFC',
  description: 'Ensino fundamental completo'
}, {
  value: 'EMI',
  description: 'Ensino médio incompleto'
}, {
  value: 'EMC',
  description: 'Ensino médio completo'
}, {
  value: 'SI',
  description: 'Superior incompleto'
}, {
  value: 'SC',
  description: 'Superior completo'
}, {
  value: 'PG',
  description: 'Pós-graduação'
}, {
  value: 'MES',
  description: 'Mestrado'
}, {
  value: 'DT',
  description: 'Doutorado'
}, {
  value: 'PD',
  description: 'Pós-Doutorado'
}];
/**
 * TYPES_PAYMENTS
 */
exports.SCHOOLINGS = SCHOOLINGS;
var TYPES_PAYMENTS = {
  CREDIT_CARD: 'CREDIT_CARD',
  TICKET: 'TICKET'
};
/**
 * LIST TIPO EMBALAGEM
 */
exports.TYPES_PAYMENTS = TYPES_PAYMENTS;
var LIST_EMBALAGENS = [{
  id: 'GRANEL',
  description: 'Granel'
}, {
  id: 'UNIDADE',
  description: 'Unidade'
}, {
  id: 'G',
  description: 'G'
}, {
  id: 'KG',
  description: 'Kg'
}, {
  id: 'DUZIA',
  description: 'Dúzia'
}];

/**
 * LIST TIPO EMBALAGEM
 */
exports.LIST_EMBALAGENS = LIST_EMBALAGENS;
var LIST_TYPES_PROVIDERS = [{
  id: 'DISTRIBUTOR',
  description: 'Distribuidor'
}, {
  id: 'PRODUCER',
  description: 'Produtor'
}, {
  id: 'RETAILER',
  description: 'Varejista'
}];

/**
 * LIST MONTH MINUS
 */
exports.LIST_TYPES_PROVIDERS = LIST_TYPES_PROVIDERS;
var LIST_MONTHS_MINUS = [{
  id: 'JAN',
  description: 'JAN'
}, {
  id: 'FEV',
  description: 'FEV'
}, {
  id: 'MAR',
  description: 'MAR'
}, {
  id: 'ABR',
  description: 'ABR'
}, {
  id: 'MAI',
  description: 'MAI'
}, {
  id: 'JUN',
  description: 'JUN'
}, {
  id: 'JUL',
  description: 'JUL'
}, {
  id: 'AGO',
  description: 'AGO'
}, {
  id: 'SET',
  description: 'SET'
}, {
  id: 'OUT',
  description: 'OUT'
}, {
  id: 'NOV',
  description: 'NOV'
}, {
  id: 'DEZ',
  description: 'DEZ'
}];

/**
 * MODELS TAGS
 */
exports.LIST_MONTHS_MINUS = LIST_MONTHS_MINUS;
var MODELS_TAGS_RASTREIO = [{
  id: 1,
  description: 'Etiqueta 80mm por 64mm sem informações nutricionais',
  sizeWidth: 80,
  sizeHeight: 64
}, {
  id: 2,
  description: 'Etiqueta 80mm por 64mm com informações nutricionais',
  sizeWidth: 80,
  sizeHeight: 64
}, {
  id: 1,
  description: 'Etiqueta 100mm por 75mm sem informações nutricionais',
  sizeWidth: 100,
  sizeHeight: 75
}, {
  id: 1,
  description: 'Etiqueta 100mm por 75mm com informações nutricionais',
  sizeWidth: 100,
  sizeHeight: 75
}, {
  id: 1,
  description: 'Etiqueta 120mmm por 50mm',
  sizeWidth: 120,
  sizeHeight: 50
}, {
  id: 1,
  description: 'Etiqueta 100mmm por 50mm',
  sizeWidth: 120,
  sizeHeight: 50
}, {
  id: 1,
  description: 'Etiqueta 100mmm por 60mm',
  sizeWidth: 100,
  sizeHeight: 60
}];

/**
 * ROLES USERS
 */
exports.MODELS_TAGS_RASTREIO = MODELS_TAGS_RASTREIO;
var ROLES_USERS_FUNCTIONS = [{
  value: 'Coordinator',
  description: 'Coordenador'
}, {
  value: 'Operator',
  description: 'Operador'
}, {
  value: 'Manager',
  description: 'Gerente'
}];

/**
 * ROLES USERS COMPLETE
 */
exports.ROLES_USERS_FUNCTIONS = ROLES_USERS_FUNCTIONS;
var ROLES_USERS_FUNCTIONS_COMPLETE = [{
  type: 'Admin',
  description: 'Administrador'
}, {
  type: 'Owner',
  description: 'Proprietário'
}, {
  type: 'Coordinator',
  description: 'Coordenador'
}, {
  type: 'Operator',
  description: 'Operador'
}, {
  type: 'Manager',
  description: 'Gerente'
}];

/**
 * LIST CUSTOM FIELDS
 */
exports.ROLES_USERS_FUNCTIONS_COMPLETE = ROLES_USERS_FUNCTIONS_COMPLETE;
var LIST_CUSTOM_FIELDS = [{
  type: 'seekbar',
  name: 'Range de valor'
}, {
  type: 'checklist',
  name: 'Checklist'
}, {
  type: 'radio',
  name: 'Escolha única'
}, {
  type: 'number',
  name: 'Número'
}, {
  type: 'select',
  name: 'Select'
}, {
  type: 'string',
  name: 'Texto'
}, {
  type: 'date',
  name: 'Data'
}, {
  type: 'hour',
  name: 'Hora'
}];

/**
 * LIST CUSTOM FIELDS CONTAIN OPTIONS
 */
exports.LIST_CUSTOM_FIELDS = LIST_CUSTOM_FIELDS;
var LIST_CUSTOM_FIELDS_OPTIONS = ['seekbar', 'checklist', 'radio', 'select'];

/**
 * TYPES
 */
exports.LIST_CUSTOM_FIELDS_OPTIONS = LIST_CUSTOM_FIELDS_OPTIONS;
var SUBPLOT = 'subplot';
exports.SUBPLOT = SUBPLOT;
var ROW = 'row';
exports.ROW = ROW;
var FAMILIE = 'familie';
exports.FAMILIE = FAMILIE;
var BUNCH = 'bunche';
exports.BUNCH = BUNCH;