"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOGGLE_SIDEBAR = exports.TOGGLE_EXPAND_MENU_ITEM = exports.SET_TABS = exports.SET_ROMANEIO_LIST = exports.SET_MEASURE_LIST = exports.SET_LOADING = exports.SET_LIST_VARIETIES = exports.SET_LIST_PRODUCT = exports.SET_LIST_CULTIVATIONS = exports.SET_INVENTORY = exports.SET_IDENTIFIERS = exports.SET_FARM_PAYMENT_DETAIL = exports.SET_FARM = exports.SET_CURRENT_USER = exports.SET_CLASSIFICATION = exports.SET_ALL_USERS = exports.SET_ALL_TYPE_OCURRENCES = exports.SET_ALL_OCURRENCES = exports.SET_ALL_LIST_NOTIFICATIONS = exports.SET_ALL_FARMS = exports.SET_ADD_ITEM_LIST_NOTIFICATIONS = exports.RESET_TRACEABILITY_TAG_LIST_CREATED = exports.RESET_SERVICE_SELECT = exports.RESET_SERVICES = exports.RESET_CURRENT_USER = exports.REMOVE_TRACEABILITY_TAG_LIST_CREATED = exports.REMOVE_SELECTED_PLOTS = exports.REMOVE_SCHEDULE_PLOTS = exports.REMOVE_BALANCE_DATA = exports.REMOVE_ALL_PLOTS = exports.INSERT_SUBPLOTS_PLOTS = exports.INSERT_LINES_SUBPLOTS = exports.INSERT_FAMILIES_LINE = exports.INSERT_BUNCHES_FAMILIES = exports.FORMAT_PLOSTS_SEND_SAVE = exports.EVENT_CHECK_SUB_PLOT = exports.EVENT_CHECK_PLOT = exports.EVENT_CHECK_LINE = exports.EVENT_CHECK_FAMILIES = exports.EVENT_CHECK_BUNCHES = exports.CLOSE_MENU = exports.ADD_TRACEABILITY__TAG = exports.ADD_TRACEABILITY_TAG_LIST_CREATED = exports.ADD_SERVICE_TYPES = exports.ADD_SERVICE_SELECT = exports.ADD_SERVICES = exports.ADD_SELECTEDS_ITEMS = exports.ADD_SCHEDULE_PLOTS = exports.ADD_PROVIDERS = exports.ADD_PRODUCT_CUSTOMIZATION = exports.ADD_PLOT_SELECTED = exports.ADD_PLOTS = exports.ADD_ORDER_SERVICES = exports.ADD_ITENS_SERVICE_TYPES = exports.ADD_CYCLES = exports.ADD_CUSTOMERS = exports.ADD_BALANCE_DATA = void 0;
// SERVICE
var ADD_SERVICE_TYPES = 'ADD_SERVICE_TYPES';
exports.ADD_SERVICE_TYPES = ADD_SERVICE_TYPES;
var ADD_ITENS_SERVICE_TYPES = 'ADD_ITENS_SERVICE_TYPES';
exports.ADD_ITENS_SERVICE_TYPES = ADD_ITENS_SERVICE_TYPES;
var ADD_SERVICES = 'ADD_SERVICES';
exports.ADD_SERVICES = ADD_SERVICES;
var ADD_CYCLES = 'ADD_CYCLES';
exports.ADD_CYCLES = ADD_CYCLES;
var ADD_ORDER_SERVICES = 'ADD_ORDER_SERVICES';
// PLOTS
exports.ADD_ORDER_SERVICES = ADD_ORDER_SERVICES;
var ADD_PLOTS = 'ADD_PLOTS';
exports.ADD_PLOTS = ADD_PLOTS;
var ADD_PLOT_SELECTED = 'ADD_PLOT_SELECTED';
exports.ADD_PLOT_SELECTED = ADD_PLOT_SELECTED;
var ADD_SELECTEDS_ITEMS = 'ADD_SELECTEDS_ITEMS';
exports.ADD_SELECTEDS_ITEMS = ADD_SELECTEDS_ITEMS;
var REMOVE_ALL_PLOTS = 'REMOVE_ALL_PLOTS';
exports.REMOVE_ALL_PLOTS = REMOVE_ALL_PLOTS;
var REMOVE_SELECTED_PLOTS = 'REMOVE_SELECTED_PLOTS';
exports.REMOVE_SELECTED_PLOTS = REMOVE_SELECTED_PLOTS;
var REMOVE_SCHEDULE_PLOTS = 'REMOVE_SCHEDULE_PLOTS';
exports.REMOVE_SCHEDULE_PLOTS = REMOVE_SCHEDULE_PLOTS;
var EVENT_CHECK_PLOT = 'EVENT_CHECK_PLOT';
exports.EVENT_CHECK_PLOT = EVENT_CHECK_PLOT;
var EVENT_CHECK_SUB_PLOT = 'EVENT_CHECK_SUB_PLOT';
exports.EVENT_CHECK_SUB_PLOT = EVENT_CHECK_SUB_PLOT;
var EVENT_CHECK_LINE = 'EVENT_CHECK_LINE';
exports.EVENT_CHECK_LINE = EVENT_CHECK_LINE;
var EVENT_CHECK_FAMILIES = 'EVENT_CHECK_FAMILIES';
exports.EVENT_CHECK_FAMILIES = EVENT_CHECK_FAMILIES;
var EVENT_CHECK_BUNCHES = 'EVENT_CHECK_BUNCHES';
exports.EVENT_CHECK_BUNCHES = EVENT_CHECK_BUNCHES;
var INSERT_SUBPLOTS_PLOTS = 'INSERT_SUBPLOTS_PLOTS';
exports.INSERT_SUBPLOTS_PLOTS = INSERT_SUBPLOTS_PLOTS;
var INSERT_LINES_SUBPLOTS = 'INSERT_LINES_SUBPLOTS';
exports.INSERT_LINES_SUBPLOTS = INSERT_LINES_SUBPLOTS;
var INSERT_FAMILIES_LINE = 'INSERT_FAMILIES_LINE';
exports.INSERT_FAMILIES_LINE = INSERT_FAMILIES_LINE;
var INSERT_BUNCHES_FAMILIES = 'INSERT_BUNCHES_FAMILIES';
exports.INSERT_BUNCHES_FAMILIES = INSERT_BUNCHES_FAMILIES;
var ADD_SCHEDULE_PLOTS = 'ADD_SCHEDULE_PLOTS';
exports.ADD_SCHEDULE_PLOTS = ADD_SCHEDULE_PLOTS;
var ADD_SERVICE_SELECT = 'ADD_SERVICE_SELECT';
exports.ADD_SERVICE_SELECT = ADD_SERVICE_SELECT;
var RESET_SERVICE_SELECT = 'RESET_SERVICE_SELECT';
exports.RESET_SERVICE_SELECT = RESET_SERVICE_SELECT;
var RESET_SERVICES = 'RESET_SERVICES';
exports.RESET_SERVICES = RESET_SERVICES;
var FORMAT_PLOSTS_SEND_SAVE = 'FORMAT_PLOSTS_SEND_SAVE';
// USERS
exports.FORMAT_PLOSTS_SEND_SAVE = FORMAT_PLOSTS_SEND_SAVE;
var SET_CURRENT_USER = 'SET_CURRENT_USER';
exports.SET_CURRENT_USER = SET_CURRENT_USER;
var RESET_CURRENT_USER = 'RESET_CURRENT_USER';
exports.RESET_CURRENT_USER = RESET_CURRENT_USER;
var SET_ALL_USERS = 'SET_ALL_USERS';
// OCURRENCES
exports.SET_ALL_USERS = SET_ALL_USERS;
var SET_ALL_OCURRENCES = 'SET_ALL_OCURRENCES';
exports.SET_ALL_OCURRENCES = SET_ALL_OCURRENCES;
var SET_ALL_TYPE_OCURRENCES = 'SET_ALL_TYPE_OCURRENCES';
// FARM
exports.SET_ALL_TYPE_OCURRENCES = SET_ALL_TYPE_OCURRENCES;
var SET_FARM = 'SET_FARM';
exports.SET_FARM = SET_FARM;
var SET_ALL_FARMS = 'SET_ALL_FARMS';
// MENU
exports.SET_ALL_FARMS = SET_ALL_FARMS;
var TOGGLE_EXPAND_MENU_ITEM = 'TOGGLE_EXPAND_MENU_ITEM';
// APP
exports.TOGGLE_EXPAND_MENU_ITEM = TOGGLE_EXPAND_MENU_ITEM;
var CLOSE_MENU = 'CLOSE_MENU';
exports.CLOSE_MENU = CLOSE_MENU;
var TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
exports.TOGGLE_SIDEBAR = TOGGLE_SIDEBAR;
var SET_LOADING = 'SET_LOADING';
// STOCK
exports.SET_LOADING = SET_LOADING;
var SET_INVENTORY = 'SET_INVENTORY';
// PAYMENT
exports.SET_INVENTORY = SET_INVENTORY;
var SET_FARM_PAYMENT_DETAIL = 'SET_FARM_PAYMENT_DETAIL';
// IDENTIFIERS
exports.SET_FARM_PAYMENT_DETAIL = SET_FARM_PAYMENT_DETAIL;
var SET_IDENTIFIERS = 'SET_IDENTIFIERS';
// DASHBOARD
exports.SET_IDENTIFIERS = SET_IDENTIFIERS;
var SET_TABS = 'SET_TABS';
// BALANCE
exports.SET_TABS = SET_TABS;
var ADD_BALANCE_DATA = 'ADD_BALANCE_DATA';
exports.ADD_BALANCE_DATA = ADD_BALANCE_DATA;
var REMOVE_BALANCE_DATA = 'REMOVE_BALANCE_DATA';
// PROVIDERS
exports.REMOVE_BALANCE_DATA = REMOVE_BALANCE_DATA;
var ADD_PROVIDERS = 'ADD_PROVIDERS';
// CUSTOMERS
exports.ADD_PROVIDERS = ADD_PROVIDERS;
var ADD_CUSTOMERS = 'ADD_CUSTOMERS';
// PRODUCT_CUSTOMIZATION
exports.ADD_CUSTOMERS = ADD_CUSTOMERS;
var ADD_PRODUCT_CUSTOMIZATION = 'ADD_PRODUCT_CUSTOMIZATION';
// TRACEABILITY__TAG
exports.ADD_PRODUCT_CUSTOMIZATION = ADD_PRODUCT_CUSTOMIZATION;
var ADD_TRACEABILITY__TAG = 'ADD_TRACEABILITY__TAG';
exports.ADD_TRACEABILITY__TAG = ADD_TRACEABILITY__TAG;
var ADD_TRACEABILITY_TAG_LIST_CREATED = 'ADD_TRACEABILITY_TAG_LIST_CREATED';
exports.ADD_TRACEABILITY_TAG_LIST_CREATED = ADD_TRACEABILITY_TAG_LIST_CREATED;
var REMOVE_TRACEABILITY_TAG_LIST_CREATED = 'REMOVE_TRACEABILITY_TAG_LIST_CREATED';
exports.REMOVE_TRACEABILITY_TAG_LIST_CREATED = REMOVE_TRACEABILITY_TAG_LIST_CREATED;
var RESET_TRACEABILITY_TAG_LIST_CREATED = 'RESET_TRACEABILITY_TAG_LIST_CREATED';
// CLASSIFICATION
exports.RESET_TRACEABILITY_TAG_LIST_CREATED = RESET_TRACEABILITY_TAG_LIST_CREATED;
var SET_CLASSIFICATION = 'SET_CLASSIFICATION';
// SET_MEASURE_LIST
exports.SET_CLASSIFICATION = SET_CLASSIFICATION;
var SET_MEASURE_LIST = 'SET_MEASURE_LIST';
// PRODUCTS
exports.SET_MEASURE_LIST = SET_MEASURE_LIST;
var SET_LIST_PRODUCT = 'SET_LIST_PRODUCT';
exports.SET_LIST_PRODUCT = SET_LIST_PRODUCT;
var SET_LIST_VARIETIES = 'SET_LIST_VARIETIES';
exports.SET_LIST_VARIETIES = SET_LIST_VARIETIES;
var SET_LIST_CULTIVATIONS = 'SET_LIST_CULTIVATIONS';
// NOTIFICATIONS
exports.SET_LIST_CULTIVATIONS = SET_LIST_CULTIVATIONS;
var SET_ALL_LIST_NOTIFICATIONS = 'SET_ALL_LIST_NOTIFICATIONS';
exports.SET_ALL_LIST_NOTIFICATIONS = SET_ALL_LIST_NOTIFICATIONS;
var SET_ADD_ITEM_LIST_NOTIFICATIONS = 'SET_ADD_ITEM_LIST_NOTIFICATIONS';
// ROMANEIO
exports.SET_ADD_ITEM_LIST_NOTIFICATIONS = SET_ADD_ITEM_LIST_NOTIFICATIONS;
var SET_ROMANEIO_LIST = 'SET_ROMANEIO_LIST';
exports.SET_ROMANEIO_LIST = SET_ROMANEIO_LIST;