"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "field field-check-all-subplots"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("b-checkbox", {
    attrs: {
      disabled: _vm.subplotsFiltered.length === 0
    },
    nativeOn: {
      click: function click($event) {
        return _vm.actionItem();
      }
    },
    model: {
      value: _vm.computedValue,
      callback: function callback($$v) {
        _vm.computedValue = $$v;
      },
      expression: "computedValue"
    }
  }, [_vm._v("\n        Selecionar todos\n      ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-button", {
    attrs: {
      disabled: !_vm.computedValue,
      type: "is-text"
    },
    on: {
      click: function click($event) {
        return _vm.actionItem("clear");
      }
    }
  }, [_vm._v("\n        Limpar seleção\n      ")])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;