"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "confirm-registration-page"
  }, [_c("div", {
    staticClass: "container-auth"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "wrap-auth register-success"
  }, [_vm.isLoading ? _c("div", [_vm._v("\n        " + _vm._s(_vm.$t("pages.confirm_registration.load")) + "\n      ")]) : _vm._e(), _vm._v(" "), !_vm.error && _vm.registerSuccess ? _c("div", [_c("b-icon", {
    staticClass: "icon-success",
    attrs: {
      icon: "check"
    }
  }), _vm._v(" "), _c("h2", {
    staticClass: "title"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.confirm_registration.account_enabled")) + "\n        ")])], 1) : _vm._e(), _vm._v(" "), _vm.error ? _c("div", [_c("h2", {
    staticClass: "title"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.confirm_registration.alerts.title")) + "\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "description"
  }, [_vm._v("\n          " + _vm._s(_vm.message) + "\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "container-btn-actions"
  }, [_c("div", {
    staticClass: "wrap-btn"
  }, [_c("b-button", {
    attrs: {
      rounded: ""
    },
    on: {
      click: _vm.redirect
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t("pages.confirm_registration.go_login")) + "\n            ")])], 1)])]) : _vm._e()])])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container-logo-auth"
  }, [_c("img", {
    staticClass: "logo-fibo-auth",
    attrs: {
      src: require("@/assets/logo.png")
    }
  })]);
}];
render._withStripped = true;