"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "modal-cred-card-payment",
    attrs: {
      active: _vm.showModalItem,
      width: 865,
      scroll: "keep",
      "can-cancel": ["escape"],
      "on-cancel": _vm.closedModalItem
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.showModalItem = $event;
      }
    }
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "card-header"
  }, [_c("h1", [_vm._v("Dados para pagamento - Pagamento com Boleto")]), _vm._v(" "), _c("button", {
    staticClass: "button is-text close",
    attrs: {
      type: "button",
      "data-dismiss": "modal",
      "aria-label": "Close"
    },
    on: {
      click: _vm.closedModalItem
    }
  }, [_c("i", {
    staticClass: "icon-ic_close"
  })])]), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "payment-card-details"
  }, [_c("div", {
    staticClass: "payment-card-details-values"
  }, [_c("div", {
    staticClass: "row-detail"
  }, [_c("label", [_vm._v("Plano:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.typePayment.name))])]), _vm._v(" "), _c("div", {
    staticClass: "row-detail"
  }, [_c("label", [_vm._v("Vigência:")]), _vm._v(" "), _c("p", [_vm._v("12 meses de")])]), _vm._v(" "), _c("div", {
    staticClass: "row-detail"
  }, [_c("label", [_vm._v("Valor:")]), _vm._v(" "), _c("p", [_vm._v("R$ " + _vm._s(_vm._f("formatMoedaBRL")(_vm.valueToPlan)))])])])]), _vm._v(" "), _c("div", {
    staticClass: "payment-ticket"
  }, [_c("div", {
    staticClass: "payment-ticket-details"
  }, [_c("div", {
    staticClass: "payment-ticket-details-footer"
  }, [_c("p", [_vm._v("O valor da sua assinatura é determinado pelo o tamanho da área total da sua fazenda.")])])]), _vm._v(" "), _c("div", {
    staticClass: "payment-ticket-information"
  }, [_c("div", {
    staticClass: "payment-ticket-information-title"
  }, [_c("h4", [_vm._v("Informe seus dados de pagamento")])]), _vm._v(" "), _c("form", {
    on: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.submitFormTicket.apply(null, arguments);
      }
    }
  }, [_c("b-field", {
    attrs: {
      label: "Nome completo",
      type: _vm.$v.formTicket.name.$error ? "is-danger" : ""
    }
  }, [_c("b-input", {
    class: {
      "is-danger": _vm.$v.formTicket.name.$error
    },
    model: {
      value: _vm.$v.formTicket.name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formTicket.name, "$model", $$v);
      },
      expression: "$v.formTicket.name.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "CPF"
    }
  }, [_c("the-mask", {
    staticClass: "input",
    class: {
      "is-danger": _vm.$v.formTicket.identity.$error
    },
    attrs: {
      autocomplete: "off",
      mask: ["###.###.###-##"]
    },
    model: {
      value: _vm.$v.formTicket.identity.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formTicket.identity, "$model", $$v);
      },
      expression: "$v.formTicket.identity.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "CEP"
    }
  }, [_c("the-mask", {
    staticClass: "input",
    class: {
      "is-danger": _vm.$v.formTicket.zip.$error
    },
    attrs: {
      autocomplete: "off",
      mask: ["##.###-###"]
    },
    nativeOn: {
      change: function change($event) {
        return _vm.consultZipcode(_vm.$v.formTicket.zip.$model);
      }
    },
    model: {
      value: _vm.$v.formTicket.zip.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formTicket.zip, "$model", $$v);
      },
      expression: "$v.formTicket.zip.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Logradouro",
      type: _vm.$v.formTicket.street.$error ? "is-danger" : ""
    }
  }, [_c("b-input", {
    class: {
      "is-danger": _vm.$v.formTicket.street.$error
    },
    model: {
      value: _vm.$v.formTicket.street.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formTicket.street, "$model", $$v);
      },
      expression: "$v.formTicket.street.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Complemento",
      type: _vm.$v.formTicket.complement.$error ? "is-danger" : ""
    }
  }, [_c("b-input", {
    class: {
      "is-danger": _vm.$v.formTicket.complement.$error
    },
    model: {
      value: _vm.$v.formTicket.complement.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formTicket.complement, "$model", $$v);
      },
      expression: "$v.formTicket.complement.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Número",
      type: _vm.$v.formTicket.number.$error ? "is-danger" : ""
    }
  }, [_c("b-input", {
    class: {
      "is-danger": _vm.$v.formTicket.number.$error
    },
    model: {
      value: _vm.$v.formTicket.number.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formTicket.number, "$model", $$v);
      },
      expression: "$v.formTicket.number.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Cidade",
      type: _vm.$v.formTicket.city.$error ? "is-danger" : ""
    }
  }, [_c("b-input", {
    class: {
      "is-danger": _vm.$v.formTicket.city.$error
    },
    model: {
      value: _vm.$v.formTicket.city.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formTicket.city, "$model", $$v);
      },
      expression: "$v.formTicket.city.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Estado",
      type: _vm.$v.formTicket.state.$error ? "is-danger" : ""
    }
  }, [_c("the-mask", {
    staticClass: "input",
    class: {
      "is-danger": _vm.$v.formTicket.state.$error
    },
    attrs: {
      autocomplete: "off",
      mask: ["AA"]
    },
    model: {
      value: _vm.$v.formTicket.state.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formTicket.state, "$model", $$v);
      },
      expression: "$v.formTicket.state.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Bairro",
      type: _vm.$v.formTicket.district.$error ? "is-danger" : ""
    }
  }, [_c("b-input", {
    class: {
      "is-danger": _vm.$v.formTicket.district.$error
    },
    model: {
      value: _vm.$v.formTicket.district.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formTicket.district, "$model", $$v);
      },
      expression: "$v.formTicket.district.$model"
    }
  })], 1), _vm._v(" "), _c("p", [_c("span", [_vm._v("Importante:")]), _vm._v(" Pagamento somente à vista. O boleto vence em 5 dias úteis e é necessário imprimir ou utilizar o código de barras do mesmo para fazer o pagamento. O boleto não será enviado para seu endereço fisíco. Uma copia do boleto será enviada para o seu e-mail.\n              ")]), _vm._v(" "), _c("div", {
    staticClass: "field field-checkbox",
    attrs: {
      type: _vm.$v.formTicket.check.$error ? "is-danger" : ""
    }
  }, [_c("b-checkbox", {
    on: {
      change: function change($event) {
        return _vm.$v.formTicket.check.$touch();
      }
    },
    model: {
      value: _vm.$v.formTicket.check.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formTicket.check, "$model", $$v);
      },
      expression: "$v.formTicket.check.$model"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "container-auth-form-info-terms"
  }, [_vm._v("\n                  Tenho mais de 18 anos e concordo com as condições acima, o\n                  "), _c("a", {
    attrs: {
      href: "".concat(_vm.urlTerms, "/fiboo-terms-of-use.pdf"),
      target: "_blank"
    }
  }, [_vm._v("\n                    " + _vm._s(_vm.$t("pages.signup.terms_service")) + "\n                  ")]), _vm._v("\n                  " + _vm._s(_vm.$t("pages.signup.and_with")) + "\n                  "), _c("a", {
    attrs: {
      href: "".concat(_vm.urlTerms, "/fiboo-privacy-policy.pdf"),
      target: "_blank"
    }
  }, [_vm._v("\n                    " + _vm._s(_vm.$t("pages.signup.privacy_policy")) + "\n                  ")]), _vm._v("\n                  " + _vm._s(_vm.$t("pages.signup.the_fiboo")) + "\n                ")])], 1), _vm._v(" "), _c("button", {
    attrs: {
      disabled: _vm.$v.formTicket.$invalid,
      type: "submit"
    }
  }, [_vm._v("\n                Realizar pagamento\n              ")])], 1)])])])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;