"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    staticClass: "farm-map-plot-management-page",
    attrs: {
      "return-menu": "/farm-management/list",
      menu: _vm.menuItemsFarm.children,
      "sub-menu": _vm.menuItemsFarm.subMenu,
      "return-text": "Lista de fazendas"
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("div", {
    staticClass: "context-map"
  }, [_vm.showModal ? _c("template-map-detail", {
    attrs: {
      "properties-plot": _vm.propertiesPlot,
      "sub-plots-qtd": _vm.subPlotsQtd,
      "sub-plots-list": _vm.subPlotsList,
      "is-error-nick-name": _vm.isErrorNickName,
      "selected-features": _vm.selectedFeatures,
      "valid-calc-total-areas": _vm.validCalcTotalAreas,
      "edit-draw": _vm.editDraw
    },
    on: {
      "update:subPlotsQtd": function updateSubPlotsQtd($event) {
        _vm.subPlotsQtd = $event;
      },
      "update:sub-plots-qtd": function updateSubPlotsQtd($event) {
        _vm.subPlotsQtd = $event;
      },
      "update:subPlotsList": function updateSubPlotsList($event) {
        _vm.subPlotsList = $event;
      },
      "update:sub-plots-list": function updateSubPlotsList($event) {
        _vm.subPlotsList = $event;
      },
      createNewPlot: _vm.createNewPlot,
      closeDetail: _vm.closeDetail,
      updateQtdSuPlot: _vm.updateQtdSuPlot,
      editPlot: _vm.editPlotDraw
    }
  }) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "header-componentes-container"
  }, [_vm.mapMode !== "edit" ? _c("button", {
    on: {
      click: _vm.drawingMap
    }
  }, [_vm._v("\n          " + _vm._s(_vm.editDraw ? "Editar Mapa" : "Nova área") + "\n        ")]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "field-search"
  }, [_c("gmap-autocomplete", {
    attrs: {
      id: "searchTextField",
      "select-first-on-enter": true,
      options: _vm.optionsAutoComplete
    },
    on: {
      place_changed: function place_changed($event) {
        return _vm.searchLocation($event);
      }
    }
  })], 1)]), _vm._v(" "), _c("gmap-map", {
    ref: "mapRef",
    staticClass: "map-container",
    attrs: {
      center: _vm.center,
      zoom: _vm.zoom,
      "map-type-id": "roadmap",
      options: _vm.mapOptions
    },
    scopedSlots: _vm._u([{
      key: "visible",
      fn: function fn() {
        return [_vm.paths.length > 0 ? _c("gmap-polygon", {
          ref: "polygon",
          attrs: {
            paths: _vm.paths,
            editable: !_vm.editDraw || _vm.mapMode === "edit",
            options: _vm.polygonOptions
          },
          on: {
            paths_changed: function paths_changed($event) {
              return _vm.updateEdited($event);
            },
            rightclick: _vm.handleClickForDelete
          }
        }) : _vm._e(), _vm._v(" "), _vm.mapMode === "edit" ? _c("gmap-drawing-manager", {
          attrs: {
            "rectangle-options": _vm.polygonOptions,
            "circle-options": _vm.polygonOptions,
            "polyline-options": _vm.polygonOptions,
            "polygon-options": _vm.polygonOptions,
            options: _vm.polygonOptions,
            shapes: _vm.shapes
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(on) {
              return [_c("div", {
                staticClass: "container-buttons-drawing"
              }, [!_vm.drawingON ? _c("button", {
                staticClass: "button editing-map",
                class: {
                  active: _vm.drawingON
                },
                attrs: {
                  id: "editing-map-drawing"
                },
                on: {
                  click: function click($event) {
                    return _vm.initDrawing(on);
                  }
                }
              }, [_c("b-icon", {
                attrs: {
                  icon: "fountain-pen-tip"
                }
              })], 1) : _vm._e(), _vm._v(" "), _c("button", {
                staticClass: "button cancel-editing-map",
                on: {
                  click: function click($event) {
                    return _vm.cancelDrawing(on);
                  }
                }
              }, [_vm._v("\n                  Cancelar\n                ")]), _vm._v(" "), !_vm.drawingON ? _c("button", {
                staticClass: "button cancel-del-editing-map",
                attrs: {
                  disabled: !_vm.paths.length
                },
                on: {
                  click: function click($event) {
                    return _vm.deleteDrawing(on);
                  }
                }
              }, [_c("span", {
                staticClass: "icon"
              }, [_c("i", {
                staticClass: "icon-delete"
              })])]) : _vm._e(), _vm._v(" "), _c("button", {
                staticClass: "button save-editing-map",
                on: {
                  click: function click($event) {
                    return _vm.saveDrawing(on);
                  }
                }
              }, [_vm._v("\n                  Salvar\n                ")])])];
            }
          }], null, false, 1484606018)
        }) : _vm._e()];
      },
      proxy: true
    }])
  })], 1)])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;