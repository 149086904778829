import { render, staticRenderFns } from "./ContentNewTag.vue?vue&type=template&id=59f30a3a"
import script from "./ContentNewTag.vue?vue&type=script&lang=js"
export * from "./ContentNewTag.vue?vue&type=script&lang=js"
import style0 from "./ContentNewTag.vue?vue&type=style&index=0&id=59f30a3a&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('59f30a3a')) {
      api.createRecord('59f30a3a', component.options)
    } else {
      api.reload('59f30a3a', component.options)
    }
    module.hot.accept("./ContentNewTag.vue?vue&type=template&id=59f30a3a", function () {
      api.rerender('59f30a3a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/flow/traceabilitytags/components/ContentNewTag.vue"
export default component.exports