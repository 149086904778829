"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "contente-list-traceability-tag-tab"
  }, [_c("div", {
    staticClass: "list"
  }, _vm._l(_vm.listTraceabilityTagsCreate, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "list-item"
    }, [_c("div", {
      staticClass: "content-btn-print"
    }, [_c("b-button", {
      on: {
        click: function click($event) {
          return _vm.editTag(item);
        }
      }
    }, [_c("i", {
      staticClass: "icon-ic_edit"
    }), _vm._v("\n          Editar produto\n        ")]), _vm._v(" "), _c("b-button", {
      staticClass: "btn-delete",
      on: {
        click: function click($event) {
          return _vm.verifyDelete(item);
        }
      }
    }, [_c("i", {
      staticClass: "icon-delete"
    }), _vm._v("\n          Remover produto\n        ")])], 1), _vm._v(" "), _c("div", {
      staticClass: "columns"
    }, [_c("div", {
      staticClass: "column half"
    }, [_c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label"
    }, [_vm._v("Fornecedor:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.nameProvider(item)))])])]), _vm._v(" "), _c("div", {
      staticClass: "column half"
    }, [_c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label _155"
    }, [_vm._v("Cliente (comprador):")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.nameClient(item)))])])])]), _vm._v(" "), _c("div", {
      staticClass: "columns"
    }, [_c("div", {
      staticClass: "column"
    }, [_c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label"
    }, [_vm._v("Lote:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(item.batch))])]), _vm._v(" "), _c("div", {
      staticClass: "columns"
    }, [_c("div", {
      staticClass: "column is-two-fifths"
    }, [_c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label"
    }, [_vm._v("Produto:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.nameProduct(item)))])])]), _vm._v(" "), _c("div", {
      staticClass: "column is-one-third"
    }, [_c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label _85"
    }, [_vm._v("Variedade:")]), _vm._v(" "), _c("p", {
      staticClass: "_100"
    }, [_vm._v("\n                  " + _vm._s(_vm.nomeVariedade(item)) + "\n                ")])])]), _vm._v(" "), _c("div", {
      staticClass: "column"
    }, [_c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label _70"
    }, [_vm._v("Cultivar:")]), _vm._v(" "), _c("p", {
      staticClass: "_170"
    }, [_vm._v("\n                  " + _vm._s(_vm.nameCultivation(item)) + "\n                ")])])])]), _vm._v(" "), _c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label"
    }, [_vm._v("NFE:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(item.NFE))])]), _vm._v(" "), _c("div", {
      staticClass: "columns"
    }, [_c("div", {
      staticClass: "column is-two-fifths"
    }, [_c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label"
    }, [_vm._v("Tipo de embalagem:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(item.packageType))])])]), _vm._v(" "), _c("div", {
      staticClass: "column is-one-third"
    }, [_c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label _180"
    }, [_vm._v("Quantidade por porção:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(item.portionAmount))])])]), _vm._v(" "), _c("div", {
      staticClass: "column"
    }, [_c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label _135"
    }, [_vm._v("Quantidade caixa:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(item.boxQuantity))])])])]), _vm._v(" "), _c("div", {
      staticClass: "columns"
    }, [_c("div", {
      staticClass: "column is-two-fifths"
    }, [_c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label"
    }, [_vm._v("Classificação:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.nameClassification(item)))])])]), _vm._v(" "), _c("div", {
      staticClass: "column"
    }, [_c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label _145"
    }, [_vm._v("Peso Líquido caixa:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(item.netWeightBox || ""))])])])]), _vm._v(" "), _c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label"
    }, [_vm._v("Personalização produto:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.nameCustomization(item)))])]), _vm._v(" "), _c("div", {
      staticClass: "columns"
    }, [_c("div", {
      staticClass: "column is-two-fifths"
    }, [_c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label"
    }, [_vm._v("Data Fabricação:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm._f("date")(item.fabricationDate)))])])]), _vm._v(" "), _c("div", {
      staticClass: "column"
    }, [_c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label _110"
    }, [_vm._v("Data validade:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm._f("date")(item.expirationDate)))])])])]), _vm._v(" "), _c("div", {
      staticClass: "row-detail"
    }, [_c("label", {
      staticClass: "label"
    }, [_vm._v("Data da colheita:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm._f("date")(item.harvestDate)))])]), _vm._v(" "), _c("div", {
      staticClass: "content-btn-print"
    }, [_c("b-button", {
      on: {
        click: function click($event) {
          return _vm.printTag(item);
        }
      }
    }, [_c("i", {
      staticClass: "icon-ic_print"
    }), _vm._v("\n              Imprimir etiqueta de rastreabilidade\n            ")])], 1)])])]);
  }), 0), _vm._v(" "), _c("div", {
    staticClass: "content-btns-integrations"
  }, [_c("b-button", {
    staticClass: "btn-return-edit",
    on: {
      click: _vm.returnEdit
    }
  }, [_vm._v("\n      Adicionar outro produto\n    ")]), _vm._v(" "), _c("b-button", {
    staticClass: "btn-save",
    on: {
      click: _vm.saveTag
    }
  }, [_vm._v("\n      Concluir\n    ")])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;