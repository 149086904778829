"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-notification", {
    staticClass: "load-trasaction-content",
    attrs: {
      type: "is-warning",
      closable: false
    }
  }, [_vm._v("\n  " + _vm._s(_vm.message) + " "), _c("b-loading", {
    attrs: {
      "is-full-page": _vm.isFullPage,
      active: _vm.load,
      "can-cancel": false
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.load = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;