"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "auth-page-change-password"
  }, [_c("div", {
    staticClass: "container-auth"
  }, [_vm._m(0), _vm._v(" "), !_vm.updateSuccess ? _c("div", {
    staticClass: "wrap-auth"
  }, [_c("form", {
    staticClass: "auth-form validate-form",
    on: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_c("h2", {
    staticClass: "login-form-title"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.change_password.title")) + "\n        ")]), _vm._v(" "), _c("b-field", {
    attrs: {
      label: _vm.$t("pages.change_password.label_pass"),
      type: _vm.$v.form.password.$error || _vm.error ? "is-danger" : "",
      message: _vm.$v.form.password.$error ? _vm.$t("pages.change_password.alerts.error_password") : ""
    }
  }, [_c("b-input", {
    attrs: {
      required: "",
      type: "password",
      "password-reveal": true
    },
    nativeOn: {
      input: function input($event) {
        return _vm.setPassword($event.target.value);
      }
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.password"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: _vm.$t("pages.change_password.label_confirm"),
      type: _vm.$v.form.passwordConfirm.$error || _vm.error ? "is-danger" : "",
      message: _vm.$v.form.passwordConfirm.$error ? _vm.$t("pages.change_password.alerts.error_password_confirm") : ""
    }
  }, [_c("b-input", {
    attrs: {
      required: "",
      type: "password",
      "password-reveal": true
    },
    nativeOn: {
      input: function input($event) {
        return _vm.setPasswordConfirm($event.target.value);
      }
    },
    model: {
      value: _vm.form.passwordConfirm,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "passwordConfirm", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.passwordConfirm"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "container-auth-form-btn"
  }, [_c("div", {
    staticClass: "wrap-auth-form-btn"
  }, [_c("b-button", {
    attrs: {
      rounded: "",
      "native-type": "submit",
      disabled: _vm.isDisabled
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t("pages.change_password.btn_create")) + "\n            ")])], 1)])], 1)]) : _c("div", {
    staticClass: "wrap-auth register-success"
  }, [_c("h2", {
    staticClass: "login-form-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.change_password.text_new_password")) + "\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "description-success"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.change_password.text_password_success")) + "\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "container-auth-form-btn"
  }, [_c("div", {
    staticClass: "wrap-auth-form-btn"
  }, [_c("b-button", {
    attrs: {
      rounded: ""
    },
    on: {
      click: function click($event) {
        return _vm.redirect("auth/login");
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.change_password.go_login")) + "\n          ")])], 1)])])])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container-logo-auth"
  }, [_c("img", {
    staticClass: "logo-fibo-auth",
    attrs: {
      src: require("@/assets/logo.png")
    }
  })]);
}];
render._withStripped = true;