"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _store = _interopRequireDefault(require("../store"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var instance = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 180000
});
instance.interceptors.request.use(function (config) {
  if (_store.default.state.auth.user.token) {
    config.headers['Authorization'] = "Bearer ".concat(_store.default.state.auth.user.token);
  }
  if (config.url.indexOf('/fetch-registers-balance') >= 0) {
    config.baseURL = 'http://localhost:3000/v2/';
  }
  config.headers.Platform = 'web';
  return config;
}, function (error) {
  return Promise.reject(error.response);
});
instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (!error.response) {
    var err = {
      response: {
        data: {
          message: 'not_connection'
        }
      }
    };
    return Promise.reject(err);
  }
  return Promise.reject(error.response);
});
var _default = exports.default = instance;