"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-label": "Moda Cadastro Fornecedor",
      "aria-modal": "",
      scroll: "keep",
      "can-cancel": false
    },
    model: {
      value: _vm.isComponentModalActive,
      callback: function callback($$v) {
        _vm.isComponentModalActive = $$v;
      },
      expression: "isComponentModalActive"
    }
  }, [_c("div", {
    attrs: {
      id: "form-new-customer"
    }
  }, [_c("div", {
    staticClass: "modal-card",
    staticStyle: {
      width: "600px"
    }
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("\n          Incluir Novo Ciclo\n        ")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.close
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.name.$error,
      label: "Nome do Ciclo",
      placeholder: "Nome do Ciclo"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.name, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.name, "$model", $$v);
      },
      expression: "$v.form.name.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.form.description.$error,
      label: "Descrição",
      placeholder: "Descrição do ciclo",
      type: "textarea"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.description, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.description.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.description, "$model", $$v);
      },
      expression: "$v.form.description.$model"
    }
  })], 1), _vm._v(" "), _c("footer", {
    staticClass: "modal-card-foot"
  }, [_c("b-button", {
    attrs: {
      label: "Salvar"
    },
    on: {
      click: _vm.checkForm
    }
  })], 1)])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;