"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typeserviceService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var fetch = function fetch(farmId, products) {
  return (0, _http.default)({
    url: "/servicetypes",
    method: 'get',
    params: {
      farmId: farmId,
      productIds: products
    }
  });
};
var listScheduling = function listScheduling(farmId, products) {
  return (0, _http.default)({
    url: "/servicetypes/listscheduling",
    method: 'get',
    params: {
      farmId: farmId,
      productsIds: products
    }
  });
};
var create = function create(payload) {
  var data = {
    name: payload.name,
    description: payload.description
  };
  if (payload.tool) data.tool = payload.tool;
  if (payload.followUpTypeId) data.followUpTypeId = payload.followUpTypeId;
  if (payload.followUpTimeValue) data.followUpTimeValue = payload.followUpTimeValue;
  if (payload.followUpTimeUnit) data.followUpTimeUnit = payload.followUpTimeUnit;
  if (payload.products) data.products = payload.products;
  if (payload.farms) data.farms = payload.farms;
  return (0, _http.default)({
    url: "/servicetypes",
    method: 'post',
    data: data
  });
};
var update = function update(payload, id) {
  return (0, _http.default)({
    url: "/servicetypes/".concat(id),
    method: 'put',
    data: payload
  });
};
var deleteItem = function deleteItem(id) {
  return (0, _http.default)({
    url: "/servicetypes/".concat(id),
    method: 'delete'
  });
};
var getLinkedServiceStepTypes = function getLinkedServiceStepTypes(serviceTypeId) {
  return (0, _http.default)({
    url: "/servicesteptype/linked/servicetypes/".concat(serviceTypeId),
    method: 'get'
  });
};
var createServiceStepType = function createServiceStepType(data) {
  return (0, _http.default)({
    url: "/servicesteptype",
    method: 'post',
    data: data
  });
};
var deleteServiceStepType = function deleteServiceStepType(step) {
  return (0, _http.default)({
    url: "/servicesteptype/".concat(step),
    method: 'delete'
  });
};
var typeserviceService = exports.typeserviceService = {
  fetch: fetch,
  create: create,
  update: update,
  deleteItem: deleteItem,
  listScheduling: listScheduling,
  getLinkedServiceStepTypes: getLinkedServiceStepTypes,
  createServiceStepType: createServiceStepType,
  deleteServiceStepType: deleteServiceStepType
};