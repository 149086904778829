"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-field", {
    class: "itj-input-component ".concat(_vm.classField),
    attrs: {
      message: "",
      type: _vm.error ? "is-danger" : "",
      label: _vm.included && !_vm.computedValue ? "" : _vm.label,
      expanded: _vm.expanded,
      horizontal: _vm.horizontal
    }
  }, [_vm.mask.length ? _c("div", {
    staticClass: "control",
    class: {
      empty: !_vm.computedValue
    }
  }, [_c("the-mask", _vm._b({
    ref: "input",
    class: "input ".concat(_vm.classInput, " ").concat(_vm.error ? "is-danger" : ""),
    attrs: {
      autocomplete: _vm.complete,
      placeholder: _vm.placeholder,
      mask: _vm.mask,
      disabled: _vm.disabled,
      minlength: _vm.min,
      maxlength: _vm.max,
      masked: _vm.masked
    },
    nativeOn: {
      blur: function blur($event) {
        return _vm.onBlur.apply(null, arguments);
      },
      focus: function focus($event) {
        return _vm.onFocus.apply(null, arguments);
      }
    },
    model: {
      value: _vm.computedValue,
      callback: function callback($$v) {
        _vm.computedValue = $$v;
      },
      expression: "computedValue"
    }
  }, "the-mask", _vm.$attrs, false))], 1) : _c("b-input", _vm._b({
    class: _objectSpread({
      empty: !_vm.computedValue
    }, _vm.classInput),
    attrs: {
      autocomplete: _vm.complete,
      placeholder: _vm.placeholder,
      type: _vm.type,
      disabled: _vm.disabled,
      minlength: _vm.min,
      maxlength: _vm.max,
      "password-reveal": _vm.passwordReveal
    },
    on: {
      blur: _vm.onBlur,
      focus: _vm.onFocus
    },
    model: {
      value: _vm.computedValue,
      callback: function callback($$v) {
        _vm.computedValue = $$v;
      },
      expression: "computedValue"
    }
  }, "b-input", _vm.$attrs, false)), _vm._v(" "), _vm._t("default"), _vm._v(" "), _vm.description ? _c("span", {
    staticClass: "item-description-field"
  }, [_vm._v("\n    " + _vm._s(_vm.description) + "\n  ")]) : _vm._e()], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;