"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    class: "page-occurrences-list",
    attrs: {
      "return-menu": "/farm-management/dashboard",
      menu: _vm.menuItemsOccurrences.children,
      subtitle: _vm.menuItemsOccurrences.meta.title
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("div", {
    staticClass: "list-occurrences"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h3", {
    staticClass: "title-destaq-page"
  }, [_vm._v("\n          Lista de ocorrências\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "header-options"
  }, [_c("filter-date", {
    attrs: {
      "date-start": _vm.occurrenceDateStart,
      "date-end": _vm.occurrenceDateEnd
    },
    on: {
      "update:dateStart": function updateDateStart($event) {
        _vm.occurrenceDateStart = $event;
      },
      "update:date-start": function updateDateStart($event) {
        _vm.occurrenceDateStart = $event;
      },
      "update:dateEnd": function updateDateEnd($event) {
        _vm.occurrenceDateEnd = $event;
      },
      "update:date-end": function updateDateEnd($event) {
        _vm.occurrenceDateEnd = $event;
      },
      updateDate: _vm.updateDateFilter
    }
  })], 1), _vm._v(" "), _c("input-search", {
    attrs: {
      placeholder: "Pesquisar (Ex.: Descrição/ Classificação/ Criticidade)"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "services-grid-titles columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("h4", [_vm._v("\n            Não analisadas\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("h4", [_vm._v("\n            Em análise\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("h4", [_vm._v("\n            Analisadas\n          ")])])]), _vm._v(" "), _c("div", {
    staticClass: "services-grid columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "item-list-grid"
  }, _vm._l(_vm.arrayFilter(_vm.notReviewed), function (occurrence) {
    return _c("card", {
      key: occurrence.id,
      attrs: {
        date: occurrence.date,
        id: occurrence.id,
        subtitle: "Classificação",
        title: _vm.nameItem(occurrence),
        "users-list": [occurrence.user],
        status: _vm._f("convetCriticity")(occurrence.criticality),
        "status-class": occurrence.criticality,
        syze: "small"
      },
      nativeOn: {
        click: function click($event) {
          return _vm.openModal(occurrence);
        }
      }
    });
  }), 1)]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "item-list-grid"
  }, _vm._l(_vm.arrayFilter(_vm.inReview), function (occurrence) {
    return _c("card", {
      key: occurrence.id,
      attrs: {
        date: occurrence.date,
        id: occurrence.id,
        subtitle: "Classificação",
        title: _vm.nameItem(occurrence),
        "users-list": [occurrence.user],
        status: _vm._f("convetCriticity")(occurrence.criticality),
        "status-class": occurrence.criticality,
        syze: "small"
      },
      nativeOn: {
        click: function click($event) {
          return _vm.openModal(occurrence);
        }
      }
    });
  }), 1)]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "item-list-grid"
  }, _vm._l(_vm.arrayFilter(_vm.analyzeds), function (occurrence) {
    return _c("card", {
      key: occurrence.id,
      attrs: {
        date: occurrence.date,
        id: occurrence.id,
        subtitle: "Classificação",
        title: _vm.nameItem(occurrence),
        "users-list": [occurrence.user],
        status: _vm._f("convetCriticity")(occurrence.criticality),
        "status-class": occurrence.criticality,
        syze: "small"
      },
      nativeOn: {
        click: function click($event) {
          return _vm.openModal(occurrence);
        }
      }
    });
  }), 1)])])]), _vm._v(" "), _vm.showModal ? _c("modal-occurrence", {
    attrs: {
      "show-modal": _vm.showModal,
      "occurrence-selected": _vm.occurrenceSelected,
      "occurrence-types": _vm.allOccurrencesModel
    },
    on: {
      closedModal: _vm.closedModalOccurrence
    }
  }) : _vm._e()], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;