"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'MapMaker',
  data: function data() {
    return {
      center: {
        lat: -15.800639736833038,
        lng: -47.86137374761963
      },
      places: [],
      markers: [],
      position: {
        lat: -15.800639736833038,
        lng: -47.86137374761963
      },
      currentPlace: null,
      autocomplete: ''
    };
  },
  props: {
    lng: {
      type: [String, Number],
      default: null
    },
    lat: {
      type: [String, Number],
      default: null
    },
    horizontal: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'Pesquise pela localidade do fornecedor'
    },
    updateCenter: {
      type: [String, Number],
      default: null
    },
    mapType: {
      type: String,
      default: 'satellite'
    }
  },
  watch: {
    updateCenter: function updateCenter(val) {
      if (val) {
        this.setLocation();
      }
    }
  },
  mounted: function mounted() {
    this.setLocation();
  },
  methods: {
    setPlace: function setPlace(place) {
      this.currentPlace = place.geometry.location;
      this.position = place.geometry.location;
      this.center = place.geometry.location;
    },
    updateCoordinates: function updateCoordinates(location) {
      this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      };
      this.$emit('update:lat', location.latLng.lat());
      this.$emit('update:lng', location.latLng.lng());
    },
    geolocate: function geolocate() {
      var _this = this;
      navigator.geolocation.getCurrentPosition(function (position) {
        _this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        if (_this.lat !== '' && _this.lng !== '') {
          _this.position.lng = Number(_this.lng);
          _this.position.lat = Number(_this.lat);
          _this.center.lng = Number(_this.lng);
          _this.center.lat = Number(_this.lat);
        } else {
          _this.position = _this.center;
        }
        _this.$emit('update:lat', _this.center.lat);
        _this.$emit('update:lng', _this.center.lng);
      });
    },
    setLocation: function setLocation() {
      if (this.lat && this.lng) {
        var location = {
          lat: Number(this.lat),
          lng: Number(this.lng)
        };
        this.center = location;
        this.position = location;
      }
    }
  }
};
exports.default = _default;