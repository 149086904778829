"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    staticClass: "page-farm-data",
    attrs: {
      menu: _vm.menuItemsFarm.children,
      "sub-menu": _vm.menuItemsFarm.subMenu,
      "return-text": "Lista de fazendas",
      "return-menu": _vm.menuItemsFarm.path
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("header", {
    staticClass: "header"
  }, [_c("h3", [_vm._v("\n        " + _vm._s(_vm.$t("pages.farmdata.title")) + "\n      ")]), _vm._v(" "), _c("h6", [_vm._v("\n        " + _vm._s(_vm.$t("pages.farmdata.subtitle")) + "\n      ")])]), _vm._v(" "), _c("form", {
    staticClass: "form-data-farm",
    on: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.submitFormFarm.apply(null, arguments);
      }
    }
  }, [_c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.farm_name"),
      type: _vm.$v.form.name.$error ? "is-danger" : "",
      message: _vm.$v.form.name.$error ? "Nome da fazenda é obrigatório" : ""
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "Nome da fazenda"
    },
    model: {
      value: _vm.$v.form.name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.name, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.name.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "field-uf",
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.initials_name"),
      type: _vm.$v.form.initials.$error ? "is-danger" : "",
      message: _vm.$v.form.initials.$error ? "Sigla da fazenda é obrigatória" : ""
    }
  }, [_c("div", {
    staticClass: "control is-clearfix"
  }, [_c("the-mask", {
    staticClass: "input",
    class: {
      "is-danger": _vm.$v.form.initials.$error
    },
    attrs: {
      autocomplete: "off",
      placeholder: "ABC",
      mask: ["AAA"]
    },
    model: {
      value: _vm.$v.form.initials.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.initials, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.initials.$model"
    }
  })], 1)])], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.address"),
      type: _vm.$v.form.address.$error ? "is-danger" : "",
      message: _vm.$v.form.address.$error ? "Endereço da fazenda é obrigatório" : ""
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "Endereço completo da fazenda"
    },
    model: {
      value: _vm.$v.form.address.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.address, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.address.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.city"),
      type: _vm.$v.form.city.$error ? "is-danger" : "",
      message: _vm.$v.form.city.$error ? "Informe a cidade" : ""
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "Nome da cidade"
    },
    model: {
      value: _vm.$v.form.city.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.city, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.city.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.state"),
      type: _vm.$v.form.state.$error ? "is-danger" : "",
      message: _vm.$v.form.state.$error ? "Informe o estado" : ""
    }
  }, [_c("div", {
    staticClass: "control is-clearfix"
  }, [_c("b-select", {
    attrs: {
      placeholder: "Estado"
    },
    model: {
      value: _vm.$v.form.state.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.state, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.state.$model"
    }
  }, _vm._l(_vm.states, function (state, index) {
    return _c("option", {
      key: index,
      domProps: {
        value: state.uf
      }
    }, [_vm._v("\n                " + _vm._s(state.uf) + " - " + _vm._s(state.description) + "\n              ")]);
  }), 0)], 1)])], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.phone"),
      type: _vm.$v.form.phone.$error ? "is-danger" : "",
      message: _vm.$v.form.phone.$error ? "Telefone é obrigatório" : ""
    }
  }, [_c("div", {
    staticClass: "control is-clearfix"
  }, [_c("the-mask", {
    staticClass: "input",
    attrs: {
      autocomplete: "off",
      placeholder: "(00) 00000-0000",
      mask: ["(##) #####-####"]
    },
    model: {
      value: _vm.$v.form.phone.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.phone, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.phone.$model"
    }
  })], 1)]), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.cnpj_cnpf"),
      type: _vm.$v.form.cnpj.$error ? "is-danger" : "",
      message: _vm.$v.form.cnpj.$error ? "CNPJ ou CPF da fazenda é obrigatório" : ""
    }
  }, [_c("div", {
    staticClass: "control is-clearfix"
  }, [_c("the-mask", {
    staticClass: "input",
    class: {
      "is-danger": _vm.$v.form.cnpj.$error
    },
    attrs: {
      autocomplete: "off",
      placeholder: "00.000.000/0000-00 ou 000.000.000-00",
      mask: ["###.###.###-##", "##.###.###/####-##"]
    },
    model: {
      value: _vm.$v.form.cnpj.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.cnpj, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.cnpj.$model"
    }
  })], 1)]), _vm._v(" "), _c("b-field", {
    attrs: {
      type: _vm.$v.form.stateRegistration.$error ? "is-danger" : "",
      label: _vm.$t("pages.farmdata.inputs.state_registration")
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "Número da inscrição"
    },
    model: {
      value: _vm.$v.form.stateRegistration.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.stateRegistration, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.stateRegistration.$model"
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.technical_manager"),
      type: _vm.$v.form.technicalManager.$error ? "is-danger" : "",
      message: _vm.$v.form.technicalManager.$error ? "Responsável técnico é obrigatório" : ""
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "Nome do responsável técnico"
    },
    model: {
      value: _vm.$v.form.technicalManager.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.technicalManager, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.technicalManager.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.crea"),
      type: _vm.$v.form.technicalIdentification.$error ? "is-danger" : "",
      message: _vm.$v.form.technicalIdentification.$error ? "Informe o CREA" : ""
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "Número de identificação"
    },
    model: {
      value: _vm.$v.form.technicalIdentification.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.technicalIdentification, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.technicalIdentification.$model"
    }
  })], 1)], 1), _vm._v(" "), _c("h3", {
    staticClass: "title-header-field"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.farmdata.title_owner")) + "\n      ")]), _vm._v(" "), _c("b-field", {
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.owner")
    }
  }, [_c("b-input", {
    attrs: {
      disabled: "",
      placeholder: "Nome completo do proprietário"
    },
    model: {
      value: _vm.owner.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.owner, "firstName", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "owner.firstName"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.email")
    }
  }, [_c("b-input", {
    attrs: {
      disabled: "",
      placeholder: "name@servidor.com"
    },
    model: {
      value: _vm.owner.email,
      callback: function callback($$v) {
        _vm.$set(_vm.owner, "email", $$v);
      },
      expression: "owner.email"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.phone")
    }
  }, [_c("div", {
    staticClass: "control is-clearfix"
  }, [_c("the-mask", {
    staticClass: "input",
    attrs: {
      autocomplete: "off",
      placeholder: "(00) 00000-0000",
      mask: ["(##) #####-####"],
      disabled: ""
    },
    model: {
      value: _vm.owner.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.owner, "phone", $$v);
      },
      expression: "owner.phone"
    }
  })], 1)]), _vm._v(" "), _c("b-field", {
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.cpf")
    }
  }, [_c("div", {
    staticClass: "control is-clearfix"
  }, [_c("the-mask", {
    staticClass: "input",
    attrs: {
      autocomplete: "off",
      placeholder: "000.000.000-00",
      mask: ["###.###.###-##"],
      disabled: ""
    },
    model: {
      value: _vm.owner.cpf,
      callback: function callback($$v) {
        _vm.$set(_vm.owner, "cpf", $$v);
      },
      expression: "owner.cpf"
    }
  })], 1)]), _vm._v(" "), _c("map-maker-card", {
    attrs: {
      lat: _vm.form.lat,
      lng: _vm.form.lng,
      horizontal: false,
      "update-center": _vm.updateCenterMap,
      "map-type": "terrain",
      placeholder: "Pesquise a localidade"
    },
    on: {
      "update:lat": function updateLat($event) {
        return _vm.$set(_vm.form, "lat", $event);
      },
      "update:lng": function updateLng($event) {
        return _vm.$set(_vm.form, "lng", $event);
      }
    }
  }), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.lat.$error,
      label: _vm.$t("pages.farmdata.inputs.lat"),
      placeholder: "Latitude",
      disabled: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.lat, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.lat.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.lat, "$model", $$v);
      },
      expression: "$v.form.lat.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.form.lng.$error,
      label: _vm.$t("pages.farmdata.inputs.lng"),
      placeholder: "Longitude",
      disabled: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.lng, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.lng.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.lng, "$model", $$v);
      },
      expression: "$v.form.lng.$model"
    }
  })], 1), _vm._v(" "), _c("app-input", {
    staticClass: "field-about",
    attrs: {
      error: _vm.$v.form.about.$error,
      label: _vm.$t("pages.farmdata.inputs.about"),
      placeholder: _vm.$t("pages.farmdata.inputs.about"),
      type: "textarea"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.about, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.about.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.about, "$model", $$v);
      },
      expression: "$v.form.about.$model"
    }
  }), _vm._v(" "), _c("b-field", {
    staticClass: "field-images",
    attrs: {
      label: _vm.$t("pages.farmdata.inputs.images")
    }
  }, [_c("div", {
    staticClass: "content-images"
  }, [_c("b-upload", {
    staticClass: "new-image",
    model: {
      value: _vm.imageUpload,
      callback: function callback($$v) {
        _vm.imageUpload = $$v;
      },
      expression: "imageUpload"
    }
  }, [!_vm.loadingImage ? _c("span", {
    staticClass: "btn-upload"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/add-file.png")
    }
  })]) : _vm._e()]), _vm._v(" "), _vm._l(_vm.form.images, function (img, idx) {
    return _c("label", {
      key: idx,
      staticClass: "upload control"
    }, [_c("span", {
      staticClass: "img-upload"
    }, [_c("img", {
      staticClass: "preview",
      attrs: {
        src: img
      }
    })]), _vm._v(" "), _c("b-button", {
      staticClass: "btn-delete",
      on: {
        click: function click($event) {
          return _vm.deleteImage(img);
        }
      }
    }, [_c("i", {
      staticClass: "icon-ic_menos"
    })])], 1);
  })], 2)]), _vm._v(" "), _c("div", {
    staticClass: "field btns-actions is-horizontal"
  }, [_c("div", {
    staticClass: "field"
  }, [_c("button", {
    staticClass: "button is-secundary",
    attrs: {
      type: "button",
      disabled: _vm.edited
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.cancel.apply(null, arguments);
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.farmdata.cancel")) + "\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "field"
  }, [_c("button", {
    staticClass: "button is-primary",
    attrs: {
      type: "submit"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.farmdata.save")) + "\n          ")])])])], 1)])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;