"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "layout",
    class: "wraper-container-".concat(this.$route.name)
  }, [_c("sidebar"), _vm._v(" "), _c("div", {
    staticClass: "content-wrap",
    attrs: {
      id: "content-wrap"
    }
  }, [_c("main", {
    staticClass: "content",
    attrs: {
      id: "content",
      role: "main"
    }
  }, [_c("nav", {
    staticClass: "navbar navbar-fiboo"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "navbar-menu"
  }, [_vm.showNotifications ? _c("div", {
    staticClass: "navbar-end"
  }, [_c("b-dropdown", {
    attrs: {
      position: "is-bottom-left",
      "append-to-body": "",
      "aria-role": "menu",
      "trap-focus": ""
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c("a", {
          staticClass: "navbar-item",
          attrs: {
            role: "button"
          },
          on: {
            click: _vm.openOccurrence
          }
        }, [_c("span", [_vm._v("Avisos")]), _vm._v(" "), _c("b-icon", {
          class: {
            "icon-notification-alert": _vm.isNotifications
          },
          attrs: {
            icon: _vm.iconNotification
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3083165892)
  }, [_vm._v(" "), _vm._l(_vm.listAllNotifications, function (item, idx) {
    return _c("b-dropdown-item", {
      key: idx,
      attrs: {
        "aria-role": "listitem"
      }
    }, [_c("div", {
      staticClass: "media"
    }, [_c("b-icon", {
      staticClass: "media-left",
      attrs: {
        icon: "file-document-alert"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "media-content"
    }, [_c("h3", [_vm._v(_vm._s(item.title))]), _vm._v(" "), _c("small", [_vm._v(_vm._s(item.description))])])], 1)]);
  }), _vm._v(" "), !_vm.listAllNotifications.length ? _c("b-dropdown-item", {
    attrs: {
      custom: "",
      "aria-role": "listitem"
    }
  }, [_vm._v("\n                Sem notificações até o momento\n              ")]) : _vm._e()], 2)], 1) : _vm._e()])]), _vm._v(" "), _c("keep-alive", {
    attrs: {
      include: "foo"
    }
  }, [_c("router-view")], 1)], 1)]), _vm._v(" "), _c("b-loading", {
    attrs: {
      "is-full-page": true,
      active: _vm.isLoading,
      "can-cancel": false
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.isLoading = $event;
      }
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar-brand"
  }, [_c("a", {
    staticClass: "navbar-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/logo.png"),
      alt: "Fiboo"
    }
  })])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;