"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "component-content-new-tag"
  }, [_c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Fornecedor",
      placeholder: "Selecione o fornecedor",
      error: _vm.$v.form.provideId.$error,
      items: _vm.listProviders,
      disabled: _vm.blockEdit,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.provideId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.provideId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.provideId, "$model", $$v);
      },
      expression: "$v.form.provideId.$model"
    }
  }), _vm._v(" "), _c("b-button", {
    staticClass: "btn-add-field",
    on: {
      click: _vm.openModalNewProvider
    }
  }, [_vm._v("\n        Novo fornecedor\n      ")])], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Cliente(Comprador)",
      placeholder: "Selecione o cliente",
      error: _vm.$v.form.clientId.$error,
      items: _vm.listCustomers,
      disabled: _vm.blockEdit,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.clientId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.clientId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.clientId, "$model", $$v);
      },
      expression: "$v.form.clientId.$model"
    }
  }), _vm._v(" "), _c("b-button", {
    staticClass: "btn-add-field",
    on: {
      click: _vm.openModalNewCustomer
    }
  }, [_vm._v("\n        Novo cliente\n      ")])], 1)], 1), _vm._v(" "), _vm.editForm ? _c("div", [_c("b-field", [_c("b-checkbox", {
    model: {
      value: _vm.$v.form.includeSupplierLabel.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.includeSupplierLabel, "$model", $$v);
      },
      expression: "$v.form.includeSupplierLabel.$model"
    }
  }, [_vm._v("\n        Incluir os dados do Fornecedor na etiqueta\n      ")])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.editForm && _vm.listTraceabilityTagsCreate.length ? _c("b-button", {
    on: {
      click: _vm.goListProducts
    }
  }, [_vm._v("\n    Ir para lista de produtos\n  ")]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "divider"
  }), _vm._v(" "), _vm.editForm ? _c("div", [_c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Produto",
      placeholder: "Selecione o produto",
      error: _vm.$v.form.productId.$error,
      items: _vm.listProducts,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.productId, "$error", $event);
      }
    },
    model: {
      value: _vm.productId,
      callback: function callback($$v) {
        _vm.productId = $$v;
      },
      expression: "productId"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Variedade",
      placeholder: "Selecione a variedade",
      error: _vm.$v.form.varietyId.$error,
      items: _vm.listVarieties,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.varietyId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.varietyId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.varietyId, "$model", $$v);
      },
      expression: "$v.form.varietyId.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Cultivar",
      placeholder: "Selecione o cultivo",
      error: _vm.$v.form.cultivationId.$error,
      items: _vm.listCultivations,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.cultivationId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.cultivationId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.cultivationId, "$model", $$v);
      },
      expression: "$v.form.cultivationId.$model"
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      expanded: "",
      label: "Tipo de embalagem"
    }
  }, [_c("div", {
    staticClass: "block"
  }, _vm._l(_vm.listEmbalagens, function (item, idx) {
    return _c("b-radio", {
      key: idx,
      attrs: {
        "native-value": item.id,
        name: "tipoEmbalagem"
      },
      model: {
        value: _vm.$v.form.packageType.$model,
        callback: function callback($$v) {
          _vm.$set(_vm.$v.form.packageType, "$model", $$v);
        },
        expression: "$v.form.packageType.$model"
      }
    }, [_vm._v("\n            " + _vm._s(item.description) + "\n          ")]);
  }), 1)]), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.NFE.$error,
      mask: ["##############"],
      label: "NFE",
      placeholder: "Número da NFE"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.NFE, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.NFE.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.NFE, "$model", $$v);
      },
      expression: "$v.form.NFE.$model"
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.portionAmount.$error,
      label: "Quantidade por porção",
      placeholder: "Informe a quantidade por porção"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.portionAmount, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.portionAmount.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.portionAmount, "$model", $$v);
      },
      expression: "$v.form.portionAmount.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.boxQuantity.$error,
      label: "Quantidade Caixa",
      placeholder: "Informe a quantidade da caixa"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.boxQuantity, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.boxQuantity.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.boxQuantity, "$model", $$v);
      },
      expression: "$v.form.boxQuantity.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "field-classification",
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Classificação",
      placeholder: "Selecione a classificação",
      error: _vm.$v.form.classification.$error,
      items: _vm.listClassifications,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.classification, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.classification.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.classification, "$model", $$v);
      },
      expression: "$v.form.classification.$model"
    }
  }), _vm._v(" "), _vm.showDetailClassication ? _c("b-button", {
    staticClass: "btn-detail-classification",
    on: {
      click: _vm.openDetailClassication
    }
  }, [_c("i", {
    staticClass: "icon-ic_info"
  })]) : _vm._e()], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", [_c("app-input", {
    attrs: {
      error: _vm.$v.form.netWeightBox.$error,
      label: "Peso líquido caixa",
      placeholder: "Informe o peso líquido da caixa"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.netWeightBox, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.netWeightBox.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.netWeightBox, "$model", $$v);
      },
      expression: "$v.form.netWeightBox.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Personalização produto",
      placeholder: "Personalização do produto",
      error: _vm.$v.form.productCustomizationId.$error,
      items: _vm.listProductCustomization,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.productCustomizationId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.productCustomizationId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.productCustomizationId, "$model", $$v);
      },
      expression: "$v.form.productCustomizationId.$model"
    }
  }), _vm._v(" "), _c("b-button", {
    staticClass: "btn-add-field",
    on: {
      click: _vm.openModalNewProductCustomization
    }
  }, [_vm._v("\n          Nova personalização\n        ")])], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.fabricationDate.$error,
      label: "Data fabricação",
      placeholder: "DD/MM/AAAA",
      mask: ["##/##/####"],
      masked: true
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.fabricationDate, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.fabricationDate.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.fabricationDate, "$model", $$v);
      },
      expression: "$v.form.fabricationDate.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.expirationDate.$error,
      label: "Data validade",
      placeholder: "DD/MM/AAAA",
      mask: ["##/##/####"],
      masked: true
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.expirationDate, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.expirationDate.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.expirationDate, "$model", $$v);
      },
      expression: "$v.form.expirationDate.$model"
    }
  })], 1), _vm._v(" "), false ? _c("b-field", {
    staticClass: "field-radio-dates"
  }, [_c("b-radio", {
    attrs: {
      size: "is-small",
      "native-value": "data-manual"
    },
    model: {
      value: _vm.$v.form.typeDataManual.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.typeDataManual, "$model", $$v);
      },
      expression: "$v.form.typeDataManual.$model"
    }
  }, [_vm._v("\n          Informar datas manualmente na etiqueta\n        ")]), _vm._v(" "), _c("b-radio", {
    attrs: {
      size: "is-small",
      "native-value": "carimbo-datador"
    },
    model: {
      value: _vm.$v.form.typeDataManual.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.typeDataManual, "$model", $$v);
      },
      expression: "$v.form.typeDataManual.$model"
    }
  }, [_vm._v("\n          Informar datas com carimbo datador\n        ")])], 1) : _vm._e()], 1), _vm._v(" "), _c("h4", [_vm._v("Informações da colheita")]), _vm._v(" "), _c("b-field", {
    staticStyle: {
      "max-width": "305px"
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.harvestDate.$error,
      label: "Data colheita",
      placeholder: "DD/MM/AAAA",
      mask: ["##/##/####"],
      masked: true
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.harvestDate, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.harvestDate.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.harvestDate, "$model", $$v);
      },
      expression: "$v.form.harvestDate.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Talhão",
      placeholder: "Selecione o talhão",
      items: _vm.allPlots,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    model: {
      value: _vm.plotColheita,
      callback: function callback($$v) {
        _vm.plotColheita = $$v;
      },
      expression: "plotColheita"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "SubTalhão",
      placeholder: "Selecione o SubTalhão",
      items: _vm.listSubPlot,
      option: "id",
      "name-item": "nickname",
      expanded: ""
    },
    model: {
      value: _vm.subPlotColheita,
      callback: function callback($$v) {
        _vm.subPlotColheita = $$v;
      },
      expression: "subPlotColheita"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "field-button-add",
    attrs: {
      expanded: ""
    }
  }, [_c("b-button", {
    staticClass: "btn-add-rounded",
    on: {
      click: _vm.addNewHarvest
    }
  }, [_vm._v("\n          +\n        ")])], 1)], 1), _vm._v(" "), _vm.form.harvests.length ? _c("b-field", [_c("b-table", {
    attrs: {
      data: _vm.form.harvests
    }
  }, [_c("b-table-column", {
    attrs: {
      field: "plot",
      label: "Talhão"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n          " + _vm._s(props.row.plot) + "\n        ")];
      }
    }], null, false, 3023930458)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "subPlot",
      label: "SubTalhão"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n          " + _vm._s(props.row.subPlot) + "\n        ")];
      }
    }], null, false, 1186978942)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-button", {
          staticClass: "btn-remove-rounded",
          on: {
            click: function click($event) {
              return _vm.removeItemHarvests(props.row);
            }
          }
        }, [_vm._v("\n            -\n          ")])];
      }
    }], null, false, 3563665578)
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "content-btn"
  }, [_vm.editForm ? _c("b-button", {
    staticClass: "btn-save",
    on: {
      click: _vm.saveEtiqueta
    }
  }, [_vm._v("\n        Salvar produto\n      ")]) : _vm._e()], 1)], 1) : _c("div", [!_vm.editForm ? _c("list-traceability-tag", {
    attrs: {
      "edit-form": _vm.editForm,
      "set-form": _vm.setFormEdit,
      "return-edit": _vm.returnEdit,
      "reset-form": _vm.resetForm,
      "list-products": _vm.listProducts
    },
    on: {
      "update:editForm": function updateEditForm($event) {
        _vm.editForm = $event;
      },
      "update:edit-form": function updateEditForm($event) {
        _vm.editForm = $event;
      }
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;