"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'CardPaymentDetails',
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  computed: {
    descriptions: function descriptions() {
      return this.plan.descriptions || [];
    }
  }
};
exports.default = _default;