"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    staticClass: "faq-page",
    attrs: {
      menu: _vm.menuItems.children,
      "sub-menu": _vm.menuItems.subMenu,
      "return-text": "Lista de fazendas",
      "return-menu": _vm.menuItems.path
    }
  }, [_c("template", {
    slot: "context"
  }, _vm._l(_vm.lista, function (list, idx) {
    return _c("div", {
      key: idx
    }, [_c("list", {
      attrs: {
        questions: list.title
      }
    }, [_c("response", {
      attrs: {
        answer: list.description
      }
    }), _vm._v(" "), _vm._l(list.itens, function (iten) {
      return _c("response", {
        key: iten,
        attrs: {
          answer: iten
        }
      });
    }), _vm._v(" "), _c("ul", [list.url.length !== 0 ? _c("li", {
      staticClass: "answer"
    }, [_vm._v("\n            " + _vm._s(_vm.msg) + "\n            "), _c("a", {
      staticClass: "link",
      attrs: {
        href: list.url,
        target: "_blank"
      }
    }, [_vm._v(_vm._s(_vm.pdf))])]) : _vm._e()]), _vm._v(" "), list.detail.title.length !== 0 ? _c("acess", [_c("router-link", {
      staticClass: "redirection",
      attrs: {
        to: list.detail.link
      }
    }, [_vm._v("\n            " + _vm._s(list.detail.title) + "\n          ")])], 1) : _vm._e()], 2)], 1);
  }), 0)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;