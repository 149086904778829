"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'UserList',
  data: function data() {
    return {
      imgDefault: require('@/assets/images/user-icon.png')
    };
  },
  props: {
    users: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  }
};
exports.default = _default2;