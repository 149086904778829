"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
var _vue = _interopRequireDefault(require("vue"));
var _buefy = _interopRequireDefault(require("buefy"));
var _vuelidate = _interopRequireDefault(require("vuelidate"));
var _vuelayers = _interopRequireDefault(require("vuelayers"));
var _vueQrcodeComponent = _interopRequireDefault(require("vue-qrcode-component"));
var _vueFullscreen = _interopRequireDefault(require("vue-fullscreen"));
var _vueTelInput = _interopRequireDefault(require("vue-tel-input"));
require("vuelayers/lib/style.css");
var GmapVue = _interopRequireWildcard(require("gmap-vue"));
var _vueTheMask = _interopRequireDefault(require("vue-the-mask"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
_vue.default.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    libraries: 'places,drawing,visualization'
  },
  installComponents: true
});
_vue.default.config.productionTip = false;
_vue.default.use(_vuelayers.default, {
  dataProjection: 'EPSG:4326'
});
_vue.default.use(_vuelidate.default);
_vue.default.use(_buefy.default, {
  defaultDayNames: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  defaultMonthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
  defaultFirstDayOfWeek: 0,
  defaultDateFormatter: function defaultDateFormatter(date) {
    return date.toLocaleDateString('pt-BR');
  }
});
_vue.default.use(_vueTelInput.default);
_vue.default.use(_vueFullscreen.default);
_vue.default.use(_vueTheMask.default);
_vue.default.component('qr-code', _vueQrcodeComponent.default);