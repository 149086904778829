"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    staticClass: "stock-page",
    attrs: {
      menu: _vm.menuItems.children,
      "sub-menu": _vm.menuItems.subMenu,
      "return-text": "Lista de fazendas",
      "return-menu": _vm.menuItems.path
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h2", {
    staticClass: "header-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.stock.title")) + "\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "search-bar"
  }, [_c("div", {
    staticClass: "field search-input"
  }, [_c("div", {
    staticClass: "control has-icons-right is-clearfix"
  })]), _vm._v(" "), _c("div", {
    staticClass: "field-new-item"
  }, [_c("b-button", {
    on: {
      click: _vm.openModalProduct
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.stock.btn_add_local")) + "\n          ")])], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "table-items-list"
  }, [_c("b-table", {
    attrs: {
      data: _vm.isEmpty ? [] : _vm.filterStock,
      striped: true,
      hoverable: true,
      loading: _vm.isLoading,
      "mobile-cards": true,
      paginated: _vm.isPaginated,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "pagination-simple": _vm.isPaginationSimple,
      "default-sort-direction": _vm.defaultSortDirection
    },
    on: {
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  }, [[_c("b-table-column", {
    attrs: {
      field: "name",
      label: _vm.$t("pages.stock.labels.product"),
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("a", {
          on: {
            click: function click($event) {
              return _vm.registerProduct(props.row);
            }
          }
        }, [_vm._v("\n              " + _vm._s(props.row.name) + "\n            ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "quantity",
      width: "180",
      label: _vm.$t("pages.stock.labels.amount"),
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("a", {
          on: {
            click: function click($event) {
              return _vm.registerProduct(props.row);
            }
          }
        }, [_vm._v("\n              " + _vm._s(_vm._f("stockQuantityMask")(props.row.quantity)) + "\n            ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "supplier",
      width: "386",
      label: _vm.$t("pages.stock.labels.provider"),
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("a", {
          on: {
            click: function click($event) {
              return _vm.registerProduct(props.row);
            }
          }
        }, [_vm._v("\n              " + _vm._s(props.row.supplier) + "\n            ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("button", {
          staticClass: "button is-warnin edit-item",
          attrs: {
            type: "button"
          },
          on: {
            click: function click($event) {
              return _vm.openModalProduct(props.row);
            }
          }
        }, [_c("span", {
          staticClass: "icon is-small"
        }, [_c("i", {
          staticClass: "icon-ic_edit"
        })])]), _vm._v(" "), _c("button", {
          staticClass: "button is-danger delete-item",
          attrs: {
            type: "button"
          },
          on: {
            click: function click($event) {
              return _vm.verifyDelete(props.row);
            }
          }
        }, [_c("span", {
          staticClass: "icon is-small"
        }, [_c("i", {
          staticClass: "icon-ic_excluir"
        })])])];
      }
    }])
  })], _vm._v(" "), _c("template", {
    slot: "empty"
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-grey has-text-centered"
  }, [_c("p", [_c("b-icon", {
    attrs: {
      icon: "emoticon-sad",
      size: "is-large"
    }
  })], 1), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.$t("pages.stock.no_results")))])])])])], 2)], 1)])], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;