"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cyclesService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var fectchAllCyclesServices = function fectchAllCyclesServices(params) {
  return (0, _http.default)({
    url: "/cycles-services",
    method: 'get',
    params: params
  });
};
var createCyclesServices = function createCyclesServices(payload) {
  return (0, _http.default)({
    url: "/cycles-services",
    method: 'post',
    data: payload
  });
};
var cyclesService = {
  fectchAllCyclesServices: fectchAllCyclesServices,
  createCyclesServices: createCyclesServices
};
exports.cyclesService = cyclesService;