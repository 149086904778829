"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default2 = exports.default = {
  name: 'InputSearch',
  props: {
    value: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: 'icon-ic_search'
    },
    syze: {
      type: Number,
      default: 400
    },
    placeholderSelect: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    filterOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    filterValue: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    textNewItem: {
      type: String,
      default: undefined
    },
    clickNewItem: {
      type: Function,
      default: function _default() {}
    },
    addFilterOptions: {
      type: Function,
      default: function _default() {}
    }
  },
  watch: {
    search: function search(val) {
      this.$emit('input', val);
    },
    filter: function filter(val) {
      // this.$emit('update:filterValue', val)
      this.$emit('filterValue', val);
      this.addFilterOptions(val);
    }
  },
  data: function data() {
    return {
      search: this.value,
      filter: this.filterValue
    };
  },
  computed: {
    rowsFilter: function rowsFilter() {
      return _lodash.default.chunk(this.filterOptions, 3);
    }
  },
  methods: {
    clickItem: function clickItem() {
      this.$emit('clickNewItem');
    }
  }
};