"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderServicesService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var fectchAllOrderServices = function fectchAllOrderServices(params) {
  return (0, _http.default)({
    url: "/order-service",
    method: 'get',
    params: params
  });
};
var createOrderService = function createOrderService(payload) {
  return (0, _http.default)({
    url: "/order-service",
    method: 'post',
    data: payload
  });
};
var orderServicesService = {
  fectchAllOrderServices: fectchAllOrderServices,
  createOrderService: createOrderService
};
exports.orderServicesService = orderServicesService;