"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'CardNutritionalInformation',
  props: {
    information: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      itensList: [{
        name: 'Valor energético',
        percent: 'valorEnergeticoML',
        vd: 'valorEnergeticoVD'
      }, {
        name: 'Carboidratos',
        percent: 'carboidratoTotaisML',
        vd: 'carboidratoTotaisVD'
      }, {
        name: 'Proteínas',
        percent: 'proteinasML',
        vd: 'proteinasVD'
      }, {
        name: 'Gordura totais',
        percent: 'gorduraTotaisML',
        vd: 'gorduraTotaisVD'
      }, {
        name: 'Gordura saturadas',
        percent: 'gorduraSaturadasML',
        vd: 'gorduraSaturadasVD'
      }, {
        name: 'Gordura trans',
        percent: 'gorduraTransML',
        vd: 'gorduraTransVD'
      }, {
        name: 'Fibra alimentar',
        percent: 'fibraAlimentarML',
        vd: 'fibraAlimentarVD'
      }, {
        name: 'Sódio',
        percent: 'sodioML',
        vd: 'sodioVD'
      }]
    };
  }
};