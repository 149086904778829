"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-payment-details"
  }, [_c("div", {
    staticClass: "header"
  }, [_vm._v("\n    " + _vm._s(_vm.plan.name) + "\n  ")]), _vm._v(" "), _c("div", {
    staticClass: "list-itens-details"
  }, _vm._l(_vm.descriptions, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "list-item"
    }, [_c("span", {
      domProps: {
        innerHTML: _vm._s(item)
      }
    })]);
  }), 0)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;