"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'DialogAlert',
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'is-primary'
    },
    size: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: function _default() {
        return 'OK';
      }
    },
    cancelText: {
      type: String,
      default: function _default() {
        return 'Cancelar';
      }
    },
    onConfirm: {
      type: Function,
      default: function _default() {}
    },
    product: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    showCancel: function showCancel() {
      return this.cancelText.length;
    }
  },
  methods: {
    closeModal: function closeModal() {
      this.$parent.close();
    },
    confirm: function confirm() {
      this.$parent.close();
      this.onConfirm(this.product);
    }
  }
};
exports.default = _default2;