"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "modal-occurrence",
    attrs: {
      active: _vm.showModalItem,
      width: 1130,
      scroll: "keep",
      "can-cancel": ["escape"],
      "on-cancel": _vm.closedModalItem
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.showModalItem = $event;
      }
    }
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "card-header"
  }, [_c("h1", [_vm._v("Ocorrência registrada")]), _vm._v(" "), _c("button", {
    staticClass: "button is-text close",
    attrs: {
      type: "button",
      "data-dismiss": "modal",
      "aria-label": "Close"
    },
    on: {
      click: _vm.closedModalItem
    }
  }, [_c("i", {
    staticClass: "icon-ic_close"
  })])]), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "field date-filter"
  }, [_c("button", {
    staticClass: "button",
    attrs: {
      type: "button"
    }
  }, [_c("span", [_c("i", {
    staticClass: "icon-green icon-ic_calendar"
  })]), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm._f("date")(_vm.ocurrence.date)))])])]), _vm._v(" "), _c("div", {
    staticClass: "columns items-scope-details"
  }, [_c("div", {
    staticClass: "column slider-carousel"
  }, [_c("div", {
    staticClass: "field field-desc-photos"
  }, [_c("button", {
    staticClass: "button",
    attrs: {
      type: "button"
    }
  }, [_c("span", [_c("i", {
    staticClass: "icon-green icon-ic_calendar"
  })]), _vm._v(" "), _c("span", [_vm._v("Fotos")])])]), _vm._v(" "), _vm.ocurrence.images && _vm.ocurrence.images.length ? _c("Carousel", {
    attrs: {
      cards: _vm.getImages(_vm.ocurrence.images)
    }
  }) : _c("div", {
    staticClass: "no-images-msg"
  }, [_c("p", [_vm._v("Sem imagens registradas")])])], 1), _vm._v(" "), _c("div", {
    staticClass: "column items-inputs-details"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    staticClass: "select-square",
    attrs: {
      label: "Classificação"
    }
  }, [_c("b-select", {
    attrs: {
      placeholder: "Selecionar"
    },
    model: {
      value: _vm.ocurrence.typeId,
      callback: function callback($$v) {
        _vm.$set(_vm.ocurrence, "typeId", $$v);
      },
      expression: "ocurrence.typeId"
    }
  }, [_c("option", {
    attrs: {
      value: ""
    }
  }, [_vm._v("\n                      Selecionar\n                    ")]), _vm._v(" "), _vm._l(_vm.occurrenceTypes, function (item, index) {
    return _c("option", {
      key: index,
      domProps: {
        value: item.id
      }
    }, [_vm._v("\n                      " + _vm._s(item.name) + "\n                    ")]);
  })], 2)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    staticClass: "select-square",
    attrs: {
      label: "Criticidade"
    }
  }, [_c("b-select", {
    attrs: {
      placeholder: "Selecionar"
    },
    model: {
      value: _vm.ocurrence.criticality,
      callback: function callback($$v) {
        _vm.$set(_vm.ocurrence, "criticality", $$v);
      },
      expression: "ocurrence.criticality"
    }
  }, [_c("option", {
    attrs: {
      value: ""
    }
  }, [_vm._v("\n                      Selecionar\n                    ")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "LOW"
    }
  }, [_vm._v("\n                      Baixa\n                    ")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "MEDIUM"
    }
  }, [_vm._v("\n                      Média\n                    ")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "HIGH"
    }
  }, [_vm._v("\n                      Alta\n                    ")])])], 1)], 1)]), _vm._v(" "), _c("b-field", {
    staticClass: "text-area-square",
    attrs: {
      label: "Descrição"
    }
  }, [_c("b-input", {
    attrs: {
      type: "textarea",
      placeholder: "Descreva o que aconteceu",
      minlength: "10",
      maxlength: "300",
      disabled: ""
    },
    model: {
      value: _vm.ocurrence.description,
      callback: function callback($$v) {
        _vm.$set(_vm.ocurrence, "description", $$v);
      },
      expression: "ocurrence.description"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "field-audio audio-view-square",
    attrs: {
      label: "Áudio"
    }
  }, [_vm.ocurrence.audios && _vm.ocurrence.audios.length ? _c("div", _vm._l(_vm.ocurrence.audios, function (item, index) {
    return _c("audio", {
      key: index,
      ref: "player",
      refInFor: true,
      attrs: {
        controls: ""
      }
    }, [_c("source", {
      attrs: {
        src: item
      }
    }), _vm._v("Seu navegador não suporta o elemento de áudio.\n                ")]);
  }), 0) : _c("div", [_vm._v("\n                Áudio não registrado\n              ")])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    staticClass: "location-description",
    attrs: {
      label: "Local"
    }
  }, [_c("p", [_vm.ocurrence.plot ? _c("span", [_vm._v(_vm._s(_vm._f("locationScheduleComplete")([_vm.ocurrence.plot])))]) : _vm._e(), _vm._v(" "), _vm.ocurrence.subPlot ? _c("span", [_vm._v(_vm._s(_vm._f("locationScheduleComplete")([_vm.ocurrence.subPlot])))]) : _vm._e(), _vm._v(" "), _vm.ocurrence.row ? _c("span", [_vm._v(_vm._s(_vm._f("locationScheduleComplete")([_vm.ocurrence.row])))]) : _vm._e(), _vm._v(" "), _vm.ocurrence.family ? _c("span", [_vm._v(_vm._s(_vm._f("locationScheduleComplete")([_vm.ocurrence.family])))]) : _vm._e(), _vm._v(" "), _vm.ocurrence.bunch ? _c("span", [_vm._v(_vm._s(_vm._f("locationScheduleComplete")([_vm.ocurrence.bunch])))]) : _vm._e()])])], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    staticClass: "select-square",
    attrs: {
      label: "Status"
    }
  }, [_c("b-select", {
    attrs: {
      placeholder: "Selecionar"
    },
    model: {
      value: _vm.ocurrence.status,
      callback: function callback($$v) {
        _vm.$set(_vm.ocurrence, "status", $$v);
      },
      expression: "ocurrence.status"
    }
  }, [_c("option", {
    attrs: {
      value: "NOT_REVIEWED"
    }
  }, [_vm._v("\n                      Não analisado\n                    ")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "IN_REVIEW"
    }
  }, [_vm._v("\n                      Em análise\n                    ")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "ANALYZED"
    }
  }, [_vm._v("\n                      Analisado\n                    ")])])], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    staticClass: "location-description",
    attrs: {
      label: "Local informando manualmente"
    }
  }, [_c("p", [_c("span", [_vm._v(_vm._s(_vm.ocurrence.occurrenceLocation))])])])], 1)]), _vm._v(" "), _c("b-field", {
    staticClass: "list-operators",
    attrs: {
      label: "Operador"
    }
  }, [_c("user-list", {
    attrs: {
      users: [_vm.ocurrence.user]
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "list-operators",
    attrs: {
      label: "Coordenador"
    }
  }, [_c("b-select", {
    attrs: {
      placeholder: "Selecione o coordenador responsável"
    },
    model: {
      value: _vm.ocurrence.coordinatorId,
      callback: function callback($$v) {
        _vm.$set(_vm.ocurrence, "coordinatorId", $$v);
      },
      expression: "ocurrence.coordinatorId"
    }
  }, _vm._l(_vm.listCoordinators, function (user) {
    return _c("option", {
      key: user.id,
      domProps: {
        value: user.id
      }
    }, [_vm._v("\n                  " + _vm._s(user.firstName + " " + user.lastName) + "\n                ")]);
  }), 0)], 1), _vm._v(" "), _c("b-field", {
    staticClass: "list-operators",
    attrs: {
      label: "Código do identificador"
    }
  }, [_c("p", [_vm._v(_vm._s(_vm.ocurrence.tagCode || "--"))])])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "row-historic"
  }, [_c("h1", {
    staticClass: "title-init"
  }, [_c("i", {
    staticClass: "icon-green icon-ic_historico"
  }), _vm._v("\n            Histórico\n          ")]), _vm._v(" "), _c("b-field", {
    staticClass: "comment-field",
    attrs: {
      type: _vm.erroCommnet ? "is-danger" : null,
      message: _vm.erroCommnet ? "Mínimo de 10 caracteres" : null
    }
  }, [_c("p", {
    staticClass: "control"
  }, [_c("a", {
    staticClass: "button is-small"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/user-icon.png")
    }
  })])]), _vm._v(" "), _c("b-input", {
    attrs: {
      placeholder: "Adicionar comentário"
    },
    model: {
      value: _vm.comment,
      callback: function callback($$v) {
        _vm.comment = $$v;
      },
      expression: "comment"
    }
  }), _vm._v(" "), _c("p", {
    staticClass: "control is-rigth-item"
  }, [_c("button", {
    staticClass: "button is-primary",
    on: {
      click: _vm.onInsertComment
    }
  }, [_c("i", {
    staticClass: "icon-ic_plus"
  })])])], 1), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "list-comments"
  }, _vm._l(_vm.logs, function (item, key) {
    return _c("div", {
      key: key,
      staticClass: "list-cts-item"
    }, [_c("img", {
      staticClass: "list-cts-item_img",
      attrs: {
        src: require("@/assets/images/user-icon.png"),
        alt: "Operador"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "list-cts-item_text"
    }, [_c("p", [_c("span", [_vm._v(_vm._s(_vm.userName(item.user) || "Coordenador"))]), _vm._v("\n                    " + _vm._s(_vm._f("datePost")(item.date)) + " postou " + _vm._s(item.comment) + "\n                  ")])])]);
  }), 0)])], 1), _vm._v(" "), _c("footer", {
    staticClass: "modal-footer"
  }, [_vm._t("footer", function () {
    return [_vm.verifyEdit ? _c("button", {
      staticClass: "btn-close valid",
      attrs: {
        type: "button"
      },
      on: {
        click: _vm.saveItens
      }
    }, [_vm._v("\n              Salvar\n            ")]) : _vm._e()];
  })], 2)])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;