"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "list-talhoes"
  }, _vm._l(_vm.plots, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item-list-tl",
      class: {
        active: index === _vm.plotSelected
      }
    }, [_c("div", {
      staticClass: "is-sel-tl"
    }, [_vm.isChecked ? _c("label", {
      staticClass: "b-checkbox checkbox check-mark b-check-is-tl",
      attrs: {
        tabindex: "0"
      }
    }, [_c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.checked,
        expression: "item.checked"
      }],
      attrs: {
        id: item.id,
        type: "checkbox"
      },
      domProps: {
        value: item.checked,
        checked: Array.isArray(item.checked) ? _vm._i(item.checked, item.checked) > -1 : item.checked
      },
      on: {
        click: function click($event) {
          return _vm.checkedPlots($event, item, index);
        },
        change: function change($event) {
          var $$a = item.checked,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = item.checked,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(item, "checked", $$c);
          }
        }
      }
    }), _vm._v(" "), _c("span", {
      staticClass: "check"
    }), _vm._v(" "), _c("span", {
      staticClass: "control-label"
    })]) : _vm._e(), _vm._v(" "), _c("div", {
      class: _vm.selectClass
    }, [_c("div", {
      staticClass: "image is-small-tl",
      on: {
        click: function click($event) {
          return _vm.selectTalhao(index);
        }
      }
    }, [item.imageUrl ? _c("img", {
      attrs: {
        src: item.imageUrl
      }
    }) : _c("img", {
      attrs: {
        src: require("@/assets/images/img-tach.png")
      }
    }), _vm._v(" "), _c("p", {
      staticClass: "sub-title-tl"
    }, [_vm._v("\n            " + _vm._s(_vm._f("convertArea")(item.area)) + "\n          ")]), _vm._v(" "), _c("h3", {
      staticClass: "title-tl"
    }, [_vm._v("\n            " + _vm._s(item.name) + "\n          ")])])])])]);
  }), 0);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;