"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-label": "Moda Cadastro Fornecedor",
      "aria-modal": "",
      scroll: "keep",
      "can-cancel": false
    },
    model: {
      value: _vm.isModal,
      callback: function callback($$v) {
        _vm.isModal = $$v;
      },
      expression: "isModal"
    }
  }, [_c("form", {
    attrs: {
      id: "form-print-etiqueta"
    },
    on: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.checkForm.apply(null, arguments);
      }
    }
  }, [_c("div", {
    staticClass: "modal-card",
    staticStyle: {
      width: "600px"
    }
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("\n          Imprimir\n        ")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.close
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("app-select", {
    attrs: {
      label: "Selecione o modelo da etiqueta de rastreabilide",
      placeholder: "modelo da etiqueta de rastreabilide",
      items: _vm.listModels,
      option: "id",
      "name-item": "description",
      expanded: ""
    },
    model: {
      value: _vm.modelTag,
      callback: function callback($$v) {
        _vm.modelTag = $$v;
      },
      expression: "modelTag"
    }
  }), _vm._v(" "), _vm.modelTag ? _c("div", {
    staticClass: "card-tag-rastreio",
    attrs: {
      id: "cardTagRastreio"
    }
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column half"
  }, [_c("div", {
    staticClass: "row-detail"
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("PRODUTO:")]), _vm._v(" "), _c("p", {
    staticClass: "upper bold"
  }, [_vm._v("\n                  " + _vm._s(_vm.tag.product.name || "") + "\n                ")])])]), _vm._v(" "), _c("div", {
    staticClass: "column half"
  }, [_c("div", {
    staticClass: "row-detail"
  }, [_c("label", {
    staticClass: "label _155"
  }, [_vm._v("PESO LÍQUIDO CAIXA")]), _vm._v(" "), _c("p", {
    staticClass: "upper bold"
  }, [_vm._v("\n                  " + _vm._s(_vm.tag.netWeightBox ? "".concat(_vm.tag.netWeightBox, " kg") : "") + "\n                ")])])])]), _vm._v(" "), _c("div", {
    staticClass: "row-detail inline"
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("Lote:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.tag.batch || "--"))])]), _vm._v(" "), _c("div", {
    staticClass: "divider"
  }), _vm._v(" "), _c("div", {
    staticClass: "row-detail"
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("PRODUZIDO POR:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.provider.name || ""))])]), _vm._v(" "), _c("div", {
    staticClass: "row-detail"
  }, [_c("p", [_vm._v("Localidade " + _vm._s(_vm.provider.address) + " " + _vm._s(_vm.provider.addressNumber))]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.provider.state))]), _vm._v(" "), _c("p", [_vm._v("CEP: " + _vm._s(_vm.provider.zipCode))]), _vm._v(" "), _c("p", [_vm._v("IE: " + _vm._s(_vm.provider.ierg))])]), _vm._v(" "), _c("div", {
    staticClass: "row-detail"
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("DISTRIBUIDO POR:")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.tag.client.name))]), _vm._v(" "), _c("p", [_vm._v("Localidade " + _vm._s(_vm.tag.client.address) + " " + _vm._s(_vm.tag.client.addressNumber))]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.tag.client.state))]), _vm._v(" "), _c("p", [_vm._v("CEP: " + _vm._s(_vm.tag.client.zipCode))]), _vm._v(" "), _c("p", [_vm._v("IE: " + _vm._s(_vm.tag.client.ierg))])]), _vm._v(" "), _c("div", {
    staticClass: "card-defails-fiboo"
  }, [_c("img", {
    staticClass: "logo",
    attrs: {
      src: require("@/assets/logo.png")
    }
  }), _vm._v(" "), _c("qr-code", {
    attrs: {
      text: _vm.tag.link
    }
  }), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.tag.identifier))]), _vm._v(" "), _c("h4", [_vm._v("Aqui tem tecnologia e inovação")]), _vm._v(" "), _c("a", {
    attrs: {
      href: "http://www.fiboo.com.br/",
      target: "_blank"
    }
  }, [_vm._v("WWW.FIBOO.COM.BR")])], 1)]) : _vm._e()], 1), _vm._v(" "), _c("footer", {
    staticClass: "modal-card-foot"
  }, [_c("b-button", {
    attrs: {
      "native-type": "submit",
      label: "Imprimir"
    }
  })], 1)])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;