"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-label": "Moda Cadastro Fornecedor",
      "aria-modal": "",
      scroll: "keep",
      "can-cancel": false
    },
    model: {
      value: _vm.isComponentModalActive,
      callback: function callback($$v) {
        _vm.isComponentModalActive = $$v;
      },
      expression: "isComponentModalActive"
    }
  }, [_c("form", {
    attrs: {
      id: "form-new-customer"
    },
    on: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.checkForm.apply(null, arguments);
      }
    }
  }, [_c("div", {
    staticClass: "modal-card",
    staticStyle: {
      width: "600px"
    }
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("\n          Incluir personalização de Produto\n        ")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.close
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-three-quarters"
  }, [_c("app-select", {
    attrs: {
      label: "Produto",
      placeholder: "Selecione o produto",
      error: _vm.$v.form.product.$error,
      items: _vm.listProducts,
      option: "id",
      "name-item": "name",
      expanded: "",
      disabled: true
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.product, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.product.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.product, "$model", $$v);
      },
      expression: "$v.form.product.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.form.name.$error,
      label: "Nome da personalização",
      placeholder: "Informe o nome para sua personalização",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.name, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.name, "$model", $$v);
      },
      expression: "$v.form.name.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    attrs: {
      label: "Imagem Produto"
    }
  }, [_c("upload-image-user", {
    attrs: {
      file: _vm.form.image
    },
    on: {
      "update:file": function updateFile($event) {
        return _vm.$set(_vm.form, "image", $event);
      }
    }
  })], 1)], 1)]), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Informações nutricionais"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", [_vm._v("Porção por embalagem")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.portionPackage.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.portionPackage, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.portionPackage.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.portionPackage, "$model", $$v);
      },
      expression: "$v.form.portionPackage.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", [_vm._v("Porção")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.portion.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.portion, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.portion.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.portion, "$model", $$v);
      },
      expression: "$v.form.portion.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", [_vm._v("Medida Caseira")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.homeMeasure.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.homeMeasure, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.homeMeasure.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.homeMeasure, "$model", $$v);
      },
      expression: "$v.form.homeMeasure.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("label", [_vm._v("100g ou ml")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("label", [_vm._v("Porção")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("label", [_vm._v("% VD*")])])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", [_vm._v("Valor energético (kcal)")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.valorEnergeticoML.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.valorEnergeticoML, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.valorEnergeticoML.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.valorEnergeticoML, "$model", $$v);
      },
      expression: "$v.form.valorEnergeticoML.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.valorEnergeticoPortion.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.valorEnergeticoPortion, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.valorEnergeticoPortion.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.valorEnergeticoPortion, "$model", $$v);
      },
      expression: "$v.form.valorEnergeticoPortion.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.valorEnergeticoVD.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.valorEnergeticoVD, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.valorEnergeticoVD.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.valorEnergeticoVD, "$model", $$v);
      },
      expression: "$v.form.valorEnergeticoVD.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", [_vm._v("Carboidrato totais (g)")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.carboidratoTotaisML.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.carboidratoTotaisML, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.carboidratoTotaisML.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.carboidratoTotaisML, "$model", $$v);
      },
      expression: "$v.form.carboidratoTotaisML.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.carboidratoTotaisPortion.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.carboidratoTotaisPortion, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.carboidratoTotaisPortion.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.carboidratoTotaisPortion, "$model", $$v);
      },
      expression: "$v.form.carboidratoTotaisPortion.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.carboidratoTotaisVD.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.carboidratoTotaisVD, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.carboidratoTotaisVD.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.carboidratoTotaisVD, "$model", $$v);
      },
      expression: "$v.form.carboidratoTotaisVD.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", [_vm._v("Açucares totais (g)")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.acucaresTotaisML.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.acucaresTotaisML, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.acucaresTotaisML.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.acucaresTotaisML, "$model", $$v);
      },
      expression: "$v.form.acucaresTotaisML.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.acucaresTotaisPortion.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.acucaresTotaisPortion, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.acucaresTotaisPortion.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.acucaresTotaisPortion, "$model", $$v);
      },
      expression: "$v.form.acucaresTotaisPortion.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.acucaresTotaisVD.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.acucaresTotaisVD, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.acucaresTotaisVD.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.acucaresTotaisVD, "$model", $$v);
      },
      expression: "$v.form.acucaresTotaisVD.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", [_vm._v("Açucares adiciodos (g)")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.acucaresAdiciodosML.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.acucaresAdiciodosML, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.acucaresAdiciodosML.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.acucaresAdiciodosML, "$model", $$v);
      },
      expression: "$v.form.acucaresAdiciodosML.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.acucaresAdiciodosPortion.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.acucaresAdiciodosPortion, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.acucaresAdiciodosPortion.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.acucaresAdiciodosPortion, "$model", $$v);
      },
      expression: "$v.form.acucaresAdiciodosPortion.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.acucaresAdiciodosVD.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.acucaresAdiciodosVD, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.acucaresAdiciodosVD.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.acucaresAdiciodosVD, "$model", $$v);
      },
      expression: "$v.form.acucaresAdiciodosVD.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", [_vm._v("Proteínas (g)")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.proteinasML.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.proteinasML, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.proteinasML.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.proteinasML, "$model", $$v);
      },
      expression: "$v.form.proteinasML.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.proteinasPortion.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.proteinasPortion, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.proteinasPortion.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.proteinasPortion, "$model", $$v);
      },
      expression: "$v.form.proteinasPortion.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.proteinasVD.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.proteinasVD, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.proteinasVD.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.proteinasVD, "$model", $$v);
      },
      expression: "$v.form.proteinasVD.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", [_vm._v("Gordura totais (g)")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.gorduraTotaisML.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.gorduraTotaisML, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.gorduraTotaisML.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.gorduraTotaisML, "$model", $$v);
      },
      expression: "$v.form.gorduraTotaisML.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.gorduraTotaisPortion.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.gorduraTotaisPortion, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.gorduraTotaisPortion.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.gorduraTotaisPortion, "$model", $$v);
      },
      expression: "$v.form.gorduraTotaisPortion.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.gorduraTotaisVD.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.gorduraTotaisVD, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.gorduraTotaisVD.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.gorduraTotaisVD, "$model", $$v);
      },
      expression: "$v.form.gorduraTotaisVD.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", [_vm._v("Gordura saturadas (g)")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.gorduraSaturadasML.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.gorduraSaturadasML, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.gorduraSaturadasML.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.gorduraSaturadasML, "$model", $$v);
      },
      expression: "$v.form.gorduraSaturadasML.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.gorduraSaturadasPortion.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.gorduraSaturadasPortion, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.gorduraSaturadasPortion.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.gorduraSaturadasPortion, "$model", $$v);
      },
      expression: "$v.form.gorduraSaturadasPortion.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.gorduraSaturadasVD.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.gorduraSaturadasVD, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.gorduraSaturadasVD.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.gorduraSaturadasVD, "$model", $$v);
      },
      expression: "$v.form.gorduraSaturadasVD.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", [_vm._v("Gordura trans (g)")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.gorduraTransML.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.gorduraTransML, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.gorduraTransML.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.gorduraTransML, "$model", $$v);
      },
      expression: "$v.form.gorduraTransML.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.gorduraTransPortion.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.gorduraTransPortion, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.gorduraTransPortion.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.gorduraTransPortion, "$model", $$v);
      },
      expression: "$v.form.gorduraTransPortion.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.gorduraTransVD.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.gorduraTransVD, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.gorduraTransVD.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.gorduraTransVD, "$model", $$v);
      },
      expression: "$v.form.gorduraTransVD.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", [_vm._v("Fibra alimentar (g)")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.fibraAlimentarML.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.fibraAlimentarML, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.fibraAlimentarML.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.fibraAlimentarML, "$model", $$v);
      },
      expression: "$v.form.fibraAlimentarML.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.fibraAlimentarPortion.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.fibraAlimentarPortion, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.fibraAlimentarPortion.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.fibraAlimentarPortion, "$model", $$v);
      },
      expression: "$v.form.fibraAlimentarPortion.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.fibraAlimentarVD.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.fibraAlimentarVD, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.fibraAlimentarVD.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.fibraAlimentarVD, "$model", $$v);
      },
      expression: "$v.form.fibraAlimentarVD.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", [_vm._v("Sódio (mg)")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.sodioML.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.sodioML, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.sodioML.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.sodioML, "$model", $$v);
      },
      expression: "$v.form.sodioML.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.sodioPortion.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.sodioPortion, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.sodioPortion.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.sodioPortion, "$model", $$v);
      },
      expression: "$v.form.sodioPortion.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.sodioVD.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.sodioVD, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.sodioVD.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.sodioVD, "$model", $$v);
      },
      expression: "$v.form.sodioVD.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("label", [_vm._v("*Percentual de valores diários fornecidos pela porça.")]), _c("br"), _vm._v(" "), _c("label", [_vm._v("**Valor diário não estabelecido")])])]), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.codigoBarras.$error,
      label: "Código de Barras",
      placeholder: "Informar código de barras",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.codigoBarras, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.codigoBarras.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.codigoBarras, "$model", $$v);
      },
      expression: "$v.form.codigoBarras.$model"
    }
  }), _vm._v(" "), _c("b-button", {
    staticClass: "btn-add-field",
    on: {
      click: _vm.generateTicket
    }
  }, [_vm._v("\n              Gerar código de barras EAN-13\n            ")])], 1)], 1), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.form.description.$error,
      label: "Descrição produto personalizado",
      placeholder: "Descreva sobre seu produto",
      type: "textarea",
      minlength: "10",
      maxlength: "100"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.description, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.description.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.description, "$model", $$v);
      },
      expression: "$v.form.description.$model"
    }
  }), _vm._v(" "), _c("b-field", {
    staticClass: "field-months-list",
    attrs: {
      label: "Disponibilidade"
    }
  }, [_c("b-checkbox", {
    on: {
      input: _vm.selectAll
    },
    model: {
      value: _vm.checkALL,
      callback: function callback($$v) {
        _vm.checkALL = $$v;
      },
      expression: "checkALL"
    }
  }, [_vm._v("\n            Selecionar todos\n          ")])], 1), _vm._v(" "), _c("b-field", _vm._l(6, function (idx) {
    return _c("b-checkbox", {
      key: idx,
      attrs: {
        "native-value": _vm.listMonths[idx - 1].id
      },
      model: {
        value: _vm.form.availability,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "availability", $$v);
        },
        expression: "form.availability"
      }
    }, [_vm._v("\n            " + _vm._s(_vm.listMonths[idx - 1].description) + "\n          ")]);
  }), 1), _vm._v(" "), _c("b-field", _vm._l(6, function (idx) {
    return _c("b-checkbox", {
      key: idx,
      attrs: {
        "native-value": _vm.listMonths[idx + 5].id
      },
      model: {
        value: _vm.form.availability,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "availability", $$v);
        },
        expression: "form.availability"
      }
    }, [_vm._v("\n            " + _vm._s(_vm.listMonths[idx + 5].description) + "\n          ")]);
  }), 1), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-two-thirds"
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.codiboEorigem.$error,
      label: "Códibo e-origem",
      placeholder: "Informar código de barras"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.codiboEorigem, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.codiboEorigem.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.codiboEorigem, "$model", $$v);
      },
      expression: "$v.form.codiboEorigem.$model"
    }
  })], 1)])], 1), _vm._v(" "), _c("footer", {
    staticClass: "modal-card-foot"
  }, [_c("b-button", {
    attrs: {
      "native-type": "submit",
      label: "Salvar"
    }
  })], 1)])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;