"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    staticClass: "page-productive-areas",
    attrs: {
      menu: _vm.menuItemsFarm.children,
      "sub-menu": _vm.menuItemsFarm.subMenu,
      "return-text": "Lista de fazendas",
      "return-menu": _vm.menuItemsFarm.path
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("header", {
    staticClass: "header"
  }, [_c("h3", {
    staticClass: "title-destaq-page"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.productive_areas.list.title")) + "\n      ")]), _vm._v(" "), _c("input-search", {
    attrs: {
      placeholder: _vm.$t("pages.productive_areas.list.place_search")
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _vm._v(" "), _vm.listPlotsRow.length ? _c("div", {
    staticClass: "list-cards-item"
  }, _vm._l(_vm.listPlotsRow, function (plotItem, index) {
    return _c("div", {
      key: index,
      staticClass: "columns"
    }, _vm._l(_vm.listPlotsColumn(plotItem), function (i, j) {
      return _c("div", {
        key: j,
        staticClass: "column"
      }, [_c("div", {
        staticClass: "card"
      }, [_c("div", {
        staticClass: "card-content",
        style: _vm.filterPlots[i].imageUrl ? "background: #dadada url('".concat(_vm.filterPlots[i].imageUrl, "') no-repeat;background-size: cover;") : "",
        on: {
          click: function click($event) {
            return _vm.openDetail(_vm.filterPlots[i]);
          }
        }
      }, [_c("h6", [_vm._v(_vm._s(_vm._f("convertArea")(_vm.filterPlots[i].area)))])]), _vm._v(" "), _c("footer", {
        staticClass: "card-footer"
      }, [_c("p", {
        staticClass: "card-footer-item"
      }, [_c("i", {
        staticClass: "icon-ic_location"
      }), _vm._v(" "), _c("span", [_vm._v("\n                  " + _vm._s(_vm.filterPlots[i].name) + "\n                ")])]), _vm._v(" "), _c("p", {
        staticClass: "card-footer-item"
      }, [_c("b-dropdown", {
        attrs: {
          "aria-role": "list",
          position: "is-bottom-left"
        }
      }, [[_c("button", {
        staticClass: "button is-text",
        attrs: {
          slot: "trigger",
          type: "button"
        },
        slot: "trigger"
      }, [_c("span", [_c("i", {
        staticClass: "icon-ic_more ic-more"
      })])])], _vm._v(" "), _c("b-dropdown-item", {
        attrs: {
          "aria-role": "listitem"
        },
        on: {
          click: function click($event) {
            return _vm.openDetail(_vm.filterPlots[i]);
          }
        }
      }, [_vm._v("\n                    " + _vm._s(_vm.$t("pages.productive_areas.btn_edit")) + "\n                  ")]), _vm._v(" "), _c("b-dropdown-item", {
        attrs: {
          "aria-role": "listitem"
        },
        on: {
          click: function click($event) {
            return _vm.verifyDelete(_vm.filterPlots[i]);
          }
        }
      }, [_vm._v("\n                    " + _vm._s(_vm.$t("pages.productive_areas.btn_delete")) + "\n                  ")])], 2)], 1)])])]);
    }), 0);
  }), 0) : _vm._e(), _vm._v(" "), !_vm.listPlotsRow.length ? _c("div", [_c("no-record-found", {
    attrs: {
      title: _vm.$t("pages.productive_areas.list.404_title"),
      description: _vm.$t("pages.productive_areas.list.404_message")
    }
  })], 1) : _vm._e()])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;