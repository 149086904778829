"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default2 = {
  name: 'DatePicker',
  props: {
    placeholder: {
      type: String,
      default: 'Informe a data'
    },
    minDate: {
      type: Date,
      default: function _default() {
        return new Date();
      }
    },
    value: {
      type: [String, Date],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      newValue: this.value,
      date: new Date()
    };
  },
  computed: {
    computedValue: {
      get: function get() {
        return this.newValue;
      },
      set: function set(value) {
        this.newValue = value;
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value: function value(_value) {
      this.newValue = _value;
    }
  },
  methods: {
    changeMonth: function changeMonth(type) {
      var num = 1;
      if (type === 'prev') num = -1;
      var valueMonth = this.date.getMonth() <= 11 ? this.date.getMonth() + num : 0;
      this.date = new Date(this.date);
      this.date.setMonth(valueMonth);
    },
    formatViewDate: function formatViewDate() {
      _moment.default.locale('pt-BR');
      var check = (0, _moment.default)(this.date);
      var month = check.format('MMMM');
      var year = check.format('YYYY');
      return month + ' de ' + year;
    }
  }
};
exports.default = _default2;