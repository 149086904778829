"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "sidebar",
    class: {
      admin: _vm.user.role === "Admin"
    }
  }, [_vm._m(0), _vm._v(" "), _vm._l(_vm.menu, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "menu-nav"
    }, [_c("div", {
      staticClass: "menu-item-click"
    }, [_c("router-link", {
      attrs: {
        to: item.link
      }
    }, [_c("span", {
      staticClass: "icon"
    }, [_c("i", {
      class: item.icon
    })])])], 1)]);
  }), _vm._v(" "), _c("footer", [_c("div", {
    staticClass: "menu-nav"
  }, [_c("div", {
    staticClass: "menu-item-click"
  }, [_c("router-link", {
    attrs: {
      to: "/faq"
    }
  }, [_c("span", {
    staticClass: "icon"
  }, [_c("i", {
    staticClass: "icon-ic_info"
  })])]), _vm._v(" "), _c("a", {
    staticClass: "router-link-exact-active router-link-active",
    on: {
      click: _vm.logoutSystem
    }
  }, [_vm._m(1)])], 1)])])], 2);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "block"
  }, [_c("span", {
    staticClass: "icon icon-logo"
  }, [_c("i", {
    staticClass: "icon-ic_fiboo"
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "icon"
  }, [_c("i", {
    staticClass: "icon-ic_logout"
  })]);
}];
render._withStripped = true;