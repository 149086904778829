"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "list-item-check-print"
  }, [_c("div", {
    staticClass: "list-content"
  }, _vm._l(_vm.listQrCodes, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "field field-actions-checks"
    }, [_c("tr", [_c("b-checkbox", {
      attrs: {
        "native-value": item,
        disabled: !item.url
      },
      on: {
        input: _vm.checkItem
      },
      model: {
        value: _vm.list,
        callback: function callback($$v) {
          _vm.list = $$v;
        },
        expression: "list"
      }
    }, [_c("td", {
      staticStyle: {
        "line-height": "5px"
      }
    }, [_vm._v("\n            " + _vm._s(item.code) + "\n          ")]), _vm._v(" "), _c("td", {
      staticStyle: {
        "line-height": "5px"
      }
    }, [_vm._v("\n            " + _vm._s(item.groupId ? _vm.$t("pages.identifiers.cards.qrcode.flagtag") : _vm.$t("pages.identifiers.cards.qrcode.individualtag")) + "\n          ")]), _vm._v(" "), _c("td", {
      staticStyle: {
        "line-height": "5px"
      }
    }, [_vm._v("\n            " + _vm._s(item.url ? "" : _vm.$t("pages.identifiers.processing")) + "\n          ")])])], 1)]);
  }), 0)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;