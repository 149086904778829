"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-form-send-email-qr-codes"
  }, [_c("header", {
    staticClass: "card-header"
  }, [_c("p", {
    staticClass: "card-header-title"
  }, [_vm._v("\n      " + _vm._s(_vm.$t("pages.identifiers.cards.email.title")) + "\n    ")])]), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_c("b-field", [_c("b-input", {
    ref: "input",
    attrs: {
      type: "email",
      value: "john@",
      autocomplete: false,
      placeholder: _vm.$t("pages.identifiers.cards.email.placeholder")
    },
    on: {
      input: _vm.onInput
    },
    model: {
      value: _vm.computedValue,
      callback: function callback($$v) {
        _vm.computedValue = $$v;
      },
      expression: "computedValue"
    }
  })], 1), _vm._v(" "), _c("p", [_vm._v("\n      " + _vm._s(_vm.$t("pages.identifiers.cards.email.description")) + "\n    ")])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;