"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var _vue = _interopRequireDefault(require("vue"));
var _buefy = _interopRequireDefault(require("buefy"));
var _vuelidate = _interopRequireDefault(require("vuelidate"));
var _vuelayers = _interopRequireDefault(require("vuelayers"));
var _vueQrcodeComponent = _interopRequireDefault(require("vue-qrcode-component"));
var _vueFullscreen = _interopRequireDefault(require("vue-fullscreen"));
var _vueTelInput = _interopRequireDefault(require("vue-tel-input"));
require("vuelayers/lib/style.css");
var GmapVue = _interopRequireWildcard(require("gmap-vue"));
var _vueTheMask = _interopRequireDefault(require("vue-the-mask"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
_vue.default.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    libraries: 'places,drawing,visualization'
  },
  installComponents: true
});
_vue.default.config.productionTip = false;
_vue.default.use(_vuelayers.default, {
  dataProjection: 'EPSG:4326'
});
_vue.default.use(_vuelidate.default);
_vue.default.use(_buefy.default, {
  defaultDayNames: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  defaultMonthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
  defaultFirstDayOfWeek: 0,
  defaultDateFormatter: function defaultDateFormatter(date) {
    return date.toLocaleDateString('pt-BR');
  }
});
_vue.default.use(_vueTelInput.default);
_vue.default.use(_vueFullscreen.default);
_vue.default.use(_vueTheMask.default);
_vue.default.component('qr-code', _vueQrcodeComponent.default);