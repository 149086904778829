"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.traceabilityTagService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var traceabilityTagService = {
  fetch: fetch,
  create: create,
  update: update,
  deleteItem: deleteItem,
  fetchItem: fetchItem
};
exports.traceabilityTagService = traceabilityTagService;
function fetch(params) {
  // eslint-disable-next-line no-undef
  return _http.default.get('/traceability-tag', {
    params: params
  });
}
function fetchItem(batch) {
  // eslint-disable-next-line no-undef
  var tag = batch.toLowerCase();
  return _http.default.get("/traceability-tag/consult/".concat(tag));
}
function create(data) {
  // eslint-disable-next-line no-undef
  return _http.default.post('/traceability-tag', data);
}
function update(data, id) {
  // eslint-disable-next-line no-undef
  return _http.default.put("/traceability-tag/".concat(id), data);
}
function deleteItem(id) {
  // eslint-disable-next-line no-undef
  return _http.default.delete("/traceability-tag/".concat(id));
}