"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "card-carousel"
  }, [_c("Card", {
    staticClass: "current-element",
    attrs: {
      "img-name": _vm.currentElement
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "arrows-count-bottom"
  }, [_c("nav", {
    staticClass: "pagination is-centered is-small"
  }, [_c("ArrowButton", {
    attrs: {
      "arrow-type": "left",
      disabled: this.reachedMaxLeft
    },
    nativeOn: {
      click: function click($event) {
        return _vm.showPrevElement.apply(null, arguments);
      }
    }
  }), _vm._v(" "), _c("p", {
    staticClass: "pagination-list"
  }, [_vm._v("\n        " + _vm._s(_vm.currentElementIndex + 1) + " de " + _vm._s(this.cards.length) + "\n      ")]), _vm._v(" "), _c("ArrowButton", {
    attrs: {
      "arrow-type": "right",
      disabled: this.reachedMaxRight
    },
    nativeOn: {
      click: function click($event) {
        return _vm.showNextElement.apply(null, arguments);
      }
    }
  })], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;