"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _QrCodeExample = _interopRequireDefault(require("./QrCodeExample"));
var _FlagQrCodeExample = _interopRequireDefault(require("./FlagQrCodeExample"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default2 = {
  name: 'CardQrcode',
  components: {
    QrCodeExample: _QrCodeExample.default,
    FlagQrCodeExample: _FlagQrCodeExample.default
  },
  data: function data() {
    return {
      generateFlag: false
    };
  },
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    action: {
      type: Function,
      default: function _default() {}
    }
  },
  methods: {
    checkboxChange: function checkboxChange() {
      this.$emit('action', this.generateFlag);
    }
  }
};
exports.default = _default2;