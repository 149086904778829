"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    staticClass: "page-productive-areas-detail",
    attrs: {
      menu: _vm.menuItemsFarm.children,
      "sub-menu": _vm.menuItemsFarm.subMenu,
      "return-text": "Lista de fazendas",
      "return-menu": _vm.menuItemsFarm.path
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("header", {
    staticClass: "header",
    style: _vm.plot.imageUrl ? "background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.42), rgba(0, 0, 0, 0.88)), url('".concat(_vm.plot.imageUrl, "');") : ""
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("h4", [_c("i", {
    staticClass: "icon-ic_location"
  }), _vm._v("\n            " + _vm._s(_vm.plot.name) + "\n            "), _c("ButtonQRCode", {
    attrs: {
      item: _vm.plot,
      type: "Plot"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-button", {
    attrs: {
      type: "is-text"
    },
    on: {
      click: _vm.editDrawing
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.productive_areas.detail.btn_edit_plot")) + "\n          ")])], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "body-context"
  }, [_c("div", {
    staticClass: "columns titles-descriptions"
  }, [_c("div", {
    staticClass: "column"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.productive_areas.detail.text_area_size")) + "\n          "), _c("span", [_vm._v("\n            " + _vm._s(_vm._f("convertArea")(_vm.plot.area)) + "\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.productive_areas.detail.text_number_of_subplots")) + "\n          "), _c("span", [_vm._v("\n            " + _vm._s(_vm.subPlots.length) + "\n          ")])])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("b-button", {
    attrs: {
      type: "is-text btn-return"
    },
    on: {
      click: _vm.returnList
    }
  }, [_c("i", {
    staticClass: "icon-ic_voltar"
  }), _vm._v(" Voltar\n        ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "columns details-btn-new-item"
  }, [_c("div", {
    staticClass: "column"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.productive_areas.detail.subplots")) + "\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-button", {
    attrs: {
      type: "btn-new-sub-plot"
    },
    on: {
      click: _vm.openModalSubPlot
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.productive_areas.detail.btn_add_subplot")) + "\n          ")])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "list-subplots"
  }, [!_vm.subPlots.length ? _c("div", {
    staticClass: "block not-found"
  }, [_c("b-icon", {
    attrs: {
      icon: "emoticon-sad"
    }
  }), _vm._v(" " + _vm._s(_vm.$t("pages.productive_areas.detail.404")) + "\n        ")], 1) : _c("div", {
    staticClass: "list-suplots-areas"
  }, [_c("list-suplots-areas", {
    attrs: {
      plot: _vm.plot,
      "sub-plots": _vm.subPlots
    },
    on: {
      getSubPlots: _vm.getSubPlotsRest,
      openModalEdit: _vm.openModalEdit
    }
  })], 1)])])])], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;