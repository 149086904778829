"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'Farm',
  path: '/farm-management/list',
  icon: 'icon-ic_fazenda',
  meta: {
    expanded: false,
    title: 'Fazendas',
    description: 'Gestão de fazendas',
    requiresAuth: true,
    block: true
  },
  subMenu: [{
    name: 'FarmData',
    path: '/farm-management/farm-data',
    icon: 'icon-ic_info',
    meta: {
      title: 'Dados da fazenda',
      requiresAuth: true
    }
  }, {
    name: 'faq',
    path: '/faq',
    icon: 'icon-ic_info',
    meta: {
      title: 'Ajuda',
      requiresAuth: true
    }
  }],
  children: [{
    name: 'dashboard',
    path: '/farm-management/dashboard',
    icon: 'icon-ic_dashboard',
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    }
  }, {
    name: 'map',
    path: '/farm-management/map',
    icon: 'icon-ic_calendar',
    meta: {
      title: 'Mapa',
      requiresAuth: true
    }
  },
  // {
  //   name: 'routes',
  //   path: '/farm-management/routes',
  //   icon: 'icon-ic_mapa',
  //   // component: lazyLoading('farm/map'),
  //   meta: {
  //     title: 'Rotas',
  //     requiresAuth: true
  //   }
  // },
  {
    name: 'ProductiveAreasList',
    path: '/farm-management/productiveareas',
    icon: 'icon-ic_location',
    // component: lazyLoading('farm/map'),
    meta: {
      title: 'Áreas produtivas',
      requiresAuth: true
    }
  }, {
    name: 'services',
    path: '/services/schedule',
    icon: 'icon-ic_agenda',
    meta: {
      title: 'Serviços',
      requiresAuth: true
    }
  }, {
    name: 'ocorrencias',
    path: '/ocurrrences/list',
    icon: 'icon-ic_ocorrencia',
    meta: {
      title: 'Ocorrências',
      requiresAuth: true
    }
  }, {
    name: 'equipe',
    path: '/team/list',
    icon: 'icon-ic_operadores',
    meta: {
      title: 'Equipe',
      requiresAuth: true
    }
  }, {
    name: 'estoque',
    path: '/stock',
    icon: 'icon-ic_estoque',
    meta: {
      title: 'Estoque',
      requiresAuth: true
    }
  }, {
    name: 'traceabilitytags',
    path: '/traceabilitytags',
    icon: 'icon-ic_location',
    meta: {
      title: 'Etiqueta Rastreabilidade',
      requiresAuth: true
    }
  }, {
    name: 'identificadores',
    path: '/identifiers',
    icon: 'icon-ic_identificadores',
    meta: {
      title: 'Identificadores',
      requiresAuth: true
    }
  }, {
    name: 'importBalance',
    path: '/import-balance',
    icon: 'icon-ic_info',
    meta: {
      title: 'Importar Dados Balança',
      requiresAuth: true
    }
  }, {
    name: 'reports',
    path: '/custom-reports',
    icon: 'icon-ic_pdf1',
    meta: {
      title: 'Romaneio',
      requiresAuth: true
    }
  }]
};
exports.default = _default;