"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-example-qr-codes"
  }, [_c("header", {
    staticClass: "card-header"
  }, [_c("p", {
    staticClass: "card-header-title"
  }, [_vm._v("\n      " + _vm._s(_vm.$t("pages.identifiers.cards.qrcode.title")) + "\n    ")])]), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [!_vm.generateFlag ? _c("qr-code-example") : _vm._e(), _vm._v(" "), _vm.generateFlag ? _c("FlagQrCodeExample") : _vm._e(), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.$t("pages.identifiers.cards.qrcode.description")))])], 1), _vm._v(" "), _c("section", [_c("div", {
    staticClass: "block radio-flag"
  }, [_c("b-radio", {
    attrs: {
      input: _vm.checkboxChange(),
      size: "is-small",
      "native-value": false
    },
    model: {
      value: _vm.generateFlag,
      callback: function callback($$v) {
        _vm.generateFlag = $$v;
      },
      expression: "generateFlag"
    }
  }, [_c("p", [_vm._v(_vm._s(_vm.$t("pages.identifiers.cards.qrcode.individualtag")))])]), _vm._v(" "), _c("b-radio", {
    attrs: {
      input: _vm.checkboxChange(),
      size: "is-small",
      "native-value": true
    },
    model: {
      value: _vm.generateFlag,
      callback: function callback($$v) {
        _vm.generateFlag = $$v;
      },
      expression: "generateFlag"
    }
  }, [_c("p", [_vm._v(_vm._s(_vm.$t("pages.identifiers.cards.qrcode.flagtag")))])])], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;