"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "questions",
    on: {
      click: function click($event) {
        _vm.visible = !_vm.visible;
      }
    }
  }, [_c("div", {
    staticClass: "transition"
  }, [_c("h4", {
    staticClass: "questions-title"
  }, [_vm._v("\n      " + _vm._s(_vm.questions) + "\n    ")]), _vm._v(" "), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.visible,
      expression: "!visible"
    }],
    staticClass: "invisible",
    attrs: {
      src: require("../../../assets/images/seta-para-cima.png"),
      alt: ""
    }
  }), _vm._v(" "), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "visible",
    attrs: {
      src: require("../../../assets/images/seta-para-baixo.png"),
      alt: ""
    }
  })]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "questions-fade"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.visible,
      expression: "!visible"
    }],
    staticClass: "questions-content"
  }, [_vm._t("default")], 2)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;