"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-field", {
    class: "app-select-component ".concat(_vm.classField),
    attrs: {
      message: "",
      type: _vm.error ? "is-danger" : "",
      label: _vm.included && !_vm.computedValue ? "" : _vm.label,
      horizontal: _vm.horizontal
    }
  }, [_c("b-select", {
    attrs: {
      placeholder: _vm.placeholder,
      disabled: _vm.disabled
    },
    model: {
      value: _vm.computedValue,
      callback: function callback($$v) {
        _vm.computedValue = $$v;
      },
      expression: "computedValue"
    }
  }, [_vm.showCancel ? _c("option", {
    domProps: {
      value: null
    }
  }, [_vm._v("\n      " + _vm._s(_vm.optionName) + "\n    ")]) : _vm._e(), _vm._v(" "), _vm._l(_vm.items, function (item, idx) {
    return _c("option", {
      key: idx,
      domProps: {
        value: item[_vm.option]
      }
    }, [_vm._v("\n      " + _vm._s(_vm.translateName(item)) + "\n    ")]);
  })], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;