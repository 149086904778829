"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.servicesService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var servicesService = {
  fetchServices: fetchServices,
  fetchValuesCFStepsFinisheds: fetchValuesCFStepsFinisheds,
  fetchServicesFinisheds: fetchServicesFinisheds,
  fetchServiceItem: fetchServiceItem,
  fetchServiceDetailItem: fetchServiceDetailItem,
  deleteService: deleteService,
  fectchServiceProgess: fectchServiceProgess,
  listServiceTechnics: listServiceTechnics,
  fetchServiceCustomFieldValues: fetchServiceCustomFieldValues,
  updateServiceFinished: updateServiceFinished,
  deleteServiceFinished: deleteServiceFinished
};
exports.servicesService = servicesService;
function fetchServices(params) {
  // eslint-disable-next-line no-undef
  return _http.default.get('/services', {
    params: params
  });
}
function fetchServiceItem(id) {
  var path = "services/".concat(id);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function fetchServiceDetailItem(id) {
  var path = "services/".concat(id, "/detail");

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function listServiceTechnics(idProduct, idFarm) {
  var path = "/servicetypes/query/".concat(idFarm, "?productId=").concat(idProduct);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function deleteService(idService, data) {
  var path = "/services/".concat(idService);

  // eslint-disable-next-line no-undef
  return _http.default.delete(path, {
    data: data
  });
}
function fectchServiceProgess(idService, params) {
  var path = "/services/".concat(idService, "/progress");
  // eslint-disable-next-line no-undef
  return _http.default.get(path, {
    params: params
  });
}
function fetchServiceCustomFieldValues(payload) {
  return _http.default.post("/services/custom-fields/get-values", payload);
}
function updateServiceFinished(id, payload) {
  return _http.default.put("/services/".concat(id, "/update/finished"), payload);
}
function deleteServiceFinished(id, payload) {
  return _http.default.put("/services/".concat(id, "/delete/finished"), payload);
}
function fetchServicesFinisheds(params) {
  // eslint-disable-next-line no-undef
  return _http.default.get('/services/list/finisheds', {
    params: params
  });
}
function fetchValuesCFStepsFinisheds(params) {
  var path = "/services/finished/values/steps";
  // eslint-disable-next-line no-undef
  return _http.default.get(path, {
    params: params
  });
}