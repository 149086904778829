"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("table", {
    attrs: {
      id: "myTable"
    }
  }, [_c("tr", {
    staticClass: "pagebreak"
  }, [_c("td", [_c("div", {
    staticClass: "mainQRCode"
  }, [_c("qr-code", {
    attrs: {
      text: _vm.qrCodeText,
      size: 80
    }
  }), _vm._v(" "), _c("p", {
    staticClass: "txtbaixo"
  }, [_vm._v("\n          " + _vm._s(_vm.qrCodeText) + "\n        ")])], 1)])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;