"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    staticClass: "identifiers-page",
    attrs: {
      menu: _vm.menuItems.children,
      "sub-menu": _vm.menuItems.subMenu,
      "return-text": "Lista de fazendas",
      "return-menu": _vm.menuItems.path
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h2", {
    staticClass: "header-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.identifiers.title")) + "\n      ")])]), _vm._v(" "), _c("b-tabs", {
    attrs: {
      type: "is-toggle"
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("b-tab-item", {
    attrs: {
      label: "Geração de Tags"
    }
  }, [_c("div", [_c("div", {
    staticClass: "columns content-columns-qrcodes"
  }, [_c("div", {
    staticClass: "column is-two-thirds"
  }, [_c("card-amount", {
    on: {
      action: _vm.verifyGenerateCodes
    },
    model: {
      value: _vm.amount,
      callback: function callback($$v) {
        _vm.amount = $$v;
      },
      expression: "amount"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("CardQrcode", {
    on: {
      action: _vm.generateFlag
    }
  })], 1)]), _vm._v(" "), _vm.listPrint.length >= 10 ? _c("div", {
    staticClass: "columns content-columns-qrcodes"
  }, [_c("div", {
    staticClass: "column is-two-thirds"
  }, [_c("card-send-email", {
    model: {
      value: _vm.emailDownload,
      callback: function callback($$v) {
        _vm.emailDownload = $$v;
      },
      expression: "emailDownload"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.identifiersList.data.length ? _c("div", {
    staticClass: "content-list-items"
  }, [_c("h3", {
    staticClass: "title-destaq-page"
  }, [_vm._v("\n              " + _vm._s(_vm.$t("pages.identifiers.title_list")) + "\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "field field-actions-checks"
  }, [_vm.listPrint.length ? _c("div", {
    staticClass: "display-qrs-selecteds"
  }, [_c("p", [_vm._v("\n                  " + _vm._s(_vm.listPrint.length) + " " + _vm._s(_vm.$t("pages.identifiers.qr_codes_selecteds")) + "\n                ")])]) : _vm._e(), _vm._v(" "), _c("b-checkbox", {
    model: {
      value: _vm.checkAll,
      callback: function callback($$v) {
        _vm.checkAll = $$v;
      },
      expression: "checkAll"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.$t("pages.identifiers.select_all")) + "\n              ")]), _vm._v(" "), _c("b-button", {
    staticClass: "btn-deselect",
    on: {
      click: function click($event) {
        return _vm.allCheckedOption("markOff");
      }
    }
  }, [_vm._v("\n                " + _vm._s(_vm.$t("pages.identifiers.clear_all")) + "\n              ")]), _vm._v(" "), _c("div", [_c("tr", [_c("b-checkbox", {
    staticClass: "ckbox",
    model: {
      value: _vm.checkIndividual,
      callback: function callback($$v) {
        _vm.checkIndividual = $$v;
      },
      expression: "checkIndividual"
    }
  }, [_vm._v("\n                    " + _vm._s(_vm.$t("pages.identifiers.cards.qrcode.individualtag")) + "\n                  ")])], 1), _vm._v(" "), _c("tr", [_c("b-checkbox", {
    staticClass: "ckbox",
    model: {
      value: _vm.checkFlag,
      callback: function callback($$v) {
        _vm.checkFlag = $$v;
      },
      expression: "checkFlag"
    }
  }, [_vm._v("\n                    " + _vm._s(_vm.$t("pages.identifiers.cards.qrcode.flagtag")) + "\n                  ")])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "display-right"
  }, [_c("p", [_vm._v("\n                  " + _vm._s(_vm.identifiersList.total) + " " + _vm._s(_vm.$t("pages.identifiers.qr_codes_available")) + "\n                ")]), _vm._v(" "), _vm.listPrint.length ? _c("b-button", {
    staticClass: "btn-print-action",
    on: {
      click: _vm.printItems
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.$t("pages.identifiers.download_all")) + "\n                ")]) : _vm._e()], 1)], 1)]) : _vm._e(), _vm._v(" "), _vm.listFiltered.length && !_vm.isLoading ? _c("list-item-check", {
    attrs: {
      "list-qr-codes": _vm.listFiltered,
      "list-print": _vm.listPrint,
      "check-option": _vm.allCheckedOption,
      "check-all": _vm.checkAll
    },
    on: {
      "update:listQrCodes": function updateListQrCodes($event) {
        _vm.listFiltered = $event;
      },
      "update:list-qr-codes": function updateListQrCodes($event) {
        _vm.listFiltered = $event;
      },
      "update:listPrint": function updateListPrint($event) {
        _vm.listPrint = $event;
      },
      "update:list-print": function updateListPrint($event) {
        _vm.listPrint = $event;
      },
      "update:checkAll": function updateCheckAll($event) {
        _vm.checkAll = $event;
      },
      "update:check-all": function updateCheckAll($event) {
        _vm.checkAll = $event;
      }
    }
  }) : _c("no-record-found", {
    attrs: {
      title: "Resultados não encontrados",
      description: "Sem registros para a página selecionada"
    }
  }), _vm._v(" "), _vm.identifiersList.total > _vm.perPage ? _c("b-pagination", {
    attrs: {
      total: _vm.identifiersList.total,
      current: _vm.pageCurrent,
      "range-before": _vm.rangeBefore,
      "range-after": _vm.rangeAfter,
      "per-page": _vm.perPage,
      "icon-prev": _vm.prevIcon,
      "icon-next": _vm.nextIcon,
      "aria-next-label": "Próxima página",
      "aria-previous-label": "Página anterior",
      "aria-page-label": "Página",
      "aria-current-label": "Pagina atual"
    },
    on: {
      "update:current": function updateCurrent($event) {
        _vm.pageCurrent = $event;
      }
    }
  }) : _vm._e()], 1)]), _vm._v(" "), _c("b-tab-item", {
    attrs: {
      label: "Relatório de downloads"
    }
  }, [_vm.activeTab === 1 ? _c("list-reports-tags") : _vm._e()], 1)], 1)], 1)], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;