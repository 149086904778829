"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-field", {
    staticClass: "field-dates-filter-template",
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    class: {
      "is-danger": _vm.errorStart
    },
    attrs: {
      label: "Data de início"
    }
  }, [_c("b-datepicker", {
    ref: "datepicker",
    attrs: {
      expanded: "",
      placeholder: "DD/MM/AAAA",
      icon: "calendar-today",
      value: _vm.dateStart
    },
    on: {
      input: _vm.updateInit
    }
  })], 1), _vm._v(" "), _c("b-field", {
    class: {
      "is-danger": _vm.errorEnd
    },
    attrs: {
      label: "Data de fim"
    }
  }, [_c("b-datepicker", {
    ref: "datepicker",
    attrs: {
      expanded: "",
      placeholder: "DD/MM/AAAA",
      icon: "calendar-today",
      value: _vm.dateEnd
    },
    on: {
      input: _vm.updateEnd
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;