"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    staticClass: "routes-farms-manafement-page",
    attrs: {
      "return-menu": "/farm-management/dashboard",
      menu: _vm.menuItemsFarm.children,
      "sub-menu": _vm.menuItemsFarm.subMenu,
      subtitle: _vm.menuItemsFarm.meta.title
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h2", {
    staticClass: "header-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.routes.title")) + "\n      ")])]), _vm._v(" "), _c("div", {
    staticClass: "table-items-list"
  }, [_c("b-table", {
    attrs: {
      data: _vm.listUsers,
      striped: true,
      hoverable: true,
      loading: _vm.isLoading,
      "mobile-cards": true,
      paginated: _vm.optionsTable.isPaginated,
      "per-page": _vm.optionsTable.perPage,
      "current-page": _vm.currentPage,
      "pagination-simple": _vm.optionsTable.isPaginationSimple,
      "default-sort-direction": _vm.optionsTable.defaultSortDirection,
      "show-detail-icon": true,
      "custom-detail-row": ""
    },
    on: {
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  }, [[_c("b-table-column", {
    attrs: {
      field: "firstName",
      label: _vm.$t("pages.team.labels.name"),
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("a", {
          on: {
            click: function click($event) {
              return _vm.openDetail(props.row);
            }
          }
        }, [_c("div", {
          staticClass: "avatar-user-list"
        }, [props.row.imageUrl ? _c("img", {
          attrs: {
            src: props.row.imageUrl
          }
        }) : _c("img", {
          attrs: {
            src: require("@/assets/images/user-icon.png")
          }
        })]), _vm._v("\n              " + _vm._s(props.row.firstName) + " " + _vm._s(props.row.lastName) + "\n            ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "registration",
      label: _vm.$t("pages.team.labels.registration"),
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("a", {
          on: {
            click: function click($event) {
              return _vm.openDetail(props.row);
            }
          }
        }, [_vm._v("\n              " + _vm._s(props.row.registration) + "\n            ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "role",
      label: _vm.$t("pages.team.labels.role")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("a", {
          on: {
            click: function click($event) {
              return _vm.openDetail(props.row);
            }
          }
        }, [_vm._v("\n              " + _vm._s(_vm._f("convertRole")(props.row.role)) + "\n            ")])];
      }
    }])
  })], _vm._v(" "), _c("template", {
    slot: "empty"
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-grey has-text-centered"
  }, [_c("p", [_c("b-icon", {
    attrs: {
      icon: "emoticon-sad",
      size: "is-large"
    }
  })], 1), _vm._v(" "), _c("p", [_vm._v("\n                " + _vm._s(_vm.$t("pages.team.no_results")) + "\n              ")])])])])], 2)], 1)])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;