"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default2 = {
  name: 'FilterDate',
  props: {
    dateStart: {
      type: Date,
      default: new Date()
    },
    dateEnd: {
      type: Date,
      default: new Date()
    },
    updateDate: {
      type: Function,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      errorStart: false,
      errorEnd: false
    };
  },
  methods: {
    updateInit: function updateInit(val) {
      var start = (0, _moment.default)(val);
      var end = (0, _moment.default)(this.dateEnd);
      this.errorStart = start > end;
      if (start <= end) this.$emit('updateDate', {
        data: val,
        type: 'from'
      });
    },
    updateEnd: function updateEnd(val) {
      var start = (0, _moment.default)(this.dateStart);
      var end = (0, _moment.default)(val);
      this.errorEnd = start > end;
      if (start <= end) this.$emit('updateDate', {
        data: val,
        type: 'to'
      });
    }
  }
};
exports.default = _default2;