"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "modal-form-farm-list-farms",
    attrs: {
      active: _vm.showModalItem,
      width: 944,
      scroll: "keep",
      "can-cancel": ["escape"],
      "on-cancel": _vm.cancelModal
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.showModalItem = $event;
      }
    }
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "media"
  }, [_c("div", {
    staticClass: "media-content"
  }, [_c("button", {
    staticClass: "btn-x-close",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.cancelModal
    }
  }, [_c("i", {
    staticClass: "icon-ic_close"
  })]), _vm._v(" "), _c("p", {
    staticClass: "title"
  }, [_vm._v("\n            Imprimir\n          ")])])]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("b-field", {
    attrs: {
      label: "Selecione o modelo da etiqueta de rastreabilidade"
    }
  }, [_c("b-field", [_c("b-select", {
    attrs: {
      placeholder: "Selecionar"
    },
    model: {
      value: _vm.imageType,
      callback: function callback($$v) {
        _vm.imageType = $$v;
      },
      expression: "imageType"
    }
  }, _vm._l(_vm.imageTypes, function (item, index) {
    return _c("option", {
      key: index,
      domProps: {
        value: item.id
      }
    }, [_vm._v("\n                " + _vm._s(item.name) + "\n              ")]);
  }), 0)], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "content",
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      id: "tag"
    }
  }, [_c("img", {
    staticStyle: {
      width: "500px"
    },
    attrs: {
      id: "tagIMage",
      src: _vm.dataImage,
      alt: "Base64 encoded image"
    }
  })])]), _vm._v(" "), _c("div", {
    staticClass: "field btns-actions",
    staticStyle: {
      margin: "20px"
    }
  }, [_c("button", {
    staticClass: "button is-primary",
    staticStyle: {
      margin: "20px"
    },
    on: {
      click: _vm.printImage
    }
  }, [_vm._v("\n        Imprimir\n      ")]), _vm._v(" "), _c("button", {
    staticClass: "button is-primary",
    staticStyle: {
      margin: "20px"
    },
    on: {
      click: _vm.downloadImage
    }
  }, [_vm._v("\n        Download\n      ")])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;