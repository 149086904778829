"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "auth-page"
  }, [_c("div", {
    staticClass: "container-auth"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "wrap-auth register-success"
  }, [_c("h2", {
    staticClass: "login-form-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.login.user_not_confirmed_title")) + "\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "description-success"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.login.user_not_confirmed_subtitle")) + "\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "container-auth-form-btn"
  }, [_c("div", {
    staticClass: "wrap-auth-form-btn"
  }, [_c("b-button", {
    attrs: {
      rounded: ""
    },
    on: {
      click: _vm.resendEmailUserRest
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.login.resend_email")) + "\n          ")])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "container-auth-return-login"
  }, [_c("a", {
    on: {
      click: function click($event) {
        return _vm.redirect("auth/login");
      }
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.login.login")) + "\n        ")])])])])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container-logo-auth"
  }, [_c("img", {
    staticClass: "logo-fibo-auth",
    attrs: {
      src: require("@/assets/logo.png")
    }
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;