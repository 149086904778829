"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'ButtonActionsHeader',
  props: {
    goToBack: {
      type: Function,
      default: function _default() {}
    },
    confirmCancel: {
      type: Function,
      default: function _default() {}
    },
    updateSignature: {
      type: Function,
      default: function _default() {}
    },
    verifyArea: {
      type: Boolean,
      default: false
    },
    farmName: {
      type: String,
      default: ''
    },
    showUpdateSignature: {
      type: Boolean,
      default: false
    }
  }
};