"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    class: "archiveds-page",
    attrs: {
      "return-menu": "/farm-management/dashboard",
      menu: _vm.menuItemsServices.children,
      subtitle: "Serviços"
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h2", {
    staticClass: "header-title"
  }, [_vm._v("\n        Arquivados\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "search-bar"
  }, [_c("div", {
    staticClass: "field search-input"
  }, [_c("div", {
    staticClass: "control has-icons-right is-clearfix"
  }, [_c("input", {
    staticClass: "input",
    attrs: {
      type: "search",
      autocomplete: "on",
      placeholder: "Pesquisar (Ex.: Serviços/ Operador/ Data)"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "icon is-right"
  }, [_c("i", {
    staticClass: "mdi mdi-magnify mdi-24px"
  })])])])])]), _vm._v(" "), _c("div", {
    staticClass: "table-items-list"
  }, [_c("p", {
    staticClass: "text-destaq"
  }, [_vm._v("\n        Os serviços que permanecerem na pasta “Arquivados” por mais de 30 dias serão excluídos automaticamente.\n      ")]), _vm._v(" "), _c("b-table", {
    attrs: {
      data: _vm.isEmpty ? [] : _vm.storeServices,
      striped: true,
      hoverable: true,
      loading: _vm.isLoading,
      "mobile-cards": true,
      paginated: _vm.isPaginated,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "pagination-simple": _vm.isPaginationSimple,
      "default-sort-direction": _vm.defaultSortDirection
    },
    on: {
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  }, [[_c("b-table-column", {
    attrs: {
      field: "name",
      label: "Tipo de serviço",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.name) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "plots",
      label: "Local",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return _vm._l(props.row.plots, function (item, index) {
          return _c("span", {
            key: index,
            staticClass: "destaq-item-col"
          }, [_vm._v("\n              " + _vm._s(item.name) + "\n            ")]);
        });
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "dueDate",
      label: "Data do agendamento"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(_vm._f("date")(new Date(props.row.dueDate))) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      width: "40"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-dropdown", {
          attrs: {
            "aria-role": "list",
            position: "is-top-left"
          }
        }, [_c("b-button", {
          staticClass: "btn-click-dots",
          attrs: {
            slot: "trigger",
            outlined: "",
            "icon-right": "dots-vertical"
          },
          slot: "trigger"
        }), _vm._v(" "), _c("b-dropdown-item", {
          attrs: {
            "aria-role": "listitem"
          },
          on: {
            click: function click($event) {
              return _vm.preparesChange(props.row);
            }
          }
        }, [_vm._v("\n                Mover para a agenda\n              ")]), _vm._v(" "), _c("b-dropdown-item", {
          attrs: {
            "aria-role": "listitem"
          },
          on: {
            click: function click($event) {
              return _vm.verifyDelete(props.row);
            }
          }
        }, [_vm._v("\n                Excluir\n              ")])], 1)];
      }
    }])
  })], _vm._v(" "), _c("template", {
    slot: "empty"
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-grey has-text-centered"
  }, [_c("p", [_c("b-icon", {
    attrs: {
      icon: "emoticon-sad",
      size: "is-large"
    }
  })], 1), _vm._v(" "), _c("p", [_vm._v("Sem resultados.")])])])])], 2)], 1)])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;