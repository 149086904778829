"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'ModalDelete',
  data: function data() {
    return {
      showModalItem: this.showModal
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Excluir agendamento'
    },
    description: {
      type: String,
      default: 'O serviço será excluido da agenda da fazenda e essa ação não poderá ser desfeita. Você tem certeza que deseja excluir?'
    },
    listButtons: {
      type: Array,
      default: function _default() {
        return ['cancel', 'confirm'];
      }
    },
    textCancel: {
      type: String,
      default: 'Cancelar'
    },
    textConfirm: {
      type: String,
      default: 'Excluir'
    },
    textConfirmAll: {
      type: String,
      default: 'Deletar este e os próximos'
    },
    confirmAction: {
      type: Function,
      default: function _default() {}
    }
  },
  methods: {
    cancelModal: function cancelModal() {
      this.$parent.close();
    },
    confirmModal: function confirmModal(type) {
      this.confirmAction(type);
    }
  }
};
exports.default = _default2;