"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'Services',
  meta: {
    expanded: false,
    title: 'Serviços',
    iconClass: 'i-bitcoin',
    requiresAuth: true
  },
  children: [{
    name: 'schedule',
    path: '/services/schedule',
    icon: 'icon-ic_calendar',
    meta: {
      title: 'Agenda',
      requiresAuth: true
    }
  }, {
    name: 'archived-services',
    path: '/services/archiveds',
    icon: 'icon-ic_arquivados',
    meta: {
      title: 'Arquivados',
      requiresAuth: true
    }
  }, {
    name: 'edit-list-services',
    path: '/services/edit/list',
    icon: 'icon-ic_concluido',
    meta: {
      title: 'Finalizados',
      requiresAuth: true
    }
  }, {
    name: 'custom-fields-list-client',
    path: '/customfields/list-client',
    icon: 'icon-ic_settings',
    meta: {
      title: 'Campos Personalizados',
      requiresAuth: true
    }
  }]
};
exports.default = _default;