"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  props: {
    questions: {
      type: [String, Number],
      default: ''
    }
  },
  data: function data() {
    return {
      visible: 'true'
    };
  }
};
exports.default = _default;