"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-valid-tag-wrap"
  }, [!_vm.tag.id ? _c("div", {
    staticClass: "container-wrap error"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "container-wrap"
  }, [_vm.error ? _c("h2", [_vm._v("\n        Etiqueta não encontrada\n      ")]) : _vm._e()])]) : _vm._e(), _vm._v(" "), _vm.tag.id ? _c("div", {
    staticClass: "container-wrap"
  }, [_c("div", {
    staticClass: "container-logo-auth"
  }, [_vm._m(1), _vm._v(" "), _c("div", {
    staticClass: "content-right-header"
  }, [_c("h3", [_vm._v(_vm._s(_vm.$t("pages.valid_tag.title_description")))]), _vm._v(" "), _c("b-button", {
    on: {
      click: _vm.redirect
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.valid_tag.btn_singup")) + "\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "wrap-social"
  }, [_c("button", {
    staticClass: "icon",
    on: {
      click: _vm.openInsta
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon-instagram.png")
    }
  })]), _vm._v(" "), _c("button", {
    staticClass: "icon",
    on: {
      click: _vm.openFace
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon-facebook.png")
    }
  })])])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "bottom-section-header"
  }, [_vm._v("\n      " + _vm._s(_vm.$t("pages.valid_tag.label_product")) + "\n    ")]), _vm._v(" "), _c("div", {
    staticClass: "section-product"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column center"
  }, [_vm.customization && _vm.customization.imageUrl ? _c("img", {
    attrs: {
      src: _vm.customization.imageUrl
    }
  }) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("label", [_vm._v("Produto")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.tag.product.name || ""))])]), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("label", [_vm._v("Código de Rastreio")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.tag.identifier || ""))])])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_vm.customization.description ? _c("label", [_vm._v("Sobre o Produto")]) : _vm._e(), _vm._v(" "), _vm.customization.description ? _c("p", [_vm._v("\n            " + _vm._s(_vm.customization.description) + "\n          ")]) : _vm._e()])])]), _vm._v(" "), _c("div", {
    staticClass: "section-product-information"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_vm.showInformationNutritional ? _c("card-nutritional-information", {
    attrs: {
      information: _vm.customization
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.availabilitys.length ? _c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("label", [_vm._v("Disponibilidade")]), _vm._v(" "), _vm.availabilitys.length ? _c("div", {
    staticClass: "list-availability"
  }, _vm._l(_vm.listMonthsOne, function (number, idx) {
    return _c("span", {
      key: idx
    }, [_vm._v("\n                " + _vm._s(_vm.availabilitys[idx]) + "\n              ")]);
  }), 0) : _vm._e(), _vm._v(" "), _vm.availabilitys.length && _vm.availabilitys.length > 6 ? _c("div", {
    staticClass: "list-availability"
  }, _vm._l(_vm.listMonthsTwo, function (number, idx) {
    return _c("span", {
      key: idx
    }, [_vm._v("\n                " + _vm._s(_vm.availabilitys[6 + idx]) + "\n              ")]);
  }), 0) : _vm._e()])]) : _vm._e()])]), _vm._v(" "), _c("div", {
    staticClass: "bottom-section-header"
  }, [_vm._v("\n      " + _vm._s(_vm.$t("pages.valid_tag.label_product_geocode")) + "\n    ")]), _vm._v(" "), _c("div", {
    staticClass: "section-product-geocode"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-two-thirds"
  }, [_c("gmap-map", {
    ref: "map",
    staticStyle: {
      width: "100%",
      height: "400px"
    },
    attrs: {
      center: _vm.center,
      zoom: 8,
      "map-type-id": "satellite"
    }
  }, _vm._l(_vm.markers, function (m, index) {
    return _c("gmap-marker", {
      key: index,
      attrs: {
        position: m.position,
        clickable: false,
        draggable: false
      }
    });
  }), 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("p", [_vm._v("FORNECEDOR:")]), _vm._v(" "), _c("p", {
    staticClass: "upper"
  }, [_vm._v("\n              " + _vm._s(_vm.provider.name) + "\n            ")]), _vm._v(" "), _c("p", [_vm._v("CNPJ: " + _vm._s(_vm._f("maskCNPJ")(_vm.providerCNPJ)))]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.provider.address) + " " + _vm._s(_vm.provider.addressNumber) + " - " + _vm._s(_vm.provider.state))]), _vm._v(" "), _vm.provider.phone ? _c("p", [_vm._v("\n              " + _vm._s(_vm._f("maskPhone")(_vm.provider.phone)) + "\n            ")]) : _vm._e(), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.provider.email || ""))])]), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("p", [_vm._v("DISTRIBUIDOR:")]), _vm._v(" "), _c("p", {
    staticClass: "upper"
  }, [_vm._v("\n              " + _vm._s(_vm.client.name) + "\n            ")]), _vm._v(" "), _c("p", [_vm._v("CNPJ: " + _vm._s(_vm._f("maskCNPJ")(_vm.client.cpfcnpj)))]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.client.address) + " " + _vm._s(_vm.client.addressNumber) + " - " + _vm._s(_vm.client.state))]), _vm._v(" "), _vm.client.phone ? _c("p", [_vm._v("\n              " + _vm._s(_vm._f("maskPhone")(_vm.client.phone)) + "\n            ")]) : _vm._e(), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.client.email || ""))])])])])]), _vm._v(" "), _vm.tag.farm.about ? _c("div", {
    staticClass: "bottom-section-header"
  }, [_vm._v("\n      " + _vm._s(_vm.$t("pages.valid_tag.label_provider")) + "\n    ")]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "section-provider"
  }, [_c("p", [_vm._v("\n        " + _vm._s(_vm.tag.farm.about || "") + "\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "list-images"
  }, [_c("carousel", {
    attrs: {
      "per-page": 4
    }
  }, _vm._l(_vm.tag.farm.images, function (img, idx) {
    return _c("slide", {
      key: idx
    }, [_c("b-image", {
      attrs: {
        src: img
      }
    })], 1);
  }), 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "section-contact"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-three-fifths"
  }, [_c("h2", [_vm._v(_vm._s(_vm.$t("pages.valid_tag.title_about")))]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.$t("pages.valid_tag.text_about_1")))]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.$t("pages.valid_tag.text_about_2")))])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("form", {
    on: {
      submit: _vm.sendContact
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.name.$error,
      placeholder: "Nome completo"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.name, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.name, "$model", $$v);
      },
      expression: "$v.form.name.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.form.mail.$error,
      placeholder: "e-mail",
      type: "email"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.mail, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.mail.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.mail, "$model", $$v);
      },
      expression: "$v.form.mail.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.form.phone.$error,
      mask: ["(##) #####-####"],
      placeholder: "Whatsapp"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.phone, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.phone.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.phone, "$model", $$v);
      },
      expression: "$v.form.phone.$model"
    }
  }), _vm._v(" "), _c("b-button", {
    attrs: {
      tag: "input",
      "native-type": "submit",
      value: "Enviar"
    }
  })], 1)])])]), _vm._v(" "), _c("div", {
    staticClass: "section-footer"
  }, [_c("h3", [_vm._v(_vm._s(_vm.$t("pages.valid_tag.text_footer")))]), _vm._v(" "), _c("b-icon", {
    attrs: {
      icon: "instagram"
    },
    nativeOn: {
      click: function click($event) {
        return _vm.openInsta.apply(null, arguments);
      }
    }
  }), _vm._v(" "), _c("b-icon", {
    attrs: {
      icon: "facebook"
    },
    nativeOn: {
      click: function click($event) {
        return _vm.openFace.apply(null, arguments);
      }
    }
  })], 1)]) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container-logo-auth"
  }, [_c("img", {
    staticClass: "logo-fibo-auth",
    attrs: {
      src: require("@/assets/logo.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-left-header"
  }, [_c("img", {
    staticClass: "logo-fibo-auth",
    attrs: {
      src: require("@/assets/logo.png")
    }
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;