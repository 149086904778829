"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validators = require("vuelidate/lib/validators");
var itemsDescriptions = [{
  subplot: 'Subtalhão'
}, {
  rows: 'Linha'
}, {
  families: 'Familía'
}, {
  bunche: 'Cacho'
}];
var _default2 = exports.default = {
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    type: {
      type: String,
      default: null
    },
    saveItem: {
      type: Function,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      number: null,
      createLineStructure: false
    };
  },
  validations: {
    number: {
      required: _validators.required,
      between: (0, _validators.between)(1, 99999)
    },
    createLineStructure: {}
  },
  computed: {
    descriptionItem: function descriptionItem() {
      var _this = this;
      var data = itemsDescriptions.find(function (ele) {
        return ele[_this.type];
      });
      return data ? data[this.type] : '';
    }
  },
  methods: {
    closeModal: function closeModal() {
      this.$parent.close();
    },
    submitForm: function submitForm() {
      this.$v.number.$touch();
      if (this.$v.number.$error) {
        return this.$buefy.notification.open({
          duration: 5000,
          message: 'Preencha o valor solicitado corretamente',
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: true
        });
      }
      this.saveItem({
        number: this.number,
        item: this.item,
        createLineStructure: this.createLineStructure
      });
    }
  }
};