"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("side-menu-template", [_c("template", {
    slot: "menu-header"
  }, [_c("label", [_c("strong", [_vm._v("Seja bem-vindo(a),")]), _c("br"), _vm._v("\n      " + _vm._s(_vm.userAdmin.firstName) + " " + _vm._s(_vm.userAdmin.lastName)), _c("br"), _vm._v(" "), _c("span", {
    staticClass: "text-profile"
  }, [_c("strong", [_vm._v("Perfil: ")]), _vm._v(_vm._s(_vm._f("convertRole")(_vm.userAdmin.role)) + "\n      ")])]), _vm._v(" "), _c("h3", [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _vm.showReturn ? _c("div", {
    staticClass: "return-div-item"
  }, [_c("button", {
    staticClass: "button-return",
    attrs: {
      type: "button"
    },
    on: {
      click: function click($event) {
        return _vm.returnPage();
      }
    }
  }, [_c("span", {
    staticClass: "icon"
  }, [_c("i", {
    staticClass: "icon-ic_voltar"
  })]), _vm._v(" "), _c("span", [_vm._v("\n          " + _vm._s(_vm.returnText) + "\n        ")])])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "divider"
  }), _vm._v(" "), _vm.subtitle ? _c("h4", [_vm._v("\n      " + _vm._s(_vm.subtitle) + "\n    ")]) : _vm._e()]), _vm._v(" "), _vm.titleNewItem ? _c("template", {
    slot: "menu-button"
  }, [_c("a", {
    on: {
      click: _vm.newItemMethod
    }
  }, [_vm._v("\n      " + _vm._s(_vm.titleNewItem) + " "), _c("span", [_vm._v("+")])])]) : _vm._e(), _vm._v(" "), _c("template", {
    slot: "menu-options"
  }, [_vm.allMenuItens.length ? _c("div", {
    staticClass: "menu-items-list"
  }, _vm._l(_vm.filteredMenus, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "menu-item-line"
    }, [_c("button", {
      staticClass: "button",
      class: {
        active: _vm.isActiveIten(item)
      },
      attrs: {
        type: "button"
      },
      on: {
        click: function click($event) {
          return _vm.openMenuItem(item);
        }
      }
    }, [item.icon ? _c("span", {
      staticClass: "icon is-small"
    }, [_c("i", {
      class: item.icon
    })]) : _vm._e(), _vm._v(" "), _c("span", [_vm._v("\n            " + _vm._s(item.meta.title) + "\n          ")])])]);
  }), 0) : _c("b-notification", {
    attrs: {
      closable: false
    }
  }, [_vm._v("\n      " + _vm._s(_vm.empty) + "\n    ")]), _vm._v(" "), _vm.allMenuItensTwo.length ? _c("div", {
    staticClass: "second-menu"
  }, [_c("div", {
    staticClass: "divider"
  }), _vm._v(" "), _vm.subMenuTitle ? _c("h4", [_vm._v("\n        " + _vm._s(_vm.subMenuTitle) + "\n      ")]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "menu-items-list"
  }, _vm._l(_vm.allMenuItensTwo, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "menu-item-line"
    }, [_c("button", {
      staticClass: "button",
      class: {
        active: _vm.isActiveIten(item)
      },
      attrs: {
        type: "button"
      },
      on: {
        click: function click($event) {
          return _vm.openMenuItem(item);
        }
      }
    }, [item.icon ? _c("span", {
      staticClass: "icon is-small"
    }, [_c("i", {
      class: item.icon
    })]) : _vm._e(), _vm._v(" "), _c("span", [_vm._v("\n              " + _vm._s(item.meta.title) + "\n            ")])])]);
  }), 0)]) : _vm._e(), _vm._v(" "), _vm.allMenuItensThree.length ? _c("div", {
    staticClass: "second-menu"
  }, [_c("div", {
    staticClass: "divider"
  }), _vm._v(" "), _vm.subMenuTitleThree ? _c("h4", [_vm._v("\n        " + _vm._s(_vm.subMenuTitleThree) + "\n      ")]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "menu-items-list"
  }, _vm._l(_vm.allMenuItensThree, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "menu-item-line"
    }, [_c("button", {
      staticClass: "button",
      class: {
        active: _vm.isActiveIten(item)
      },
      attrs: {
        type: "button"
      },
      on: {
        click: function click($event) {
          return _vm.openMenuItem(item);
        }
      }
    }, [item.icon ? _c("span", {
      staticClass: "icon is-small"
    }, [_c("i", {
      class: item.icon
    })]) : _vm._e(), _vm._v(" "), _c("span", [_vm._v("\n              " + _vm._s(item.meta.title) + "\n            ")])])]);
  }), 0)]) : _vm._e()], 1)], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;