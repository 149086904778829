"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-agendamento"
  }, [_c("main", [_c("section", {
    staticClass: "main-center",
    attrs: {
      id: "mainCenter"
    }
  }, [_c("div", {
    staticClass: "infor-init"
  }, [_c("div", {
    staticClass: "sel-date-tl"
  }, [_c("div", {
    staticClass: "field field-type-services-sel"
  }, [_vm._m(0), _vm._v(" "), _c("app-select", {
    attrs: {
      disabled: _vm.disabledItem || _vm.disableProgress,
      placeholder: "Selecionar o serviço",
      items: _vm.storeServicetypes,
      option: "id",
      "name-item": "name"
    },
    model: {
      value: _vm.scheduling.typeService,
      callback: function callback($$v) {
        _vm.$set(_vm.scheduling, "typeService", $$v);
      },
      expression: "scheduling.typeService"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "date-picker-schedule"
  }, [_c("button", {
    staticClass: "button",
    attrs: {
      type: "button"
    },
    on: {
      click: function click($event) {
        return _vm.$refs.datepicker.toggle();
      }
    }
  }, [_c("i", {
    staticClass: "icon icon-ic_calendar"
  })]), _vm._v(" "), _c("date-picker", {
    staticClass: "border-bt",
    attrs: {
      placeholder: "Informe a data"
    },
    model: {
      value: _vm.scheduling.date,
      callback: function callback($$v) {
        _vm.$set(_vm.scheduling, "date", $$v);
      },
      expression: "scheduling.date"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "field field-local"
  }, [_c("div", {
    staticClass: "columns"
  }, [_vm._m(1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [!_vm.disabledItem && !_vm.disableProgress ? _c("div", {
    staticClass: "edit-information"
  }, [_c("a", {
    on: {
      click: _vm.returnStepDown
    }
  }, [_vm._v("Editar informações")])]) : _vm._e()])])])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "flow-list-view-tl"
  }, [_c("div", {
    staticClass: "leftsidebar"
  }, [_c("list-itens-left", {
    attrs: {
      plots: _vm.checkedPlots,
      "select-talhao": _vm.selectPlot,
      "plot-selected": _vm.plotSelected,
      "is-checked": false
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "select-sub-tl"
  }, [_vm.checkedPlots.length > 0 ? _c("div", {
    staticClass: "list-collapse-tl"
  }, _vm._l(_vm.checkedPlots[_vm.plotSelected].subplots, function (item, index) {
    return _c("Colapse", {
      key: index,
      attrs: {
        item: item,
        "is-checked": false,
        type: "subtalhao"
      },
      on: {
        onClickOpen: _vm.loadLines
      }
    }, [_c("template", {
      slot: "body"
    }, [item.lines && item.lines.length > 0 ? _c("div", {
      staticClass: "bg-sub-tl-one"
    }, [_c("div", {
      staticClass: "second"
    }, _vm._l(item.lines, function (itemLIne, iLine) {
      return _c("Colapse", {
        key: iLine,
        staticClass: "third-sel",
        attrs: {
          item: itemLIne,
          "is-checked": false
        },
        on: {
          onClickOpen: _vm.loadFamilies
        }
      }, [_c("template", {
        slot: "body"
      }, [itemLIne.families && itemLIne.families.length > 0 ? _c("div", {
        staticClass: "bg-sub-tl-two"
      }, [_c("div", _vm._l(itemLIne.families, function (itemFamilies, iFamily) {
        return _c("Colapse", {
          key: iFamily,
          staticClass: "four-sel",
          attrs: {
            sub: item,
            item: itemFamilies,
            "is-checked": false
          },
          on: {
            onClickOpen: _vm.loadBunches
          }
        }, [_c("template", {
          slot: "body"
        }, [itemFamilies.bunches && itemFamilies.bunches.length > 0 ? _c("div", {
          staticClass: "bg-sub-tl-thrre"
        }, _vm._l(itemFamilies.bunches, function (itemCacho, iCacho) {
          return _c("div", {
            key: iCacho,
            staticClass: "collapse-fade-service",
            attrs: {
              "name-item": "cacho" + itemCacho.id
            }
          }, [_c("div", {
            staticClass: "collapse card"
          }, [_c("div", {
            staticClass: "collapse-trigger"
          }, [_c("div", {
            staticClass: "card-header",
            attrs: {
              role: "button",
              "aria-controls": "contentIdForA11y3"
            }
          }, [_c("p", {
            staticClass: "card-header-title"
          }, [_vm._v("\n                                              " + _vm._s(itemCacho.name) + "\n                                            ")])])])])]);
        }), 0) : _c("div", {
          staticClass: "bg-sub-tl-thrre"
        }, [_c("div", {
          staticClass: "msg-notice"
        }, [_vm._v("\n                                      Não foram encontradas\n                                      "), _c("b", [_vm._v("Famílias")]), _vm._v(" cadastradas\n                                    ")])])])], 2);
      }), 1)]) : _c("div", {
        staticClass: "bg-sub-tl-two"
      }, [_c("div", {
        staticClass: "msg-notice"
      }, [_vm._v("\n                              Não foram encontradas\n                              "), _c("b", [_vm._v("Famílias")]), _vm._v(" cadastradas\n                            ")])])])], 2);
    }), 1)]) : _c("div", {
      staticClass: "bg-sub-tl-two"
    }, [_c("div", {
      staticClass: "msg-notice"
    }, [_vm._v("\n                      Não foram encontradas\n                      "), _c("b", [_vm._v("Linhas")]), _vm._v(" cadastradas\n                    ")])])])], 2);
  }), 1) : _vm._e()])])]), _vm._v(" "), _c("footer", {
    staticClass: "footer-agendamento-component"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col"
  }, [_c("app-select", {
    staticClass: "margin-top",
    attrs: {
      label: "Auto-alocável",
      placeholder: "Selecionar",
      disabled: _vm.disabledItem || _vm.disableProgress,
      items: _vm.autoAplicationOptions,
      option: "value",
      "name-item": "name"
    },
    model: {
      value: _vm.scheduling.selfAllocatable,
      callback: function callback($$v) {
        _vm.$set(_vm.scheduling, "selfAllocatable", $$v);
      },
      expression: "scheduling.selfAllocatable"
    }
  }), _vm._v(" "), _c("app-select", {
    attrs: {
      placeholder: "Selecionar",
      label: "Recorrência",
      disabled: _vm.disabledItem || _vm.disableProgress,
      items: _vm.timePeriod,
      option: "value",
      "name-item": "name"
    },
    model: {
      value: _vm.scheduling.recurrenceType,
      callback: function callback($$v) {
        _vm.$set(_vm.scheduling, "recurrenceType", $$v);
      },
      expression: "scheduling.recurrenceType"
    }
  }), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Data final da recorrência"
    }
  }, [_c("date-picker", {
    attrs: {
      placeholder: "Informe a data"
    },
    model: {
      value: _vm.scheduling.endRecurrenceDate,
      callback: function callback($$v) {
        _vm.$set(_vm.scheduling, "endRecurrenceDate", $$v);
      },
      expression: "scheduling.endRecurrenceDate"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "col"
  }, [_c("b-field", {
    staticClass: "margin-top",
    attrs: {
      label: "Serviço sucessor"
    }
  }, [_vm.serviceSelected && _vm.serviceFollowUpActivity.name ? _c("p", [_vm._v("\n                " + _vm._s(_vm.serviceFollowUpActivity.name) + "\n              ")]) : _c("p", {
    staticClass: "not-allowed"
  }, [_vm._v("\n                --\n              ")])]), _vm._v(" "), _c("b-field", {
    staticClass: "input-date",
    attrs: {
      label: "Data do serviço sucessor"
    }
  }, [_vm.serviceSelected && _vm.serviceFollowUpActivity.name ? _c("b-input", {
    attrs: {
      disabled: "",
      type: "text",
      value: _vm.dateServiceFollowUpActivity
    }
  }) : _c("p", {
    staticClass: "not-allowed"
  }, [_vm._v("\n                --\n              ")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "col"
  }, [_c("b-field", {
    staticClass: "margin-top cycles-list-field",
    attrs: {
      label: "Ciclo"
    }
  }, [_c("b-button", {
    attrs: {
      type: "is-info",
      "icon-right": "plus"
    },
    on: {
      click: _vm.modalAddCycles
    }
  }), _vm._v(" "), _vm.cyclesList.length ? _c("app-select", {
    staticClass: "margin-top",
    attrs: {
      placeholder: "Selecionar",
      items: _vm.cyclesList,
      "show-cancel": true,
      option: "id",
      "name-item": "name"
    },
    model: {
      value: _vm.scheduling.cycle,
      callback: function callback($$v) {
        _vm.$set(_vm.scheduling, "cycle", $$v);
      },
      expression: "scheduling.cycle"
    }
  }) : _c("p", {
    staticClass: "not-allowed"
  }, [_vm._v("\n                --\n              ")])], 1), _vm._v(" "), _c("b-field", {
    staticClass: "margin-top cycles-list-field",
    attrs: {
      label: "OS"
    }
  }, [_c("b-button", {
    attrs: {
      type: "is-info",
      "icon-right": "plus"
    },
    on: {
      click: _vm.modalAddOrderService
    }
  }), _vm._v(" "), _vm.orderServiceList.length ? _c("app-select", {
    staticClass: "margin-top",
    attrs: {
      placeholder: "Selecionar",
      items: _vm.orderServiceList,
      "show-cancel": true,
      option: "id",
      "name-item": "name"
    },
    model: {
      value: _vm.scheduling.os,
      callback: function callback($$v) {
        _vm.$set(_vm.scheduling, "os", $$v);
      },
      expression: "scheduling.os"
    }
  }) : _c("p", {
    staticClass: "not-allowed"
  }, [_vm._v("\n                --\n              ")])], 1)], 1)]), _vm._v(" "), _vm.isColheita ? _c("div", {
    staticClass: "row row-margin-bottom-part"
  }, _vm._l(_vm.scheduling.customFields, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "col"
    }, [_c("b-field", {
      staticClass: "border-bottom margin-top",
      attrs: {
        label: item.name
      }
    }, [item.name === "Distribuidor" ? _c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.value,
        expression: "item.value"
      }],
      staticClass: "input",
      attrs: {
        id: item.id,
        placeholder: item.placeholder,
        disabled: _vm.disabledItem
      },
      domProps: {
        value: item.value
      },
      on: {
        input: function input($event) {
          if ($event.target.composing) return;
          _vm.$set(item, "value", $event.target.value);
        }
      }
    }) : _vm._e(), _vm._v(" "), item.name.indexOf("Peso") > -1 ? _c("the-mask", {
      staticClass: "input",
      attrs: {
        id: item.id,
        autocomplete: "on",
        placeholder: item.placeholder,
        mask: ["# KG", "## KG", "### KG", "#.### KG", "##.### KG", "###.### KG"],
        disabled: _vm.disabledItem
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _vm._e(), _vm._v(" "), item.name.indexOf("Valor do Kilo") > -1 ? _c("money", _vm._b({
      staticClass: "input",
      attrs: {
        id: item.id,
        placeholder: item.placeholder,
        disabled: _vm.disabledItem
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }, "money", _vm.money, false)) : _vm._e()], 1)], 1);
  }), 0) : _c("div", {
    staticClass: "row"
  }, _vm._l(_vm.scheduling.customFields, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "col col-custom-fields"
    }, [_c("b-field", {
      staticClass: "border-bottom margin-top",
      attrs: {
        label: item.name
      }
    }, [_c("b-input", {
      attrs: {
        id: item.id,
        type: "textarea",
        maxlength: "200",
        value: item.value,
        placeholder: item.placeholder,
        disabled: _vm.disabledItem
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1)], 1);
  }), 0), _vm._v(" "), _c("div", [_vm.showColheitaInformation ? _c("b-collapse", {
    staticClass: "card",
    attrs: {
      animation: "slide",
      "aria-id": "contentIdForA11y3"
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn(props) {
        return _c("div", {
          staticClass: "card-header",
          attrs: {
            role: "button",
            "aria-controls": "contentIdForA11y3"
          }
        }, [_c("a", {
          staticClass: "card-header-icon"
        }, [_c("b-icon", {
          attrs: {
            icon: props.open ? "menu-down" : "menu-up"
          }
        })], 1), _vm._v(" "), _c("p", {
          staticClass: "card-header-title"
        }, [_vm._v("\n                Informações da colheita e rastreabilidade do produto\n              ")])]);
      }
    }], null, false, 3585529939)
  }, [_vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-9"
  }, [_c("div", {
    staticClass: "field search-right"
  }, [_c("label", {
    staticClass: "label"
  }, [_c("i", {
    staticClass: "icon icon-ic_operadores"
  }), _vm._v("\n                      Clientes\n                    ")]), _vm._v(" "), !_vm.disabledItem ? _c("b-field", [_c("b-autocomplete", {
    ref: "autocompleteClients",
    attrs: {
      data: _vm.filteredClients,
      placeholder: "Pesquisar clientes",
      field: "title",
      loading: _vm.isFetching
    },
    on: {
      select: function select(option) {
        return _vm.onInsertClient(option);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("div", {
          staticClass: "media"
        }, [_c("div", {
          staticClass: "media-left"
        }, [!props.option.imageUrl ? _c("img", {
          attrs: {
            width: "26",
            src: require("@/assets/images/user-icon.png")
          }
        }) : _c("img", {
          attrs: {
            width: "26",
            src: props.option.imageUrl
          }
        })]), _vm._v(" "), _c("div", {
          staticClass: "media-content"
        }, [_vm._v("\n                              " + _vm._s(props.option.name) + "\n                            ")])])];
      }
    }], null, false, 3782559286),
    model: {
      value: _vm.selectClient,
      callback: function callback($$v) {
        _vm.selectClient = $$v;
      },
      expression: "selectClient"
    }
  }, [_vm._v(" "), _c("template", {
    slot: "empty"
  }, [_vm._v("\n                          Cliente " + _vm._s(_vm.selectClient) + " não encontrado\n                        ")])], 2)], 1) : _vm._e()], 1)]), _vm._v(" "), _c("div", {
    staticClass: "column is-3"
  }, [_c("span", {
    staticClass: "insert-client-label",
    on: {
      click: function click($event) {
        return _vm.openModalClient();
      }
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_c("a", [_vm._v("Cadastrar Cliente")])])]), _vm._v(" "), _vm.listClients.length != 0 ? _c("span", {
    staticClass: "delete-label",
    on: {
      click: function click($event) {
        return _vm.deleteClient();
      }
    }
  }, [_c("label", {
    staticClass: "label"
  }, [_c("a", [_vm._v("Remover Cliente")])])]) : _vm._e()])]), _vm._v(" "), _c("b-tabs", {
    attrs: {
      type: "is-boxed"
    },
    model: {
      value: _vm.clientSelectedIndex,
      callback: function callback($$v) {
        _vm.clientSelectedIndex = $$v;
      },
      expression: "clientSelectedIndex"
    }
  }, [_vm._l(_vm.listClients, function (client, index) {
    return [_c("b-tab-item", {
      key: index,
      attrs: {
        label: client.name || ""
      }
    }, [_vm.showBatchRegister ? _c("ContentBatch", {
      attrs: {
        products: _vm.products,
        client: _vm.selectedClient,
        batch: _vm.batchUpdate
      },
      on: {
        openClassification: _vm.openModalClassification,
        closedModalBatch: function closedModalBatch(item) {
          return _vm.resolvedCallbackModalBatch(item);
        }
      }
    }) : _vm._e(), _vm._v(" "), !_vm.showBatchRegister ? _c("div", _vm._l(_vm.batchProductsClient, function (batchPrd, indexPrd) {
      return _c("ol", {
        key: indexPrd
      }, [_c("li", {
        staticClass: "box",
        staticStyle: {
          "margin-bottom": "5px"
        }
      }, [_c("div", {
        staticClass: "columns"
      }, [_c("div", {
        staticClass: "column is-4"
      }, [_c("b-field", {
        attrs: {
          label: "Lote"
        }
      }, [_vm._v("\n                                " + _vm._s(batchPrd.batchName || "") + "\n                              ")]), _vm._v(" "), _c("b-field", {
        attrs: {
          label: "Tipo de Buquê Penca"
        }
      }, [_vm._v("\n                                " + _vm._s(batchPrd.bouquetType || "") + "\n                              ")]), _vm._v(" "), _c("b-field", {
        attrs: {
          label: "Quantidade caixa"
        }
      }, [_vm._v("\n                                " + _vm._s(batchPrd.quantity || "") + "\n                              ")])], 1), _vm._v(" "), _c("div", {
        staticClass: "column is-2"
      }, [_c("div", {
        staticStyle: {
          height: "60px"
        }
      }), _vm._v(" "), _c("b-field", {
        attrs: {
          label: "Produto"
        }
      }, [_vm._v("\n                                " + _vm._s(_vm.getProduct(batchPrd.productId) ? _vm.getProduct(batchPrd.productId).name : "") + "\n                              ")]), _vm._v(" "), _c("b-field", {
        attrs: {
          label: "Classificação"
        }
      }, [_vm._v("\n                                " + _vm._s(batchPrd.classification || "") + "\n                              ")])], 1), _vm._v(" "), _c("div", {
        staticClass: "column is-3"
      }, [_c("span", {
        staticClass: "etiqueta-label",
        on: {
          click: function click($event) {
            return _vm.editBatch(batchPrd);
          }
        }
      }, [_c("label", {
        staticClass: "label"
      }, [_c("i", {
        staticClass: "icon icon-ic_edit"
      }), _vm._v(" "), _c("a", [_vm._v("Editar produto")])])]), _vm._v(" "), _c("b-field", {
        staticStyle: {
          "margin-top": "35px"
        },
        attrs: {
          label: "Variedade"
        }
      }, [_vm._v("\n                                " + _vm._s(_vm.getVariety(batchPrd.varietyId) ? _vm.getVariety(batchPrd.varietyId).name : "") + "\n                              ")]), _vm._v(" "), _c("b-field", {
        attrs: {
          label: "Cultivar"
        }
      }, [_vm._v("\n                                " + _vm._s(_vm.getCultivation(batchPrd.varietyId, batchPrd.cultivationId) ? _vm.getCultivation(batchPrd.varietyId, batchPrd.cultivationId).name : "") + "\n                              ")])], 1), _vm._v(" "), _c("div", {
        staticClass: "column is-3"
      }, [_c("div", [_c("span", {
        staticClass: "delete-label",
        on: {
          click: function click($event) {
            return _vm.deleteBatch(batchPrd);
          }
        }
      }, [_c("a", [_vm._v("Remover produto")])])]), _vm._v(" "), _c("div", [_c("div", {
        staticStyle: {
          height: "95px"
        }
      }), _vm._v(" "), _c("b-field", {
        attrs: {
          label: "Peso liquido caixa"
        }
      }, [_vm._v("\n                                  " + _vm._s(batchPrd.weight) + " Kg\n                                ")]), _vm._v(" "), _c("span", {
        staticClass: "etiqueta-label",
        on: {
          click: function click($event) {
            return _vm.printTag(batchPrd);
          }
        }
      }, [_c("label", {
        staticClass: "label"
      }, [_c("i", {
        staticClass: "icon icon-ic_print"
      }), _vm._v(" "), _c("a", [_vm._v("Imprimir etiqueta")])])])], 1)])])])]);
    }), 0) : _vm._e()], 1)];
  }), _vm._v(" "), _vm.listClients.length != 0 ? _c("span", {
    staticClass: "edit-information",
    on: {
      click: _vm.openModalBatch
    }
  }, [_c("a", [_vm._v("Click aqui para adicionar um novo lote")])]) : _vm._e(), _vm._v(" "), _vm.listClients.length == 0 ? _c("span", {
    staticClass: "edit-information",
    on: {
      click: _vm.openModalClient
    }
  }, [_c("a", [_vm._v("Click aqui para adicionar um cliente a colheita")])]) : _vm._e()], 2)], 1)]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "row row-historic"
  }, [_vm._m(2), _vm._v(" "), _c("b-field", {
    attrs: {
      type: _vm.erroCommnet ? "is-danger" : null,
      message: _vm.erroCommnet ? "Mínimo de 10 caracteres" : null
    }
  }, [_c("p", {
    staticClass: "control"
  }, [_c("a", {
    staticClass: "button is-small"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/user-icon.png")
    }
  })])]), _vm._v(" "), _c("b-input", {
    attrs: {
      placeholder: "Adicionar comentário",
      disabled: _vm.disabledItem
    },
    on: {
      blur: _vm.onInsertComment
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.onInsertComment.apply(null, arguments);
      }
    },
    model: {
      value: _vm.comment,
      callback: function callback($$v) {
        _vm.comment = $$v;
      },
      expression: "comment"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "list-comments"
  }, _vm._l(_vm.scheduling.logs, function (item, key) {
    return _c("div", {
      key: key,
      staticClass: "list-cts-item"
    }, [_c("img", {
      staticClass: "list-cts-item_img",
      attrs: {
        src: require("@/assets/images/user-icon.png"),
        alt: "Operador"
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "list-cts-item_text"
    }, [_c("p", [_c("span", [_vm._v("Coordenador")]), _vm._v("\n                    " + _vm._s(_vm._f("datePost")(item.date)) + " postou " + _vm._s(item.comment) + "\n                  ")])])]);
  }), 0)])], 1)])]), _vm._v(" "), _c("aside", {
    staticClass: "aside-right"
  }, [_c("div", {
    staticClass: "content-progres"
  }, [_c("div", {
    staticClass: "row"
  }, [_vm._m(3), _vm._v(" "), _c("progress", {
    staticClass: "progress is-small",
    attrs: {
      max: "100"
    },
    domProps: {
      value: _vm.serviceProgress
    }
  }, [_vm._v("\n            " + _vm._s(_vm.serviceProgress) + " %\n          ")]), _vm._v(" "), _c("small", [_vm._v(_vm._s(_vm.serviceProgress) + " %")])])]), _vm._v(" "), _c("div", {
    staticClass: "content-head"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "field search-right"
  }, [_vm._m(4), _vm._v(" "), !_vm.disabledItem ? _c("b-field", [_c("b-autocomplete", {
    ref: "autocompleteOperators",
    attrs: {
      data: _vm.filteredOperators,
      placeholder: "Pesquisar operador",
      field: "title",
      loading: _vm.isFetching
    },
    on: {
      typing: _vm.getAsyncData,
      select: function select(option) {
        return _vm.onInsertWorker(option);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("div", {
          staticClass: "media"
        }, [_c("div", {
          staticClass: "media-left"
        }, [!props.option.imageUrl ? _c("img", {
          attrs: {
            width: "26",
            src: require("@/assets/images/user-icon.png")
          }
        }) : _c("img", {
          attrs: {
            width: "26",
            src: props.option.imageUrl
          }
        })]), _vm._v(" "), _c("div", {
          staticClass: "media-content"
        }, [_vm._v("\n                      " + _vm._s(props.option.firstName) + " " + _vm._s(props.option.lastName) + "\n                    ")])])];
      }
    }], null, false, 2738792289),
    model: {
      value: _vm.selectOperator,
      callback: function callback($$v) {
        _vm.selectOperator = $$v;
      },
      expression: "selectOperator"
    }
  }, [_vm._v(" "), _c("template", {
    slot: "empty"
  }, [_vm._v("\n                  Operador " + _vm._s(_vm.selectOperator) + " não encontrado\n                ")])], 2)], 1) : _vm._e()], 1)]), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "list-operators"
  }, _vm._l(_vm.scheduling.workers, function (item, key) {
    return _c("div", {
      key: key,
      staticClass: "list-op-lt"
    }, [!item.imageUrl ? _c("img", {
      staticClass: "list-op-lt_img",
      attrs: {
        src: require("@/assets/images/user-icon.png"),
        alt: item.firstName
      }
    }) : _c("img", {
      staticClass: "list-op-lt_img",
      attrs: {
        src: item.imageUrl,
        alt: item.firstName
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "list-op-lt_text"
    }, [_vm._v("\n                " + _vm._s(item.firstName) + " " + _vm._s(item.lastName) + "\n              ")]), _vm._v(" "), !_vm.disabledItem ? _c("div", {
      staticClass: "delete-item",
      on: {
        click: function click($event) {
          return _vm.deleteWorker(item);
        }
      }
    }, [_c("i", {
      staticClass: "mdi mdi-window-close"
    })]) : _vm._e()]);
  }), 0)])])])]), _vm._v(" "), _vm.showModalTag ? _c("ModalTag", {
    attrs: {
      "show-modal": _vm.showModalTag,
      "batch-id": _vm.selectedPrintBatch.id
    },
    on: {
      "update:showModal": function updateShowModal($event) {
        _vm.showModalTag = $event;
      },
      "update:show-modal": function updateShowModal($event) {
        _vm.showModalTag = $event;
      },
      closedModal: _vm.resolvedCallbackModalTag
    }
  }) : _vm._e(), _vm._v(" "), _vm.showModalClient ? _c("ModalClient", {
    attrs: {
      "show-modal": _vm.showModalClient
    },
    on: {
      "update:showModal": function updateShowModal($event) {
        _vm.showModalClient = $event;
      },
      "update:show-modal": function updateShowModal($event) {
        _vm.showModalClient = $event;
      },
      closedModal: _vm.resolvedCallbackModalClient
    }
  }) : _vm._e(), _vm._v(" "), _c("ModalClassification", {
    attrs: {
      "v-if": _vm.showModalClassification,
      "show-modal": _vm.showModalClassification
    },
    on: {
      "update:showModal": function updateShowModal($event) {
        _vm.showModalClassification = $event;
      },
      "update:show-modal": function updateShowModal($event) {
        _vm.showModalClassification = $event;
      },
      closedModal: _vm.resolvedCallbackModalClassification
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("label", {
    staticClass: "label"
  }, [_c("i", {
    staticClass: "icon icon-ic_tipos_de_servicos"
  }), _vm._v(" "), _c("span", [_vm._v("Tipo de serviço")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "column"
  }, [_c("label", {
    staticClass: "label"
  }, [_c("i", {
    staticClass: "icon icon-ic_location"
  }), _vm._v(" "), _c("span", [_vm._v("Local")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("h1", {
    staticClass: "title"
  }, [_c("i", {
    staticClass: "icon icon-ic_historico"
  }), _vm._v("\n            Histórico\n          ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("h3", [_c("i", {
    staticClass: "icon icon-ic_progresso"
  }), _vm._v(" Progresso")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("label", {
    staticClass: "label"
  }, [_c("i", {
    staticClass: "icon icon-ic_operadores"
  }), _vm._v("\n              Operadores\n            ")]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;