"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("button", {
    staticClass: "button",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.openModal
    }
  }, [_vm._m(0), _vm._v(" "), _c("span", {
    staticClass: "text"
  }, [_vm._v("\n    QR CODE\n  ")])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "icon is-medium"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/ic_download.svg"),
      alt: "icon"
    }
  })]);
}];
render._withStripped = true;