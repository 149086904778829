"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _SideMenuTemplate = _interopRequireDefault(require("@/ui/templates/SideMenuTemplate"));
var _store = _interopRequireDefault(require("@/store"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _default2 = {
  name: 'SideMenu',
  components: {
    SideMenuTemplate: _SideMenuTemplate.default
  },
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    titleNewItem: {
      type: String,
      default: null
    },
    newItemMethod: {
      type: Function,
      default: function _default() {}
    },
    allMenuItens: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    empty: {
      type: String,
      default: null
    },
    allMenuItensTwo: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    allMenuItensThree: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    openMenuSub: {
      type: Function,
      default: function _default() {}
    },
    returnText: {
      type: String,
      default: 'Voltar'
    },
    returnEnable: {
      type: Boolean,
      default: true
    },
    returnMenu: {
      type: String,
      default: null
    },
    showReturn: {
      type: Boolean,
      default: true
    },
    subMenuTitle: {
      type: String,
      default: ''
    },
    subMenuTitleThree: {
      type: String,
      default: ''
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)({
    userAdmin: 'userAdmin'
  })), {}, {
    filteredMenus: function filteredMenus() {
      var user = _store.default.state.auth.user;
      var menus = this.allMenuItens.filter(function (item) {
        if (item.meta.whiteList) {
          if (item.meta.whiteList.includes(user.role)) {
            return item;
          }
        } else return item;
      });
      return menus;
    }
  }),
  methods: {
    openMenuItem: function openMenuItem(item) {
      if (!item.meta.block) this.$router.push({
        path: item.path
      });
    },
    isActiveIten: function isActiveIten(item) {
      if (Object.keys(this.$route.params).length) {
        var prop = Object.keys(this.$route.params)[0];
        return this.$route.path === item.path + '/' + this.$route.params[prop];
      }
      return item.path === this.$route.path;
    },
    returnPage: function returnPage() {
      if (this.returnEnable) {
        if (this.returnMenu) this.$router.push({
          path: this.returnMenu
        });
        // this.$router.back()
      } else {
        this.$toast.open({
          message: 'Rota em desenvolvimento',
          type: 'is-success'
        });
      }
    }
  }
};
exports.default = _default2;