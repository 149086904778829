"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "modal-new-rating",
    attrs: {
      active: _vm.showModal,
      width: 598,
      scroll: "keep",
      "can-cancel": ["escape"],
      "on-cancel": _vm.cancelModal
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.showModal = $event;
      }
    }
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "media"
  }, [_c("div", {
    staticClass: "media-content"
  }, [_c("button", {
    staticClass: "btn-x-close",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.cancelModal
    }
  }, [_c("i", {
    staticClass: "icon-ic_close"
  })]), _vm._v(" "), _c("p", {
    staticClass: "title"
  }, [_vm._v("\n            Classificação\n          ")])])]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("form", [_c("b-field", {
    attrs: {
      label: "Nome da Classificação",
      type: _vm.errors.includes("name") ? "is-danger" : ""
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "Informe o nome da classificação"
    },
    model: {
      value: _vm.newOccurrenceRating.name,
      callback: function callback($$v) {
        _vm.$set(_vm.newOccurrenceRating, "name", $$v);
      },
      expression: "newOccurrenceRating.name"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "field-criticidade",
    attrs: {
      horizontal: "",
      label: "Criticidade",
      type: _vm.errors.includes("criticality") ? "is-danger" : ""
    }
  }, [_c("b-select", {
    attrs: {
      placeholder: "Selecionar"
    },
    model: {
      value: _vm.newOccurrenceRating.criticality,
      callback: function callback($$v) {
        _vm.$set(_vm.newOccurrenceRating, "criticality", $$v);
      },
      expression: "newOccurrenceRating.criticality"
    }
  }, [_c("option", {
    attrs: {
      value: "LOW"
    }
  }, [_vm._v("\n                Baixa\n              ")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "MEDIUM"
    }
  }, [_vm._v("\n                Média\n              ")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "HIGH"
    }
  }, [_vm._v("\n                Alta\n              ")])])], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Descrição",
      type: _vm.errors.includes("description") ? "is-danger" : ""
    }
  }, [_c("b-input", {
    attrs: {
      type: "textarea",
      minlength: "10",
      maxlength: "300",
      placeholder: "Descreva de forma breve sobre a classificação"
    },
    model: {
      value: _vm.newOccurrenceRating.description,
      callback: function callback($$v) {
        _vm.$set(_vm.newOccurrenceRating, "description", $$v);
      },
      expression: "newOccurrenceRating.description"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("\n              Produto(cultivo) Vinculado "), _c("span", [_vm._v("(caso não informe um cultivo será vinculado a todos)")])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "col is-half"
  }, [_c("draggable", {
    staticClass: "list-group",
    attrs: {
      list: _vm.products,
      group: "products"
    }
  }, _vm._l(_vm.products, function (element, index) {
    return _c("div", {
      key: index,
      staticClass: "list-group-item"
    }, [_vm._v("\n                    " + _vm._s(element.name) + "\n                  ")]);
  }), 0)], 1), _vm._v(" "), _c("div", {
    staticClass: "col is-half"
  }, [_c("draggable", {
    staticClass: "list-group",
    attrs: {
      list: _vm.newOccurrenceRating.products,
      group: "products"
    }
  }, _vm._l(_vm.newOccurrenceRating.products, function (element, index) {
    return _c("div", {
      key: index,
      staticClass: "list-group-item"
    }, [_vm._v("\n                    " + _vm._s(element.name) + "\n                  ")]);
  }), 0)], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("\n              Fazenda Vinculada "), _c("span", [_vm._v("(caso não informe uma fazenda será vinculado a todas)")])]), _vm._v(" "), _c("autocomplete", {
    attrs: {
      data: _vm.farms,
      name: "name",
      complement: "state",
      "text-empty": "Fazenda não encontrada",
      fetch: _vm.getAsyncFarm,
      "on-insert": _vm.onInsertFarm
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "list-farms"
  }, _vm._l(_vm.newOccurrenceRating.farms, function (farm, idx) {
    return _c("div", {
      key: idx,
      staticClass: "list-item"
    }, [_vm._v("\n                " + _vm._s(farm.name) + "\n                "), _c("button", {
      staticClass: "button btn-delete",
      attrs: {
        type: "button"
      },
      on: {
        click: function click($event) {
          return _vm.onInsertFarm(farm);
        }
      }
    }, [_c("i", {
      staticClass: "icon-ic_close"
    })])]);
  }), 0)], 1), _vm._v(" "), _c("b-field", {
    staticClass: "row-save-form"
  }, [_c("b-button", {
    staticClass: "btn-save-form",
    on: {
      click: _vm.resolved
    }
  }, [_vm._v("\n              " + _vm._s(_vm.newOccurrenceRating.id ? "Gravar" : "Criar") + "\n            ")])], 1)], 1)])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;