"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'Autocomplete',
  props: {
    data: {
      type: [Array, String],
      default: function _default() {
        return [];
      }
    },
    isLoad: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    complement: {
      type: String,
      default: ''
    },
    textEmpty: {
      type: String,
      default: 'Item não encontrado'
    },
    fetch: {
      type: Function,
      default: function _default() {}
    },
    onInsert: {
      type: Function,
      default: function _default() {}
    }
  }
};