"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    staticClass: "routes-farm-user-management-page",
    attrs: {
      menu: _vm.menuItems.children,
      "sub-menu": _vm.menuItems.subMenu,
      "return-text": "Lista de fazendas",
      "return-menu": _vm.menuItems.path
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h2", {
    staticClass: "header-title"
  }, [_c("b-button", {
    staticClass: "btn-isback",
    attrs: {
      "icon-right": "arrow-left"
    },
    on: {
      click: _vm.previous
    }
  }), _vm._v("\n        Rotas - Aleandro Vieira\n      ")], 1)]), _vm._v(" "), _c("div", {
    staticClass: "section-filters"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      label: "Atividade",
      placeholder: "Atividade"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    attrs: {
      label: "Data"
    }
  }, [_c("date-picker", {
    staticClass: "border-bt",
    attrs: {
      placeholder: "Informe a data"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      label: "Local",
      placeholder: "ST 1"
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "section-dashs"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card-dash-routes", {
    attrs: {
      data: "2,01",
      title: "Área Trabalha (ha)"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("card-dash-routes", {
    attrs: {
      data: "12,1",
      title: "Distância Percorrida (km)"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("card-dash-routes", {
    attrs: {
      data: "11,9",
      title: "Distância em deslocamento (km)"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("card-dash-routes", {
    attrs: {
      data: "8:15",
      title: "Tempo trabalho (hrs)"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("card-dash-routes", {
    attrs: {
      data: "07:45",
      title: "Tempo parado (hrs)"
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "section-content"
  }, [_c("gmap-map", {
    staticStyle: {
      width: "100%",
      height: "400px"
    },
    attrs: {
      center: _vm.center,
      zoom: 12
    }
  }, [_c("DirectionsRenderer", {
    attrs: {
      "travel-mode": "DRIVING",
      origin: _vm.startLocation,
      destination: _vm.endLocation
    }
  })], 1)], 1)])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;