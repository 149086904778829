"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dashboardService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var fetchTabs = function fetchTabs(farmId) {
  return _http.default.get("/dashboard/tabs/".concat(farmId));
};
var fetchDetailMountDashboard = function fetchDetailMountDashboard(farmId) {
  return _http.default.get("/dashboard/details-mount/".concat(farmId));
};
var fetchLastServiceExecutions = function fetchLastServiceExecutions(farmId) {
  return _http.default.get("/dashboard/last-service-executions/".concat(farmId));
};
var fetchMostFrequentOccurrences = function fetchMostFrequentOccurrences(farmId) {
  return _http.default.get("/dashboard/most-frequent-occurrences/".concat(farmId));
};
var numberEmployeesArea = function numberEmployeesArea(farmId) {
  return _http.default.get("/dashboard/number-employees-area/".concat(farmId));
};
var rankingValidOccurrences = function rankingValidOccurrences(farmId) {
  return _http.default.get("/dashboard/ranking-valid-occurrences/".concat(farmId));
};
var dashboardService = {
  fetchTabs: fetchTabs,
  fetchDetailMountDashboard: fetchDetailMountDashboard,
  fetchLastServiceExecutions: fetchLastServiceExecutions,
  fetchMostFrequentOccurrences: fetchMostFrequentOccurrences,
  numberEmployeesArea: numberEmployeesArea,
  rankingValidOccurrences: rankingValidOccurrences
};
exports.dashboardService = dashboardService;