"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "card card-create-plot-details"
  }, [_c("header", {
    staticClass: "card-header"
  }, [_c("p", {
    staticClass: "card-header-title"
  }, [_c("i", {
    staticClass: "icon-ic_location"
  }), _vm._v(" "), _c("b-field", {
    attrs: {
      type: _vm.isErrorNickName ? "is-danger" : ""
    }
  }, [!_vm.editDraw ? _c("b-input", {
    attrs: {
      placeholder: "Talhão 00"
    },
    model: {
      value: _vm.propertiesPlot.nickName,
      callback: function callback($$v) {
        _vm.$set(_vm.propertiesPlot, "nickName", $$v);
      },
      expression: "propertiesPlot.nickName"
    }
  }) : _c("b-input", {
    attrs: {
      placeholder: "Talhão 00",
      disabled: ""
    },
    model: {
      value: _vm.propertiesPlot.name,
      callback: function callback($$v) {
        _vm.$set(_vm.propertiesPlot, "name", $$v);
      },
      expression: "propertiesPlot.name"
    }
  })], 1)], 1), _vm._v(" "), _c("a", {
    staticClass: "card-header-icon",
    attrs: {
      title: "Close"
    },
    on: {
      click: _vm.closeDetailItem
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "close"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "row-details-init"
  }, [_c("b-field", {
    staticClass: "field-area",
    attrs: {
      horizontal: "",
      label: "Tamanho da área (hectare)"
    }
  }, [_vm._v("\n          " + _vm._s(_vm._f("convertArea")(_vm.propertiesPlot.area)) + "\n        ")]), _vm._v(" "), _c("b-field", {
    staticClass: "field-subplots",
    attrs: {
      horizontal: "",
      label: "Quantidade de sub-talhões"
    }
  }, [_c("b-input", {
    attrs: {
      type: "number",
      min: "1",
      max: "99",
      name: "qtd-subplots",
      expanded: "",
      value: _vm.subPlotsQtd,
      disabled: _vm.editDraw
    },
    on: {
      input: _vm.updateQtd
    }
  })], 1)], 1), _vm._v(" "), !_vm.editDraw ? _c("div", {
    staticClass: "list-subpltos-create"
  }, [_c("h4", {
    class: {
      "error-plot": !_vm.validCalcTotalAreas
    }
  }, [_vm._v("\n          Sub-talhões | " + _vm._s(_vm._f("convertArea")(_vm.sumSubPlotsArea)) + "\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "list-items"
  }, _vm._l(Number(_vm.subPlotsQtd), function (item, index) {
    return _c("b-field", {
      key: index,
      staticClass: "item-ol",
      attrs: {
        grouped: ""
      }
    }, [_c("b-field", {
      staticClass: "field-subplots-code",
      attrs: {
        horizontal: "",
        label: "Sub-talhão"
      }
    }, [_c("b-input", {
      attrs: {
        type: "number",
        name: "code-subplots-".concat(index),
        placeholder: "00",
        expanded: "",
        min: "1",
        max: "99"
      },
      model: {
        value: _vm.subPlotsList.code[index],
        callback: function callback($$v) {
          _vm.$set(_vm.subPlotsList.code, index, $$v);
        },
        expression: "subPlotsList.code[index]"
      }
    })], 1), _vm._v(" "), _c("b-field", {
      staticClass: "field-subplots-area",
      attrs: {
        horizontal: "",
        label: "Tamanho da área (hectare)"
      }
    }, [_c("the-mask", {
      staticClass: "input",
      attrs: {
        autocomplete: "off",
        mask: ["#.##", "##.##", "###.##", "####.##", "#####.##"],
        masked: true
      },
      model: {
        value: _vm.subPlotsList.area[index],
        callback: function callback($$v) {
          _vm.$set(_vm.subPlotsList.area, index, $$v);
        },
        expression: "subPlotsList.area[index]"
      }
    })], 1)], 1);
  }), 1)]) : _vm._e()])]), _vm._v(" "), _c("div", {
    staticClass: "card-footer"
  }, [_c("button", {
    staticClass: "button",
    on: {
      click: _vm.createNewPlotItem
    }
  }, [_vm._v("\n      " + _vm._s(!_vm.editDraw ? "Criar" : "Gravar") + "\n    ")])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;