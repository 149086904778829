"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customerService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var customerService = {
  fetch: fetch,
  create: create
};
exports.customerService = customerService;
function fetch(params) {
  // eslint-disable-next-line no-undef
  return _http.default.get('/customers', {
    params: params
  });
}
function create(data) {
  // eslint-disable-next-line no-undef
  return _http.default.post('/customers', data);
}