"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.messageHelper = void 0;
var _router = _interopRequireDefault(require("@/router"));
var _buefy = require("buefy");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var messageHelper = {
  success: success,
  error: error
};
// eslint-disable-next-line no-undef
exports.messageHelper = messageHelper;
function success(message) {
  if (!message) {
    message = _router.default.app.$t('success.default');
  }
  _buefy.ToastProgrammatic.open({
    duration: 5000,
    message: message,
    position: 'is-top-right',
    type: 'is-success'
  });
}
// eslint-disable-next-line no-undef
function error(message) {
  _buefy.ToastProgrammatic.open({
    duration: 5000,
    message: message,
    position: 'is-top-right',
    type: 'is-danger'
  });
}