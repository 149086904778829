"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _auth = require("./auth.service");
Object.keys(_auth).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _auth[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _auth[key];
    }
  });
});
var _user = require("./user.service");
Object.keys(_user).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _user[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _user[key];
    }
  });
});
var _farm = require("./farm.service");
Object.keys(_farm).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _farm[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _farm[key];
    }
  });
});
var _plot = require("./plot.service");
Object.keys(_plot).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _plot[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _plot[key];
    }
  });
});
var _services = require("./services.service");
Object.keys(_services).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _services[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _services[key];
    }
  });
});
var _payment = require("./payment.service");
Object.keys(_payment).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _payment[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _payment[key];
    }
  });
});
var _identifier = require("./identifier.service");
Object.keys(_identifier).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _identifier[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _identifier[key];
    }
  });
});
var _dashboard = require("./dashboard.service");
Object.keys(_dashboard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _dashboard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _dashboard[key];
    }
  });
});
var _typeservice = require("./typeservice.service");
Object.keys(_typeservice).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _typeservice[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _typeservice[key];
    }
  });
});
var _occurrence = require("./occurrence.service");
Object.keys(_occurrence).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _occurrence[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _occurrence[key];
    }
  });
});
var _client = require("./client.service");
Object.keys(_client).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _client[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _client[key];
    }
  });
});
var _batch = require("./batch.service");
Object.keys(_batch).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _batch[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _batch[key];
    }
  });
});
var _balance = require("./balance.service");
Object.keys(_balance).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _balance[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _balance[key];
    }
  });
});
var _provider = require("./provider.service");
Object.keys(_provider).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _provider[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _provider[key];
    }
  });
});
var _customer = require("./customer.service");
Object.keys(_customer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _customer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _customer[key];
    }
  });
});
var _productCustomization = require("./product.customization.service");
Object.keys(_productCustomization).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _productCustomization[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _productCustomization[key];
    }
  });
});
var _traceabilityTag = require("./traceability.tag.service");
Object.keys(_traceabilityTag).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _traceabilityTag[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _traceabilityTag[key];
    }
  });
});
var _classification = require("./classification.service");
Object.keys(_classification).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _classification[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _classification[key];
    }
  });
});
var _measure = require("./measure.service");
Object.keys(_measure).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _measure[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _measure[key];
    }
  });
});
var _product = require("./product.service");
Object.keys(_product).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _product[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _product[key];
    }
  });
});
var _cycles = require("./cycles.service");
Object.keys(_cycles).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _cycles[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _cycles[key];
    }
  });
});
var _orderService = require("./orderService.service");
Object.keys(_orderService).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _orderService[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _orderService[key];
    }
  });
});