"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("@/ui/components");
var _components2 = require("./components");
var _vuex = require("vuex");
var _Page = _interopRequireDefault(require("@/ui/layout/Page"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _default = {
  name: 'RoutesFarmUserManagement',
  components: {
    Page: _Page.default,
    AppInput: _components.AppInput,
    DirectionsRenderer: _components2.DirectionsRenderer,
    CardDashRoutes: _components2.CardDashRoutes,
    DatePicker: _components.DatePicker
  },
  data: function data() {
    return {
      center: {
        lat: -15.821435,
        lng: -48.114201
      },
      startLocation: {
        lat: -15.821435,
        lng: -48.114201
      },
      endLocation: {
        lat: -15.8107481,
        lng: -48.065751
      },
      position: {
        lat: -15.800639736833038,
        lng: -47.86137374761963
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)({
    menuItems: 'menuItemsFarm',
    isLoading: 'isLoading'
  })),
  created: function created() {
    var _this = this;
    setTimeout(function () {
      _this.setDefault();
    }, 3000);
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)([])), {}, {
    updateCoordinates: function updateCoordinates(location) {
      this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      };
    },
    setDefault: function setDefault() {
      this.center = {
        lat: -15.821435,
        lng: -48.114201
      };
      this.startLocation = {
        lat: -15.821435,
        lng: -48.114201
      };
      this.endLocation = {
        lat: -15.8107481,
        lng: -48.065751
      };
    },
    setPlace: function setPlace(place) {
      this.currentPlace = place;
    },
    addMarker: function addMarker(index) {
      var marker = {
        lat: this.currentPlace.geometry.location.lat(),
        lng: this.currentPlace.geometry.location.lng()
      };
      if (index === 0) this.startLocation = marker;
      if (index === 1) this.endLocation = marker;
      this.center = marker;
    },
    previous: function previous() {
      return this.$router.push({
        path: '/farm-management/routes'
      });
    }
  })
};
exports.default = _default;