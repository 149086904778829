"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'ModalMap',
  components: {},
  data: function data() {
    return {
      showModalItem: false,
      center: {
        lat: -15.800639736833038,
        lng: -47.86137374761963
      },
      position: {
        lat: -15.800639736833038,
        lng: -47.86137374761963
      },
      places: [],
      markers: [],
      currentPlace: null
    };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    closedModalMap: {
      type: Function,
      default: function _default() {}
    },
    lng: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    lat: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  mounted: function mounted() {
    this.showModalItem = this.showModal;
    this.geolocate();
  },
  destroyed: function destroyed() {},
  watch: {
    showModal: function showModal(val) {
      if (val) {
        this.geolocate();
      }
    }
  },
  methods: {
    // receives a place object via the autocomplete component
    setPlace: function setPlace(place) {
      this.currentPlace = place.geometry.location;
      this.position = place.geometry.location;
      this.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      this.center = place.geometry.location;
    },
    updateCoordinates: function updateCoordinates(location) {
      this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      };
    },
    cancelModal: function cancelModal() {
      this.$emit('closedModalMap');
    },
    save: function save() {
      this.$emit('closedModalMap', this.position);
    },
    addMarker: function addMarker() {
      if (this.currentPlace) {
        var marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.markers.push({
          position: marker
        });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    geolocate: function geolocate() {
      var _this = this;
      navigator.geolocation.getCurrentPosition(function (position) {
        _this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        if (_this.lat !== '' && _this.lng !== '') {
          _this.position.lng = Number(_this.lng);
          _this.position.lat = Number(_this.lat);
          _this.center.lng = Number(_this.lng);
          _this.center.lat = Number(_this.lat);
        } else {
          _this.position = _this.center;
        }
      });
    }
  }
};
exports.default = _default2;