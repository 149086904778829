"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'ModalFinishService',
  data: function data() {
    return {
      scheduling: {
        finishingDescription: ''
      }
    };
  },
  props: {
    finalizeService: {
      type: Function,
      default: function _default() {}
    }
  },
  methods: {
    cancelModal: function cancelModal() {
      this.$parent.close();
    },
    validInput: function validInput() {
      var data = this.scheduling.finishingDescription;
      return data.length >= 10;
    },
    finalize: function finalize() {
      if (!this.validInput()) {
        return this.$buefy.toast.open({
          message: this.$t('errors.error_input_length_finalize_service'),
          type: 'is-danger'
        });
      }
      this.finalizeService(this.scheduling.finishingDescription);
    }
  }
};
exports.default = _default2;