"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-datepicker", {
    ref: "datepicker",
    attrs: {
      placeholder: _vm.placeholder,
      "min-date": _vm.minDate,
      "focused-date": _vm.date,
      disabled: _vm.disabled
    },
    model: {
      value: _vm.computedValue,
      callback: function callback($$v) {
        _vm.computedValue = $$v;
      },
      expression: "computedValue"
    }
  }, [_c("template", {
    slot: "header"
  }, [_c("b-field", [_c("div", {
    staticClass: "pagination field is-centered"
  }, [_c("div", {
    staticClass: "pagination-list"
  }, [_c("p", {
    staticClass: "control"
  }, [_c("span", {
    staticClass: "is-static"
  }, [_vm._v(_vm._s(_vm.formatViewDate()))])])]), _vm._v(" "), _c("a", {
    staticClass: "pagination-preious",
    attrs: {
      role: "button",
      href: "#"
    },
    on: {
      click: function click($event) {
        return _vm.changeMonth("prev");
      }
    }
  }, [_c("span", {
    staticClass: "icon has-text-primary is"
  }, [_c("i", {
    staticClass: "mdi mdi-chevron-left mdi-24px"
  })])]), _vm._v(" "), _c("a", {
    staticClass: "pagination-next",
    attrs: {
      role: "button",
      href: "#"
    },
    on: {
      click: function click($event) {
        return _vm.changeMonth("next");
      }
    }
  }, [_c("span", {
    staticClass: "icon has-text-primary is"
  }, [_c("i", {
    staticClass: "mdi mdi-chevron-right mdi-24px"
  })])])])])], 1)], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;