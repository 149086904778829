"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _constants = require("@/config/constants");
var _default = {
  name: 'ContentDetailPaymentsList',
  props: {
    subscription: {
      type: Object,
      required: true
    },
    typePayment: {
      type: Object,
      required: true
    },
    dataInvoices: {
      type: [Object, Array],
      required: true
    },
    confirmChangePaymentMethod: {
      type: Function,
      required: true
    },
    generateNewTicket: {
      type: Function,
      required: true
    },
    openTicketNewTab: {
      type: Function,
      required: true
    }
  },
  data: function data() {
    return {
      TYPES_PAYMENTS: _constants.TYPES_PAYMENTS
    };
  },
  computed: {
    paymentsMade: function paymentsMade() {
      var invoices = this.dataInvoices;
      return invoices || [];
    }
  }
};
exports.default = _default;