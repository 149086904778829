"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'UploadImageUser',
  data: function data() {
    return {
      imageBase64: '',
      imageUrl: '',
      fileUser: null
    };
  },
  props: {
    file: {
      type: [Object, File, String],
      default: function _default() {
        return {};
      }
    }
  },
  watch: {
    fileUser: function fileUser(val) {
      var _this = this;
      if (val) {
        var reader = new FileReader();
        reader.readAsDataURL(val);
        reader.onloadend = function () {
          _this.imageBase64 = reader.result;
          _this.$emit('update:file', reader.result);
        };
      }
    }
  },
  methods: {
    removePhoto: function removePhoto() {
      this.imageBase64 = '';
      this.imageUrl = '';
      this.fileUser = null;
      this.$emit('update:file', '');
    }
  }
};
exports.default = _default2;