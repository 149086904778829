"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "user-list-component"
  }, [_c("ul", _vm._l(_vm.users, function (item, index) {
    return _c("li", {
      key: index
    }, [_c("img", {
      attrs: {
        src: item.img ? item.img : _vm.imgDefault
      }
    }), _vm._v(" "), _c("h3", [_vm._v(_vm._s(item.firstName) + " " + _vm._s(item.lastName))]), _vm._v(" "), item.informations ? _c("p", [_vm._v("\n        " + _vm._s(item.informations) + "\n      ")]) : _vm._e()]);
  }), 0)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;