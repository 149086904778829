"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "farms-list-component"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "header-options"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.farm.list.title")) + "\n      ")])]), _vm._v(" "), _c("div", {
    staticClass: "search-and-btn-add"
  }, [_c("input-search", {
    attrs: {
      placeholder: _vm.$t("pages.farm.list.place_search"),
      "filter-value": _vm.filterActivies
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm._v(" "), _vm.checkPermission ? _c("div", {
    staticClass: "btn-add-item"
  }, [_c("button", {
    staticClass: "button",
    attrs: {
      type: "button"
    },
    on: {
      click: function click($event) {
        return _vm.openModal();
      }
    }
  }, [_vm._m(0), _vm._v(" "), _c("span", [_vm._v("\n            " + _vm._s(_vm.$t("pages.farm.list.btn_new_item")) + "\n          ")])])]) : _vm._e()], 1)]), _vm._v(" "), _c("div", {
    staticClass: "farms-list-grid"
  }, [_vm._l(_vm.farmsList, function (farm, index) {
    return _c("div", {
      key: index,
      staticClass: "card-list-item-farms"
    }, [_c("div", {
      staticClass: "card"
    }, [_c("div", {
      staticClass: "media"
    }, [_c("div", {
      staticClass: "media-left",
      on: {
        click: function click($event) {
          return _vm.selectFarm(farm);
        }
      }
    }, [_vm._m(1, true)]), _vm._v(" "), _c("div", {
      staticClass: "media-content",
      on: {
        click: function click($event) {
          return _vm.selectFarm(farm);
        }
      }
    }, [_c("p", {
      staticClass: "title"
    }, [_vm._v("\n              " + _vm._s(farm.name) + "\n            ")]), _vm._v(" "), _c("p", {
      staticClass: "subtitle"
    }, [_vm._v("\n              " + _vm._s(_vm.getUser(farm, "firstName")) + " " + _vm._s(_vm.getUser(farm, "lastName")) + "\n            ")])]), _vm._v(" "), _vm.checkPermission ? _c("div", {
      staticClass: "btn-options-items"
    }, [_c("b-dropdown", {
      attrs: {
        "aria-role": "list",
        position: "is-bottom-left"
      }
    }, [_c("button", {
      staticClass: "button is-text",
      attrs: {
        slot: "trigger"
      },
      slot: "trigger"
    }, [_c("i", {
      staticClass: "icon-ic_more"
    })]), _vm._v(" "), _c("b-dropdown-item", {
      attrs: {
        "aria-role": "listitem"
      },
      on: {
        click: function click($event) {
          return _vm.openModal(farm);
        }
      }
    }, [_vm._v("\n                Editar\n              ")]), _vm._v(" "), _c("b-dropdown-item", {
      attrs: {
        "aria-role": "listitem"
      },
      on: {
        click: function click($event) {
          return _vm.verifyDelete(farm);
        }
      }
    }, [_vm._v("\n                Excluir\n              ")])], 1)], 1) : _vm._e()])])]);
  }), _vm._v(" "), !_vm.farmsList.length ? _c("div", [_c("no-record-found", {
    attrs: {
      title: _vm.$t("pages.farm.list.404_msg")
    }
  })], 1) : _vm._e()], 2)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "icon"
  }, [_c("i", {
    staticClass: "icon-ic_new_fazenda"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "icon is-large"
  }, [_c("i", {
    staticClass: "icon-ic_fazenda"
  })]);
}];
render._withStripped = true;