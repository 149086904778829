"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "list-detail-collapse-bunches bg-sub-tl-thrre"
  }, _vm._l(_vm.bunches, function (bunch, idBunch) {
    return _c("div", {
      key: idBunch,
      staticClass: "collapse-fade-service",
      attrs: {
        "name-item": "cacho" + bunch.id
      }
    }, [_c("div", {
      staticClass: "collapse card"
    }, [_c("div", {
      staticClass: "collapse-trigger"
    }, [_c("div", {
      staticClass: "card-header",
      attrs: {
        role: "button",
        "aria-controls": "contentIdForA11y3"
      }
    }, [_c("p", {
      staticClass: "card-header-title"
    }, [_c("label", {
      staticClass: "b-checkbox checkbox",
      attrs: {
        tabindex: "0"
      }
    }, [_c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: bunch.checked,
        expression: "bunch.checked"
      }],
      attrs: {
        id: bunch.id,
        type: "checkbox"
      },
      domProps: {
        value: bunch.checked,
        checked: Array.isArray(bunch.checked) ? _vm._i(bunch.checked, bunch.checked) > -1 : bunch.checked
      },
      on: {
        click: function click($event) {
          return _vm.selectActionBunche($event, bunch);
        },
        change: function change($event) {
          var $$a = bunch.checked,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = bunch.checked,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(bunch, "checked", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(bunch, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(bunch, "checked", $$c);
          }
        }
      }
    }), _vm._v(" "), _c("span", {
      staticClass: "check"
    }), _vm._v(" "), _c("span", {
      staticClass: "control-label"
    })]), _vm._v("\n            " + _vm._s(bunch.name) + "\n          ")])])])])]);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;