"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "collapse card"
  }, [_c("div", {
    staticClass: "collapse-trigger"
  }, [_c("div", {
    staticClass: "card-header",
    attrs: {
      role: "button",
      "aria-controls": "contentIdForA11y3"
    }
  }, [_c("p", {
    staticClass: "card-header-title"
  }, [_vm.isChecked ? _c("label", {
    staticClass: "b-checkbox checkbox",
    attrs: {
      tabindex: "0"
    }
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.checked,
      expression: "item.checked"
    }],
    attrs: {
      id: _vm.item.id,
      type: "checkbox"
    },
    domProps: {
      value: _vm.item.checked,
      checked: Array.isArray(_vm.item.checked) ? _vm._i(_vm.item.checked, _vm.item.checked) > -1 : _vm.item.checked
    },
    on: {
      click: function click($event) {
        return _vm.onChangeCheck($event, _vm.item, _vm.sub);
      },
      change: function change($event) {
        var $$a = _vm.item.checked,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = _vm.item.checked,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.item, "checked", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.item, "checked", $$c);
        }
      }
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "check"
  }), _vm._v(" "), _c("span", {
    staticClass: "control-label"
  })]) : _vm._e(), _vm._v("\n        " + _vm._s(_vm.item.name) + " " + _vm._s(_vm.type === "subtalhao" ? " - ".concat(_vm.item.code) : "") + "\n      ")]), _vm._v(" "), _c("a", {
    staticClass: "card-header-icon",
    on: {
      click: function click($event) {
        return _vm.openContent(_vm.item, _vm.sub, _vm.plot);
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.countResgiters(_vm.item)) + "\n        "), _c("span", {
    staticClass: "icon"
  }, [_c("i", {
    staticClass: "mdi mdi-24px",
    class: {
      "mdi-chevron-up": _vm.item.open,
      "mdi-chevron-down": !_vm.item.open
    }
  })])])])]), _vm._v(" "), _vm.item.open ? _c("div", {
    staticClass: "collapse-content",
    attrs: {
      id: "contentIdForA11y3"
    }
  }, [_c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "content"
  }, [_vm._t("body")], 2)])]) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;