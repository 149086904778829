"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    class: "edit-list-services",
    attrs: {
      "return-menu": "/farm-management/dashboard",
      menu: _vm.menuItemsServices.children,
      subtitle: "Serviços"
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h2", {
    staticClass: "header-title"
  }, [_vm._v("\n        Serviços Finalizados\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "search-bar"
  }, [_c("b-field", {
    attrs: {
      grouped: "",
      position: "is-centered"
    }
  }, [_c("b-field", {
    attrs: {
      label: "Pesquisar pelo nome do serviço",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "Pesquisar pelo nome do serviço"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Buscar pelo responsável",
      grouped: "",
      position: "is-right"
    }
  }, [_c("app-select", {
    attrs: {
      placeholder: "Selecione o usuário responsável",
      items: _vm.listUsers,
      option: "id",
      "name-item": "name"
    },
    model: {
      value: _vm.filterUser,
      callback: function callback($$v) {
        _vm.filterUser = $$v;
      },
      expression: "filterUser"
    }
  }), _vm._v(" "), _c("p", {
    staticClass: "control"
  }, [_c("b-button", {
    attrs: {
      label: "Pesquisar",
      type: "is-info"
    },
    on: {
      click: _vm.getFilter
    }
  })], 1)], 1)], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "table-items-list"
  }, [_c("b-table", {
    attrs: {
      data: _vm.isEmpty ? [] : _vm.listServices,
      striped: true,
      hoverable: true,
      loading: _vm.isLoading,
      "mobile-cards": true,
      paginated: "",
      "backend-pagination": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      total: _vm.totalRegisters
    },
    on: {
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  }, [[_c("b-table-column", {
    attrs: {
      field: "name",
      label: "ID",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.id) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "name",
      label: "Serviço",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.name) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "plots",
      label: "Local",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return _vm._l(props.row.plots, function (item, index) {
          return _c("span", {
            key: index,
            staticClass: "destaq-item-col"
          }, [_vm._v("\n              " + _vm._s(item.name) + "\n            ")]);
        });
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "author",
      label: "Autor"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.author.firstName) + " " + _vm._s(props.row.author.lastName) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "dueDate",
      label: "Data do agendamento"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(_vm._f("date")(props.row.dueDate)) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "author",
      label: "Func. Alocados"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return _vm._l(props.row.workers, function (item, idx) {
          return _c("div", {
            key: idx
          }, [_c("span", [_vm._v("\n                " + _vm._s(item.firstName) + "\n              ")])]);
        });
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      width: "40"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-dropdown", {
          attrs: {
            "aria-role": "list",
            position: "is-top-left"
          }
        }, [_c("b-button", {
          staticClass: "btn-click-dots",
          attrs: {
            slot: "trigger",
            outlined: "",
            "icon-right": "dots-vertical"
          },
          slot: "trigger"
        }), _vm._v(" "), _c("b-dropdown-item", {
          attrs: {
            "aria-role": "listitem"
          },
          on: {
            click: function click($event) {
              return _vm.preparesChange(props.row);
            }
          }
        }, [_vm._v("\n                Editar\n              ")]), _vm._v(" "), _c("b-dropdown-item", {
          attrs: {
            "aria-role": "listitem"
          },
          on: {
            click: function click($event) {
              return _vm.verifyDelete(props.row);
            }
          }
        }, [_vm._v("\n                Excluir\n              ")])], 1)];
      }
    }])
  })], _vm._v(" "), _c("template", {
    slot: "empty"
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-grey has-text-centered"
  }, [_c("p", [_c("b-icon", {
    attrs: {
      icon: "emoticon-sad",
      size: "is-large"
    }
  })], 1), _vm._v(" "), _c("p", [_vm._v("Sem resultados.")])])])])], 2)], 1)])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;