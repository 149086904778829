"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'FieldCheckSubplots',
  props: {
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    subplotsFiltered: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    action: {
      type: Function,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      newValue: this.value
    };
  },
  computed: {
    computedValue: {
      get: function get() {
        return this.newValue;
      },
      set: function set(value) {
        this.newValue = value;
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value: function value(_value) {
      this.newValue = _value;
    }
  },
  methods: {
    actionItem: function actionItem(item) {
      this.$emit('action', item);
    }
  }
};
exports.default = _default2;