"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "animation-content modal-content dialog-modal-template",
    class: _vm.type
  }, [_c("div", {
    staticClass: "card"
  }, [_c("header", {
    staticClass: "card-header"
  }, [_c("p", {
    staticClass: "card-header-title"
  }, [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")])]), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_vm._v("\n      " + _vm._s(_vm.message) + "\n    ")]), _vm._v(" "), _c("footer", {
    staticClass: "card-footer"
  }, [_vm.showCancel ? _c("button", {
    ref: "cancelButton",
    staticClass: "button cancel-button",
    on: {
      click: _vm.closeModal
    }
  }, [_vm._v("\n        " + _vm._s(_vm.cancelText) + "\n      ")]) : _vm._e(), _vm._v(" "), _c("button", {
    ref: "confirmButton",
    staticClass: "button confirm-button",
    on: {
      click: _vm.confirm
    }
  }, [_vm._v("\n        " + _vm._s(_vm.confirmText) + "\n      ")])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;