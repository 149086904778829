"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "modal-form",
    attrs: {
      active: _vm.showModal,
      width: 800,
      scroll: "keep",
      "can-cancel": ["escape"],
      "on-cancel": _vm.cancelModal
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.showModal = $event;
      }
    }
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "media"
  }, [_c("div", {
    staticClass: "media-content"
  }, [_c("button", {
    staticClass: "btn-x-close",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.cancelModal
    }
  }, [_c("i", {
    staticClass: "icon-ic_close"
  })])])]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }), _vm._v(" "), _c("img", {
    attrs: {
      src: require("@/assets/images/img-classification.png")
    }
  })])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;