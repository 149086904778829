"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'Colapse',
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    type: {
      type: String,
      default: ''
    },
    arraySave: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selectAction: {
      type: Function,
      default: function _default() {}
    },
    isChecked: {
      type: Boolean,
      default: true
    },
    sub: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    plot: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      checkbox: false,
      isEnable: false
    };
  },
  methods: {
    openContent: function openContent(item, sub) {
      this.$emit('onClickOpen', item, sub);
    },
    onChangeCheck: function onChangeCheck(e, item, type) {
      this.selectAction(e, item, type);
    },
    countResgiters: function countResgiters(item) {
      var total = item.numberOfRows || 0;
      var totalFilter = 0;
      if (item.lines && item.lines.data) {
        var filter = item.lines.data.filter(function (i) {
          return i.checked === true;
        });
        totalFilter = filter.length;
      }
      if (item.families && item.families.data) {
        var _filter = item.families.data.filter(function (i) {
          return i.checked === true;
        });
        totalFilter = _filter.length;
      }
      if (item.bunches) {
        var _filter2 = item.bunches.filter(function (i) {
          return i.checked === true;
        });
        totalFilter = _filter2.length;
      }
      return total > 0 ? "".concat(totalFilter, "/").concat(total) : '-';
    }
  }
};