"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-user-badge-ui"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "user-data"
  }, [_c("figure", {
    staticClass: "image"
  }, [_vm.user.imageUrl ? _c("img", {
    attrs: {
      src: _vm.user.imageUrl,
      alt: _vm.user.firstName
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/images/user-icon.png"),
      alt: _vm.user.firstName
    }
  })]), _vm._v(" "), _c("div", {
    staticClass: "details"
  }, [_c("p", {
    staticClass: "name"
  }, [_vm._v("\n          " + _vm._s(_vm.user.firstName) + " " + _vm._s(_vm.user.lastName) + "\n        ")]), _vm._v(" "), _c("p", {
    staticClass: "role"
  }, [_vm._v("\n          " + _vm._s(_vm._f("convertRole")(_vm.user.role)) + "\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "registry"
  }, [_c("div", [_vm._v("\n            Nº " + _vm._s(_vm.user.registration) + "\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "qr-data"
  }, [_c("qr-code", {
    attrs: {
      text: _vm.qrCodeText,
      size: 46
    }
  })], 1)])])])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("header", {
    staticClass: "card-header"
  }, [_c("div", {
    staticClass: "card-rectangle"
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;