"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.errorResponseHelper = void 0;
var _router = _interopRequireDefault(require("@/router"));
var _helpers = require("@/helpers");
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var errorResponseHelper = {
  display: display
};
// eslint-disable-next-line no-undef
exports.errorResponseHelper = errorResponseHelper;
function display() {
  var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var errorKey = 'default';
  var response = error.response || undefined;
  if (!response) {
    response = error.data ? error : undefined;
  }
  if (response) {
    var messageError = _lodash.default.get(response, 'data.message');
    if (messageError) {
      errorKey = messageError.toLowerCase().split(' ').join('_').replace(/['"]+/g, '');
    }
  }
  var message = _router.default.app.$t("errors.".concat(errorKey));
  if (message.includes('errors.')) {
    message = _router.default.app.$t("errors.default");
  }
  _helpers.messageHelper.error(message);
}