"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm$scheduling, _vm$scheduling$type;
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "has-modal-card": "",
      "full-screen": "",
      "can-cancel": false,
      active: true
    }
  }, [_c("div", {
    staticClass: "modal-card"
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("\n        Editar Serviço - " + _vm._s(_vm.scheduling.name) + " - " + _vm._s(_vm.scheduling.id) + "\n      ")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("close");
      }
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-third"
  }, [_c("div", {
    staticClass: "field"
  }, [_c("label", {
    staticClass: "label"
  }, [_c("i", {
    staticClass: "icon icon-ic_tipos_de_servicos"
  }), _vm._v(" "), _c("span", [_vm._v("Tipo de serviço")])]), _vm._v(" "), _c("div", {
    staticClass: "control is-clearfix"
  }, [_c("b-input", {
    attrs: {
      value: (_vm$scheduling = _vm.scheduling) === null || _vm$scheduling === void 0 ? void 0 : (_vm$scheduling$type = _vm$scheduling.type) === null || _vm$scheduling$type === void 0 ? void 0 : _vm$scheduling$type.name,
      disabled: ""
    }
  })], 1)])])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("label", {
    staticClass: "label"
  }, [_c("i", {
    staticClass: "icon icon-ic_historico"
  }), _vm._v(" "), _c("span", [_vm._v("Passos Concluídos")])]), _vm._v(" "), !_vm.steps.length ? _c("div", {
    staticClass: "list-service-steps"
  }, [_c("h5", [_vm._v("Sem passos executados...")]), _vm._v(" "), _c("b-button", {
    attrs: {
      type: "is-info"
    }
  }, [_vm._v("\n                  Deseja inserir um passo de forma manual?\n                ")])], 1) : _c("div", {
    staticClass: "list-service-steps"
  }, _vm._l(_vm.steps, function (item, index) {
    var _item$plot, _item$subPlot, _item$row, _item$family;
    return _c("b-collapse", {
      key: index,
      staticClass: "card",
      attrs: {
        animation: "slide",
        open: _vm.isOpen === index,
        "aria-id": "contentIdForA11y5-" + index
      },
      on: {
        open: function open($event) {
          return _vm.openTab(index, item);
        },
        close: function close($event) {
          _vm.isOpen = null;
        }
      },
      scopedSlots: _vm._u([{
        key: "trigger",
        fn: function fn(props) {
          var _item$serviceStepType;
          return [_c("div", {
            staticClass: "card-header",
            attrs: {
              role: "button",
              "aria-controls": "contentIdForA11y5-" + index,
              "aria-expanded": props.open
            }
          }, [_c("p", {
            staticClass: "card-header-title"
          }, [_vm._v("\n                        " + _vm._s(item === null || item === void 0 ? void 0 : (_item$serviceStepType = item.serviceStepType) === null || _item$serviceStepType === void 0 ? void 0 : _item$serviceStepType.name) + " - " + _vm._s(_vm._f("dateComplete")(item.date)) + "\n                      ")]), _vm._v(" "), _c("a", {
            staticClass: "card-header-icon"
          }, [_c("b-icon", {
            attrs: {
              icon: props.open ? "menu-down" : "menu-up"
            }
          })], 1)])];
        }
      }], null, true)
    }, [_vm._v(" "), _c("div", {
      staticClass: "card-content"
    }, [_c("div", {
      staticClass: "content"
    }, [_c("b-field", {
      attrs: {
        label: "Detalhes da execução deste passo",
        grouped: ""
      }
    }, [_c("b-field", {
      attrs: {
        label: "Talhão",
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: item === null || item === void 0 ? void 0 : (_item$plot = item.plot) === null || _item$plot === void 0 ? void 0 : _item$plot.name,
        disabled: ""
      }
    })], 1), _vm._v(" "), _c("b-field", {
      attrs: {
        label: "Sub-talhão",
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: item === null || item === void 0 ? void 0 : (_item$subPlot = item.subPlot) === null || _item$subPlot === void 0 ? void 0 : _item$subPlot.name,
        disabled: ""
      }
    })], 1), _vm._v(" "), _c("b-field", {
      attrs: {
        label: "Linha",
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: item === null || item === void 0 ? void 0 : (_item$row = item.row) === null || _item$row === void 0 ? void 0 : _item$row.name,
        disabled: ""
      }
    })], 1), _vm._v(" "), _c("b-field", {
      attrs: {
        label: "Família",
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: item === null || item === void 0 ? void 0 : (_item$family = item.family) === null || _item$family === void 0 ? void 0 : _item$family.name,
        disabled: ""
      }
    })], 1)], 1), _vm._v(" "), _c("b-field", {
      attrs: {
        grouped: ""
      }
    }, [_c("b-field", {
      attrs: {
        label: "Data da execução",
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: _vm._f("dateComplete")(item === null || item === void 0 ? void 0 : item.date),
        disabled: ""
      }
    })], 1), _vm._v(" "), _c("b-field", {
      attrs: {
        label: "TAG",
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: item === null || item === void 0 ? void 0 : item.tagCode,
        disabled: ""
      }
    })], 1)], 1), _vm._v(" "), _c("b-field", {
      attrs: {
        grouped: ""
      }
    }, [_c("b-field", {
      attrs: {
        label: "Executor do passo",
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: "".concat(item === null || item === void 0 ? void 0 : item.author.firstName, " ").concat(item === null || item === void 0 ? void 0 : item.author.lastName, " | ").concat(item === null || item === void 0 ? void 0 : item.author.registration),
        disabled: ""
      }
    })], 1)], 1), _vm._v(" "), _vm.customFieldsActive.length > 0 ? _c("h5", [_vm._v("\n                        Campos Personalizados:\n                      ")]) : _vm._e(), _vm._v(" "), _vm.customFieldsActive.length > 0 ? _c("div", {
      staticClass: "list-custom-fields"
    }, _vm._l(_vm.customFieldsActive, function (cf, idx) {
      return _c("div", {
        key: "".concat(cf.id, "_").concat(idx),
        staticClass: "list-custom-field-item"
      }, [_c("b-field", {
        attrs: {
          grouped: ""
        }
      }, [cf.type === "string" || cf.type === "STRING" || cf.type === "input" ? _c("app-input", {
        attrs: {
          label: cf.label,
          placeholder: cf.label,
          expanded: ""
        },
        model: {
          value: cf.value,
          callback: function callback($$v) {
            _vm.$set(cf, "value", $$v);
          },
          expression: "cf.value"
        }
      }) : _vm._e(), _vm._v(" "), cf.type === "select" ? _c("app-select", {
        attrs: {
          label: cf.label,
          placeholder: cf.name,
          items: cf.options,
          option: "name",
          "name-item": "name",
          expanded: ""
        },
        model: {
          value: cf.value,
          callback: function callback($$v) {
            _vm.$set(cf, "value", $$v);
          },
          expression: "cf.value"
        }
      }) : _vm._e(), _vm._v(" "), _c("b-field", {
        attrs: {
          grouped: ""
        }
      }, [cf.type === "date" ? _c("app-input", {
        attrs: {
          label: cf.label,
          expanded: "",
          mask: ["##/##/####"],
          placeholder: "DD/MM/AAAA"
        },
        model: {
          value: cf.value,
          callback: function callback($$v) {
            _vm.$set(cf, "value", $$v);
          },
          expression: "cf.value"
        }
      }) : _vm._e(), _vm._v(" "), cf.type === "hour" ? _c("app-input", {
        attrs: {
          label: cf.label,
          expanded: "",
          mask: ["##:##"],
          placeholder: "HH:MM"
        },
        model: {
          value: cf.value,
          callback: function callback($$v) {
            _vm.$set(cf, "value", $$v);
          },
          expression: "cf.value"
        }
      }) : _vm._e()], 1), _vm._v(" "), cf.type === "seekbar" ? _c("b-field", {
        attrs: {
          label: cf.label
        }
      }, [_c("b-slider", {
        model: {
          value: cf.value,
          callback: function callback($$v) {
            _vm.$set(cf, "value", $$v);
          },
          expression: "cf.value"
        }
      })], 1) : _vm._e(), _vm._v(" "), cf.type === "checklist" ? _c("b-field", {
        attrs: {
          label: cf.label
        }
      }, [_c("div", {
        staticClass: "columns is-multiline is-variable is-vcentered is-mobile"
      }, _vm._l(cf.options, function (ii, idxx) {
        return _c("div", {
          key: idxx,
          staticClass: "column is-one-third"
        }, [_c("b-checkbox", {
          attrs: {
            "true-value": ii.name,
            "false-value": ""
          },
          model: {
            value: cf.value,
            callback: function callback($$v) {
              _vm.$set(cf, "value", $$v);
            },
            expression: "cf.value"
          }
        }, [_vm._v("\n                                    " + _vm._s(ii.name) + "\n                                  ")])], 1);
      }), 0)]) : _vm._e(), _vm._v(" "), cf.type === "number" ? _c("b-field", {
        attrs: {
          label: cf.label
        }
      }, [_c("b-numberinput", {
        attrs: {
          placeholder: cf.name
        },
        model: {
          value: cf.value,
          callback: function callback($$v) {
            _vm.$set(cf, "value", $$v);
          },
          expression: "cf.value"
        }
      })], 1) : _vm._e(), _vm._v(" "), cf.type === "radio" ? _c("section", [_c("b-field", {
        attrs: {
          label: cf.label
        }
      }), _vm._v(" "), _vm._l(cf.options, function (ii, idxx) {
        return _c("b-field", {
          key: idxx
        }, [_c("b-radio", {
          attrs: {
            "native-value": ii.name,
            "false-value": ""
          },
          model: {
            value: cf.value,
            callback: function callback($$v) {
              _vm.$set(cf, "value", $$v);
            },
            expression: "cf.value"
          }
        }, [_vm._v("\n                                  " + _vm._s(ii.name) + "\n                                ")])], 1);
      })], 2) : _vm._e()], 1)], 1);
    }), 0) : _c("div", {
      staticClass: "list-custom-fields"
    }, [_vm._v("\n                        Não existem Campos personalizados para esse passo!\n                      ")])], 1)])]);
  }), 1)])])]), _vm._v(" "), _c("div", {
    staticClass: "column is-one-third"
  }, [_c("label", {
    staticClass: "label"
  }, [_c("i", {
    staticClass: "icon icon-ic_location"
  }), _vm._v(" "), _c("span", [_vm._v("Local do agendamento")])]), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Talhão"
    }
  }, [_c("b-taginput", {
    attrs: {
      data: _vm.filteredTags,
      autocomplete: "",
      "allow-new": false,
      "open-on-focus": true,
      field: "name",
      icon: "label",
      placeholder: "Talhão"
    },
    on: {
      typing: _vm.getFilteredTags
    },
    model: {
      value: _vm.schedulePlots,
      callback: function callback($$v) {
        _vm.schedulePlots = $$v;
      },
      expression: "schedulePlots"
    }
  })], 1), _vm._v(" "), _vm.scheduleSubPlots.length ? _c("b-field", {
    attrs: {
      label: "Subtalhão"
    }
  }, [_c("b-taginput", {
    attrs: {
      data: _vm.filteredTags,
      autocomplete: "",
      "allow-new": false,
      "open-on-focus": true,
      field: "nickname",
      icon: "label",
      placeholder: "Subtalhão"
    },
    on: {
      typing: _vm.getFilteredTags
    },
    model: {
      value: _vm.scheduleSubPlots,
      callback: function callback($$v) {
        _vm.scheduleSubPlots = $$v;
      },
      expression: "scheduleSubPlots"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.scheduleRows.length ? _c("b-field", {
    attrs: {
      label: "Linhas"
    }
  }, [_c("b-taginput", {
    attrs: {
      data: _vm.filteredTags,
      autocomplete: "",
      "allow-new": false,
      "open-on-focus": true,
      field: "nickname",
      icon: "label",
      placeholder: "Linhas"
    },
    on: {
      typing: _vm.getFilteredTags
    },
    model: {
      value: _vm.scheduleRows,
      callback: function callback($$v) {
        _vm.scheduleRows = $$v;
      },
      expression: "scheduleRows"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.scheduleFamilies.length ? _c("b-field", {
    attrs: {
      label: "Famílias"
    }
  }, [_c("b-taginput", {
    attrs: {
      data: _vm.filteredTags,
      autocomplete: "",
      "allow-new": false,
      "open-on-focus": true,
      field: "nickname",
      icon: "label",
      placeholder: "Famílias"
    },
    on: {
      typing: _vm.getFilteredTags
    },
    model: {
      value: _vm.scheduleFamilies,
      callback: function callback($$v) {
        _vm.scheduleFamilies = $$v;
      },
      expression: "scheduleFamilies"
    }
  })], 1) : _vm._e()], 1)])]), _vm._v(" "), _c("footer", {
    staticClass: "modal-card-foot text-right"
  }, [_c("b-button", {
    attrs: {
      label: "Fechar"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("close");
      }
    }
  }), _vm._v(" "), _c("b-button", {
    attrs: {
      label: "Salvar",
      type: "is-primary"
    },
    on: {
      click: _vm.updateItens
    }
  })], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;