"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'AppInput',
  inheritAttrs: false,
  props: {
    classField: {
      type: String,
      default: ''
    },
    classInput: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    complete: {
      type: String,
      default: 'on'
    },
    placeholder: {
      type: String,
      default: ''
    },
    mask: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    included: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: [Number, String],
      default: ''
    },
    max: {
      type: [Number, String],
      default: ''
    },
    expanded: {
      type: [Boolean, String],
      default: false
    },
    masked: {
      type: Boolean,
      default: false
    },
    passwordReveal: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      newValue: this.value
    };
  },
  computed: {
    computedValue: {
      get: function get() {
        return this.newValue;
      },
      set: function set(value) {
        this.newValue = value;
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value: function value(_value) {
      this.newValue = _value;
    }
  },
  methods: {
    onBlur: function onBlur(event) {
      this.$emit('blur', event);
    },
    onFocus: function onFocus(event) {
      this.$emit('focus', event);
    }
  }
};