"use strict";

var _vue = _interopRequireDefault(require("vue"));
var _moment = _interopRequireDefault(require("moment"));
var _router = _interopRequireDefault(require("@/router"));
var _utils = require("./utils");
var _constants = require("@/config/constants");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
_vue.default.filter('hour', function (value) {
  _moment.default.locale('pt-BR');
  if (value) {
    var hour = _moment.default.utc(String(value)).format('HH:mm:ss');
    return hour;
  }
});
_vue.default.filter('datePayment', function (value) {
  _moment.default.locale('pt-BR');
  if (value) {
    var d = _moment.default.utc(String(value)).format('DD/MM/YYYY');
    var a = _moment.default.utc(String(value)).format('MMMM');
    return "".concat(a, " (").concat(d, ")");
  }
});
_vue.default.filter('datePost', function (value) {
  if (value) {
    var dateFormat = _moment.default.utc(String(value)).format('DD/MM/YYYY');
    var dateMinutes = _moment.default.utc(String(value)).format('HH:mm');
    return dateFormat + ' às ' + dateMinutes;
  }
});
_vue.default.filter('dateTypeMonth', function (value) {
  if (value) {
    _moment.default.locale('pt-BR');
    var dateFormat = _moment.default.utc(value).format('DD');
    var dateMinutes = _moment.default.utc(value).format('MMMM');
    return dateFormat + ' de ' + dateMinutes;
  }
});
_vue.default.filter('date', function (d) {
  if (d) {
    var date = _moment.default.utc(d);
    if (date.isValid()) {
      if (date.isValid()) return date.format('DD/MM/YYYY');
    }
  }
  return d;
});
_vue.default.filter('dateComplete', function (value) {
  if (value) {
    var dateFormat = _moment.default.utc(String(value)).format('DD/MM/YYYY');
    var dateMinutes = _moment.default.utc(String(value)).format('HH:mm:ss');
    return dateFormat + ' ' + dateMinutes;
  }
});
_vue.default.filter('dateCompleteNoTZ', function (value) {
  if (value) {
    var dateFormat = (0, _moment.default)(String(value)).format('DD/MM/YYYY');
    var dateMinutes = (0, _moment.default)(String(value)).format('HH:mm:ss');
    return dateFormat + ' ' + dateMinutes;
  }
});
_vue.default.filter('convetCriticity', function (value) {
  if (value) {
    var status = [{
      type: 'LOW',
      description: 'Baixa'
    }, {
      type: 'MEDIUM',
      description: 'Média'
    }, {
      type: 'HIGH',
      description: 'Alta'
    }];
    var description = status.find(function (item) {
      return item.type.toLowerCase() === value.toLowerCase();
    });
    return description.description;
  }
});
_vue.default.filter('locationSchedule', function (value) {
  if (value) {
    var textItem = '';
    value.map(function (item, index) {
      if (item) {
        var name = item.nickname || item.name;
        if (index === 0) textItem += name.slice(0, 1) + name.slice(-1);else textItem += ' - ' + name.slice(0, 1) + name.slice(-1);
      }
    });
    return value.length ? textItem.toUpperCase() : '';
  }
});
_vue.default.filter('locationScheduleComplete', function (value) {
  if (value) {
    var textItem = '';
    value.map(function (item, index) {
      if (item) {
        textItem = item.nickname ? item.nickname : item.name;
      }
    });
    return value.length ? textItem.toUpperCase() : '';
  }
});
_vue.default.filter('deadlineSuccessor', function (value) {
  if (value.followUpTimeUnit) {
    var translate = [{
      'DAY': 'dia(s)'
    }, {
      'WEEK': 'semana(s)'
    }, {
      'MONTH': 'mês'
    }];
    var unit = translate.find(function (item) {
      return item[value.followUpTimeUnit];
    });
    var descUnit = unit ? unit[value.followUpTimeUnit] : '';
    return value.followUpTimeValue + ' ' + descUnit;
  }
  return '';
});
_vue.default.filter('convertArea', function (value) {
  if (value) {
    var metros = value;
    var ha = (0, _utils.calc)(metros / 10000);
    return ha + ' ha';
  }
  return '';
});
_vue.default.filter('convertAreaNoUnit', function (value) {
  if (value && _typeof(value) !== 'object') {
    var metros = value;
    var ha = (0, _utils.calc)(metros / 10000);
    return ha;
  }
  return '0';
});
_vue.default.filter('convertRole', function (value) {
  if (value) {
    var status = _constants.ROLES_USERS_FUNCTIONS_COMPLETE;
    var description = status.find(function (item) {
      return item.type.toLowerCase() === value.toLowerCase();
    });
    return description.description;
  }
});
_vue.default.filter('translateActivated', function (value) {
  return value ? _router.default.app.$t('pages.team.text_yes') : _router.default.app.$t('pages.team.text_no');
});
_vue.default.filter('translateActivatedDescription', function (value) {
  return value ? _router.default.app.$t('pages.team.text_activated_yes') : _router.default.app.$t('pages.team.text_activated_no');
});
_vue.default.filter('translateSchooling', function (value) {
  var data = _constants.SCHOOLINGS.find(function (item) {
    return item.value === value;
  });
  return data ? data.description : ' -- ';
});
_vue.default.filter('credCard', function (value) {
  if (value) {
    var number = value;
    number = number.replace(/(\d{4})(\d)/, '$1 $2');
    number = number.replace(/(\d{4})(\d)/, '$1 $2');
    number = number.replace(/(\d{4})(\d)/, '$1 $2');
    number = number.replace(/(\d{4})(\d)/, '$1 $2');
    return number;
  }
  return '**** **** **** ****';
});
_vue.default.filter('credCardDate', function (value) {
  if (value) {
    var number = value.replace(/(\d{2})(\d)/, '$1/$2');
    return number;
  }
  return 'MM/AAAA';
});
_vue.default.filter('convertCentsToReal', function (value) {
  if (value) {
    var number = (Number(value) / 100).toFixed(2);
    return number;
  }
  return '';
});
_vue.default.filter('convetDatePayment', function (value) {
  if (value) {
    if (value === 'PAID') return 'Pago';
    if (value === 'PENDING') return 'Pendente';
    if (value === 'PROCESSING') return 'Em processamento';
    if (value === 'DEACTIVATED') return 'Desativado';
    if (value === 'CANCELED') return 'Cancelado';
  }
  return '--';
});
_vue.default.filter('convetTryNumberPayment', function (value) {
  if (value) {
    if (value > 1) return 'Pendente';
    if (value === 1) return 'Pago';
  }
  return '';
});
_vue.default.filter('maskCPF', function (value) {
  var cpf = value;
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return cpf;
});
_vue.default.filter('maskCNPJ', function (value) {
  var text = value;
  if (text && text.length > 11) {
    text = text.replace(/\D/g, '');
    text = text.replace(/(\d{2})(\d)/, '$1.$2');
    text = text.replace(/(\d{3})(\d)/, '$1.$2');
    text = text.replace(/(\d{3})(\d)/, '$1/$2');
    text = text.replace(/(\d{4})(\d)/, '$1-$2');
    return text;
  } else if (text && text.length === 11) {
    text = text.replace(/\D/g, '');
    text = text.replace(/(\d{3})(\d)/, '$1.$2');
    text = text.replace(/(\d{3})(\d)/, '$1.$2');
    text = text.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return text;
  }
  return '';
});
_vue.default.filter('maskPhone', function (value) {
  if (!value) return '';
  var phone = value.replace(/\D/g, '');
  if (phone.length === 11) {
    phone = phone.replace(/(\d{2})(\d)/, '($1) $2');
    phone = phone.replace(/(\d{5})(\d)/, '$1-$2');
  } else {
    phone = phone.replace(/(\d{2})(\d)/, '($1) $2');
    phone = phone.replace(/(\d{4})(\d)/, '$1-$2');
  }
  return phone;
});
_vue.default.filter('stockQuantityMask', function (value) {
  if (!value) return '';
  return value.toFixed(2);
});
_vue.default.filter('convertStatusPayment', function (value) {
  if (value) {
    if (value === 'PAID') return 'Pago';
    if (value === 'PENDING') return 'Aguardando pagamento';
    if (value === 'PROCESSING') return 'Em processamento';
    if (value === 'DEACTIVATED') return 'Desativado';
    if (value === 'CANCELED') return 'Cancelado';
    if (value === 'ACTIVE') return 'Ativo';
  }
  return '--';
});
_vue.default.filter('formatMoedaBRL', function (value) {
  if (value) {
    var valor = value + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';
    valor = valor.replace(/([0-9]{2})$/g, ',$1');
    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
    }
    return valor;
  }
  return '--';
});
_vue.default.filter('maskHideEmail', function (value) {
  if (value) {
    var maskid = value.replace(/^(.)(.*)(.@.*)$/, function (_, a, b, c) {
      return a + b.replace(/./g, '*') + c;
    });
    return maskid;
  }
  return '--';
});