"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'Occurrences',
  meta: {
    expanded: false,
    title: 'Ocorrências',
    iconClass: 'i-bitcoin',
    requiresAuth: true
  },
  children: [{
    name: 'occurrences-list',
    path: '/ocurrrences/list',
    icon: 'icon-ic_lista',
    meta: {
      title: 'Lista de ocorrências',
      requiresAuth: true
    }
  }]
};
exports.default = _default;