"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-layout",
    class: _vm.classPage
  }, [_c("side-menu", {
    attrs: {
      title: _vm.farm.name || _vm.titleMenu,
      "all-menu-itens": _vm.menu,
      "return-enable": _vm.returnEnable,
      subtitle: _vm.subtitle,
      "return-menu": _vm.returnMenu,
      "all-menu-itens-two": _vm.subMenu,
      "all-menu-itens-three": _vm.subMenuThree,
      "return-text": _vm.returnText,
      "show-return": _vm.showReturn,
      "sub-menu-title": _vm.subMenuTitle,
      "sub-menu-title-three": _vm.subMenuTitleThree
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "context-item-fixed"
  }, [_vm._t("context")], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;