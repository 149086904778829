"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'UserBadge',
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    qrCodeText: function qrCodeText() {
      var text = this.user.registration;
      if (this.user.role === 'Operator') {
        text = this.user.qrCode;
      }
      return text || '';
    }
  }
};