"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "no-records-found-component"
  }, [_c("div", {
    staticClass: "nrfc_wrapper"
  }, [_c("div", {
    staticClass: "nrfc_content"
  }, [_c("h1", [_vm._v("\n        " + _vm._s(_vm.titleDestaq)), _c("br"), _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.description))])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;