"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var batchService = {
  fetchBatchsByFarm: fetchBatchsByFarm,
  deleteBatch: deleteBatch,
  updateBatch: updateBatch,
  createBatch: createBatch,
  fetchBatchsByService: fetchBatchsByService
};
exports.batchService = batchService;
function fetchBatchsByFarm(farmId) {
  var path = "/batch/".concat(farmId);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function fetchBatchsByService(serviceId) {
  var path = "/services/batch/".concat(serviceId);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function deleteBatch(id) {
  var path = "/batch/".concat(id);
  // eslint-disable-next-line no-undef
  return _http.default.delete(path);
}
function updateBatch(payload) {
  var path = "/batch/".concat(payload.id);
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.put(path, data);
}
function createBatch(payload) {
  var path = "/batch";
  var data = {
    clientId: payload.clientId,
    farmId: payload.farmId,
    productId: payload.productId,
    varietyId: payload.varietyId,
    cultivationId: payload.cultivationId,
    bouquetType: payload.bouquetType,
    classification: payload.classification,
    weight: payload.weight,
    quantity: payload.quantity,
    batchName: payload.batchName
  };

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}