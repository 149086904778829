"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-autocomplete", {
    ref: "autocomplet-system",
    attrs: {
      data: _vm.data,
      placeholder: "Pesquisa nome da fazenda (Ex.: Fazenda Reis)",
      field: "title",
      loading: _vm.isLoad
    },
    on: {
      typing: _vm.fetch,
      select: function select(option) {
        return _vm.onInsert(option);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("div", {
          staticClass: "media"
        }, [_c("div", {
          staticClass: "media-content"
        }, [_vm._v("\n        " + _vm._s(props.option["name"]) + " " + _vm._s(_vm.complement ? " - ".concat(props.option[_vm.complement]) : "") + "\n      ")])])];
      }
    }])
  }, [_vm._v(" "), _c("template", {
    slot: "empty"
  }, [_vm._v("\n    " + _vm._s(_vm.textEmpty) + "\n  ")])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;