"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueTheMask = require("vue-the-mask");
var _default2 = {
  name: 'TemplateMapDetail',
  components: {
    TheMask: _vueTheMask.TheMask
  },
  computed: {
    sumSubPlotsArea: function sumSubPlotsArea() {
      var total = 0;
      this.subPlotsList.area.map(function (item) {
        total += Number(item) / 0.00010000;
      });
      return total;
    }
  },
  props: {
    popup: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    propertiesPlot: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    subPlotsQtd: {
      type: Number,
      default: null
    },
    subPlotsList: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isErrorNickName: {
      type: Boolean,
      default: false
    },
    idFeature: {
      type: String,
      default: ''
    },
    editDraw: {
      type: Boolean,
      default: false
    },
    validCalcTotalAreas: {
      type: Boolean,
      default: false
    },
    createNewPlot: {
      type: Function,
      default: function _default() {}
    },
    updateQtdSuPlot: {
      type: Function,
      default: function _default() {}
    },
    closeDetail: {
      type: Function,
      default: function _default() {}
    },
    editPlot: {
      type: Function,
      default: function _default() {}
    }
  },
  methods: {
    closeDetailItem: function closeDetailItem() {
      this.$emit('closeDetail', this.idFeature);
    },
    updateQtd: function updateQtd(value) {
      this.$emit('updateQtdSuPlot', value);
    },
    createNewPlotItem: function createNewPlotItem() {
      if (this.editDraw) this.$emit('editPlot');else this.$emit('createNewPlot');
    }
  }
};
exports.default = _default2;