"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var types = _interopRequireWildcard(require("../mutation-types"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var state = {
  sidebar: {
    opened: false,
    withoutAnimation: false
  },
  isLoading: false
};
var getters = {
  isLoading: function isLoading(state) {
    return state.isLoading;
  }
};
var mutations = _defineProperty(_defineProperty(_defineProperty({}, types.CLOSE_MENU, function (state) {
  if (document.documentElement.clientWidth < 992) {
    state.sidebar.opened = false;
  }
}), types.TOGGLE_SIDEBAR, function (state, opened) {
  state.sidebar.opened = opened;
}), "setLoading", function setLoading(state, isLoading) {
  state.isLoading = isLoading || false;
});
var actions = {
  closeMenu: function closeMenu(_ref) {
    var commit = _ref.commit;
    commit(types.CLOSE_MENU);
  },
  toggleSidebar: function toggleSidebar(_ref2, opened) {
    var commit = _ref2.commit;
    commit(types.TOGGLE_SIDEBAR, opened);
  },
  setLoading: function setLoading(_ref3, isLoading) {
    var commit = _ref3.commit;
    commit('setLoading', isLoading);
  }
};
var _default = exports.default = {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};