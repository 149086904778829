"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'Farm',
  path: '/services/types',
  icon: 'icon-ic_fazenda',
  meta: {
    expanded: false,
    title: 'Fazendas',
    description: 'Gestão de fazendas',
    requiresAuth: true,
    block: true
  },
  subMenu: [{
    name: 'ocurrrences-ratings',
    path: '/ocurrrences/ratings',
    icon: 'icon-ic_classificacao',
    meta: {
      title: 'Classificações',
      requiresAuth: true,
      whiteList: ['Admin']
    }
  }],
  children: [{
    name: 'types-services',
    path: '/services/types',
    icon: 'icon-ic_tipos_de_servicos',
    meta: {
      title: 'Tipos de serviços',
      requiresAuth: true,
      whiteList: ['Admin']
    }
  }, {
    name: 'custom-fields-list',
    path: '/customfields/list',
    icon: 'icon-ic_settings',
    meta: {
      title: 'Custom Fields',
      requiresAuth: true,
      whiteList: ['Admin']
    }
  }],
  childrenThree: [{
    name: 'types-services',
    path: '/products/types',
    icon: 'icon-ic_tipos_de_servicos',
    meta: {
      title: 'Tipos de produtos',
      requiresAuth: true,
      whiteList: ['Admin']
    }
  }]
};
exports.default = _default;