"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueChartjs = require("vue-chartjs");
var _chart = _interopRequireDefault(require("chart.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
_chart.default.defaults.global.legend.display = false;
var reactiveProp = _vueChartjs.mixins.reactiveProp;
var _default2 = {
  extends: _vueChartjs.Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  mounted: function mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
exports.default = _default2;