"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.paymentService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
var _axios = _interopRequireDefault(require("axios"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var paymentService = {
  fetchFarms: fetchFarms,
  getSubcribitions: getSubcribitions,
  fetchPlansPayment: fetchPlansPayment,
  consultCreditCardBin: consultCreditCardBin,
  subscriptionPayment: subscriptionPayment,
  consultPaymentRecurrent: consultPaymentRecurrent,
  consultTransationsOrderID: consultTransationsOrderID,
  fetchAccountManagementData: fetchAccountManagementData,
  blockFarm: blockFarm,
  fetchZip: fetchZip,
  changePaymentMethod: changePaymentMethod,
  cancelPlan: cancelPlan,
  fetchFarmPayment: fetchFarmPayment,
  updateSignature: updateSignature,
  generateNewTicket: generateNewTicket,
  fetchDiscount: fetchDiscount,
  fetchSubscriptionDetail: fetchSubscriptionDetail,
  fetchPaymentInvoices: fetchPaymentInvoices
};
exports.paymentService = paymentService;
function fetchFarms(params) {
  var path = "/payment/farms";

  // eslint-disable-next-line no-undef
  return _http.default.get(path, {
    params: params
  });
}
function getSubcribitions(params) {
  var path = "/me/subscription";

  // eslint-disable-next-line no-undef
  return _http.default.get(path, {
    params: params
  });
}
function fetchPlansPayment(params) {
  var path = "/payment/plans";

  // eslint-disable-next-line no-undef
  return _http.default.get(path, {
    params: params
  });
}
function subscriptionPayment(payload) {
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post('/me/subscription', data);
}
function consultCreditCardBin(number) {
  var path = "/payment/consult/bin/".concat(number);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function consultPaymentRecurrent(recurrenteId) {
  var path = "/payment/consult/recurrent/".concat(recurrenteId);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function consultTransationsOrderID(params) {
  var path = "/payment/consult";

  // eslint-disable-next-line no-undef
  return _http.default.get(path, {
    params: params
  });
}
function fetchAccountManagementData(params) {
  var path = "/payment/consult/accounts/managements";

  // eslint-disable-next-line no-undef
  return _http.default.get(path, {
    params: params
  });
}
function blockFarm(payload) {
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post('/payment/block', data);
}
function fetchZip(zip) {
  return (0, _axios.default)({
    url: "https://viacep.com.br/ws/".concat(zip, "/json/"),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
}
function changePaymentMethod(payload) {
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post('/payment/change-method', data);
}
function cancelPlan(payload) {
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post('/payment/cancel', data);
}
function fetchFarmPayment(farmId) {
  var path = "/payment/consult/".concat(farmId);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function updateSignature(payload) {
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post('/payment/update-signature', data);
}
function generateNewTicket(payload) {
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post('/payment/generate-new-parcel', data);
}
function fetchDiscount(payload) {
  return _http.default.get("/payment/discount/consult/".concat(payload));
}
function fetchSubscriptionDetail(id) {
  return _http.default.get("/payment/subscription/".concat(id));
}
function fetchPaymentInvoices(id) {
  return _http.default.get("/payment/invoices/".concat(id));
}