"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
var _constants = require("@/config/constants");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var authService = exports.authService = {
  login: login,
  addUserToSession: addUserToSession,
  forgotPasswordOwner: forgotPasswordOwner,
  resetPasswordOwner: resetPasswordOwner,
  confirmRegistration: confirmRegistration,
  resendEmail: resendEmail,
  registerOwner: registerOwner,
  logout: logout
};
function login(email, password) {
  var data = {
    email: email,
    password: password
  };

  // eslint-disable-next-line no-undef
  return _http.default.post('/auth/admin', data);
}
function addUserToSession(response) {
  localStorage.removeItem(_constants.KEY_USER_LOCAL_STORAGE);
  localStorage.removeItem(_constants.KEY_USER_LOCAL_STORAGE_FARM);
  var token = response.token;
  if (token) {
    localStorage.setItem(_constants.KEY_USER_LOCAL_STORAGE, JSON.stringify(response));
  }
}
function forgotPasswordOwner(email) {
  var data = {
    email: email
  };

  // eslint-disable-next-line no-undef
  return _http.default.post('/forgotpassword', data);
}
function resetPasswordOwner(password, token) {
  var data = {
    password: password,
    token: token
  };

  // eslint-disable-next-line no-undef
  return _http.default.post('/resetpassword', data);
}
function resendEmail(email) {
  var data = {
    email: email
  };

  // eslint-disable-next-line no-undef
  return _http.default.post('/confirmation/resend', data);
}
function confirmRegistration(token, user) {
  var data = {
    token: token,
    user: user
  };

  // eslint-disable-next-line no-undef
  return _http.default.post('/confirmation', data);
}
function registerOwner(payload) {
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post('/register/owner', data);
}
function logout() {
  localStorage.removeItem(_constants.KEY_USER_LOCAL_STORAGE);
  localStorage.removeItem(_constants.KEY_USER_LOCAL_STORAGE_FARM);
  localStorage.clear();
  document.cookie = 'FIBOO-WEB-PLATFORM=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}