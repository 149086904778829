"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _SideMenuTemplate = _interopRequireDefault(require("@/ui/templates/SideMenuTemplate"));
var _store = _interopRequireDefault(require("@/store"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var _default2 = exports.default = {
  name: 'SideMenu',
  components: {
    SideMenuTemplate: _SideMenuTemplate.default
  },
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    titleNewItem: {
      type: String,
      default: null
    },
    newItemMethod: {
      type: Function,
      default: function _default() {}
    },
    allMenuItens: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    empty: {
      type: String,
      default: null
    },
    allMenuItensTwo: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    allMenuItensThree: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    openMenuSub: {
      type: Function,
      default: function _default() {}
    },
    returnText: {
      type: String,
      default: 'Voltar'
    },
    returnEnable: {
      type: Boolean,
      default: true
    },
    returnMenu: {
      type: String,
      default: null
    },
    showReturn: {
      type: Boolean,
      default: true
    },
    subMenuTitle: {
      type: String,
      default: ''
    },
    subMenuTitleThree: {
      type: String,
      default: ''
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)({
    userAdmin: 'userAdmin'
  })), {}, {
    filteredMenus: function filteredMenus() {
      var user = _store.default.state.auth.user;
      var menus = this.allMenuItens.filter(function (item) {
        if (item.meta.whiteList) {
          if (item.meta.whiteList.includes(user.role)) {
            return item;
          }
        } else return item;
      });
      return menus;
    }
  }),
  methods: {
    openMenuItem: function openMenuItem(item) {
      if (!item.meta.block) this.$router.push({
        path: item.path
      });
    },
    isActiveIten: function isActiveIten(item) {
      if (Object.keys(this.$route.params).length) {
        var prop = Object.keys(this.$route.params)[0];
        return this.$route.path === item.path + '/' + this.$route.params[prop];
      }
      return item.path === this.$route.path;
    },
    returnPage: function returnPage() {
      if (this.returnEnable) {
        if (this.returnMenu) this.$router.push({
          path: this.returnMenu
        });
        // this.$router.back()
      } else {
        this.$toast.open({
          message: 'Rota em desenvolvimento',
          type: 'is-success'
        });
      }
    }
  }
};