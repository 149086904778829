"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.plotService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var plotService = {
  createPlot: createPlot,
  updatePlot: updatePlot,
  fetchPlots: fetchPlots,
  fetchPlotItem: fetchPlotItem,
  deletePlot: deletePlot,
  fetchSubPlot: fetchSubPlot,
  createSubPlot: createSubPlot,
  updateSubPlot: updateSubPlot,
  deleteSubPlot: deleteSubPlot,
  fetchLines: fetchLines,
  generateStructure: generateStructure,
  fetchRow: fetchRow,
  createRow: createRow,
  updateRow: updateRow,
  deleteRow: deleteRow,
  fetchFamilies: fetchFamilies,
  createFamilies: createFamilies,
  updateFamilie: updateFamilie,
  deleteFamilies: deleteFamilies,
  fetchBunches: fetchBunches,
  createBunches: createBunches,
  updateBunches: updateBunches,
  deleteBunches: deleteBunches
};
exports.plotService = plotService;
function createPlot(farmId, payload) {
  var path = "/farms/".concat(farmId, "/plots");
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}
function updatePlot(payload) {
  var path = "/plots/".concat(payload.id);
  delete payload.id;
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.put(path, data);
}
function fetchPlots(farmId) {
  var path = "farms/".concat(farmId, "/plots");

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function fetchPlotItem(id) {
  var path = "/plots/".concat(id);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function deletePlot(id) {
  var path = "/plots/".concat(id);

  // eslint-disable-next-line no-undef
  return _http.default.delete(path);
}
function fetchSubPlot(idPlot) {
  var path = "/plots/".concat(idPlot, "/subplots");

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function createSubPlot(idPlot, payload) {
  var path = "/plots/".concat(idPlot, "/subplots");
  var data = payload;
  if (!Array.isArray(payload)) path += '-several';

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}
function updateSubPlot(idSubPlot, payload) {
  var path = "/subplots/".concat(idSubPlot);
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.put(path, data);
}
function deleteSubPlot(payload) {
  return (0, _http.default)({
    url: "/subplots/delete/all",
    method: 'delete',
    data: payload
  });
}
function generateStructure(id, payload) {
  var path = "/subplots/".concat(id, "/rows/generate");
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}
function fetchRow(idSubPlot, filter, page, limit) {
  var path = "subplots/".concat(idSubPlot, "/rows");
  var params = {};
  if (filter) params.filter = filter;
  if (page) params.page = page;
  if (limit) params.limit = limit;

  // eslint-disable-next-line no-undef
  return _http.default.get(path, {
    params: params
  });
}
function createRow(idSubplot, payload) {
  var path = "/subplots/".concat(idSubplot, "/rows");
  var data = payload;
  if (!Array.isArray(payload)) path += '-several';

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}
function updateRow(id, payload) {
  var path = "/rows/".concat(id);
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.put(path, data);
}
function deleteRow(id) {
  var path = "/rows/".concat(id);

  // eslint-disable-next-line no-undef
  return _http.default.delete(path);
}
function fetchFamilies(idRow, params) {
  var path = "rows/".concat(idRow, "/families");

  // eslint-disable-next-line no-undef
  return _http.default.get(path, {
    params: params
  });
}
function createFamilies(idRow, payload) {
  var path = "/rows/".concat(idRow, "/families");
  var data = payload;
  if (!Array.isArray(payload)) path += '-several';

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}
function updateFamilie(id, payload) {
  var path = "/families/".concat(id);
  var data = payload;
  // eslint-disable-next-line no-undef
  return _http.default.put(path, data);
}
function deleteFamilies(id) {
  var path = "/families/".concat(id);

  // eslint-disable-next-line no-undef
  return _http.default.delete(path);
}
function fetchBunches(idFamilie) {
  var path = "/families/".concat(idFamilie, "/bunches");

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function createBunches(idFamilie, payload) {
  var path = "/families/".concat(idFamilie, "/bunches");
  var data = payload;
  if (!Array.isArray(payload)) path += '-several';

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}
function updateBunches(id, payload) {
  var path = "/bunches/".concat(id);
  var data = payload;
  // eslint-disable-next-line no-undef
  return _http.default.put(path, data);
}
function deleteBunches(id) {
  var path = "/bunches/".concat(id);

  // eslint-disable-next-line no-undef
  return _http.default.delete(path);
}
function fetchLines(idLine) {
  var path = "subplots/".concat(idLine, "/rows");

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}