"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "auth-page"
  }, [_c("div", {
    staticClass: "container-auth"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "wrap-auth"
  }, [_c("form", {
    staticClass: "auth-form validate-form",
    on: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_c("h2", {
    staticClass: "login-form-title"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.login.title")) + "\n        ")]), _vm._v(" "), _vm.msgAlert ? _c("b-notification", {
    attrs: {
      type: _vm.error ? "is-danger" : "",
      "aria-close-label": "Close notification",
      role: "alert"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.msgAlert) + "\n        ")]) : _vm._e(), _vm._v(" "), _c("b-field", {
    staticClass: "fixo",
    attrs: {
      label: _vm.$t("pages.login.email"),
      type: _vm.$v.form.username.$error || _vm.error ? "is-danger" : "",
      message: _vm.$v.form.username.$error ? _vm.$t("pages.login.alerts.error_username") : ""
    }
  }, [_c("b-input", {
    attrs: {
      type: "email"
    },
    model: {
      value: _vm.$v.form.username.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.username, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.form.username.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "pass-auth-fiboo fixo",
    attrs: {
      label: _vm.$t("pages.login.password"),
      type: _vm.$v.form.password.$error || _vm.error ? "is-danger" : "",
      message: _vm.$v.form.password.$error ? _vm.$t("pages.login.alerts.error_password") : ""
    }
  }, [_c("b-input", {
    attrs: {
      type: "password",
      "password-reveal": true
    },
    model: {
      value: _vm.$v.form.password.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.password, "$model", $$v);
      },
      expression: "$v.form.password.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "container-auth-form-btn"
  }, [_c("div", {
    staticClass: "wrap-auth-form-remember"
  }, [_c("div", {
    staticClass: "field"
  }, [_c("b-checkbox", {
    model: {
      value: _vm.rememberEmail,
      callback: function callback($$v) {
        _vm.rememberEmail = $$v;
      },
      expression: "rememberEmail"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.$t("pages.login.remember_email")) + "\n              ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "field"
  }, [_c("b-button", {
    attrs: {
      type: "is-text"
    },
    on: {
      click: function click($event) {
        return _vm.redirect("auth/recover-password");
      }
    }
  }, [_vm._v("\n                " + _vm._s(_vm.$t("pages.login.forgot_password")) + "\n              ")])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "wrap-auth-form-btn"
  }, [_c("b-button", {
    attrs: {
      "native-type": "submit",
      rounded: "",
      disabled: _vm.isDisabled
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t("pages.login.login")) + "\n            ")])], 1)]), _vm._v(" "), _c("b-button", {
    staticClass: "btn-singup-redirect",
    on: {
      click: function click($event) {
        return _vm.redirect("auth/signup");
      }
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.login.dont_have_access")) + "\n        ")])], 1)])])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container-logo-auth"
  }, [_c("img", {
    staticClass: "logo-fibo-auth",
    attrs: {
      src: require("@/assets/logo.png")
    }
  })]);
}];
render._withStripped = true;