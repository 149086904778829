"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-fibo-finish-service"
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "card-content"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("\n        Finalizar serviço\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_vm._v("\n        Você está finalizando um serviço que não foi totalmente completo. Para prosseguir com o encerramento deste serviço escreva uma justificativa abaixo, informando o motivo da finalização. A justificativa ficará no histórico deste serviço.\n        "), _c("b-field", {
    staticClass: "text-area-finalize"
  }, [_c("b-input", {
    attrs: {
      type: "textarea",
      minlength: "10",
      maxlength: "1000",
      placeholder: "Descrever o motivo da finalização"
    },
    model: {
      value: _vm.scheduling.finishingDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.scheduling, "finishingDescription", $$v);
      },
      expression: "scheduling.finishingDescription"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "buttom-finalize"
  }, [_c("b-button", {
    staticClass: "cancel",
    on: {
      click: _vm.cancelModal
    }
  }, [_vm._v("\n            Cancelar\n          ")]), _vm._v(" "), _c("b-button", {
    staticClass: "save",
    attrs: {
      disabled: _vm.scheduling.finishingDescription.length < 10
    },
    on: {
      click: _vm.finalize
    }
  }, [_vm._v("\n            Finalizar\n          ")])], 1)], 1)])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;