"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "farm-list-management-page"
  }, [_c("side-menu", {
    attrs: {
      title: _vm.menuItemsFarm.meta.description,
      "show-return": false,
      "all-menu-itens": [_vm.menuItemsFarm],
      "return-enable": false
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "context-item-fixed"
  }, [_c("farms-list")], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;