"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "custom-card",
    attrs: {
      id: _vm.id
    }
  }, [_c("a", {
    attrs: {
      href: "#"
    },
    on: {
      click: _vm.emitData
    }
  }, [_c("div", {
    staticClass: "card-template",
    class: "card-sized-".concat(_vm.syze, " ").concat(_vm.alertClass)
  }, [_c("div", {
    staticClass: "card-header"
  }, [_c("div", {
    staticClass: "top"
  }, [_vm.subtitle ? _c("div", {
    staticClass: "card-subtitle"
  }, [_c("p", [_vm._v(_vm._s(_vm.subtitle))])]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "card-title"
  }, [_c("p", {
    style: _vm.stylesTitle
  }, [_vm._v(_vm._s(_vm.title))])]), _vm._v(" "), _vm.date ? _c("div", {
    staticClass: "card-date"
  }, [_c("i", {
    staticClass: "icon icon-ic_calendar"
  }), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm._f("date")(_vm.date)))])]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "card-footer"
  }, [_c("div", {
    staticClass: "card-footer-item"
  }, [_vm.status ? _c("div", {
    staticClass: "card-status",
    class: _vm.statusClass
  }, [_vm._v("\n            " + _vm._s(_vm.status) + "\n          ")]) : _vm._e(), _vm._v(" "), _vm.location.length ? _c("div", {
    staticClass: "card-location"
  }, [_c("i", {
    staticClass: "icon icon-ic_location"
  }), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm._f("locationSchedule")(_vm.location)))])]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "card-footer-item card-users",
    class: {
      points: _vm.usersList.length > _vm.maxUsers
    }
  }, _vm._l(_vm.userListArray, function (user, i) {
    return _c("img", {
      key: i,
      style: "right:" + i * 12 + "px",
      attrs: {
        src: _vm.getImgUrl(user),
        alt: ""
      }
    });
  }), 0)])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;