"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Layout = _interopRequireDefault(require("./ui/layout/Layout"));
var _LayoutFull = _interopRequireDefault(require("./ui/layout/LayoutFull"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = {
  name: 'App',
  components: {
    Layout: _Layout.default,
    LayoutFull: _LayoutFull.default
  }
};