"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "auth-recover-password-page"
  }, [_c("div", {
    staticClass: "container-auth"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "wrap-auth"
  }, [_c("form", {
    staticClass: "auth-form validate-form",
    on: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_c("h2", {
    staticClass: "login-form-title"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.recover_password.title")) + "\n        ")]), _vm._v(" "), _vm.msgAlert ? _c("b-notification", {
    attrs: {
      type: _vm.error ? "is-danger" : "",
      "aria-close-label": "Close notification",
      role: "alert"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.msgAlert) + "\n        ")]) : _vm._e(), _vm._v(" "), _vm.sendSuccess ? _c("p", [_vm._v("\n          " + _vm._s(_vm.msgSend) + "\n        ")]) : _vm._e(), _vm._v(" "), !_vm.sendSuccess ? _c("div", [_c("b-field", {
    attrs: {
      label: _vm.$t("pages.recover_password.email"),
      type: _vm.$v.form.username.$error || _vm.error ? "is-danger" : "",
      message: _vm.$v.form.username.$error ? "Informe o e-mail" : ""
    }
  }, [_c("b-input", {
    attrs: {
      type: "email"
    },
    model: {
      value: _vm.form.username,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "container-auth-form-btn"
  }, [_c("div", {
    staticClass: "wrap-auth-form-btn"
  }, [_c("b-button", {
    attrs: {
      "native-type": "submit",
      rounded: "",
      disabled: _vm.isDisabled
    }
  }, [_vm._v("\n                " + _vm._s(_vm.$t("pages.recover_password.send")) + "\n              ")])], 1)])], 1) : _vm._e(), _vm._v(" "), _c("b-button", {
    staticClass: "btn-singup-redirect",
    on: {
      click: function click($event) {
        return _vm.redirect("auth/login");
      }
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.recover_password.return")) + "\n        ")])], 1)])])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container-logo-auth"
  }, [_c("img", {
    staticClass: "logo-fibo-auth",
    attrs: {
      src: require("@/assets/logo.png")
    }
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;