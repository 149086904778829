"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.balanceService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var balanceService = {
  fetchData: fetchData,
  fetchHistory: fetchHistory
};
exports.balanceService = balanceService;
function fetchData(farmId) {
  var path = "/fetch-registers-balance/".concat(farmId, "?env=").concat(process.env.VUE_APP_BASE_ENV);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function fetchHistory(farmId) {
  var path = "/balance/history/".concat(farmId);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}