"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("button", {
    staticClass: "btn-pag",
    class: {
      "pagination-previous": _vm.arrowType === "left",
      "pagination-next": _vm.arrowType === "right"
    }
  }, [_c("span", {
    staticClass: "icon",
    attrs: {
      "aria-hidden": ""
    }
  }, [_c("i", {
    staticClass: "mdi mdi-24px",
    class: "mdi-chevron-".concat(_vm.arrowType)
  })])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;