"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'Card',
  props: {
    id: {
      type: [String, Number],
      default: undefined
    },
    title: {
      type: String,
      default: 'Sem nome'
    },
    subtitle: {
      type: String,
      default: undefined
    },
    date: {
      type: String,
      default: undefined
    },
    usersList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    status: {
      type: String,
      default: null
    },
    location: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    syze: {
      type: String,
      default: 'large'
    },
    statusClass: {
      type: String,
      default: ''
    },
    stylesTitle: {
      type: String,
      default: ''
    },
    alertClass: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      maxUsers: 5
    };
  },
  computed: {
    userListArray: function userListArray() {
      var array = this.usersList;
      return array.length > this.maxUsers ? [array[0], array[1], array[2], array[3], array[4]] : array;
    }
  },
  methods: {
    emitData: function emitData(id) {
      this.$emit('cardSelected', id);
    },
    getImgUrl: function getImgUrl(user) {
      if (user && user.imageUrl) return user.imageUrl;
      return require('@/assets/images/user-icon.png');
    }
  }
};
exports.default = _default2;