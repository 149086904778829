"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.occurrenceService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var fetchOccurrencesRatings = function fetchOccurrencesRatings(params) {
  return (0, _http.default)({
    url: "/occurrencetypes",
    method: 'get'
  });
};
var listFetchOcurrencesRatings = function listFetchOcurrencesRatings(farmId, product) {
  return (0, _http.default)({
    url: "/occurrencetypes/listocurrencesratings",
    method: 'get',
    params: {
      farmId: farmId,
      productsId: product
    }
  });
};
var saveOccurrence = function saveOccurrence(payload, idOccurrence, idFarm) {
  var data = {
    farmId: idFarm,
    status: payload.status,
    criticality: payload.criticality,
    typeId: payload.typeId,
    coordinatorId: payload.coordinatorId
  };
  return (0, _http.default)({
    url: "/occurrences/".concat(idOccurrence),
    method: 'put',
    data: data
  });
};
var saveLogs = function saveLogs(data, idOccurrence) {
  return (0, _http.default)({
    url: "occurrences/".concat(idOccurrence, "/logs"),
    method: 'post',
    data: data
  });
};
var createRatings = function createRatings(payload) {
  return (0, _http.default)({
    url: "/occurrencetypes",
    method: 'post',
    data: payload
  });
};
var fetchRating = function fetchRating(id, params) {
  return (0, _http.default)({
    url: "/occurrencetypes/".concat(id),
    method: 'get',
    params: params
  });
};
var updateRatings = function updateRatings(payload, id) {
  return (0, _http.default)({
    url: "/occurrencetypes/".concat(id),
    method: 'put',
    data: payload
  });
};
var deleteRatings = function deleteRatings(id) {
  return (0, _http.default)({
    url: "/occurrencetypes/".concat(id),
    method: 'delete'
  });
};
var occurrenceService = {
  fetchOccurrencesRatings: fetchOccurrencesRatings,
  listFetchOcurrencesRatings: listFetchOcurrencesRatings,
  saveOccurrence: saveOccurrence,
  saveLogs: saveLogs,
  createRatings: createRatings,
  fetchRating: fetchRating,
  updateRatings: updateRatings,
  deleteRatings: deleteRatings
};
exports.occurrenceService = occurrenceService;