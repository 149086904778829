"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "auth-singup"
  }, [_c("div", {
    staticClass: "container-auth"
  }, [_vm._m(0), _vm._v(" "), !_vm.registerSuccess ? _c("div", {
    staticClass: "wrap-auth"
  }, [_c("form", {
    staticClass: "auth-form validate-form",
    on: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_c("h2", {
    staticClass: "login-form-title"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.signup.title")) + "\n        ")]), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: _vm.$t("pages.signup.labels.first_name"),
      type: _vm.$v.form.firstName.$error || _vm.error ? "is-danger" : "",
      message: _vm.$v.form.firstName.$error ? "Informe o primeiro nome" : ""
    }
  }, [_c("b-input", {
    attrs: {
      required: ""
    },
    model: {
      value: _vm.form.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "firstName", $$v);
      },
      expression: "form.firstName"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: _vm.$t("pages.signup.labels.last_name"),
      type: _vm.$v.form.lastName.$error || _vm.error ? "is-danger" : "",
      message: _vm.$v.form.lastName.$error ? "Informe o seu sobrenome" : ""
    }
  }, [_c("b-input", {
    attrs: {
      required: ""
    },
    model: {
      value: _vm.form.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lastName", $$v);
      },
      expression: "form.lastName"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.signup.labels.cpf")) + "\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "control is-clearfix"
  }, [_c("the-mask", {
    staticClass: "input",
    class: {
      "is-danger": _vm.form.cpf.length > 10 && !_vm.$v.form.cpf.validCPF || _vm.error
    },
    attrs: {
      autocomplete: "off",
      mask: ["###.###.###-##"]
    },
    model: {
      value: _vm.form.cpf,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cpf", $$v);
      },
      expression: "form.cpf"
    }
  })], 1)]), _vm._v(" "), _c("b-field", {
    attrs: {
      label: _vm.$t("pages.signup.labels.email"),
      type: _vm.$v.form.email.$error || _vm.error ? "is-danger" : "",
      message: _vm.$v.form.email.$error ? "Informe o e-mail" : ""
    }
  }, [_c("b-input", {
    attrs: {
      type: "email"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.signup.labels.phone")) + "\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "control is-clearfix"
  }, [_c("vue-tel-input", {
    staticClass: "input",
    class: {
      "is-danger": _vm.$v.form.phone.$error || _vm.error
    },
    attrs: {
      autocomplete: "off",
      placeholder: "",
      "input-options": {
        showDialCode: true
      }
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1)]), _vm._v(" "), _c("b-field", {
    staticClass: "pass-auth-fiboo",
    attrs: {
      label: _vm.$t("pages.signup.labels.password"),
      type: _vm.$v.form.password.$error || _vm.error ? "is-danger" : "",
      message: _vm.$v.form.password.$error ? "Informe a sua senha (Min. 6 caracteres)" : ""
    }
  }, [_c("b-input", {
    attrs: {
      type: "password",
      "password-reveal": true
    },
    nativeOn: {
      input: function input($event) {
        return _vm.setPassword($event.target.value);
      }
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.password"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "pass-auth-fiboo",
    attrs: {
      label: _vm.$t("pages.signup.labels.confirm_password"),
      type: _vm.$v.form.passwordConfirm.$error || _vm.error ? "is-danger" : "",
      message: _vm.$v.form.passwordConfirm.$error ? "Confirme sua senha" : ""
    }
  }, [_c("b-input", {
    attrs: {
      type: "password",
      "password-reveal": true
    },
    nativeOn: {
      input: function input($event) {
        return _vm.setPasswordConfirm($event.target.value);
      }
    },
    model: {
      value: _vm.form.passwordConfirm,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "passwordConfirm", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.passwordConfirm"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "field field-checkbox"
  }, [_c("b-checkbox", {
    nativeOn: {
      click: function click($event) {
        return _vm.acceptTerms.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.terms,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "terms", $$v);
      },
      expression: "form.terms"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "container-auth-form-info-terms"
  }, [_vm._v("\n            " + _vm._s(_vm.termo) + "\n            "), _c("a", {
    attrs: {
      href: "".concat(_vm.urlTerms, "/fiboo-terms-of-use.pdf"),
      target: "_blank"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t("pages.signup.terms_service")) + "\n            ")]), _vm._v("\n            " + _vm._s(_vm.$t("pages.signup.and_with")) + "\n            "), _c("a", {
    attrs: {
      href: "".concat(_vm.urlTerms, "/fiboo-privacy-policy.pdf"),
      target: "_blank"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t("pages.signup.privacy_policy")) + "\n            ")]), _vm._v("\n            " + _vm._s(_vm.$t("pages.signup.the_fiboo")) + "\n          ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "container-auth-form-btn"
  }, [_c("div", {
    staticClass: "wrap-auth-form-btn"
  }, [_c("b-button", {
    attrs: {
      rounded: "",
      "native-type": "submit",
      disabled: _vm.isDisabled
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t("pages.signup.btn_create_account")) + "\n            ")])], 1)])], 1)]) : _c("div", {
    staticClass: "wrap-auth register-success"
  }, [_c("h2", {
    staticClass: "login-form-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.signup.account_create_success")) + "\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "description-success"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.signup.send_message")) + "\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "container-auth-form-btn"
  }, [_c("div", {
    staticClass: "wrap-auth-form-btn"
  }, [_c("b-button", {
    attrs: {
      rounded: ""
    },
    on: {
      click: _vm.resendEmailUserRest
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.signup.btn_resend")) + "\n          ")])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "container-auth-return-login"
  }, [_c("router-link", {
    attrs: {
      to: "auth"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t("pages.signup.log_in")) + "\n        ")])], 1)])])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container-logo-auth"
  }, [_c("img", {
    staticClass: "logo-fibo-auth",
    attrs: {
      src: require("@/assets/logo.png")
    }
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;