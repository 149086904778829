"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-description-plan"
  }, [_c("div", {
    staticClass: "card-content"
  }, [_c("card-payment-details", {
    attrs: {
      plan: _vm.plan
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "description-itens-list"
  }, _vm._l(_vm.listDetails, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "item-list"
    }, [_c("h3", [_vm._v(_vm._s(item.label))]), _vm._v(" "), _c("span", [_vm._v(_vm._s(item.value))])]);
  }), 0)], 1), _vm._v(" "), _c("h4", {
    staticClass: "descripiton-amount"
  }, [_vm._v("\n    O valor da sua assinatura é determinado pelo o tamanho da área total da sua fazenda.\n  ")])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;