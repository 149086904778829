"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'ListItemCheck',
  props: {
    listQrCodes: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    listPrint: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    checkOption: {
      type: Function,
      default: function _default() {}
    },
    checkAll: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      list: this.listPrint
    };
  },
  computed: {
    computedValue: {
      get: function get() {
        return this.listPrint;
      },
      set: function set(value) {
        this.list = value;
        this.$emit('update:listPrint', value);
      }
    }
  },
  watch: {
    listPrint: function listPrint(value) {
      this.list = value;
    }
  },
  methods: {
    checkItem: function checkItem(e) {
      var _this = this;
      if (this.checkAll) {
        this.$emit('update:checkAll', false);
      }
      this.$nextTick(function () {
        if (e) {
          _this.computedValue = e;
        }
      });
    }
  }
};
exports.default = _default2;