"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "farm-list-management-page"
  }, [_c("page", {
    staticClass: "page-farm-data",
    attrs: {
      menu: _vm.menuItemsFarm.children,
      "sub-menu": _vm.menuItemsFarm.subMenu,
      "return-text": "Lista de fazendas",
      "return-menu": _vm.menuItemsFarm.path
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("fullscreen", {
    ref: "fullscreen",
    attrs: {
      background: "#FFFFFF"
    },
    on: {
      change: _vm.fullscreenChange
    }
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-one-fifth"
  }, [!_vm.fullscreen ? _c("i", {
    staticClass: "icon-enlarge",
    on: {
      click: _vm.toggle
    }
  }) : _vm._e(), _vm._v(" "), _vm.fullscreen ? _c("i", {
    staticClass: "icon-shrink",
    on: {
      click: _vm.toggle
    }
  }) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "column is-centered"
  }, [_vm.fullscreen ? _c("img", {
    attrs: {
      width: "200",
      src: require("@/assets/logo.png")
    }
  }) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("h2", {
    staticClass: "farmName"
  }, [_vm._v("\n              Dashboard - " + _vm._s(_vm.farm.name) + "\n            ")])])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("p", {
    staticClass: "login-form-title",
    staticStyle: {
      "vertical-align": "middle",
      "text-align": "right"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.planidName) + "\n            ")])]), _vm._v(" "), _c("div", {
    staticClass: "column align-right"
  }, [_c("b-button", {
    attrs: {
      type: "is-info",
      "icon-right": "printer"
    },
    on: {
      click: _vm.printReport
    }
  }, [_vm._v("\n              Imprimir\n            ")])], 1)]), _vm._v(" "), _vm.response.tabs ? _c("b-tabs", {
    attrs: {
      type: "is-toggle"
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_vm._l(_vm.response.tabs, function (tab, index) {
    return [_c("b-tab-item", {
      key: index,
      attrs: {
        label: tab.title,
        id: "dash_tab_" + index
      }
    }, [_vm.showTabContent(index) ? _c("div", {
      staticClass: "wiser",
      attrs: {
        id: tab.id,
        "powerbi-type": "report",
        "powerbi-embed-url": "".concat(tab.linkFrame, "/ReportSection&filter=farms/id eq '").concat(_vm.ID_FAZENDA, "'"),
        "powerbi-access-token": _vm.response.embedded,
        width: _vm.window.width,
        height: _vm.window.height,
        getEmbeddedComponent: _vm.embeddedSetting
      }
    }) : _vm._e()])];
  })], 2) : _vm._e()], 1)], 1)], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;