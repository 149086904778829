"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ModalQRCode = _interopRequireDefault(require("./ModalQRCode"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default2 = exports.default = {
  name: 'ButtonQRCode',
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    type: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      modal: null
    };
  },
  methods: {
    openModal: function openModal() {
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: _ModalQRCode.default,
        hasModalCard: true,
        canCancel: ['escape', 'outside'],
        props: {
          item: this.item,
          type: this.type
        }
      });
    }
  }
};