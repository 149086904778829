"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "farms-details-update-signature"
  }, [_vm._m(0), _vm._v(" "), _vm.area ? _c("div", {
    staticClass: "farms-details-update-signature-details"
  }, [_c("div", {
    staticClass: "columns"
  }, _vm._l(_vm.plansPayments, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "column item-signature",
      class: {
        paymentSelected: _vm.typePayment.id === item.id
      },
      on: {
        click: function click($event) {
          return _vm.selectPaymentPlan(item);
        }
      }
    }, [_c("p", {
      staticClass: "type-signature"
    }, [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]), _vm._v(" "), _vm._l(item.descriptions, function (itemDescription, idx) {
      return _c("div", {
        key: idx,
        staticClass: "list-item"
      }, [_c("span", {
        domProps: {
          innerHTML: _vm._s(itemDescription)
        }
      })]);
    }), _vm._v(" "), _c("p", {
      staticClass: "value-signature-second"
    }, [_vm._v("\n          " + _vm._s("R$ ".concat(item.valuePerHectare, "/ha/m\xEAs")) + "\n        ")]), _vm._v(" "), _c("p", {
      staticClass: "footer-signature"
    }, [_vm._v("\n          Assinatura com pagamento mensal, garantindo o acesso anual na plataforma. Pagamento pelo cartão de crédito ou boleto.\n        ")])], 2);
  }), 0)]) : _c("div", {
    staticClass: "farms-details-update-signature-details"
  }, [_vm._v("\n    Para atualizar sua assinatura, é necessário cadastrar ao menos uma área produtiva\n    "), _c("router-link", {
    staticClass: "destaq-link",
    attrs: {
      to: "/farm-management/list"
    }
  }, [_vm._v("\n      clicando aqui.\n    ")])], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "farms-details-update-signature-title"
  }, [_c("h3", [_vm._v("Atualize sua assinatura")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;