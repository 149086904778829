"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-label": "Moda Cadastro Fornecedor",
      "aria-modal": "",
      scroll: "keep",
      "can-cancel": false
    },
    model: {
      value: _vm.isModal,
      callback: function callback($$v) {
        _vm.isModal = $$v;
      },
      expression: "isModal"
    }
  }, [_c("div", {
    staticClass: "modal-card",
    staticStyle: {
      width: "600px"
    }
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("\n        Detalhes de classificação\n      ")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.close
    }
  })]), _vm._v(" "), _c("div", {
    staticClass: "content-images"
  }, [_c("img", {
    attrs: {
      src: _vm.link
    }
  })])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;