"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _UserBadge = _interopRequireDefault(require("@/ui/components/UserBadge"));
var _constants = require("@/config/constants");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default2 = exports.default = {
  name: 'ModalDetail',
  data: function data() {
    return {
      idElement: 'employees-badge-card',
      whiteList: _constants.LIST_ADMINS,
      position: _constants.POSITION_DROPDOWN
    };
  },
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    print: {
      type: Function,
      default: function _default() {}
    },
    prepareAction: {
      type: Function,
      default: function _default() {}
    },
    actions: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    UserBadge: _UserBadge.default
  },
  methods: {
    cancelModal: function cancelModal() {
      this.$parent.close();
    },
    verifyEdit: function verifyEdit(role) {
      if (this.whiteList.includes(role)) {
        return this.whiteList.includes(this.currentUser.role);
      }
      return true;
    }
  }
};