"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.classificationService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var classificationService = {
  fetchClassifications: fetchClassifications
};
exports.classificationService = classificationService;
function fetchClassifications(id) {
  return _http.default.get('/classifications', {
    params: {
      productId: id
    }
  });
}