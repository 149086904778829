"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("@/ui/components");
var _components2 = require("./components");
var _vuex = require("vuex");
var _Page = _interopRequireDefault(require("@/ui/layout/Page"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var _default = exports.default = {
  name: 'RoutesFarmUserManagement',
  components: {
    Page: _Page.default,
    AppInput: _components.AppInput,
    DirectionsRenderer: _components2.DirectionsRenderer,
    CardDashRoutes: _components2.CardDashRoutes,
    DatePicker: _components.DatePicker
  },
  data: function data() {
    return {
      center: {
        lat: -15.821435,
        lng: -48.114201
      },
      startLocation: {
        lat: -15.821435,
        lng: -48.114201
      },
      endLocation: {
        lat: -15.8107481,
        lng: -48.065751
      },
      position: {
        lat: -15.800639736833038,
        lng: -47.86137374761963
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)({
    menuItems: 'menuItemsFarm',
    isLoading: 'isLoading'
  })),
  created: function created() {
    var _this = this;
    setTimeout(function () {
      _this.setDefault();
    }, 3000);
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)([])), {}, {
    updateCoordinates: function updateCoordinates(location) {
      this.position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      };
    },
    setDefault: function setDefault() {
      this.center = {
        lat: -15.821435,
        lng: -48.114201
      };
      this.startLocation = {
        lat: -15.821435,
        lng: -48.114201
      };
      this.endLocation = {
        lat: -15.8107481,
        lng: -48.065751
      };
    },
    setPlace: function setPlace(place) {
      this.currentPlace = place;
    },
    addMarker: function addMarker(index) {
      var marker = {
        lat: this.currentPlace.geometry.location.lat(),
        lng: this.currentPlace.geometry.location.lng()
      };
      if (index === 0) this.startLocation = marker;
      if (index === 1) this.endLocation = marker;
      this.center = marker;
    },
    previous: function previous() {
      return this.$router.push({
        path: '/farm-management/routes'
      });
    }
  })
};