"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calc = calc;
exports.convertDate = convertDate;
exports.convertDateISO = convertDateISO;
exports.mergeArrays = mergeArrays;
exports.removeAccents = removeAccents;
exports.replaceAll = replaceAll;
exports.sortBy = sortBy;
exports.validateCNPJ = validateCNPJ;
exports.validateCNPJorCPF = validateCNPJorCPF;
exports.validateCPF = validateCPF;
exports.validateDate = validateDate;
exports.validateDateForm = validateDateForm;
exports.validateDateGeneral = validateDateGeneral;
exports.validateEmail = validateEmail;
var _moment = _interopRequireDefault(require("moment"));
var _format = require("./format");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * sort array by valu propertie
 * @param {string} key
 * @param {string} reverse
 * @returns {string}
 */
function sortBy(key, reverse) {
  var moveSmaller = reverse ? 1 : -1;
  var moveLarger = reverse ? -1 : 1;
  return function (a, b) {
    if (a[key] > b[key]) {
      return moveSmaller;
    }
    if (a[key] < b[key]) {
      return moveLarger;
    }
    return 0;
  };
}

/**
 * replace all strings
 * @param {Array} a
 * @param {Array} b
 * @param {string} prop
 * @param {string} extra
 * @returns {Array}
 */
function mergeArrays(a, b, prop, extra) {
  return a.filter(function (firstArrayItem) {
    return b.find(function (secondArrayItem) {
      return firstArrayItem[prop] === (extra !== null ? secondArrayItem[extra] : secondArrayItem);
    });
  });
}

/**
 * replace all strings
 * @param {string} str
 * @param {string} find
 * @param {string} replace
 * @returns {string}
 */
function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
}

/**
 * validate cpf
 * @param {string} cpf
 * @returns {boolean}
 */
function validateCPF(cpf) {
  if (!cpf || cpf.length < 11) return false;
  var strCPF = replaceAll(cpf, '.', '');
  strCPF = replaceAll(strCPF, '-', '');
  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF === '00000000000') return false;
  if (strCPF === '11111111111') return false;
  if (strCPF === '22222222222') return false;
  if (strCPF === '33333333333') return false;
  if (strCPF === '44444444444') return false;
  if (strCPF === '55555555555') return false;
  if (strCPF === '66666666666') return false;
  if (strCPF === '77777777777') return false;
  if (strCPF === '88888888888') return false;
  if (strCPF === '99999999999') return false;
  for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = Soma * 10 % 11;
  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;
  Soma = 0;
  for (var _i = 1; _i <= 10; _i++) Soma = Soma + parseInt(strCPF.substring(_i - 1, _i)) * (12 - _i);
  Resto = Soma * 10 % 11;
  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

/**
 * validate cnpj
 * @param {string} s
 * @returns {boolean}
 */
function validateCNPJ(s) {
  var cnpj = s.replace(/[^\d]+/g, '');
  // Valida a quantidade de caracteres
  if (cnpj.length !== 14) return false;
  // Elimina inválidos com todos os caracteres iguais
  if (/^(\d)\1+$/.test(cnpj)) return false;
  // Cáculo de validação
  var t = cnpj.length - 2;
  var d = cnpj.substring(t);
  var d1 = parseInt(d.charAt(0));
  var d2 = parseInt(d.charAt(1));
  var calc = function calc(x) {
    var n = cnpj.substring(0, x);
    var y = x - 7;
    var s = 0;
    var r = 0;
    for (var i = x; i >= 1; i--) {
      s += n.charAt(x - i) * y--;
      if (y < 2) y = 9;
    }
    r = 11 - s % 11;
    return r > 9 ? 0 : r;
  };
  return (calc(t) === d1 && calc(t + 1)) === d2;
}

/**
 * validate cnpj or cpf
 * @param {string} text
 * @returns {boolean}
 */
function validateCNPJorCPF(text) {
  if (validateCPF(text) && text.length <= 11) return validateCPF(text);
  if (validateCNPJ(text)) return validateCNPJ(text);
  return false;
}

/**
 * validate email
 * @param {string} email
 * @returns {boolean}
 */
function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/**
 * calc
 * @param {number} num
 * @returns {boolean}
 */
function calc(num) {
  return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
}

/**
 * calc
 * @param {string} date
 * @returns {boolean}
 */
function validateDateForm(date) {
  if (date.length >= 8) {
    var currentDate = (0, _moment.default)();
    var d = (0, _format.transformDate)(date);
    var dd = d.split('/');
    var formatDate = "".concat(dd[2], "-").concat(dd[1], "-").concat(dd[0]);
    var valid = (0, _moment.default)(formatDate, 'YYYY-MM-DD', true);
    var compareDaysDiff = currentDate.diff(valid, 'days');
    return valid.isValid() && compareDaysDiff <= 720;
  }
  if (date.length <= 0) return true;
  return false;
}

/**
 * calc
 * @param {string} date
 * @returns {boolean}
 */
function validateDate(date) {
  if (date.length >= 8) {
    var currentDate = (0, _moment.default)();
    var d = (0, _format.transformDate)(date);
    var dd = d.split('/');
    var formatDate = "".concat(dd[2], "-").concat(dd[1], "-").concat(dd[0]);
    var valid = (0, _moment.default)(formatDate, 'YYYY-MM-DD', true);
    var compareDaysDiff = currentDate.diff(valid, 'days');
    return valid.isValid() && !valid.isSameOrAfter(currentDate) && compareDaysDiff <= 620;
  }
  return false;
}

/**
 * Validade DAte
 * @param {string} date
 * @returns {boolean}
 */
function validateDateGeneral(date) {
  if (date && date.length >= 8) {
    var currentDate = (0, _moment.default)();
    var d = (0, _format.transformDate)(date);
    var dd = d.split('/');
    var formatDate = "".concat(dd[2], "-").concat(dd[1], "-").concat(dd[0]);
    var valid = (0, _moment.default)(formatDate, 'YYYY-MM-DD', true);
    return valid.isValid() && !valid.isSameOrAfter(currentDate);
  }
  return false;
}

/**
 * convertDateISO
 * @param {string} date
 * @returns {date}
 */
function convertDateISO(date) {
  var d = (0, _format.transformDate)(date);
  var dd = d.split('/');
  var formatDate = "".concat(dd[2], "-").concat(dd[1], "-").concat(dd[0]);
  var dateFormated = (0, _moment.default)(formatDate, 'YYYY-MM-DD', true);
  return dateFormated.toISOString();
}

/**
 * convertDate
 * @param {string} date
 * @returns {date}
 */
function convertDate(date) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DD/MM/YYYY';
  if (date) return (0, _moment.default)(date).format(format);
  return date;
}

/**
 * delete especial caracters
 * @param {string} text
 * @returns {string}
 */
function removeAccents(text) {
  return text.replace(/[áàãâä]/gi, 'a').replace(/[éè¨ê]/gi, 'e').replace(/[íìïî]/gi, 'i').replace(/[óòöôõ]/gi, 'o').replace(/[úùüû]/gi, 'u').replace(/[ç]/gi, 'c').replace(/[ñ]/gi, 'n').replace(/[^a-zA-Z0-9]/g, ' ');
}