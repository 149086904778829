"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueTheMask = require("vue-the-mask");
var _default2 = exports.default = {
  name: 'CardAmount',
  components: {
    TheMask: _vueTheMask.TheMask
  },
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    action: {
      type: Function,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      newValue: this.value
    };
  },
  computed: {
    computedValue: {
      get: function get() {
        return this.newValue;
      },
      set: function set(value) {
        this.newValue = value;
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value: function value(_value) {
      this.newValue = _value;
    }
  },
  methods: {
    onInput: function onInput(event) {
      var _this = this;
      this.$nextTick(function () {
        if (event.target) {
          _this.computedValue = event.target.value;
        }
      });
    },
    actionItem: function actionItem() {
      this.$emit('action');
    }
  }
};