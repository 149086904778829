"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-content",
    staticStyle: {
      width: "598px"
    }
  }, [_c("div", {
    staticClass: "card modal-team-list-form"
  }, [_c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "media"
  }, [_c("div", {
    staticClass: "media-content"
  }, [_c("button", {
    staticClass: "btn-x-close",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.cancelModal
    }
  }, [_c("i", {
    staticClass: "icon-ic_close"
  })]), _vm._v(" "), _c("p", {
    staticClass: "title"
  }, [_vm._v("\n            Novo funcionário\n          ")])])]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("form", {
    attrs: {
      name: "form-user",
      autocomplete: "off"
    },
    on: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.submitFormUser.apply(null, arguments);
      }
    }
  }, [_c("b-field", {
    staticClass: "file",
    attrs: {
      label: "Foto"
    }
  }), _vm._v(" "), _c("b-field", {
    staticClass: "photo-perfil"
  }, [_vm.userData.file ? _c("span", {
    staticClass: "file-image"
  }, [_vm.imageBase64 ? _c("img", {
    attrs: {
      src: _vm.imageBase64
    }
  }) : _vm._e()]) : _vm._e(), _vm._v(" "), !_vm.userData.file && _vm.imageUrl ? _c("span", {
    staticClass: "file-image"
  }, [_c("img", {
    attrs: {
      src: _vm.imageUrl
    }
  })]) : _vm._e(), _vm._v(" "), _vm.userData.file || _vm.imageUrl ? _c("a", {
    staticClass: "button btn-remove-photo",
    on: {
      click: _vm.removePhoto
    }
  }, [_c("span", [_vm._v("\n                Remover foto\n              ")])]) : _vm._e(), _vm._v(" "), !_vm.userData.file && !_vm.imageUrl ? _c("b-upload", {
    model: {
      value: _vm.userData.file,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "file", $$v);
      },
      expression: "userData.file"
    }
  }, [_c("span", {
    staticClass: "btn-description-upload"
  }, [_c("i", {
    staticClass: "icon-ic_plus"
  }), _vm._v("\n                Selecione uma foto de perfil para o funcionário.\n              ")])]) : _vm._e()], 1), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.userData.cpf.$error,
      label: "CPF",
      mask: ["###.###.###-##"]
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.userData.cpf, "$error", $event);
      },
      blur: _vm.verifyUser
    },
    model: {
      value: _vm.$v.userData.cpf.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.userData.cpf, "$model", $$v);
      },
      expression: "$v.userData.cpf.$model"
    }
  }), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.userData.firstName.$error,
      label: "Primeiro Nome"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.userData.firstName, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.userData.firstName.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.userData.firstName, "$model", $$v);
      },
      expression: "$v.userData.firstName.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.userData.lastName.$error,
      label: "Sobrenome"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.userData.lastName, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.userData.lastName.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.userData.lastName, "$model", $$v);
      },
      expression: "$v.userData.lastName.$model"
    }
  })], 1), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.userData.birthdate.$error,
      label: "Data de nascimento",
      mask: ["##/##/####"],
      masked: true
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.userData.birthdate, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.userData.birthdate.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.userData.birthdate, "$model", $$v);
      },
      expression: "$v.userData.birthdate.$model"
    }
  }), _vm._v(" "), _vm.showPassword ? _c("div", [_c("app-input", {
    attrs: {
      id: "password",
      type: "password",
      name: "pswd",
      autocomplete: "new-password",
      error: _vm.$v.userData.password.$error,
      label: "Senha",
      "password-reveal": true
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.userData.password, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.userData.password.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.userData.password, "$model", $$v);
      },
      expression: "$v.userData.password.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    staticClass: "marginbt",
    attrs: {
      id: "ConfirmPassword",
      type: "password",
      name: "newPswd",
      autocomplete: "new-password",
      error: _vm.$v.userData.passwordConfirm.$error,
      "password-reveal": true,
      label: "Confirmar senha"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.userData.passwordConfirm, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.userData.passwordConfirm.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.userData.passwordConfirm, "$model", $$v);
      },
      expression: "$v.userData.passwordConfirm.$model"
    }
  })], 1) : _c("div", {
    staticClass: "change-password-question"
  }, [_c("b-button", {
    attrs: {
      type: "is-text",
      "icon-left": "shield-lock"
    },
    on: {
      click: function click($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    }
  }, [_vm._v("\n              Alterar senha?\n            ")])], 1), _vm._v(" "), _vm.listManagers.length ? _c("app-select", {
    attrs: {
      label: "Gestor Responsável",
      placeholder: "Selecione o gestor responsável",
      error: _vm.$v.userData.manager.$error,
      items: _vm.listManagers,
      option: "id",
      "name-item": "firstName",
      "second-item": "lastName",
      "show-cancel": true,
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.userData.manager, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.userData.manager.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.userData.manager, "$model", $$v);
      },
      expression: "$v.userData.manager.$model"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.userEdit.role ? _c("app-select", {
    staticClass: "field-uf",
    attrs: {
      label: "Função principal",
      placeholder: "Selecione",
      error: _vm.$v.userData.role.$error,
      items: _vm.roles,
      option: "value",
      "name-item": "description",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.userData.role, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.userData.role.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.userData.role, "$model", $$v);
      },
      expression: "$v.userData.role.$model"
    }
  }) : _vm._e(), _vm._v(" "), _vm.userData.role === "Operator" ? _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("app-select", {
    staticClass: "field-uf",
    attrs: {
      label: "Temporário",
      placeholder: "Selecione",
      error: _vm.$v.userData.temporary.$error,
      items: _vm.listTemporay,
      option: "value",
      "name-item": "description",
      "show-cancel": true,
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.userData.temporary, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.userData.temporary.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.userData.temporary, "$model", $$v);
      },
      expression: "$v.userData.temporary.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.errortemporaryExpirationDate,
      label: "Acesso válido até",
      mask: ["##/##/####"],
      masked: true,
      disabled: !_vm.$v.userData.temporary.$model
    },
    on: {
      "update:error": function updateError($event) {
        _vm.errortemporaryExpirationDate = $event;
      }
    },
    model: {
      value: _vm.$v.userData.temporaryExpirationDate.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.userData.temporaryExpirationDate, "$model", $$v);
      },
      expression: "$v.userData.temporaryExpirationDate.$model"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("app-select", {
    staticClass: "field-uf",
    attrs: {
      label: "Escolaridade",
      placeholder: "Selecionar",
      error: _vm.$v.userData.scholarship.$error,
      items: _vm.schoolings,
      option: "value",
      "name-item": "description",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.userData.scholarship, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.userData.scholarship.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.userData.scholarship, "$model", $$v);
      },
      expression: "$v.userData.scholarship.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.userData.email.$error,
      type: "email",
      label: "E-mail",
      disabled: _vm.isAdmin
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.userData.email, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.userData.email.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.userData.email, "$model", $$v);
      },
      expression: "$v.userData.email.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.userData.phone.$error,
      label: "Telefone",
      mask: ["(##) #####-####"],
      autocomplete: "off"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.userData.phone, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.userData.phone.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.userData.phone, "$model", $$v);
      },
      expression: "$v.userData.phone.$model"
    }
  }), _vm._v(" "), _c("b-field", {
    staticClass: "row-save-form"
  }, [_c("b-button", {
    staticClass: "btn-save-form",
    attrs: {
      "native-type": "submit"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.userData.id ? "Gravar" : "Criar") + "\n            ")])], 1)], 1)])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;