"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("form", {
    staticClass: "component-new-items-detail-area",
    on: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_c("div", {
    staticClass: "modal-card",
    staticStyle: {
      width: "1000px"
    }
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.identifiers.title_list")) + "\n      ")]), _vm._v(" "), _c("b-button", {
    staticClass: "modal-close-x",
    attrs: {
      "icon-right": "close"
    },
    on: {
      click: _vm.closeModal
    }
  })], 1), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_vm.identifiersList.data && _vm.identifiersList.data.length ? _c("div", {
    staticClass: "content-list-items"
  }, [_c("div", {
    staticClass: "field field-actions-checks"
  }, [_c("b-checkbox", {
    staticClass: "b-checkbox cb-filter",
    model: {
      value: _vm.checkAll,
      callback: function callback($$v) {
        _vm.checkAll = $$v;
      },
      expression: "checkAll"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.identifiers.select_all")) + "\n          ")]), _vm._v(" "), _c("b-button", {
    staticClass: "btn-deselect",
    on: {
      click: function click($event) {
        return _vm.allCheckedOption("markOff");
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.identifiers.clear_all")) + "\n          ")]), _vm._v(" "), _c("div", [_c("tr", [_c("b-checkbox", {
    staticClass: "b-checkbox cb-filter",
    model: {
      value: _vm.checkIndividual,
      callback: function callback($$v) {
        _vm.checkIndividual = $$v;
      },
      expression: "checkIndividual"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.$t("pages.identifiers.cards.qrcode.individualtag")) + "\n              ")])], 1), _vm._v(" "), _c("tr", [_c("b-checkbox", {
    staticClass: "b-checkbox cb-filter",
    model: {
      value: _vm.checkFlag,
      callback: function callback($$v) {
        _vm.checkFlag = $$v;
      },
      expression: "checkFlag"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.$t("pages.identifiers.cards.qrcode.flagtag")) + "\n              ")])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "display-right"
  }, [false ? _c("b-field", {
    staticClass: "field-search"
  }, [_c("b-select", {
    staticStyle: {
      margin: "5px"
    },
    attrs: {
      placeholder: "Filtro"
    },
    on: {
      change: function change($event) {
        return _vm.filterTags();
      }
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }, [_c("option", {
    attrs: {
      value: "Plot"
    }
  }, [_vm._v("\n                  Talhão\n                ")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "SubPlot"
    }
  }, [_vm._v("\n                  Sub Talhão\n                ")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "Row"
    }
  }, [_vm._v("\n                  Linha\n                ")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "Family"
    }
  }, [_vm._v("\n                  Família\n                ")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "Bunch"
    }
  }, [_vm._v("\n                  Cacho\n                ")])])], 1) : _vm._e(), _vm._v(" "), _c("p", [_vm._v("\n              " + _vm._s(_vm.identifiersList.total) + " " + _vm._s(_vm.$t("pages.identifiers.qr_codes_available")) + "\n            ")]), _vm._v(" "), _vm.listPrint && _vm.listPrint.length ? _c("b-button", {
    staticClass: "btn-print-action",
    on: {
      click: _vm.printItems
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t("pages.identifiers.download_all")) + "\n            ")]) : _vm._e()], 1)], 1)]) : _vm._e(), _vm._v(" "), _vm.listFiltered && _vm.listFiltered.length && !_vm.isLoading ? _c("list-item-check", {
    attrs: {
      "list-qr-codes": _vm.listFiltered,
      "list-print": _vm.listPrint,
      "check-option": _vm.allCheckedOption,
      "check-all": _vm.checkAll
    },
    on: {
      "update:listQrCodes": function updateListQrCodes($event) {
        _vm.listFiltered = $event;
      },
      "update:list-qr-codes": function updateListQrCodes($event) {
        _vm.listFiltered = $event;
      },
      "update:listPrint": function updateListPrint($event) {
        _vm.listPrint = $event;
      },
      "update:list-print": function updateListPrint($event) {
        _vm.listPrint = $event;
      },
      "update:checkAll": function updateCheckAll($event) {
        _vm.checkAll = $event;
      },
      "update:check-all": function updateCheckAll($event) {
        _vm.checkAll = $event;
      }
    }
  }) : _c("no-record-found", {
    attrs: {
      title: "Resultados não encontrados",
      description: "Sem registros para a página selecionada"
    }
  }), _vm._v(" "), _vm.identifiersList.total > _vm.perPage ? _c("b-pagination", {
    attrs: {
      total: _vm.identifiersList.total,
      current: _vm.pageCurrent,
      "range-before": _vm.rangeBefore,
      "range-after": _vm.rangeAfter,
      "per-page": _vm.perPage,
      "icon-prev": _vm.prevIcon,
      "icon-next": _vm.nextIcon,
      "aria-next-label": "Próxima página",
      "aria-previous-label": "Página anterior",
      "aria-page-label": "Página",
      "aria-current-label": "Pagina atual"
    },
    on: {
      "update:current": function updateCurrent($event) {
        _vm.pageCurrent = $event;
      }
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("footer", {
    staticClass: "modal-card-foot"
  })])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;