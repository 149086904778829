import { render, staticRenderFns } from "./ListReportsTags.vue?vue&type=template&id=474f4944&"
import script from "./ListReportsTags.vue?vue&type=script&lang=js&"
export * from "./ListReportsTags.vue?vue&type=script&lang=js&"
import style0 from "./ListReportsTags.vue?vue&type=style&index=0&id=474f4944&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('474f4944')) {
      api.createRecord('474f4944', component.options)
    } else {
      api.reload('474f4944', component.options)
    }
    module.hot.accept("./ListReportsTags.vue?vue&type=template&id=474f4944&", function () {
      api.rerender('474f4944', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/flow/identifiers/components/ListReportsTags.vue"
export default component.exports