"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "modal-new-service",
    attrs: {
      active: true,
      width: 598,
      scroll: "keep",
      "can-cancel": ["escape"],
      "on-cancel": _vm.cancelModal
    }
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "media"
  }, [_c("div", {
    staticClass: "media-content"
  }, [_c("button", {
    staticClass: "btn-x-close",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.cancelModal
    }
  }, [_c("i", {
    staticClass: "icon-ic_close"
  })]), _vm._v(" "), _c("p", {
    staticClass: "title"
  }, [_vm._v("\n            " + _vm._s(_vm.title) + "\n          ")])])]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("form", [_c("b-field", {
    attrs: {
      label: "Nome do serviço",
      type: _vm.errors.includes("name") ? "is-danger" : ""
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "Nome"
    },
    model: {
      value: _vm.newServiceModel.name,
      callback: function callback($$v) {
        _vm.$set(_vm.newServiceModel, "name", $$v);
      },
      expression: "newServiceModel.name"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Descrição",
      type: _vm.errors.includes("description") ? "is-danger" : ""
    }
  }, [_c("b-input", {
    attrs: {
      type: "textarea",
      minlength: "10",
      maxlength: "300",
      placeholder: "Descreva de forma breve sobre o serviço"
    },
    model: {
      value: _vm.newServiceModel.description,
      callback: function callback($$v) {
        _vm.$set(_vm.newServiceModel, "description", $$v);
      },
      expression: "newServiceModel.description"
    }
  })], 1), _vm._v(" "), _vm.activities.length > 0 ? _c("b-field", {
    staticClass: "field-successor",
    attrs: {
      horizontal: "",
      label: "Serviço sucessor",
      type: _vm.errors.includes("successor") ? "is-danger" : ""
    }
  }, [_c("b-select", {
    attrs: {
      placeholder: "Serviço sucessor"
    },
    model: {
      value: _vm.newServiceModel.followUpTypeId,
      callback: function callback($$v) {
        _vm.$set(_vm.newServiceModel, "followUpTypeId", $$v);
      },
      expression: "newServiceModel.followUpTypeId"
    }
  }, [_c("option", {
    attrs: {
      value: ""
    }
  }, [_vm._v("\n                Selecionar\n              ")]), _vm._v(" "), _vm._l(_vm.activities, function (option, index) {
    return _c("option", {
      key: index,
      domProps: {
        value: option.id
      }
    }, [_vm._v("\n                " + _vm._s(option.name) + "\n              ")]);
  })], 2)], 1) : _vm._e(), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Prazo para o agendamento do serviço sucessor"
    }
  }), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    staticClass: "field-amount",
    attrs: {
      horizontal: "",
      label: "Quantidade",
      type: _vm.errors.includes("quantity") ? "is-danger" : ""
    }
  }, [_c("the-mask", {
    staticClass: "input",
    attrs: {
      autocomplete: "off",
      placeholder: "00",
      mask: ["###"]
    },
    model: {
      value: _vm.newServiceModel.followUpTimeValue,
      callback: function callback($$v) {
        _vm.$set(_vm.newServiceModel, "followUpTimeValue", $$v);
      },
      expression: "newServiceModel.followUpTimeValue"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "field-type-deadline",
    attrs: {
      horizontal: "",
      label: "Tipo de prazo",
      type: _vm.errors.includes("deadline") ? "is-danger" : ""
    }
  }, [_c("b-select", {
    attrs: {
      placeholder: "Selecionar"
    },
    model: {
      value: _vm.newServiceModel.followUpTimeUnit,
      callback: function callback($$v) {
        _vm.$set(_vm.newServiceModel, "followUpTimeUnit", $$v);
      },
      expression: "newServiceModel.followUpTimeUnit"
    }
  }, [_c("option", {
    attrs: {
      value: ""
    }
  }, [_vm._v("\n                  Selecionar\n                ")]), _vm._v(" "), _vm._l(_vm.deadlines, function (option, index) {
    return _c("option", {
      key: index,
      domProps: {
        value: option.value
      }
    }, [_vm._v("\n                  " + _vm._s(option.description) + "\n                ")]);
  })], 2)], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Ferramenta/Insumo Vinculado"
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "Produto vinculado (Ex.: Facão/Enxada/Adubo)"
    },
    model: {
      value: _vm.newServiceModel.tool,
      callback: function callback($$v) {
        _vm.$set(_vm.newServiceModel, "tool", $$v);
      },
      expression: "newServiceModel.tool"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("\n              Produto(cultivo) Vinculado "), _c("span", [_vm._v("(caso não informe um cultivo será vinculado a todos)")])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "col is-half"
  }, [_c("draggable", {
    staticClass: "list-group",
    attrs: {
      list: _vm.products,
      group: "products"
    }
  }, _vm._l(_vm.products, function (element, index) {
    return _c("div", {
      key: index,
      staticClass: "list-group-item"
    }, [_vm._v("\n                    " + _vm._s(element.name) + "\n                  ")]);
  }), 0)], 1), _vm._v(" "), _c("div", {
    staticClass: "col is-half"
  }, [_c("draggable", {
    staticClass: "list-group",
    attrs: {
      list: _vm.newServiceModel.products,
      group: "products"
    }
  }, _vm._l(_vm.newServiceModel.products, function (element, index) {
    return _c("div", {
      key: index,
      staticClass: "list-group-item"
    }, [_vm._v("\n                    " + _vm._s(element.name) + "\n                  ")]);
  }), 0)], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "field"
  }, [_c("label", {
    staticClass: "label"
  }, [_vm._v("\n              Fazenda Vinculada "), _c("span", [_vm._v("(caso não informe um cultivo será vinculado a todos)")])]), _vm._v(" "), _c("autocomplete", {
    attrs: {
      data: _vm.farms,
      name: "name",
      complement: "state",
      "text-empty": "Fazenda não encontrada",
      fetch: _vm.getAsyncFarm,
      "on-insert": _vm.onInsertFarm
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "list-farms"
  }, _vm._l(_vm.newServiceModel.farms, function (farm, idx) {
    return _c("div", {
      key: idx,
      staticClass: "list-item"
    }, [_vm._v("\n                " + _vm._s(farm.name) + "\n                "), _c("button", {
      staticClass: "button btn-delete",
      attrs: {
        type: "button"
      },
      on: {
        click: function click($event) {
          return _vm.onInsertFarm(farm);
        }
      }
    }, [_c("i", {
      staticClass: "icon-ic_close"
    })])]);
  }), 0)], 1), _vm._v(" "), _vm.isEdit ? _c("b-field", {
    attrs: {
      label: "Passos para execução do serviço:"
    }
  }, [_c("div", {
    staticClass: "section-create-new-step-type"
  }, [_c("b-button", {
    attrs: {
      type: "is-warning"
    },
    on: {
      click: _vm.openCreateNewStep
    }
  }, [_vm._v("\n                Criar novo passo ?\n              ")])], 1), _vm._v(" "), _vm._l(_vm.listStepTypes, function (collapse, index) {
    return _c("b-collapse", {
      key: index,
      staticClass: "card card-collapse-cfs",
      attrs: {
        animation: "slide",
        open: _vm.isOpen == index,
        "aria-id": "contentIdForA11y5-" + index
      },
      on: {
        open: function open($event) {
          _vm.isOpen = index;
        }
      },
      scopedSlots: _vm._u([{
        key: "trigger",
        fn: function fn(props) {
          return [_c("div", {
            staticClass: "card-header",
            attrs: {
              role: "button",
              "aria-controls": "contentIdForA11y5-" + index,
              "aria-expanded": props.open
            }
          }, [_c("p", {
            staticClass: "card-header-title"
          }, [_vm._v("\n                    " + _vm._s(collapse.name) + "\n                  ")]), _vm._v(" "), _c("a", {
            staticClass: "card-header-icon"
          }, [_c("b-button", {
            attrs: {
              type: "is-danger",
              "icon-right": "delete"
            },
            on: {
              click: function click($event) {
                return _vm.confirmDeleteStep(collapse);
              }
            }
          }), _vm._v(" "), _c("b-icon", {
            attrs: {
              icon: props.open ? "menu-down" : "menu-up"
            }
          })], 1)])];
        }
      }], null, true)
    }, [_vm._v(" "), _c("div", {
      staticClass: "card-content"
    }, [_c("div", {
      staticClass: "content"
    }, [_c("b-field", {
      attrs: {
        label: "Adicionar novos campos"
      }
    }, [_c("b-taginput", {
      attrs: {
        data: _vm.listCustomFields,
        autocomplete: "",
        field: "label",
        icon: "label",
        placeholder: "Adicionar novo campo"
      },
      on: {
        typing: _vm.getAsyncCustonFields
      },
      model: {
        value: _vm.selectedCustomFields,
        callback: function callback($$v) {
          _vm.selectedCustomFields = $$v;
        },
        expression: "selectedCustomFields"
      }
    })], 1), _vm._v(" "), _c("div", {
      staticClass: "columns is-mobile is-multiline list-services-steps-types"
    }, _vm._l(collapse.customField, function (itemCF, idxCF) {
      return _c("div", {
        key: "cf-" + idxCF,
        staticClass: "column is-full"
      }, [_c("b-tag", {
        attrs: {
          type: "is-primary",
          closable: "",
          "aria-close-label": "Desvincular campo"
        },
        on: {
          close: function close($event) {
            return _vm.confirmUnlinkCustomField(itemCF, collapse);
          }
        }
      }, [_c("div", [_c("b", [_vm._v("Título:")]), _vm._v(" " + _vm._s(itemCF.label) + " |\n                        ")]), _vm._v(" "), _c("div", [_c("b", [_vm._v("ID:")]), _vm._v(" " + _vm._s(itemCF.id) + " |\n                        ")]), _vm._v(" "), _c("div", [_c("b", [_vm._v("Name:")]), _vm._v(" " + _vm._s(itemCF.name) + "\n                        ")])])], 1);
    }), 0), _vm._v(" "), _vm.CFjoineds.length ? _c("div", [_c("b-button", {
      attrs: {
        type: "is-success"
      },
      on: {
        click: function click($event) {
          return _vm.linkCustomFields(collapse);
        }
      }
    }, [_vm._v("\n                      Vincular CustomFields\n                    ")])], 1) : _vm._e()], 1)])]);
  })], 2) : _vm._e(), _vm._v(" "), _c("b-field", {
    staticClass: "row-save-form"
  }, [_c("b-button", {
    staticClass: "btn-save-form",
    on: {
      click: _vm.resolved
    }
  }, [_vm._v("\n              " + _vm._s(_vm.newServiceModel.id ? "Gravar" : "Criar") + "\n            ")])], 1)], 1)])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;