"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue2GoogleMaps = require("vue2-google-maps");
// DirectionsRenderer.js
var _default = (0, _vue2GoogleMaps.MapElementFactory)({
  name: 'directionsRenderer',
  ctr: function ctr() {
    return window.google.maps.DirectionsRenderer;
  },
  events: ['directions_changed'],
  mappedProps: {},
  props: {
    origin: {
      type: [Object, Array]
    },
    destination: {
      type: [Object, Array]
    },
    travelMode: {
      type: String
    }
  },
  beforeCreate: function beforeCreate(options) {},
  afterCreate: function afterCreate(directionsRenderer) {
    var _this = this;
    var directionsService = new window.google.maps.DirectionsService();
    this.$watch(function () {
      return [_this.origin, _this.destination, _this.travelMode];
    }, function () {
      var origin = _this.origin,
        destination = _this.destination,
        travelMode = _this.travelMode;
      if (!origin || !destination || !travelMode) return;
      directionsService.route({
        origin: origin,
        destination: destination,
        travelMode: travelMode
      }, function (response, status) {
        if (status !== 'OK') return;
        directionsRenderer.setDirections(response);
      });
    });
  }
});
exports.default = _default;