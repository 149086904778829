"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueChartjs = require("vue-chartjs");
var _chart = _interopRequireDefault(require("chart.js"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
_chart.default.defaults.global.legend.display = false;
var reactiveProp = _vueChartjs.mixins.reactiveProp;
var _default2 = exports.default = {
  extends: _vueChartjs.Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  mounted: function mounted() {
    this.renderChart(this.chartData, this.options);
  }
};