"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clientService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var clientService = {
  fetchClient: fetchClient,
  deleteClient: deleteClient,
  updateClient: updateClient,
  createClient: createClient
};
exports.clientService = clientService;
function fetchClient(farmId) {
  var path = "/client/".concat(farmId);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function deleteClient(id) {
  var path = "/client/".concat(id);
  // eslint-disable-next-line no-undef
  return _http.default.delete(path);
}
function updateClient(payload) {
  var path = "/client/".concat(payload.id);
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.put(path, data);
}
function createClient(payload) {
  var path = "/client";
  var data = {
    name: payload.name,
    farmId: payload.farmId,
    cnpj: payload.cnpj,
    stateRegistration: payload.stateRegistration,
    address: payload.address,
    postalCode: payload.postalCode,
    city: payload.city,
    state: payload.state
  };

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}