"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.identifierService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var identifierService = exports.identifierService = {
  fetchAll: fetchAll,
  generate: generate,
  printTags: printTags,
  downloadZip: downloadZip,
  fetchIdentifiersAssociated: fetchIdentifiersAssociated,
  getTagFamily: getTagFamily,
  generateQrCode: generateQrCode,
  downloadZipIdentifiers: downloadZipIdentifiers,
  generateTracker: generateTracker,
  downloadTracker: downloadTracker,
  fetchDownloadReportsTags: fetchDownloadReportsTags
};
function fetchAll(idFarm, page) {
  var path = "/farms/".concat(idFarm, "/identifiers");
  return _http.default.get(path, {
    params: {
      page: page,
      limit: 12
    }
  });
}
function getTagFamily(idAssociate, page, type, farmId) {
  var path = "/tags/".concat(idAssociate, "/family");
  return _http.default.get(path, {
    params: {
      page: page,
      limit: 12,
      type: type,
      farmId: farmId
    }
  });
}
function fetchIdentifiersAssociated(associatedLink, page) {
  var path = "/tags/".concat(associatedLink, "/link");
  return _http.default.get(path, {
    params: {
      page: page,
      limit: 12
    }
  });
}
function generate(idFarm, payload) {
  var path = "/tags/identifiers/".concat(idFarm, "/generate");
  var data = {
    amount: payload.amount,
    isFlag: payload.isFlag
  };
  return _http.default.post(path, data);
}
function generateQrCode(payload, params) {
  return (0, _http.default)({
    method: 'post',
    url: "/tags/generate",
    data: payload,
    params: params
  });
}
function generateTracker(batchId) {
  var path = "/tags/tracker/".concat(batchId);
  return _http.default.get(path, {
    responseType: 'buffer'
  });
}
function downloadTracker(batchId) {
  var path = "/tags/tracker/".concat(batchId);
  return _http.default.get(path, {
    responseType: 'blob'
  });
}
function printTags(data, idFarm) {
  var path = "/tags/".concat(idFarm, "/print/identifiers");
  return _http.default.post(path, data, {
    responseType: 'blob'
  });
}
function downloadZip(data) {
  return _http.default.post('/tags/download/zip', data, {
    responseType: 'blob'
  });
}
function downloadZipIdentifiers(data, idFarm) {
  return _http.default.post("/tags/identifiers/".concat(idFarm, "/download/"), data, {
    responseType: 'blob'
  });
}
function fetchDownloadReportsTags(idFarm) {
  return _http.default.get("/tags/identifiers/".concat(idFarm, "/reports/"));
}