
export default {
  name: 'NoRecordFound',
  props: {
    titleDestaq: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Não obtivemos resultados'
    },
    description: {
      type: String,
      default: ''
    }
  }
}
