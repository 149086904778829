"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    staticClass: "list-custom-fields-client-page",
    attrs: {
      "return-menu": "/farm-management/dashboard",
      menu: _vm.menuItemsServices.children,
      subtitle: "Serviços"
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h2", {
    staticClass: "header-title"
  }, [_vm._v("\n        Campos personalizados\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "search-bar"
  }, [_c("div", {
    staticClass: "field search-input"
  }, [_c("input-filter-modal", {
    attrs: {
      placeholder: "Pesquisar (Ex.: Serviços)"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "field-new-item"
  }, [_c("b-button", {
    on: {
      click: function click($event) {
        return _vm.openDetail();
      }
    }
  }, [_vm._v("\n            Novo custom field\n          ")])], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "table-items-list"
  }, [_c("b-table", {
    attrs: {
      data: _vm.list,
      loading: _vm.isLoading,
      paginated: "",
      total: _vm.total,
      "per-page": _vm.perPage,
      striped: true,
      hoverable: true,
      "backend-pagination": ""
    },
    on: {
      "page-change": _vm.onPageChange
    }
  }, [[_c("b-table-column", {
    attrs: {
      field: "id",
      label: "ID"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.id) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "label",
      label: "Título"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.label) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "name",
      label: "Nome"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.name) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "type",
      label: "Tipo"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.type) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      width: "40"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-dropdown", {
          attrs: {
            "aria-role": "list",
            label: "Editar",
            position: "is-top-left"
          }
        }, [_c("b-button", {
          staticClass: "btn-click-dots",
          attrs: {
            slot: "trigger",
            outlined: "",
            "icon-right": "dots-vertical"
          },
          slot: "trigger"
        }), _vm._v(" "), _c("b-dropdown-item", {
          attrs: {
            "aria-role": "listitem"
          },
          on: {
            click: function click($event) {
              return _vm.openDetail(props.row);
            }
          }
        }, [_vm._v("\n                Editar\n              ")]), _vm._v(" "), _c("b-dropdown-item", {
          attrs: {
            "aria-role": "listitem"
          },
          on: {
            click: function click($event) {
              return _vm.verifyDelete(props.row);
            }
          }
        }, [_vm._v("\n                Excluir\n              ")])], 1)];
      }
    }])
  })], _vm._v(" "), _c("template", {
    slot: "empty"
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-grey has-text-centered"
  }, [_c("p", [_c("b-icon", {
    attrs: {
      icon: "emoticon-sad",
      size: "is-large"
    }
  })], 1), _vm._v(" "), _c("p", [_vm._v("Sem resultados.")])])])])], 2)], 1)])], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;