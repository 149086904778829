"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "header-options"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("\n      " + _vm._s(_vm.farmName) + "\n    ")])]), _vm._v(" "), _c("div", {
    staticClass: "header-cancel"
  }, [_vm.verifyArea ? _c("b-button", {
    attrs: {
      type: "is-danger"
    },
    on: {
      click: _vm.confirmCancel
    }
  }, [_vm._v("\n      Cancelar Assinatura\n    ")]) : _vm._e(), _vm._v(" "), !_vm.showUpdateSignature ? _c("b-button", {
    attrs: {
      type: "is-primary"
    },
    on: {
      click: _vm.updateSignature
    }
  }, [_vm._v("\n      Atualizar Assinatura\n    ")]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "icon-back"
  }, [_c("span", {
    on: {
      click: function click($event) {
        return _vm.goToBack();
      }
    }
  }, [_c("i", {
    staticClass: "icon-ic_voltar"
  }), _vm._v(" Voltar\n    ")])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;