"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "modal-custom-field",
    attrs: {
      width: 650,
      active: true,
      scroll: "keep"
    }
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "media"
  }, [_c("div", {
    staticClass: "media-content"
  }, [_c("button", {
    staticClass: "btn-x-close",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.cancelModal
    }
  }, [_c("i", {
    staticClass: "icon-ic_close"
  })]), _vm._v(" "), _c("p", {
    staticClass: "title"
  }, [_vm._v("\n            " + _vm._s(_vm.title) + _vm._s(_vm.item.register) + "\n          ")])])]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("form", [_c("b-field", {
    attrs: {
      label: "Data de criação do Romaneio"
    }
  }, [_c("b-datepicker", {
    attrs: {
      "show-week-number": true,
      locale: "pt_bR",
      placeholder: "Click to select...",
      icon: "calendar-today",
      "icon-right": _vm.dateCreation ? "close-circle" : "",
      "icon-right-clickable": "",
      "trap-focus": ""
    },
    model: {
      value: _vm.dateCreation,
      callback: function callback($$v) {
        _vm.dateCreation = $$v;
      },
      expression: "dateCreation"
    }
  })], 1), _vm._v(" "), _vm.item.plot ? _c("b-field", {
    attrs: {
      label: "Local"
    }
  }, _vm._l(_vm.item.plot, function (local, idx) {
    return _c("div", {
      key: idx
    }, [_c("app-input", {
      attrs: {
        placeholder: "Local"
      },
      model: {
        value: _vm.form.plot[idx],
        callback: function callback($$v) {
          _vm.$set(_vm.form.plot, idx, $$v);
        },
        expression: "form.plot[idx]"
      }
    })], 1);
  }), 0) : _vm._e(), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Cliente(Comprador)",
      placeholder: "cliente",
      error: _vm.$v.form.clientId.$error,
      items: _vm.listCustomers,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.clientId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.clientId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.clientId, "$model", $$v);
      },
      expression: "$v.form.clientId.$model"
    }
  }), _vm._v(" "), _c("b-button", {
    staticClass: "btn-add-field",
    on: {
      click: _vm.openModalNewCustomer
    }
  }, [_vm._v("\n              Novo cliente\n            ")])], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Serviço vinculado"
    }
  }, [_c("b-autocomplete", {
    attrs: {
      data: _vm.listServices,
      placeholder: "Busque o serviço vinculado",
      field: "title",
      loading: _vm.isFetching
    },
    on: {
      typing: _vm.getAsyncData,
      select: function select(option) {
        return _vm.selectService(option);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("div", {
          staticClass: "media"
        }, [_c("div", {
          staticClass: "media-content"
        }, [_vm._v("\n                    " + _vm._s(props.option.name) + "\n                    "), _c("br"), _vm._v(" "), _c("small", [_vm._v("\n                      Data: " + _vm._s(_vm._f("dateCompleteNoTZ")(props.option.dueDate)) + "\n                    ")]), _vm._v(" "), _c("br"), _vm._v("\n                    Feito por:\n                    "), _c("br"), _vm._v(" "), _vm._l(props.option.workers, function (itemWK, idxc) {
          return _c("small", {
            key: idxc,
            staticClass: "item-user-worked"
          }, [_vm._v("\n                      " + _vm._s(itemWK.firstName) + _vm._s(itemWK.lastName) + "\n                    ")]);
        })], 2)])];
      }
    }])
  })], 1), _vm._v(" "), _vm.selectedService.id ? _c("div", {
    staticClass: "content-service-selected"
  }, [_c("b", [_vm._v("Serviço vinculado:")]), _vm._v(" " + _vm._s(_vm.selectedService.name)), _c("br"), _vm._v(" "), _c("b", [_vm._v("Data:")]), _vm._v(" " + _vm._s(_vm._f("dateComplete")(_vm.selectedService.dueDate))), _c("br"), _vm._v(" "), _c("b", [_vm._v("Feito por:")]), _c("br"), _vm._v(" "), _vm._l(_vm.selectedService.workers, function (itemWKK, idx) {
    return _c("small", {
      key: idx,
      staticClass: "item-user-worked"
    }, [_vm._v("\n              " + _vm._s(itemWKK.firstName) + _vm._s(itemWKK.lastName) + "\n            ")]);
  }), _vm._v(" "), _c("div", {
    staticClass: "content-data-steps"
  }, [_c("b", [_vm._v("Dados Informados:")]), _c("br"), _vm._v(" "), _vm._l(_vm.stepsFinishedsData, function (itemStep, idxx) {
    var _itemStep$customField;
    return _c("div", {
      key: idxx
    }, [_c("h5", [_vm._v(_vm._s(((_itemStep$customField = itemStep.customField) === null || _itemStep$customField === void 0 ? void 0 : _itemStep$customField.label) || ""))]), _vm._v(" "), _c("p", [_vm._v(_vm._s(itemStep.customFieldValue))])]);
  })], 2)], 2) : _vm._e(), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: "",
      label: "Tipo de pagamento"
    }
  }, [_c("div", {
    staticClass: "block"
  }, _vm._l(_vm.listTypePayments, function (itemTP, idx) {
    return _c("b-radio", {
      key: idx,
      attrs: {
        "native-value": itemTP.id,
        name: "tipoPayment"
      },
      model: {
        value: _vm.$v.form.paymentConditions.$model,
        callback: function callback($$v) {
          _vm.$set(_vm.$v.form.paymentConditions, "$model", $$v);
        },
        expression: "$v.form.paymentConditions.$model"
      }
    }, [_vm._v("\n                " + _vm._s(itemTP.description) + "\n              ")]);
  }), 1)]), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("app-input", {
    attrs: {
      label: "Data da Colheita",
      expanded: "",
      mask: ["##/##/####"],
      masked: true,
      placeholder: "DD/MM/AAAA"
    },
    model: {
      value: _vm.$v.form.harvestDate.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.harvestDate, "$model", $$v);
      },
      expression: "$v.form.harvestDate.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Identificação interna",
      expanded: ""
    },
    model: {
      value: _vm.$v.form.identificationDocument.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.identificationDocument, "$model", $$v);
      },
      expression: "$v.form.identificationDocument.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "content-products"
  }, [_c("b-field", {
    attrs: {
      label: "Produtos",
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Produto",
      placeholder: "Selecione o produto",
      error: _vm.$v.formSell.productId.$error,
      items: _vm.listProducts,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.formSell.productId, "$error", $event);
      }
    },
    model: {
      value: _vm.productId,
      callback: function callback($$v) {
        _vm.productId = $$v;
      },
      expression: "productId"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Variedade",
      placeholder: "Selecione a variedade",
      error: _vm.$v.formSell.varietyId.$error,
      items: _vm.listVarieties,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.formSell.varietyId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.formSell.varietyId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formSell.varietyId, "$model", $$v);
      },
      expression: "$v.formSell.varietyId.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("app-select", {
    attrs: {
      label: "Cultivar",
      placeholder: "Selecione o cultivo",
      error: _vm.$v.formSell.cultivationId.$error,
      items: _vm.listCultivations,
      option: "id",
      "name-item": "name",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.formSell.cultivationId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.formSell.cultivationId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formSell.cultivationId, "$model", $$v);
      },
      expression: "$v.formSell.cultivationId.$model"
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "Caixas",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Informe a quantidade de caixas",
      type: "text",
      id: "uname",
      expanded: ""
    },
    model: {
      value: _vm.$v.formSell.qtd.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formSell.qtd, "$model", $$v);
      },
      expression: "$v.formSell.qtd.$model"
    }
  }, "money", _vm.money, false))], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Valor de desconto",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Informe o valor do desconto",
      prefix: "R$ ",
      type: "text",
      id: "uname",
      expanded: ""
    },
    model: {
      value: _vm.formSell.offValue,
      callback: function callback($$v) {
        _vm.$set(_vm.formSell, "offValue", $$v);
      },
      expression: "formSell.offValue"
    }
  }, "money", _vm.money, false))], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "Preço de 1ª",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Preço de 1ª",
      prefix: "R$ ",
      value: _vm.formSell.price1,
      type: "text",
      id: "uname",
      expanded: ""
    },
    model: {
      value: _vm.formSell.price1,
      callback: function callback($$v) {
        _vm.$set(_vm.formSell, "price1", $$v);
      },
      expression: "formSell.price1"
    }
  }, "money", _vm.money, false))], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Preço de 2ª",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Preço de 2ª",
      prefix: "R$ ",
      type: "text",
      id: "uname",
      expanded: ""
    },
    model: {
      value: _vm.formSell.price2,
      callback: function callback($$v) {
        _vm.$set(_vm.formSell, "price2", $$v);
      },
      expression: "formSell.price2"
    }
  }, "money", _vm.money, false))], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Preço de 3ª",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Preço de 3ª",
      prefix: "R$ ",
      type: "text",
      id: "uname",
      expanded: ""
    },
    model: {
      value: _vm.formSell.price3,
      callback: function callback($$v) {
        _vm.$set(_vm.formSell, "price3", $$v);
      },
      expression: "formSell.price3"
    }
  }, "money", _vm.money, false))], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v("\n                  % de 1ª\n                  "), _c("b-tooltip", {
          attrs: {
            type: "is-dark",
            label: _vm.titleInputCaixas
          }
        }, [_c("b-icon", {
          attrs: {
            size: "is-small",
            icon: "help-circle-outline"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("app-input", {
    attrs: {
      placeholder: "Informe a auantidade de 1ª"
    },
    model: {
      value: _vm.formSell.percent1,
      callback: function callback($$v) {
        _vm.$set(_vm.formSell, "percent1", $$v);
      },
      expression: "formSell.percent1"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v("\n                  % de 2ª\n                  "), _c("b-tooltip", {
          attrs: {
            type: "is-dark",
            label: _vm.titleInputCaixas
          }
        }, [_c("b-icon", {
          attrs: {
            size: "is-small",
            icon: "help-circle-outline"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("app-input", {
    attrs: {
      placeholder: "Informe a auantidade de 2ª"
    },
    model: {
      value: _vm.formSell.percent2,
      callback: function callback($$v) {
        _vm.$set(_vm.formSell, "percent2", $$v);
      },
      expression: "formSell.percent2"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v("\n                  % de 3ª\n                  "), _c("b-tooltip", {
          attrs: {
            type: "is-dark",
            label: _vm.titleInputCaixas
          }
        }, [_c("b-icon", {
          attrs: {
            size: "is-small",
            icon: "help-circle-outline"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("app-input", {
    attrs: {
      placeholder: "Informe a auantidade de 3ª"
    },
    model: {
      value: _vm.formSell.percent3,
      callback: function callback($$v) {
        _vm.$set(_vm.formSell, "percent3", $$v);
      },
      expression: "formSell.percent3"
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "Valor de 1ª",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Valor de 1ª",
      prefix: "R$ ",
      value: _vm.price1,
      type: "text",
      id: "uname",
      expanded: "",
      disabled: ""
    }
  }, "money", _vm.money, false))], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Valor de 2ª",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "valor de 2ª",
      prefix: "R$ ",
      value: _vm.price2,
      type: "text",
      id: "uname",
      expanded: "",
      disabled: ""
    }
  }, "money", _vm.money, false))], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Valor de 3ª",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Valor 3ª",
      prefix: "R$ ",
      value: _vm.price3,
      type: "text",
      id: "uname",
      expanded: "",
      disabled: ""
    }
  }, "money", _vm.money, false))], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "Valor total R$",
      expanded: ""
    }
  }, [_c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Valor total da venda",
      prefix: "R$ ",
      value: _vm.total,
      type: "text",
      id: "uname",
      expanded: "",
      disabled: ""
    }
  }, "money", _vm.money, false))], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Kg",
      expanded: ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v("\n                  Kg Totais\n                  "), _c("b-tooltip", {
          attrs: {
            type: "is-dark",
            label: "Peso considerado da caixa: 22Kg"
          }
        }, [_c("b-icon", {
          attrs: {
            size: "is-small",
            icon: "help-circle-outline"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("money", _vm._b({
    staticClass: "input",
    attrs: {
      placeholder: "Informe o valor de 2ª",
      prefix: "Kg ",
      value: _vm.kg,
      type: "text",
      id: "uname",
      expanded: "",
      disabled: ""
    }
  }, "money", _vm.money, false))], 1)], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Observação"
    }
  }, [_c("b-input", {
    attrs: {
      type: "textarea",
      minlength: "10",
      maxlength: "1000",
      placeholder: "Gostaria de informar alguma nota"
    },
    model: {
      value: _vm.form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "note", $$v);
      },
      expression: "form.note"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Entrega (Se houver)",
      grouped: ""
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.address.$error,
      label: "Endereço",
      placeholder: "Informe o endereço",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.address, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.address.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.address, "$model", $$v);
      },
      expression: "$v.form.address.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.form.city.$error,
      label: "Cidade",
      placeholder: "Informe a cidade",
      expanded: ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.city, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.city.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.city, "$model", $$v);
      },
      expression: "$v.form.city.$model"
    }
  })], 1), _vm._v(" "), _c("b-field", {
    staticClass: "row-save-form"
  }, [_c("b-button", {
    staticClass: "btn-save-form",
    on: {
      click: _vm.resolved
    }
  }, [_vm._v("\n              Salvar\n            ")])], 1)], 1)])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;