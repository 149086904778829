"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'FlagQrCodeExample',
  data: function data() {
    return {
      qrCodeText: 'A00010010001000010000000101'
    };
  }
};