"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-import-tab-balance"
  }, [_c("div", {
    staticClass: "help-text"
  }, [_vm._v("\n    Os dados da balança vão importados de forma automática desde que o servidor esteja rodando em um computador onde a aplicação está rodando esteja na mesma rede de internet que balança.\n  ")]), _vm._v(" "), _c("div", {
    staticClass: "import-load-content"
  }, [!_vm.dataImport.total ? _c("h4", {
    staticClass: "no-load"
  }, [_vm._v("\n      Nenhum dado no momento\n    ")]) : _c("div", {
    staticClass: "list"
  }, [_c("h4", {
    staticClass: "no-load"
  }, [_vm._v("\n        " + _vm._s(_vm.dataImport.total) + " registro" + _vm._s(_vm.dataImport.total > 1 ? "s" : "") + " importados\n      ")])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;