"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var productService = exports.productService = {
  fetchProducts: fetchProducts,
  createProduct: createProduct,
  fetchVarietysProduct: fetchVarietysProduct,
  createVariety: createVariety,
  fetchCultivationVariety: fetchCultivationVariety,
  createCultivation: createCultivation
};
function fetchProducts() {
  var payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var params = {};
  if (payload && payload.name) params.name = payload.name;
  var path = "/products";

  // eslint-disable-next-line no-undef
  return _http.default.get(path, {
    params: params
  });
}
function createProduct(payload) {
  var path = "/products";

  // eslint-disable-next-line no-undef
  return _http.default.post(path, payload);
}
function fetchVarietysProduct(id) {
  var path = "/varieties/product/".concat(id);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function createVariety(payload) {
  var path = "/varieties";

  // eslint-disable-next-line no-undef
  return _http.default.post(path, payload);
}
function fetchCultivationVariety(id) {
  var path = "/cultivation/variety/".concat(id);

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}
function createCultivation(payload) {
  var path = "/cultivations";

  // eslint-disable-next-line no-undef
  return _http.default.post(path, payload);
}