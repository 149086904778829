"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    class: "page-services-schedule",
    attrs: {
      "return-menu": "/farm-management/dashboard",
      menu: _vm.menuItemsServices.children,
      subtitle: "Serviços"
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("div", {
    staticClass: "schedule-page"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h3", {
    staticClass: "title-destaq-page"
  }, [_vm._v("\n          Agenda\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "header-options"
  }, [_c("filter-date", {
    attrs: {
      "date-start": _vm.serviceDateStart,
      "date-end": _vm.serviceDateEnd
    },
    on: {
      "update:dateStart": function updateDateStart($event) {
        _vm.serviceDateStart = $event;
      },
      "update:date-start": function updateDateStart($event) {
        _vm.serviceDateStart = $event;
      },
      "update:dateEnd": function updateDateEnd($event) {
        _vm.serviceDateEnd = $event;
      },
      "update:date-end": function updateDateEnd($event) {
        _vm.serviceDateEnd = $event;
      },
      updateDate: _vm.updateDateFilter
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "search-bar"
  }, [_c("div", {
    staticClass: "field has-addons"
  }, [_c("input-search", {
    attrs: {
      placeholder: "Pesquisar (Ex.: Serviços/ Operador/ Data)",
      "filter-options": _vm.allPlots,
      "filter-value": _vm.filterValue,
      "add-filter-options": _vm.addFilterOptions
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "field-new-activity"
  }, [_c("b-button", {
    staticClass: "btn-new-activity",
    on: {
      click: function click($event) {
        return _vm.openModalSchedule();
      }
    }
  }, [_vm._v("\n              Novo agendamento\n            ")])], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "services-grid-titles columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("h4", [_vm._v("\n            Agendados\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("h4", [_vm._v("\n            Em andamento\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("h4", [_vm._v("\n            Concluídos\n          ")])])]), _vm._v(" "), !_vm.loading ? _c("div", {
    staticClass: "services-grid columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "item-list-grid"
  }, _vm._l(_vm.scheduledFiltered(_vm.agendedServices), function (service) {
    return _c("card", {
      key: service.id,
      attrs: {
        id: service.id,
        title: service.name,
        "users-list": service.workers,
        date: service.dueDate,
        location: service.plots,
        "alert-class": service.statusColor
      },
      nativeOn: {
        click: function click($event) {
          return _vm.openModalSchedule(service);
        }
      }
    });
  }), 1)]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "item-list-grid"
  }, _vm._l(_vm.scheduledFiltered(_vm.onGoingServices), function (service) {
    return _c("card", {
      key: service.id,
      attrs: {
        id: service.id,
        title: service.name,
        "users-list": service.workers,
        date: service.dueDate,
        location: service.plots,
        "alert-class": service.statusColor
      },
      nativeOn: {
        click: function click($event) {
          return _vm.openModalSchedule(service);
        }
      }
    });
  }), 1)]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "item-list-grid"
  }, _vm._l(_vm.scheduledFiltered(_vm.finishedServices), function (service) {
    return _c("card", {
      key: service.id,
      attrs: {
        id: service.id,
        title: service.name,
        "users-list": service.workers,
        date: service.dueDate,
        location: service.plots,
        "alert-class": service.statusColor
      },
      nativeOn: {
        click: function click($event) {
          return _vm.openModalSchedule(service);
        }
      }
    });
  }), 1)])]) : _vm._e()])])], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;