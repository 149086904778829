"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "field search-input"
  }, [_c("div", {
    staticClass: "control has-icons-right is-clearfix"
  }, [_vm.type === "checkbox" ? _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.computedValue,
      expression: "computedValue"
    }],
    staticClass: "input",
    attrs: {
      placeholder: _vm.placeholder,
      autocomplete: "on",
      type: "checkbox"
    },
    domProps: {
      checked: Array.isArray(_vm.computedValue) ? _vm._i(_vm.computedValue, null) > -1 : _vm.computedValue
    },
    on: {
      change: function change($event) {
        var $$a = _vm.computedValue,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.computedValue = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.computedValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.computedValue = $$c;
        }
      }
    }
  }) : _vm.type === "radio" ? _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.computedValue,
      expression: "computedValue"
    }],
    staticClass: "input",
    attrs: {
      placeholder: _vm.placeholder,
      autocomplete: "on",
      type: "radio"
    },
    domProps: {
      checked: _vm._q(_vm.computedValue, null)
    },
    on: {
      change: function change($event) {
        _vm.computedValue = null;
      }
    }
  }) : _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.computedValue,
      expression: "computedValue"
    }],
    staticClass: "input",
    attrs: {
      placeholder: _vm.placeholder,
      autocomplete: "on",
      type: _vm.type
    },
    domProps: {
      value: _vm.computedValue
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.computedValue = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm._m(0)])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "icon is-right"
  }, [_c("i", {
    staticClass: "mdi mdi-magnify mdi-24px"
  })]);
}];
render._withStripped = true;