"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Card = _interopRequireDefault(require("./Card.vue"));
var _ArrowButton = _interopRequireDefault(require("./ArrowButton.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default2 = {
  name: 'Carousel',
  props: {
    cards: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  components: {
    Card: _Card.default,
    ArrowButton: _ArrowButton.default
  },
  data: function data() {
    return {
      currentElementIndex: 0
    };
  },
  computed: {
    currentElement: function currentElement() {
      return this.cards[this.currentElementIndex];
    },
    reachedMaxLeft: function reachedMaxLeft() {
      return this.currentElementIndex === 0;
    },
    reachedMaxRight: function reachedMaxRight() {
      return this.currentElementIndex === this.cards.length - 1;
    }
  },
  methods: {
    showNextElement: function showNextElement() {
      this.currentElementIndex++;
    },
    showPrevElement: function showPrevElement() {
      this.currentElementIndex--;
    },
    showElement: function showElement(elementIndex) {
      this.currentElementIndex = elementIndex;
    }
  }
};
exports.default = _default2;