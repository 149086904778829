"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var userService = {
  getUsers: getUsers,
  createUser: createUser,
  updateUser: updateUser,
  deleteUser: deleteUser,
  bindUser: bindUser,
  sendContactInfo: sendContactInfo
};
exports.userService = userService;
function getUsers(payload) {
  var params = payload;

  // eslint-disable-next-line no-undef
  return _http.default.get('/users', {
    params: params
  });
}
function createUser(payload) {
  var path = "/register/".concat(payload.role.toLowerCase());
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}
function updateUser(payload, id) {
  var path = "/users/".concat(id);
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.put(path, data);
}
function deleteUser(id) {
  var path = "/users/".concat(id);

  // eslint-disable-next-line no-undef
  return _http.default.delete(path);
}
function bindUser(payload) {
  var path = "/users/bind";
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}
function sendContactInfo(payload) {
  var path = "/users/contact/information";
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}