"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "title-description-item-detail"
  }, [_vm.item.id ? _c("div", {
    staticClass: "rowdetail-one"
  }, [_c("div", {
    staticClass: "name-product"
  }, [_vm._v("\n        Produto "), _c("span", [_vm._v(_vm._s(_vm.productName))])]), _vm._v(" "), _c("div", {
    staticClass: "variety-product"
  }, [_vm._v("\n        Variedade "), _c("span", [_vm._v(_vm._s(_vm.varieties))])])]) : _vm._e(), _vm._v(" "), _vm.item.id ? _c("div", {
    staticClass: "rowdetail-two"
  }, [_c("div", {
    staticClass: "space-one"
  }, [_vm._v("\n        Espaçamento entre plantas "), _c("span", [_vm._v(_vm._s(_vm.item.plantSpacing || "00") + " m")])]), _vm._v(" "), _c("div", {
    staticClass: "space-two"
  }, [_vm._v("\n        Espaçamento rua molhada "), _c("span", [_vm._v(_vm._s(_vm.item.moistRoadSpacing || "00") + " m")])]), _vm._v(" "), _c("div", {
    staticClass: "space-three"
  }, [_vm._v("\n        Espaçamento rua seca "), _c("span", [_vm._v(_vm._s(_vm.item.dryRoadSpacing || "00") + " m")])])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "title-type"
  }, [_vm._v("\n      " + _vm._s(_vm.name) + "\n    ")])]), _vm._v(" "), _c("div", {
    staticClass: "search-bar"
  }, [_c("div", {
    staticClass: "field search-input"
  }, [_c("div", {
    staticClass: "control has-icons-right is-clearfix"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.content,
      expression: "content"
    }],
    staticClass: "input",
    attrs: {
      type: "text",
      autocomplete: "on",
      placeholder: _vm.place
    },
    domProps: {
      value: _vm.content
    },
    on: {
      input: [function ($event) {
        if ($event.target.composing) return;
        _vm.content = $event.target.value;
      }, _vm.handleInput]
    }
  }), _vm._v(" "), _vm._m(0)])])])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "icon is-right"
  }, [_c("i", {
    staticClass: "mdi mdi-magnify mdi-24px"
  })]);
}];
render._withStripped = true;