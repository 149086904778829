"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var types = _interopRequireWildcard(require("../mutation-types"));
var _menu_farm = _interopRequireDefault(require("../mock/menu_farm"));
var _menu_services = _interopRequireDefault(require("../mock/menu_services"));
var _menu_occurrences = _interopRequireDefault(require("../mock/menu_occurrences"));
var _menu_admin = _interopRequireDefault(require("../mock/menu_admin"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var state = {
  items: [_menu_farm.default, _menu_services.default, _menu_occurrences.default, _menu_admin.default],
  farm: _menu_farm.default,
  services: _menu_services.default,
  occurrences: _menu_occurrences.default,
  admin: _menu_admin.default
};
var mutations = _defineProperty({}, types.TOGGLE_EXPAND_MENU_ITEM, function (state, payload) {
  var menuItem = payload.menuItem;
  var expand = payload.expand;
  if (menuItem.children && menuItem.meta) {
    menuItem.meta.expanded = expand;
  }
});
var actions = {
  toggleExpandMenuItem: function toggleExpandMenuItem(_ref, payload) {
    var commit = _ref.commit;
    commit(types.TOGGLE_EXPAND_MENU_ITEM, payload);
  }
};
var _default = {
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;