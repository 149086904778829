"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("page", {
    staticClass: "list-team-page",
    attrs: {
      "return-menu": "/farm-management/dashboard",
      menu: _vm.menuItemsFarm.children,
      "sub-menu": _vm.menuItemsFarm.subMenu,
      subtitle: _vm.menuItemsFarm.meta.title
    }
  }, [_c("template", {
    slot: "context"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("h2", {
    staticClass: "header-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.team.title")) + "\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "search-and-btn-add"
  }, [_c("input-search", {
    attrs: {
      placeholder: _vm.$t("pages.team.placeholder_search"),
      "filter-value": null
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "btn-add-item"
  }, [_c("b-button", {
    attrs: {
      type: "is-text"
    },
    on: {
      click: function click($event) {
        return _vm.openModal();
      }
    }
  }, [_c("span", [_vm._v("\n              " + _vm._s(_vm.$t("pages.team.btn_add_user")) + "\n            ")])])], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "table-items-list"
  }, [_c("b-table", {
    attrs: {
      data: _vm.listUsers,
      striped: true,
      hoverable: true,
      loading: _vm.isLoading,
      "mobile-cards": true,
      paginated: _vm.optionsTable.isPaginated,
      "per-page": _vm.optionsTable.perPage,
      "current-page": _vm.currentPage,
      "pagination-simple": _vm.optionsTable.isPaginationSimple,
      "default-sort-direction": _vm.optionsTable.defaultSortDirection,
      "show-detail-icon": true,
      "custom-detail-row": ""
    },
    on: {
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  }, [[_c("b-table-column", {
    attrs: {
      field: "firstName",
      label: _vm.$t("pages.team.labels.name"),
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("a", {
          on: {
            click: function click($event) {
              return _vm.openModalDetail(props.row);
            }
          }
        }, [_c("div", {
          staticClass: "avatar-user-list"
        }, [props.row.imageUrl ? _c("img", {
          attrs: {
            src: props.row.imageUrl
          }
        }) : _c("img", {
          attrs: {
            src: require("@/assets/images/user-icon.png")
          }
        })]), _vm._v("\n              " + _vm._s(props.row.firstName) + " " + _vm._s(props.row.lastName) + "\n            ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "registration",
      label: _vm.$t("pages.team.labels.registration"),
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("a", {
          on: {
            click: function click($event) {
              return _vm.openModalDetail(props.row);
            }
          }
        }, [_vm._v("\n              " + _vm._s(props.row.registration) + "\n            ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "role",
      label: _vm.$t("pages.team.labels.role")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("a", {
          on: {
            click: function click($event) {
              return _vm.openModalDetail(props.row);
            }
          }
        }, [_vm._v("\n              " + _vm._s(_vm._f("convertRole")(props.row.role)) + "\n            ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "temporaryExpirationDate",
      label: _vm.$t("pages.team.labels.expiration_date")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("a", {
          on: {
            click: function click($event) {
              return _vm.openModalDetail(props.row);
            }
          }
        }, [_vm._v("\n              " + _vm._s(_vm._f("date")(props.row.temporaryExpirationDate || _vm.$t("pages.team.undetermined"))) + "\n            ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "activated",
      label: _vm.$t("pages.team.labels.activated")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("a", {
          on: {
            click: function click($event) {
              return _vm.openModalDetail(props.row);
            }
          }
        }, [_c("span", {
          class: {
            active: props.row.activated,
            disabled: !props.row.activated
          }
        }, [_vm._v("\n                " + _vm._s(_vm._f("translateActivated")(props.row.activated)) + "\n              ")])])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      width: "40"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm.verifyEdit(props.row.role) ? _c("b-dropdown", {
          staticClass: "dopdrown-lis-user",
          attrs: {
            "aria-role": "list",
            position: _vm.position
          }
        }, [_c("b-button", {
          staticClass: "btn-click-dots",
          attrs: {
            slot: "trigger",
            outlined: "",
            "icon-right": "dots-vertical"
          },
          slot: "trigger"
        }), _vm._v(" "), _vm.verifyAccessDeleteDisabled(props.row) ? _c("b-dropdown-item", {
          attrs: {
            "aria-role": "listitem"
          },
          on: {
            click: function click($event) {
              return _vm.prepareAction(_vm.actions.DISABLED, props.row);
            }
          }
        }, [_vm._v("\n                " + _vm._s(props.row.activated ? _vm.$t("pages.team.dropdown.disable") : _vm.$t("pages.team.dropdown.active")) + "\n              ")]) : _vm._e(), _vm._v(" "), props.row.activated ? _c("b-dropdown-item", {
          attrs: {
            "aria-role": "listitem"
          },
          on: {
            click: function click($event) {
              return _vm.prepareAction(_vm.actions.PRINT, props.row);
            }
          }
        }, [_vm._v("\n                " + _vm._s(_vm.$t("pages.team.dropdown.print_id")) + "\n              ")]) : _vm._e(), _vm._v(" "), props.row.activated ? _c("b-dropdown-item", {
          attrs: {
            "aria-role": "listitem"
          },
          on: {
            click: function click($event) {
              return _vm.prepareAction(_vm.actions.EDIT, props.row);
            }
          }
        }, [_vm._v("\n                " + _vm._s(_vm.$t("pages.team.dropdown.edit")) + "\n              ")]) : _vm._e(), _vm._v(" "), props.row.activated && _vm.verifyAccessDeleteDisabled(props.row) ? _c("b-dropdown-item", {
          attrs: {
            "aria-role": "listitem"
          },
          on: {
            click: function click($event) {
              return _vm.prepareAction(_vm.actions.DELETE, props.row);
            }
          }
        }, [_vm._v("\n                " + _vm._s(_vm.$t("pages.team.dropdown.delete")) + "\n              ")]) : _vm._e()], 1) : _vm._e()];
      }
    }])
  })], _vm._v(" "), _c("template", {
    slot: "empty"
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-grey has-text-centered"
  }, [_c("p", [_c("b-icon", {
    attrs: {
      icon: "emoticon-sad",
      size: "is-large"
    }
  })], 1), _vm._v(" "), _c("p", [_vm._v("\n                " + _vm._s(_vm.$t("pages.team.no_results")) + "\n              ")])])])])], 2)], 1), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      id: _vm.idElementPrint
    }
  }, [_c("user-badge", {
    attrs: {
      user: _vm.userSelected
    },
    on: {
      "update:user": function updateUser($event) {
        _vm.userSelected = $event;
      }
    }
  })], 1)])], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;