"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "component-card-map-maker"
  }, [_c("b-field", {
    staticClass: "search-field",
    attrs: {
      label: "Pesquisar",
      horizontal: _vm.horizontal
    }
  }, [_c("gmap-autocomplete", {
    attrs: {
      placeholder: _vm.placeholder
    },
    on: {
      place_changed: _vm.setPlace
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", [_c("gmap-map", {
    ref: "mapMaker",
    staticStyle: {
      width: "100%",
      height: "400px"
    },
    attrs: {
      center: _vm.center,
      zoom: 12,
      "map-type-id": _vm.mapType
    }
  }, [_c("gmap-marker", {
    attrs: {
      position: _vm.position,
      clickable: true,
      draggable: true
    },
    on: {
      drag: _vm.updateCoordinates
    }
  })], 1)], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;