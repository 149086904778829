"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CardPaymentDetails = _interopRequireDefault(require("./CardPaymentDetails"));
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = {
  name: 'CardDescriptionPlan',
  components: {
    CardPaymentDetails: _CardPaymentDetails.default
  },
  props: {
    payment: {
      type: Object,
      required: true
    },
    plan: {
      type: Object,
      required: true
    },
    area: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {
    listDetails: function listDetails() {
      var dateCreated = this.$options.filters.date(this.payment.createdAt);
      var dateExpired = this.$options.filters.date(this.payment.expiresAt);
      var amount = this.$options.filters.convertCentsToReal(this.payment.amount) || 0;
      var totalSize = this.area ? "".concat(this.$options.filters.convertAreaNoUnit(this.area), " hectares") : '--';
      var discount = this.payment.discountId || {};
      var discountName = discount.name || 'Nenhum cupom aplicado';
      var discountCalc = (Number(discount.amount) * Number(amount) / 100).toFixed(2) || 0;
      if (isNaN(discountCalc)) discountCalc = 0;
      var discountValue = discountCalc || 0;
      var amountTotal = Number(amount) + Number(discountCalc);
      var nextMonth = (0, _moment.default)(this.payment.createdAt).add(1, 'M').format('DD/MM/YYYY') || '--';
      return [{
        label: 'Data da contratação:',
        value: dateCreated
      }, {
        label: 'Tamanho Total hectares:',
        value: totalSize
      }, {
        label: 'Cupom desconto:',
        value: discountName
      }, {
        label: 'Valor Total do seu Plano:',
        value: "R$ ".concat(amountTotal)
      }, {
        label: 'Valor Desconto:',
        value: "R$ ".concat(discountValue)
      }, {
        label: 'Valor Atual do seu Plano:',
        value: "R$ ".concat(amount)
      }, {
        label: 'Data da Próxima Fatura:',
        value: nextMonth
      }, {
        label: 'Assinatura válida até:',
        value: dateExpired
      }];
    }
  }
};