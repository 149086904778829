"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.farmService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var farmService = exports.farmService = {
  fetchFarm: fetchFarm,
  deleteFarm: deleteFarm,
  updateFarm: updateFarm,
  createFarm: createFarm,
  fetchSizeFarm: fetchSizeFarm
};
function fetchFarm(params, current, farm) {
  var path = "/farms";
  if (current) path = path + "/".concat(farm.id);

  // eslint-disable-next-line no-undef
  return _http.default.get(path, {
    params: params
  });
}
function deleteFarm(id) {
  var path = "/farms/".concat(id);

  // eslint-disable-next-line no-undef
  return _http.default.delete(path);
}
function updateFarm(payload) {
  var path = "/farms/".concat(payload.id);
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.put(path, data);
}
function createFarm(payload) {
  var path = "/farms";
  var data = payload;

  // eslint-disable-next-line no-undef
  return _http.default.post(path, data);
}
function fetchSizeFarm(farmId) {
  var path = "/farms/".concat(farmId, "/plots/areasize");

  // eslint-disable-next-line no-undef
  return _http.default.get(path);
}