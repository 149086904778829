"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("table", {
    attrs: {
      id: "myTable"
    }
  }, [_c("tr", {
    staticClass: "pagebreak"
  }, [_c("td", [_c("div", {
    staticClass: "mainFlagQRCode"
  })])])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;