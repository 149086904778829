"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "modal-custom-field",
    attrs: {
      width: 598,
      active: true,
      scroll: "keep"
    }
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "media"
  }, [_c("div", {
    staticClass: "media-content"
  }, [_c("button", {
    staticClass: "btn-x-close",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.cancelModal
    }
  }, [_c("i", {
    staticClass: "icon-ic_close"
  })]), _vm._v(" "), _c("p", {
    staticClass: "title"
  }, [_vm._v("\n            Custom Field\n          ")])])]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("form", [_c("app-input", {
    attrs: {
      error: _vm.$v.form.name.$error,
      label: "Nome",
      placeholder: "Nome"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.name, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.name, "$model", $$v);
      },
      expression: "$v.form.name.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.form.label.$error,
      label: "Título",
      placeholder: "Defina o título"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.label, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.label.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.label, "$model", $$v);
      },
      expression: "$v.form.label.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.form.placeholder.$error,
      label: "Placeholder",
      placeholder: "Descrição dentro do campo"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.placeholder, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.placeholder.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.placeholder, "$model", $$v);
      },
      expression: "$v.form.placeholder.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.form.description.$error,
      label: "Descrição",
      placeholder: "Defina uma descrição",
      type: "textarea"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.description, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.description.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.description, "$model", $$v);
      },
      expression: "$v.form.description.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.form.value.$error,
      label: "Valor default (Caso houver)",
      placeholder: "Defina um valor padrão"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.value, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.value.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.value, "$model", $$v);
      },
      expression: "$v.form.value.$model"
    }
  }), _vm._v(" "), _c("app-select", {
    attrs: {
      error: _vm.$v.form.type.$error,
      placeholder: "Selecionar o tipo do campo",
      items: _vm.listTypesCustomFields,
      option: "type",
      "name-item": "name",
      label: "Tipo do campo",
      disabled: _vm.isEditable
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.type, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.type.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.type, "$model", $$v);
      },
      expression: "$v.form.type.$model"
    }
  }), _vm._v(" "), _vm.isContainOptions ? _c("div", {
    staticClass: "options-list-cf"
  }, [_c("b-button", {
    staticClass: "btn-add",
    attrs: {
      type: "is-success",
      "icon-right": "plus"
    },
    on: {
      click: _vm.addOption
    }
  }), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Defina os valores da opções:"
    }
  }), _vm._v(" "), _vm._l(_vm.options, function (itemCF, idx) {
    return _c("div", {
      key: idx,
      staticClass: "options-list-item-cf"
    }, [_c("b-field", {
      attrs: {
        grouped: ""
      }
    }, [_c("app-input", {
      attrs: {
        label: "Ordem",
        placeholder: "Ordem",
        expanded: ""
      },
      model: {
        value: itemCF.order,
        callback: function callback($$v) {
          _vm.$set(itemCF, "order", $$v);
        },
        expression: "itemCF.order"
      }
    }), _vm._v(" "), _c("app-input", {
      attrs: {
        label: "Título",
        placeholder: "Título",
        expanded: ""
      },
      model: {
        value: itemCF.name,
        callback: function callback($$v) {
          _vm.$set(itemCF, "name", $$v);
        },
        expression: "itemCF.name"
      }
    }), _vm._v(" "), _c("app-input", {
      attrs: {
        label: "Valor",
        placeholder: "Valor",
        expanded: ""
      },
      model: {
        value: itemCF.value,
        callback: function callback($$v) {
          _vm.$set(itemCF, "value", $$v);
        },
        expression: "itemCF.value"
      }
    }), _vm._v(" "), _c("b-field", [_c("b-button", {
      staticClass: "btn-remove",
      attrs: {
        type: "is-danger",
        "icon-right": "delete"
      },
      on: {
        click: function click($event) {
          return _vm.removeOption(idx, itemCF);
        }
      }
    })], 1)], 1)], 1);
  })], 2) : _vm._e(), _vm._v(" "), _c("b-field", {
    staticClass: "row-save-form"
  }, [_c("b-button", {
    staticClass: "btn-save-form",
    on: {
      click: _vm.resolved
    }
  }, [_vm._v("\n              Salvar\n            ")])], 1)], 1)])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;