"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productCustomizationService = void 0;
var _http = _interopRequireDefault(require("@/config/http"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var productCustomizationService = exports.productCustomizationService = {
  fetch: fetch,
  create: create
};
function fetch(params) {
  // eslint-disable-next-line no-undef
  return _http.default.get('/product-customizations', {
    params: params
  });
}
function create(data) {
  // eslint-disable-next-line no-undef
  return _http.default.post('/product-customizations', data);
}