"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "answers"
  }, [_c("ol", {
    staticClass: "list-answers"
  }, [_c("li", {
    staticClass: "answer"
  }, [_vm._v("\n      " + _vm._s(_vm.answer) + "\n    ")]), _vm._v(" "), _vm._t("default")], 2)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;