"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.menuItemsServices = exports.menuItemsOccurrences = exports.menuItemsFarm = exports.menuAdmin = void 0;
var menuItemsFarm = function menuItemsFarm(state) {
  return state.menu.farm;
};
exports.menuItemsFarm = menuItemsFarm;
var menuItemsServices = function menuItemsServices(state) {
  return state.menu.services;
};
exports.menuItemsServices = menuItemsServices;
var menuItemsOccurrences = function menuItemsOccurrences(state) {
  return state.menu.occurrences;
};
exports.menuItemsOccurrences = menuItemsOccurrences;
var menuAdmin = function menuAdmin(state) {
  return state.menu.admin;
};
exports.menuAdmin = menuAdmin;