"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("form", {
    staticClass: "component-new-subplot",
    on: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.submitNewSubPlot.apply(null, arguments);
      }
    }
  }, [_c("div", {
    staticClass: "modal-card",
    staticStyle: {
      width: "425px"
    }
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.productive_areas.detail.title_add_new_plot")) + "\n      ")]), _vm._v(" "), _c("b-button", {
    staticClass: "modal-close-x",
    attrs: {
      "icon-right": "close"
    },
    on: {
      click: _vm.closeModal
    }
  })], 1), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("b-field", {
    attrs: {
      horizontal: "",
      label: _vm.$t("pages.productive_areas.detail.text_local")
    }
  }, [_c("p", [_vm._v("\n          " + _vm._s(_vm.plot.name) + "\n        ")])]), _vm._v(" "), _c("b-field", {
    attrs: {
      horizontal: "",
      label: _vm.$t("pages.productive_areas.detail.text_amount")
    }
  }, [_c("b-input", {
    attrs: {
      placeholder: "00",
      type: "number",
      min: "01",
      max: "99999"
    },
    model: {
      value: _vm.$v.number.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.number, "$model", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "$v.number.$model"
    }
  })], 1)], 1), _vm._v(" "), _c("footer", {
    staticClass: "modal-card-foot"
  }, [_c("button", {
    staticClass: "button is-primary"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.productive_areas.detail.text_generate")) + "\n      ")])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;